import { styled } from '@mui/material/styles';
import { useForkRef } from '@mui/material';
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBInput from '../../atoms/BBInput';
import { InputProps } from '../../atoms/BBInput/BBInput';

interface DateFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  error: InputProps['error'];
  size: InputProps['size'];
  isPickerOpen: boolean;
  name: string;
}

interface ContainerOwnerState {
  isPickerOpen: boolean;
}

const Container = styled('div')<{ ownerState: ContainerOwnerState }>(({
  theme,
  ownerState,
}) => {
  const { isPickerOpen } = ownerState;
  const { colours } = theme;

  return {
    '..MuiInputAdornment-root svg path': {
      fill: colours.icon.icon,
    },

    '.Mui-error .MuiInputAdornment-root svg path': {
      fill: colours.icon.error,
    },

    '.Mui-disabled .MuiInputAdornment-root svg path': {
      fill: colours.icon.iconDisabled,
    },

    ...(isPickerOpen && {
      '.MuiFormControl-root': {
        borderColor: colours.borders.focus,
      },
    }),
  };
});

const DateField = forwardRef<HTMLDivElement, DateFieldProps>((props, ref) => {
  const { slots, slotProps, error, size, ...otherProps } = props;

  const fieldProps = useDateField<Dayjs, false, typeof otherProps>({
    ...otherProps,
    enableAccessibleFieldDOMStructure: false,
  });

  const {
    disabled,
    label,
    inputRef,
    name = '',
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClick,
    isPickerOpen,
    InputProps: { endAdornment, ref: containerRef } = {},
  } = fieldProps;

  const handleRef = useForkRef(containerRef, ref);

  return (
    <Container ref={handleRef} ownerState={{ isPickerOpen }}>
      <BBInput
        isDisabled={disabled}
        placeholderLabel={String(label)}
        name={name}
        value={String(value)}
        inputRef={inputRef}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onClick={onClick}
        error={error}
        size={size}
        endAdornment={endAdornment}
      />
    </Container>
  );
});

export default DateField;
