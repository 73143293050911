import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Control } from 'react-hook-form';

//Context
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import BBCheckbox from '../../../atoms/BBCheckbox';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import { FieldSets } from '../../CommonStyles/CommonStyles.styles';
import { getBody2MediumStyles } from '../../../../core/utils/GetTypographyStyles/GetTypographyStyles';
//Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
//Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

interface TPARequestProps {
  control: Control<any, any>;
}

const TPARequestWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing.medium,
    alignSelf: 'stretch',
  };
});

const TitlesContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing.xxxSmall,
    alignSelf: 'stretch',
  };
});

const StyledTitle = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;
  return {
    color: text.text,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
});

const StyledEntitle = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;
  return {
    color: text.textWeak,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
});

const StyledFieldset = styled(FieldSets)(({ theme, layout }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);

  const {
    dimensions: {
      spacing: { xSmall, small },
    },
    colours: { text },
  } = theme;

  return {
    gap: isMobileLayout ? xSmall : small,
    '> .MuiFormControlLabel-root': {
      '> .MuiFormControlLabel-label': {
        ...getBody2MediumStyles(theme),
        color: text.input,
      },
    },
  };
});

const TPARequest: FC<TPARequestProps> = (props: TPARequestProps) => {
  const { control } = props;
  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  return (
    <TPARequestWrapper>
      <TitlesContainer>
        <StyledTitle
          type="medium"
          variant="body2"
          text={translate('createTransaction.partyDetails.tpaDetails.tpa')}
        />
        <StyledEntitle
          type="normal"
          variant="body2"
          text={translate('createTransaction.partyDetails.tpaDetails.entitle1')}
        />
      </TitlesContainer>
      <StyledFieldset layout={layout}>
        <StyledTitle
          type="normal"
          variant="body2"
          text={translate('createTransaction.partyDetails.tpaDetails.entitle2')}
        />
        <ControllerElementWrapper
          name="threeFa"
          control={control}
          label={translate(
            'createTransaction.partyDetails.tpaDetails.sendRequest'
          )}
          component={BBCheckbox}
        />
      </StyledFieldset>
    </TPARequestWrapper>
  );
};

export default TPARequest;
