// Components - Atoms, Molecules, Organisms, Pages
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
import BBDataTable from '../../../../organisms/BBDataTable';
// Hooks
import { useColumns } from './useColumns';
// Utils
import { mapPaymentRequestToPaymentRequestRow } from './utils';

interface PaymentRequestsTableProps {
  paymentRequests: PaymentRequestDetails[];
}

const PaymentRequestsTable = (props: PaymentRequestsTableProps) => {
  const { paymentRequests } = props;

  const { columns } = useColumns();

  const rows = paymentRequests.map(mapPaymentRequestToPaymentRequestRow);

  return (
    <BBDataTable
      columnVisibilityModel={{ createdDate: false }}
      columns={columns}
      rows={rows}
      hideFooter
      defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
    />
  );
};

export default PaymentRequestsTable;
