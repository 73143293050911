import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBLogoWrapper from '../../organisms/BBLogoWrapper';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';
// Constants
import { PROPERTY_PAY_LINK } from '../../../core/utils/Constants/Constants';

interface BBMobileHeaderProps {
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
}

interface VisibleProps {
  showMenu: boolean;
}

interface StyledIconProps extends VisibleProps {}

const HeaderContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { activeDark },
    },
    dimensions: {
      spacing: { small, medium },
    },
  } = theme;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${small}px ${medium}px`,
    alignItems: 'center',
    background: activeDark,
    boxSizing: 'border-box',
  };
});

const StyledIcon = styled(IconButton)<StyledIconProps>(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '10px', // No token present for this value
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    padding: 0,
    ':hover': {
      backgroundColor: 'transparent',
    },
  };
});

const BBMobileHeader: FC<BBMobileHeaderProps> = (
  props: BBMobileHeaderProps
) => {
  const { showMenu, setShowMenu } = props;

  const { tenant } = useContext(TenantContext);

  const BurgerMenuCloseIcon = useMemo(
    () => getIcon(tenant, 'menuClose'),
    [tenant]
  );
  const BurgerMenuOpenIcon = useMemo(
    () => getIcon(tenant, 'menuOpen'),
    [tenant]
  );

  return (
    <HeaderContainer>
      <BBLogoWrapper logoVariant="logoLightMobile" link={PROPERTY_PAY_LINK} />
      <StyledIcon
        onClick={() => setShowMenu(!showMenu)}
        showMenu={showMenu}
        name="headerMenuIconButton"
        data-testid="bb-button-header-menu-icon"
      >
        {showMenu ? <BurgerMenuCloseIcon /> : <BurgerMenuOpenIcon />}
      </StyledIcon>
    </HeaderContainer>
  );
};

export default BBMobileHeader;
