import { MenuItem, MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { useMenuContext } from './BBMenuContext';
// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
    padding: `${spacing.xxxSmall}px ${spacing.xxSmall}px`,
    borderRadius: radius.small,
    border: '1px solid transparent',
    ...getBody2NormalStyles(theme),

    svg: {
      display: 'none',
      height: 20,
      width: 20,
      marginLeft: 'auto',
    },

    '&:hover': {
      backgroundColor: colours.backgrounds.activeLightest,
    },

    '&.Mui-focusVisible': {
      borderColor: colours.borders.active,
      color: colours.text.focus,
      backgroundColor: 'transparent',

      svg: {
        display: 'initial',
        color: colours.icon.active,
      },
    },

    '&.Mui-selected': {
      '&, &:hover, &.Mui-focusVisible': {
        color: colours.text.textAlt,
        backgroundColor: colours.backgrounds.active,

        svg: {
          display: 'initial',
          color: colours.icon.iconAlt,
        },
      },
    },
  };
});

interface BBMenuItemProps extends MenuItemProps {}

const BBMenuItem = (props: BBMenuItemProps) => {
  const { children, ...menuItemProps } = props;
  const { tenant } = useContext(TenantContext);
  const { onClose } = useMenuContext();

  const CheckmarkIcon = useMemo(
    () => getIcon(tenant, 'statusCheckmarkIcon'),
    [tenant]
  );

  const onClick: MenuItemProps['onClick'] = (event) => {
    onClose();
    props.onClick?.(event);
  };

  return (
    <StyledMenuItem {...menuItemProps} onClick={onClick}>
      {children}
      <CheckmarkIcon />
    </StyledMenuItem>
  );
};

export default memo(BBMenuItem);
