import { ComponentType, memo, useContext, useMemo } from 'react';
import {
  DataGrid,
  GridColDef,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBDataTablePagination from './BBDataTablePagination';
import SortDropdownComponent from './SortDropdown';
// Hooks
import { useResponsiveLayout } from './useResponsiveLayout';
import { useTableSort } from './useTableSort';
// Types
import { BBDataTableProps } from './types';
// Utils
import {
  getBodySmallBoldStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

interface DataTableOwnerState {
  numberOfResponsiveCells: number;
}

const DataTable = styled(DataGrid)<{ ownerState: DataTableOwnerState }>(({
  theme,
  disableColumnResize,
  ownerState,
}) => {
  const {
    dimensions: { spacing, radius },
    colours,
  } = theme;
  const { numberOfResponsiveCells } = ownerState;

  return {
    border: 'none',
    borderRadius: 0,
    color: colours.text.text,

    '.MuiDataGrid-columnHeader': {
      padding: `${spacing.xSmall}px 0`,

      '&[aria-colindex="1"]': {
        paddingLeft: spacing.small,
        paddingRight: spacing.xxSmall,
      },

      '&--last': {
        paddingRight: spacing.small,
      },

      '&:focus, &:focus-within': {
        outline: 'none',
      },
    },

    '.MuiDataGrid-columnHeaderTitle': {
      userSelect: 'none',
      ...getBodySmallBoldStyles(theme),
    },

    '.MuiDataGrid-columnSeparator': {
      ...(disableColumnResize && {
        display: 'none',
      }),
    },

    '.MuiDataGrid-row': {
      borderRadius: radius.card,
      alignItems: 'center',
      boxSizing: 'border-box',

      '&:hover': {
        backgroundColor: 'initial',
      },

      '&:nth-of-type(odd)': {
        backgroundColor: colours.backgrounds.bg,
      },

      // NOTE: We add font-specific properties here, separately from the rest of the
      // cell styles, to achieve a higher weight specificity for the selector,
      // otherwise there are some font properties set by the Mui DataGrid component
      // that overwrite these styles
      '.MuiDataGrid-cell': {
        ...getBodySmallNormalStyles(theme),
      },
    },

    '.MuiDataGrid-cell': {
      border: 'none',
      padding: `${spacing.xSmall}px 0`,
      paddingRight: spacing.xxSmall,

      '&[aria-colindex="1"]': {
        paddingLeft: spacing.small,
      },

      '&:last-of-type': {
        paddingRight: spacing.small,
      },

      '&:focus, &:focus-within': {
        outline: 'none',
      },
    },

    '.MuiDataGrid-topContainer::after': {
      display: 'none',
    },

    '.MuiDataGrid-iconButtonContainer': {
      width: 'initial',
      visibility: 'initial',

      '.MuiButtonBase-root': {
        padding: `0 ${spacing.xxxSmall}px`,

        '&:hover': {
          backgroundColor: 'initial',
        },
      },

      svg: {
        height: 16,
        width: 16,
        color: colours.icon.icon,
      },
    },

    '.MuiDataGrid-footerContainer': {
      paddingTop: spacing.large,
      borderTop: 'none',
      justifyContent: 'start',
      minHeight: 'initial',
    },

    [theme.breakpoints.between('md', 'lg')]: {
      '.MuiDataGrid-footerContainer': {
        paddingTop: spacing.medium,
      },
    },

    [theme.breakpoints.down('lg')]: {
      '.MuiDataGrid-topContainer': {
        display: 'none',
      },

      '.MuiDataGrid-scrollbar--horizontal': {
        display: 'none',
      },

      '.MuiDataGrid-filler': {
        display: 'none',
      },

      '.MuiDataGrid-virtualScrollerContent': {
        // NOTE: Unfortunately, the Mui DataGrid component sets width as an
        // inline style on these elements, so we have to force our preferred value
        width: 'initial !important',
      },

      '.MuiDataGrid-virtualScrollerRenderZone': {
        width: '100%',
      },

      '.MuiDataGrid-row': {
        flexWrap: 'wrap',
        padding: `${spacing.xSmall}px ${spacing.small}px`,
        width: '100%',
      },

      '.MuiDataGrid-cell': {
        [`&[aria-colindex="1"], &:nth-of-type(-n + ${
          numberOfResponsiveCells + 1
        })`]: {
          minWidth: 'initial',
          maxWidth: 'initial',
          flexBasis: '100%',
        },

        '&, &[aria-colindex="1"], &:last-of-type': {
          padding: 0,
        },

        '&[data-field=actions]': {
          marginLeft: 'auto',
        },
      },
    },

    [theme.breakpoints.down('md')]: {
      '.MuiDataGrid-footerContainer': {
        paddingTop: spacing.small,
      },
    },
  };
});

const withoutProps = (Component: ComponentType) => () => <Component />;

const BBDataTable = <R extends GridValidRowModel>(
  props: BBDataTableProps<R>
) => {
  const { columns, slots, defaultSortModel, slotProps = {} } = props;

  const SortDropdown = slots?.sortDropdown ?? SortDropdownComponent;

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);
  const apiRef = useGridApiRef();

  const isDesktopLayout: boolean = layout === 'desktop';
  const rowHeight: number | undefined = isDesktopLayout
    ? props.rowHeight
    : undefined;

  const { numberOfResponsiveCells, onTableResize } =
    useResponsiveLayout(apiRef);
  const { dropdownProps, tableProps } = useTableSort(apiRef, props);

  const [SortIcon, SortUpIcon, SortDownIcon] = useMemo(
    () => [
      withoutProps(getIcon(tenant, 'chevronSort')),
      withoutProps(getIcon(tenant, 'chevronSortUp')),
      withoutProps(getIcon(tenant, 'chevronSortDown')),
    ],
    [tenant]
  );

  return (
    <div>
      {!isDesktopLayout && (
        <SortDropdown
          {...dropdownProps}
          {...slotProps.sortDropdown}
          onChange={(...args) => {
            dropdownProps.onChange(...args);
            slotProps.sortDropdown?.onChange?.(...args);
          }}
        />
      )}

      <DataTable
        apiRef={apiRef}
        disableAutosize
        disableColumnMenu
        disableColumnResize
        pageSizeOptions={[]}
        autoHeight
        getRowHeight={() => 'auto'}
        rowHeight={rowHeight}
        showColumnVerticalBorder
        columnHeaderHeight={46}
        disableRowSelectionOnClick
        {...props}
        columns={columns as readonly GridColDef[]}
        slots={{
          columnUnsortedIcon: SortIcon,
          columnSortedAscendingIcon: SortUpIcon,
          columnSortedDescendingIcon: SortDownIcon,
          pagination: BBDataTablePagination,
          ...props.slots,
        }}
        onResize={(...args) => {
          onTableResize(...args);
          props.onResize?.(...args);
        }}
        onSortModelChange={(model, details) => {
          tableProps.onSortModelChange(model);
          props.onSortModelChange?.(model, details);
        }}
        onStateChange={(...args) => {
          tableProps.onStateChange(...args);
          props.onStateChange?.(...args);
        }}
        ownerState={{ numberOfResponsiveCells }}
        initialState={{
          ...props.initialState,
          sorting: {
            sortModel: defaultSortModel,
            ...props.initialState?.sorting,
          },
        }}
      />
    </div>
  );
};

export default (memo as <T>(component: T) => T)(BBDataTable);
