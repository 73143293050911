import { createTheme } from '@mui/material/styles';
import merge from 'deepmerge';

import WhiteLabelTheme from './whitelabel/theme';
import WhitelabelDefaultTranslations from './whitelabel/translations.en-gb.json';
import {
  BoxShadows,
  Colours,
  Dimensions,
  FontFaceDetails,
  GlobalStyles,
  ZIndexes,
} from '../../types/ThemeConfigTypes';
import { TypographyVariants } from '../../types/TypographyTypes';
import { LANG_CODE_MAPPING } from '../../utils/Constants/Constants';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    portal?: {
      assets?: string;
      logo?: string;
      minimisedlogo?: string;
      logoBGSize?: string;
      header?: {
        background?: string;
      };
    };
    customTypography: TypographyVariants;
    colours?: Colours;
    fontFace?: FontFaceDetails[];
    globalStyles?: GlobalStyles;
    zindex: ZIndexes;
    boxShadows?: BoxShadows;
  }

  interface Theme {
    portal: {
      assets?: string;
      logo?: string;
      minimisedlogo?: string;
      logoBGSize?: string;
      header?: {
        background?: string;
      };
    };
    customTypography: TypographyVariants;
    colours: Colours;
    dimensions: Dimensions;
    fontFace: FontFaceDetails[];
    globalStyles: GlobalStyles;
    zindex: ZIndexes;
    boxShadows: BoxShadows;
  }
}

export const getTenant = () =>
  new Promise((resolve) => {
    resolve('currenciesdirect');
  });

export const getTenantConfig = () =>
  new Promise((resolve) => {
    const WhiteLabelAssigned: any = WhiteLabelTheme;

    getTenant().then((tenant) => {
      if (tenant) {
        import(`./${tenant}/theme`)
          .then(({ default: TenantTheme }) => {
            const merged: any = merge(WhiteLabelAssigned, TenantTheme, {
              arrayMerge: (destinationArray, sourceArray, options) =>
                sourceArray,
            });
            const theme = createTheme(merged);
            resolve({
              tenant,
              theme,
            });
          })
          .catch(() => {
            resolve({
              tenant,
              theme: createTheme(WhiteLabelAssigned),
            });
          });
      } else {
        resolve({
          tenant,
          theme: createTheme(WhiteLabelAssigned),
        });
      }
    });
  });

export const getTenantSettings = () =>
  new Promise((resolve) => {
    let settings: any = {}; //TODO add settings type

    getTenant().then((tenant) => {
      if (!tenant) {
        resolve(settings);
      }
      if (tenant) {
        import(`./${tenant}/config`)
          .then((tenantSettings) => {
            resolve(tenantSettings);
          })
          .catch(() => {
            resolve(settings);
          });
      }
    });
  });

export const getTranslations = (
  language: string | undefined = LANG_CODE_MAPPING['EN']
) =>
  new Promise((resolve) => {
    let currentLanguage = language?.toLowerCase();
    import(`./whitelabel/translations.${currentLanguage}.json`)
      .then((WhitelabelLanguageTranslations) => {
        const OnlyWhitelabelTranslations = {
          ...WhitelabelDefaultTranslations,
          ...(currentLanguage !== 'en-gb' && WhitelabelLanguageTranslations),
        };
        getTenant().then((tenant) => {
          if (!tenant) {
            resolve(OnlyWhitelabelTranslations);
          }
          import(`./${tenant}/translations.en-gb.json`)
            .then((TenantDefaultTranslations) => {
              const WhitelabelWithTenantDefaultTranslations = merge(
                WhitelabelDefaultTranslations,
                TenantDefaultTranslations
              );
              let DefaultsWithTenantLanguageTranslations = merge(
                WhitelabelWithTenantDefaultTranslations,
                WhitelabelLanguageTranslations
              );

              if (currentLanguage !== 'en-gb') {
                DefaultsWithTenantLanguageTranslations = merge(
                  WhitelabelWithTenantDefaultTranslations,
                  WhitelabelLanguageTranslations
                );
              } else {
                DefaultsWithTenantLanguageTranslations =
                  WhitelabelWithTenantDefaultTranslations;
              }

              import(`./${tenant}/translations.${currentLanguage}.json`)
                .then((TenantLanguageTranslations) => {
                  resolve(
                    currentLanguage !== 'en-gb'
                      ? merge(
                          DefaultsWithTenantLanguageTranslations,
                          TenantLanguageTranslations
                        )
                      : DefaultsWithTenantLanguageTranslations
                  );
                })
                .catch(() => {
                  const partialLanguageCode = currentLanguage?.split('-')[0];
                  import(`./${tenant}/translations.${partialLanguageCode}.json`)
                    .then((TenantLanguageTranslations) => {
                      resolve(
                        merge(
                          DefaultsWithTenantLanguageTranslations,
                          TenantLanguageTranslations
                        )
                      );
                    })
                    .catch(() => {
                      resolve(DefaultsWithTenantLanguageTranslations);
                    });
                });
            })
            .catch(() => {
              resolve(OnlyWhitelabelTranslations);
            });
        });
      })
      .catch(() => {
        console.log('Error loading translations file');
      });
  });
