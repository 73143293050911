// Components - Atoms, Molecules, Organisms, Pages
import PPStatus, { StatusType } from '../../../atoms/PPStatus';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { TransactionStatus as TransactionStatusType } from '../../../../core/types/TransactionTypes';

const transactionStatusToStatusMapping: Record<
  TransactionStatusType,
  StatusType
> = {
  [TransactionStatusType.Draft]: 'draft',
  [TransactionStatusType.Open]: 'open',
  [TransactionStatusType.Completed]: 'completed',
  [TransactionStatusType.Cancelled]: 'cancelled',
  [TransactionStatusType.InProgress]: 'pending',
};

interface TransactionStatusProps {
  status: TransactionStatusType;
}

const TransactionStatus = (props: TransactionStatusProps) => {
  const { status } = props;

  const { translate } = useTranslations();

  return (
    <PPStatus status={transactionStatusToStatusMapping[status]}>
      {translate(`statuses.transaction.${status.toLowerCase()}`)}
    </PPStatus>
  );
};

export default TransactionStatus;
