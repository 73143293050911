import { ImageObject } from '../../../types/ImageOrgTypes';

// CD specific images
import MobileAppImg from './assets/images/mobile-app-img.png';
import { LoginBackgroundImage } from '../whitelabel/commonImages';

export const CDImageObject: ImageObject = {
  mobileApp: MobileAppImg,
  loginBgImage: LoginBackgroundImage,
};
