import { Disbursement } from '../../../../core/types/DisbursementTypes';

export type ConfirmationViewType = 'confirmation-screen' | 'completed-screen';

export enum PaymentMethods {
  Wallet = 'Wallet',
  Bank = 'Manual Bank Transfer',
}
export type paymentMethodType = PaymentMethods;

export type RouterParams = {
  id: string;
  disbursementId: string;
};

export type ConfirmationRouterParams = {
  id: string;
};

export type DataProps = {
  paymentMethod: paymentMethodType;
};

export interface ConfirmationScreenProps {
  setViewType: (viewType: ConfirmationViewType) => void;
  isFetching: boolean;
  isLoading: boolean;
  data?: Disbursement;
  error: any;
}

export interface ListItemType {
  id: number;
  label: string;
  value: string;
}

export interface CompletedScreenProps
  extends Omit<ConfirmationScreenProps, 'setViewType'> {}
