import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBStepPanel from '../../../organisms/BBStepPanel';
import RecipientDetailsFrom from './RecipientDetailsForm';
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
import Page from '../../../organisms/Page';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import { RouterParams } from './types';
//Api wrappers
import { useGetDisbursementByIdQuery } from '../../../../redux/api/disbursements';

const RecipientDetails: FC = () => {
  const { id, disbursementId } = useParams<RouterParams>();

  const {
    data: disbursementDetails,
    isLoading,
    error,
  } = useGetDisbursementByIdQuery(
    disbursementId ? Number(disbursementId) : skipToken
  );

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const [showProtectingFromFraudSection, setShowProtectingFromFraudSection] =
    useState(false);

  const activeStep: number = 1;

  return (
    <Page isLoading={isLoading} error={error}>
      {!showProtectingFromFraudSection && (
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={translate('disbursement.recipientDetails.title')}
          layout={layout}
        />
      )}
      <MainContainer layout={layout}>
        <RecipientDetailsFrom
          activeStep={activeStep}
          showProtectingFromFraudSection={showProtectingFromFraudSection}
          setShowProtectingFromFraudSection={setShowProtectingFromFraudSection}
          disbursementId={disbursementId ?? ''}
          transactionId={id ?? ''}
          disbursementDetails={disbursementDetails ?? null}
        />
        {!showProtectingFromFraudSection && (
          <BBStepPanel
            activeStep={activeStep}
            stepperType={'disbursement'}
            idValues={{
              transactionId: id,
              disbursementId: disbursementId,
            }}
          />
        )}
      </MainContainer>
    </Page>
  );
};

export default RecipientDetails;
