import { useTranslations } from '../../../core/hooks/useTranslations';
import BBConfirmationModal from '../../molecules/BBModal/BBConfirmationModal';

interface RemovePartyConfirmationModalProps {
  onContinue: () => void;
  onCancel: () => void;
}

const RemovePartyConfirmationModal = (
  props: RemovePartyConfirmationModalProps
) => {
  const { onContinue, onCancel } = props;

  const { translate } = useTranslations();

  return (
    <BBConfirmationModal
      isOpen
      handleClose={onCancel}
      message={translate('createTransaction.removePartyConfirmation.title')}
      additionMessage={translate(
        'createTransaction.removePartyConfirmation.message'
      )}
      primaryBtnLabel={translate(
        'createTransaction.removePartyConfirmation.continueButtonText'
      )}
      secondaryBtnLabel={translate(
        'createTransaction.removePartyConfirmation.cancelButtonText'
      )}
      handlePrimaryBtnClick={onContinue}
      handleSecondaryBtnClick={onCancel}
    />
  );
};

export default RemovePartyConfirmationModal;
