import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components - Atoms, Molecules, Organisms, Pages
import { FundingWarningView } from './FundingWarningView';
import PaymentMethodView from './PaymentMethodView';
import NavigationBlocker from '../../../../organisms/NavigationBlocker';
// Validation Schema or files in the same folder
import { validations } from './validations';
// Hooks
import { useWallets } from '../shared/hooks/useWallets';
// Types
import { FormValues, View } from './types';
import { Payment } from '../../../../../core/types/PaymentTypes';
import {
  PaymentMethods,
  PaymentRequestDetails,
} from '../../../../../core/types/PaymentRequestsTypes';
// RTK Slice
import { getUserProfileDetails } from '../../../../../redux/modules/userProfileDetailsSlice';
// API Wrappers
import { useUpsertPaymentMutation } from '../../../../../redux/api';
// Utils
import {
  mapFormValuesToPaymentUpsertRequest,
  mapPaymentToFormValues,
} from './utils';

const defaultValues: FormValues = {
  type: undefined,
};

interface PaymentMethodContentProps {
  payment?: Payment;
  paymentRequest?: PaymentRequestDetails;
}

const PaymentMethodContent = (props: PaymentMethodContentProps) => {
  const { payment } = props;
  const { crmAccountId } = useSelector(getUserProfileDetails);

  const [view, setView] = useState<View>({ type: 'form' });

  const navigate = useNavigate();

  const form = useForm<FormValues>({
    // @ts-ignore
    resolver: yupResolver(validations),
    mode: 'all',
    defaultValues: payment ? mapPaymentToFormValues(payment) : defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = form;

  const shouldBlockNavigation: boolean = isDirty && !isSubmitting;
  const paymentRequest: PaymentRequestDetails | undefined =
    payment?.paymentRequest ?? props.paymentRequest;

  const transactionId = props.paymentRequest?.transactionId;

  const { wallets } = useWallets(transactionId);

  const [upsertPayment] = useUpsertPaymentMutation();

  const onSubmit = async (data: FormValues) => {
    try {
      const paymentRequestId = paymentRequest?.id;
      const walletId = data.type === 'wallet' ? data.walletId : undefined;

      const { id } = await upsertPayment({
        ...mapFormValuesToPaymentUpsertRequest(data),
        id: payment?.id,
        paymentRequestId,
        crmAccountId,
        walletId,
      }).unwrap();

      navigate(`/payments/${id}/details`);
    } catch {}
  };

  const onContinue = async (data: FormValues) => {
    if (
      data.type !== 'wallet' ||
      paymentRequest?.accountToBeFunded?.paymentMethod !== PaymentMethods.Wallet
    ) {
      await handleSubmit(onSubmit)();
      return;
    }

    const wallet = wallets.find(
      (wallet) => wallet.walletNumber === data.walletId
    );

    if (wallet?.walletCurrency === paymentRequest?.currency) {
      setView({ type: 'warning', wallet });
      return;
    }

    await handleSubmit(onSubmit)();
  };

  return (
    <>
      <FormProvider {...form}>
        {view.type === 'form' && (
          <PaymentMethodView
            onContinue={onContinue}
            paymentRequest={paymentRequest}
            transactionId={transactionId}
          />
        )}

        {view.type === 'warning' && (
          <FundingWarningView
            paymentRequest={paymentRequest}
            wallet={view.wallet}
            onBack={() => setView({ type: 'form' })}
            onContinue={handleSubmit(onSubmit)}
          />
        )}
      </FormProvider>

      {shouldBlockNavigation && <NavigationBlocker />}
    </>
  );
};

export default PaymentMethodContent;
