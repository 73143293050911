// Types
import { GetBankAccountPaymentDetailsRequest } from '../../core/types/ApiTypes';
import { BankAccountPaymentDetails } from '../../core/types/BankAccountTypes';
import {
  AddBankAccountRequest,
  BankAccountDetailsResponse,
  BankAccountFieldsResponse,
  GetBankAccountFieldsRequestData,
} from '../../core/types/PaymentRequestsTypes';
// API Wrappers
import { api } from './api';

const bankDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get bank account fields based on countryCode and currency
    getBankAccountFields: builder.query<
      BankAccountFieldsResponse,
      GetBankAccountFieldsRequestData
    >({
      query: ({ countryCode, currency }) =>
        `/propertypay-services/bankAccountLabels/${countryCode}/${currency}`,
      providesTags: (_result, _error, { countryCode, currency }) => [
        { type: 'BankAccountFields', countryCode, currency },
      ],
    }),

    // Get bank accounts
    getBankAccounts: builder.query<BankAccountDetailsResponse[], void>({
      query: () => '/propertypay-services/bankAccounts',
      providesTags: (_result, _error) => [{ type: 'BankAccountList' }],
    }),

    // Add new recipient/ bank account
    addBankAccount: builder.mutation<
      BankAccountDetailsResponse,
      AddBankAccountRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/bankAccounts',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'BankAccountList' }],
    }),

    getBankAccountPaymentDetails: builder.query<
      BankAccountPaymentDetails,
      GetBankAccountPaymentDetailsRequest
    >({
      query: (data) => ({
        url: `propertypay-services/bankAccounts/rds/${data.country}/${data.currency}`,
      }),
    }),
  }),
});

export const {
  useGetBankAccountFieldsQuery,
  useGetBankAccountsQuery,
  useAddBankAccountMutation,
  useGetBankAccountPaymentDetailsQuery,
} = bankDetailsApi;
