import { FC, useMemo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import Page from '../Page';
import { WeakerText } from '../../pages/CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../core/hooks';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { ConfirmWalletBalance } from '../../../core/types/AvailableBalanceTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getISOToGMTDateFormat } from '../../../core/utils/Format';
// API Wrappers
import { useGetAvailableBalanceQuery } from '../../../redux/api';

interface AvailableFundDetailsProps {
  transactionId: string | undefined;
}

interface IconContainerProps {
  fundsAvailable: boolean;
}

interface AvailableFundsContainerProps extends LayoutProps {}

const AvailableFundsContainer = styled(Box)<AvailableFundsContainerProps>(({
  theme,
  layout,
}) => {
  const {
    dimensions: { spacing, radius },
    colours: { borders },
  } = theme;

  const isDesktopLayout = layout === 'desktop';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    padding: spacing.xSmall,
    flex: 1,
    ...(!isDesktopLayout && {
      alignSelf: 'stretch',
    }),
    border: `1px solid ${borders.border}`,
    borderRadius: radius.small,
  };
});

const SideHeadingContainer = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const { colours } = theme;

  return {
    padding: 0,
    color: colours.icon.active,

    '&.Mui-focusVisible': {
      outline: `1px solid ${colours.borders.focus}`,
      outlineOffset: 2,
    },

    svg: {
      width: 20,
      height: 20,
    },
  };
});

const FundDetailsContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});

const FundDetails = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.xxSmall,
    alignItems: 'center',
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
  } = theme;
  return {
    color: borderLighter,
    width: 1,
    margin: 0,
  };
});

const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fundsAvailable',
})<IconContainerProps>(({ theme, fundsAvailable }) => {
  const {
    colours: {
      backgrounds: { success, error },
    },
  } = theme;

  return {
    display: 'flex',
    color: fundsAvailable ? success : error,
  };
});

const AvailableFundDetails: FC<AvailableFundDetailsProps> = (
  props: AvailableFundDetailsProps
) => {
  const { transactionId } = props;

  const { translate } = useTranslations();
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const { data, isFetching, refetch } = useGetAvailableBalanceQuery(
    transactionId ? Number(transactionId) : skipToken
  );

  const [RotateIcon, ErrorIcon, FundsAvailableIcon] = useMemo(
    () => [
      getIcon(tenant, 'rotate'),
      getIcon(tenant, 'statusError'),
      getIcon(tenant, 'statusCheckmarkOutlineIcon'),
    ],
    [tenant]
  );

  return (
    <AvailableFundsContainer layout={layout}>
      <SideHeadingContainer>
        <BBText
          variant="subtitle1"
          type="bold"
          text={translate('availableFunds.heading')}
        />
        <StyledIconButton
          disabled={isFetching}
          onClick={refetch}
          data-testid="available-funds-refetch-button"
        >
          <RotateIcon />
        </StyledIconButton>
      </SideHeadingContainer>
      <Page isLoading={isFetching}>
        <FundDetailsContainer>
          {data?.confirmWalletBalancesList &&
          data?.confirmWalletBalancesList.length > 0 ? (
            <>
              {data?.confirmWalletBalancesList?.map(
                (details: ConfirmWalletBalance, index) => (
                  <FundDetails key={details.crmAccountName + index}>
                    <BBText
                      variant="subtitle1"
                      type="normal"
                      text={`${details.crmAccountName} (${details.currency})`}
                    />
                    <StyledDivider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                    <IconContainer fundsAvailable={details.areFundsAvailable}>
                      {details.areFundsAvailable ? (
                        <FundsAvailableIcon />
                      ) : (
                        <ErrorIcon />
                      )}
                    </IconContainer>
                    <BBText
                      variant="subtitle1"
                      type="normal"
                      text={translate(
                        `availableFunds.${details.areFundsAvailable ? 'fundsAvailable' : 'topUpWallet'}`
                      )}
                    />
                  </FundDetails>
                )
              )}
            </>
          ) : (
            <BBText
              variant="subtitle1"
              type="normal"
              text={translate('availableFunds.noFundsAvailable')}
            />
          )}
        </FundDetailsContainer>
        <WeakerText
          variant="subtitle2"
          type="normal"
          text={translate('availableFunds.fundsCheckCompletionDateTime', {
            dateTime: getISOToGMTDateFormat(
              data?.currentDateAndTime,
              translate('availableFunds.dateTimeSeparator')
            ),
          })}
        />
      </Page>
    </AvailableFundsContainer>
  );
};

export default AvailableFundDetails;
