import { FC } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

interface BBLinearProgressBarProps {
  value: number;
  marginBottom?: string;
}

const StyledLinearProgressBar = styled(
  LinearProgress
)<BBLinearProgressBarProps>(({ theme, marginBottom }) => {
  const {
    colours: {
      backgrounds: { active, activeLight },
    },
  } = theme;

  return {
    height: '6px',
    backgroundColor: activeLight,
    borderRadius: '8px',
    marginBottom: marginBottom ? marginBottom : '15px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: active,
      borderRadius: '8px',
    },
  };
});

const BBLinearProgressBar: FC<BBLinearProgressBarProps> = (
  props: BBLinearProgressBarProps
) => {
  return (
    <>
      <StyledLinearProgressBar variant="determinate" {...props} />
    </>
  );
};

export default BBLinearProgressBar;
