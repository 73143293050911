import { styled } from '@mui/material/styles';

export const Header = styled('header')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});
