import { FC, memo, MouseEvent, KeyboardEvent } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// Components - Atoms, Molecules, Organisms, Pages
import BBAddressList from './BBAddressList';
import BBEnterManualAddressLink from './BBEnterManualAddressLink';
// Types
import {
  AddressItemsType,
  AddressItem,
} from '../../../core/types/addressLookupTypes';

interface BBAddressLookupPopperProps {
  addressItems: AddressItemsType;
  onSelectAddress: (entry: AddressItem) => void;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  open: boolean;
  anchorEl: HTMLElement | null | undefined;
  hasAddressResults: boolean;
}

const StyledPopper = styled(Popper)<PopperProps>(({ theme }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
      borders: { active },
    },
    dimensions: { radius },
    zindex: { addressLookupPopper },
  } = theme;

  const smallRadius = radius.small + 'px';

  return {
    backgroundColor: bgAlt,
    border: `1.5px solid ${active}`,
    borderTop: 'none',
    borderRadius: `0 0 ${smallRadius} ${smallRadius}`,
    zIndex: addressLookupPopper,
  };
});

const PopperWindow = (props: PopperProps) => {
  return (
    <StyledPopper
      {...props}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 10,
            fallbackPlacements: ['bottom'],
          },
        },
      ]}
    />
  );
};

const StyledPopperWindow = styled(PopperWindow)(() => {
  return {
    width: '100%',
    inset: '-1.5px auto auto 0px !important',
    boxSizing: 'border-box',
  };
});

const AddressLookupContainer = styled(Box)<BoxProps>(({ theme }) => {
  const {
    colours: {
      scroll: { thumbBackground, trackBackground },
    },
    dimensions: {
      spacing: { xxxSmall, small },
      radius,
    },
    zindex: { scrollBarThumb },
  } = theme;

  const COLOUR_SCROLL_THUMB_BACKGROUND: string = thumbBackground;
  const COLOUR_SCROLL_TRACK_BACKGROUND: string = trackBackground;

  const smallSpacing: string = small + 'px';

  return {
    padding: `${xxxSmall}px ${smallSpacing} ${smallSpacing}`,
    maxHeight: '204px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLOUR_SCROLL_THUMB_BACKGROUND,
      borderRadius: radius.medium,
      zIndex: scrollBarThumb,
      height: 30,
    },
    '&::-webkit-scrollbar-track': {
      background: COLOUR_SCROLL_TRACK_BACKGROUND,
      width: 24,
      borderRadius: radius.medium,
    },
    '& .MuiList-padding': {
      padding: 0,
    },
  };
});

const BBAddressLookupPopper: FC<BBAddressLookupPopperProps> = ({
  addressItems,
  onSelectAddress,
  setIsManualEntry,
  setCountryChange,
  open,
  anchorEl,
  hasAddressResults,
}: BBAddressLookupPopperProps) => {
  const handleManualAddressClick = (
    event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
  };

  return (
    <StyledPopperWindow
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      disablePortal={true} //The `children` will be under the DOM hierarchy of the parent component.
    >
      <AddressLookupContainer>
        <BBAddressList
          addressList={addressItems}
          onSelectAddress={onSelectAddress}
          hasAddressResults={hasAddressResults}
        />
      </AddressLookupContainer>
      <BBEnterManualAddressLink
        handleManualAddressClick={handleManualAddressClick}
      />
    </StyledPopperWindow>
  );
};

export default memo(BBAddressLookupPopper);
