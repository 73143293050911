import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { GridColumn } from '../../../../organisms/BBDataTable';
import PaymentRequestStatus from '../../../../organisms/PaymentRequestStatus/PaymentRequestStatus';
import TableRowAction from '../../../../organisms/TableRowAction';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { PaymentRequestRow } from './types';
import { PaymentMethods } from '../../../../../core/types/PaymentRequestsTypes';
// Utils
import {
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../../core/utils/Format';

const getColumnTranslationKey = (value: string) =>
  `buyer.transactionDetails.paymentRequests.columns.${value}`;

export const useColumns = () => {
  const { translate } = useTranslations();

  const columns = useMemo<GridColumn<PaymentRequestRow>[]>(() => {
    return [
      {
        field: 'paymentType',
        headerName: translate(getColumnTranslationKey('paymentType')),
        flex: 1,
        minWidth: 295,
        valueFormatter: (_, row) => {
          return (
            translate(
              `paymentRequest.types.${row.paymentType.toLowerCase()}`
            ) ?? getFormattedValue(row.paymentTypeDescription)
          );
        },
      },
      {
        field: 'status',
        headerName: translate(getColumnTranslationKey('status')),
        flex: 1,
        minWidth: 130,
        maxWidth: 150,
        renderCell: ({ row }) => <PaymentRequestStatus status={row.status} />,
      },
      {
        field: 'amount',
        headerName: translate(getColumnTranslationKey('amount')),
        flex: 1,
        minWidth: 120,
        maxWidth: 150,
        sortType: 'number',
        valueFormatter: (_, row) =>
          getFormattedCurrencyValue(row.amount, row.currency),
      },
      {
        field: 'recipient',
        headerName: translate(getColumnTranslationKey('recipient')),
        flex: 1,
        minWidth: 190,
        maxWidth: 200,
        valueFormatter: (value, row) => {
          if (row.recipientType === PaymentMethods.Wallet) {
            return translate(
              `buyer.transactionDetails.paymentRequests.walletRecipient`,
              { currency: row.currency }
            );
          }

          return getFormattedValue(value);
        },
      },
      {
        field: 'dueDate',
        headerName: translate(getColumnTranslationKey('dueDate')),
        flex: 1,
        minWidth: 90,
        maxWidth: 150,
        sortType: 'date',
        valueFormatter: getFormattedDate,
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: 100,
        renderCell: ({ row }) => {
          return (
            <TableRowAction to={`/payment-requests/${row.id}`}>
              {translate('buyer.transactionDetails.paymentRequests.action')}
            </TableRowAction>
          );
        },
      },
      { field: 'createdDate' },
    ];
  }, [translate]);

  return { columns };
};
