import {
  ChangeEvent,
  FC,
  useContext,
  useMemo,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBLoader from '../../atoms/BBLoader';
import BBEnterManualAddressLink from './BBEnterManualAddressLink';
import BBAddressList from './BBAddressList';
import { FullScreenDialog } from '../../atoms/FullScreenDialog';
import { BBMobileOptimisedNoOptionUI } from '../../atoms/BBMobileOptimisedNoOptionUI';
// Types
import {
  AddressItem,
  AddressItemsType,
} from '../../../core/types/addressLookupTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface BBMobileOptimisedAddressLookupProps {
  open: boolean;
  isLoading: boolean;
  addressItems: AddressItemsType;
  hasAddressResults: boolean;
  placeholder: string;
  searchText: string;
  onAddressSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  handleCloseDialog: () => void;
  setShowMobileOptimisedView: (value: boolean) => void;
  onSelectAddress: (entry: AddressItem) => void;
  handleMobileOptimisedClearText: () => void;
}

const StyledInput = styled(InputBase)(({ theme }) => {
  const {
    colours: {
      borders: { input, focus },
      backgrounds: { bgAlt },
      text: { label },
    },
    dimensions: { spacing },
  } = theme;

  return {
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: bgAlt,
    borderBottom: `1px solid ${input}`,
    padding: `0px ${spacing.xSmall}px`,
    '&.Mui-focused': {
      borderBottomColor: focus,
    },
    '& input': {
      backgroundColor: bgAlt,
      padding: `${spacing.small}px 0`,
      ...getBody2NormalStyles(theme),
      '&:: placeholder': {
        color: label,
      },
    },
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      icon: { active },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '24px',
    '> svg': {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      ' > path': {
        fill: active,
      },
    },
  };
});

const AddressListContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      borders: { border },
    },
    dimensions: { spacing, radius },
  } = theme;

  return {
    padding: spacing.small,
    '> ul': {
      paddingTop: 0,
      paddingBottom: 0,

      '> li': {
        marginBottom: spacing.xxxSmall,
        border: `1px solid ${border}`,
        borderRadius: radius.small,
        '> div > span > div': {
          padding: `${spacing.xSmall}px ${spacing.small}px`,
        },

        ':last-child': {
          marginBottom: 0,
        },
      },
    },
  };
});

const NoAddressViewContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;
  return {
    padding: spacing.small,
  };
});

const BBMobileOptimisedAddressLookup: FC<
  BBMobileOptimisedAddressLookupProps
> = (props: BBMobileOptimisedAddressLookupProps) => {
  const {
    open,
    isLoading,
    addressItems,
    hasAddressResults,
    placeholder,
    searchText,
    handleCloseDialog,
    onAddressSearch,
    setIsManualEntry,
    setCountryChange,
    setShowMobileOptimisedView,
    onSelectAddress,
    handleMobileOptimisedClearText,
  } = props;

  const { tenant } = useContext(TenantContext);
  const LeftArrowIcon = useMemo(
    () => getIcon(tenant, 'navigationChevronLeftIcon'),
    [tenant]
  );
  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const handleManualAddressClick = (
    event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
    setShowMobileOptimisedView(false);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onAddressSearch(event);
  };

  const handleClearText = () => {
    handleMobileOptimisedClearText();
  };

  return (
    <FullScreenDialog open={open}>
      <StyledInput
        data-testid="full-screen-search-input"
        name="mobileOptimisedSearchField"
        autoFocus={true}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={searchText}
        startAdornment={
          <IconContainer>
            <LeftArrowIcon
              onClick={handleCloseDialog}
              data-testid="bb-left-arrow-icon"
            />
          </IconContainer>
        }
        endAdornment={
          <IconContainer>
            {isLoading ? (
              <BBLoader type="secondary" size="small" />
            ) : (
              <CloseIcon
                data-testid="bb-clear-text-icon"
                onClick={handleClearText}
              />
            )}
          </IconContainer>
        }
      />
      <BBEnterManualAddressLink
        handleManualAddressClick={handleManualAddressClick}
        isMobileOptimised={true}
      />
      {!hasAddressResults && (
        <NoAddressViewContainer data-testid="bb-no-address-container">
          <BBMobileOptimisedNoOptionUI />
        </NoAddressViewContainer>
      )}
      {addressItems && addressItems?.Items.length > 0 && hasAddressResults && (
        <AddressListContainer>
          <BBAddressList
            addressList={addressItems}
            onSelectAddress={onSelectAddress}
            hasAddressResults={hasAddressResults}
          />
        </AddressListContainer>
      )}
    </FullScreenDialog>
  );
};

export default BBMobileOptimisedAddressLookup;
