import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

// Components - Atoms, Molecules, Organisms, Pages
import ResponsiveButton from '../../../../../organisms/ResponsiveButton';
// Types
import { LayoutProps } from '../../../../../../core/types/LayoutProps';
// Utils
import IsMobileLayoutApplicable from '../../../../../../core/utils/IsMobileLayoutApplicable';

export const MainWrapper = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = layout === 'mobile';
  const {
    dimensions: {
      spacing: { medium, xLarge, xxxLarge },
    },
    colours: {
      backgrounds: { white },
    },
  } = theme;

  return {
    display: 'flex',
    padding: isMobileLayout
      ? `${xLarge}px ${medium}px`
      : `${xxxLarge}px ${xLarge}px`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: xLarge,
    alignSelf: 'center',
    background: white,
    maxWidth: '480px',
  };
});

export const CompletionInfoContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { large },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: large,
    alignSelf: 'stretch',
  };
});

export const CompletionTextWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xxSmall,
    textAlign: 'center',
  };
});

export const WrapperWithNoGap = styled(Box)(() => {
  return {
    gap: 0,
    display: 'flex',
    flexDirection: 'column',
  };
});

export const CompletionActionContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  };
});

export const BankDetailsActionContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { smallMedium },
    },
  } = theme;
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: smallMedium,
    alignSelf: 'stretch',
  };
});

export const ActionLinkWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    gap: `${xxSmall + 2}px`,
  };
});

export const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
    dimensions: {
      spacing: { smallMedium },
    },
  } = theme;
  return {
    color: borderLighter,
    width: '1px', //Token not available
    height: smallMedium,
  };
});

export const DisclaimerTextContainer = styled('span')(() => {
  return {
    textAlign: 'center',
  };
});

export const TextWrapper = styled('span')(({ theme }) => {
  return {
    '& p': {
      display: 'inline',
    },
    '& p:first-child': {
      marginRight: '5px',
      borer: '1px solid red',
    },
  };
});

export const BankDetailsContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { medium },
    },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: medium,
    alignSelf: 'stretch',
  };
});

export const DoneButton = styled(ResponsiveButton, {
  shouldForwardProp: (prop) => prop !== 'layout',
})<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = IsMobileLayoutApplicable(layout);

  return {
    width: '100%',

    ...(!isMobileLayout && {
      maxWidth: 327,
    }),
  };
});
