import { useContext, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  ActionButtonsContainer,
  FormContainer,
  MainContainer,
  StyledPageTitle,
} from '../../../CommonStyles/CommonStyles.styles';
import BBStepPanel from '../../../../organisms/BBStepPanel';
import { Header, RefreshButton, Subtitle } from '../shared/styles';
import {
  Balance,
  ExchangeSummary,
  ExchangeSummaryItem,
  ExchangeSummaryLabel,
  FieldSets,
  FormContent,
  FormHeader,
  FormHeaderTitle,
  PayInFullButton,
  WalletAmountContainer,
} from './PaymentDetailsView.styles';
import RHFBBAmount from '../../../../molecules/BBAmount';
import BBText from '../../../../atoms/BBText';
import RateTrackerTooltip from '../shared/components/RateTrackerTooltip';
import ResponsiveButton from '../../../../organisms/ResponsiveButton';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
import { useFxRateDeal } from '../shared/hooks/useFxRateDeal';
// Types
import { FormValues } from './types';
import {
  Payment,
  PaymentMethodType,
} from '../../../../../core/types/PaymentTypes';
import { Wallet } from '../../../../../core/types/WalletTypes';
import { FxDealRate } from '../../../../../core/types/PricingTypes';
import { GetFxDealRateRequest } from '../../../../../core/types/ApiTypes';
// Utils
import { getFormattedCurrencyValue } from '../../../../../core/utils/Format';
import { getFormattedQuote } from './utils';
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { useGetPaymentRequestByIdQuery } from '../../../../../redux/api';
import { skipToken } from '@reduxjs/toolkit/query';

interface PaymentDetailsViewProps {
  payment: Payment;
  wallet?: Wallet;
  onBack: () => void;
  onContinue: (data: FormValues) => void;
  onDealUpdate: (deal: FxDealRate) => void;
}

const PaymentDetailsView = (props: PaymentDetailsViewProps) => {
  const { onBack, onContinue, payment, wallet, onDealUpdate } = props;

  const lastUpdatedSide = useRef<'send' | 'recipient'>('send');
  const lastFocusedSide = useRef<'send' | 'recipient'>('send');

  const { data: paymentRequest } = useGetPaymentRequestByIdQuery(
    payment?.paymentRequest?.id ?? skipToken
  );

  const [isSendAmountFocused, setIsSendAmountFocused] = useState(false);
  const [isRecipientAmountFocused, setIsRecipientAmountFocused] =
    useState(false);

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    getFieldState,
    formState,
    resetField,
  } = useFormContext<FormValues>();

  const { isValid, isSubmitting } = formState;

  const [
    sendAmount = '',
    sendCurrency,
    recipientAmount = '',
    recipientCurrency,
  ] = watch([
    'sendAmount',
    'sendCurrency',
    'recipientAmount',
    'recipientCurrency',
  ]);

  const RotateIcon = useMemo(() => getIcon(tenant, 'rotate'), [tenant]);

  const onRateRefresh = (request: GetFxDealRateRequest, deal: FxDealRate) => {
    if (!deal.quotedPrice) {
      return;
    }

    onDealUpdate(deal);

    if (request.isBuyingAmt) {
      setValue('sendAmount', deal.quotedPrice, {
        shouldValidate: true,
        shouldTouch: true,
      });
      return;
    }

    setValue('recipientAmount', deal.quotedPrice, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const {
    isRefreshingRate,
    deal,
    refreshRate,
    isRefreshScheduled,
    cancelScheduledRefresh,
  } = useFxRateDeal({
    onRateRefresh,
    transactionId: paymentRequest?.transactionId,
  });

  const isSameCurrencyPayment = () => {
    return getValues().recipientCurrency === getValues().sendCurrency;
  };

  const activeStep: number = 1;

  const canContinue: boolean =
    isValid &&
    !isSubmitting &&
    !isRefreshingRate &&
    (isSameCurrencyPayment() || Boolean(deal));
  const isWalletPayment: boolean =
    payment.paymentMethod === PaymentMethodType.Wallet;

  const isSendFieldDisabled: boolean =
    isRecipientAmountFocused ||
    (isRefreshingRate && lastFocusedSide.current === 'recipient');
  const isRecipientFieldDisabled: boolean =
    isSendAmountFocused ||
    (isRefreshingRate && lastFocusedSide.current === 'send');

  const quoteRate: number | string = isSameCurrencyPayment()
    ? 1
    : getFormattedQuote(deal?.quotedRate);
  const leftToPay: number = payment.paymentRequest?.leftToPay ?? 0;

  const refreshSendExchangeRate = async () => {
    const { sendAmount, sendCurrency, recipientCurrency } = getValues();
    const isInvalid = getFieldState('sendAmount').invalid;

    if (!sendAmount || isInvalid || isSameCurrencyPayment()) {
      return;
    }

    refreshRate({
      amount: Number(sendAmount),
      sellingCcy: sendCurrency,
      buyingCcy: recipientCurrency,
      isBuyingAmt: false,
    });
  };

  const refreshRecipientExchangeRate = async () => {
    const { recipientAmount, sendCurrency, recipientCurrency } = getValues();
    const isInvalid = getFieldState('recipientAmount').invalid;

    if (!recipientAmount || isInvalid || isSameCurrencyPayment()) {
      return;
    }

    refreshRate({
      amount: Number(recipientAmount),
      sellingCcy: sendCurrency,
      buyingCcy: recipientCurrency,
      isBuyingAmt: true,
    });
  };

  const onExchangeRateRefresh = async () => {
    if (lastUpdatedSide.current === 'send') {
      await refreshSendExchangeRate();
      return;
    }

    await refreshRecipientExchangeRate();
  };

  const onPayInFull = () => {
    setValue('recipientAmount', leftToPay, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    onRecipientValuesChange();

    if (!isSameCurrencyPayment()) {
      const { sendCurrency, recipientCurrency } = getValues();

      refreshRate({
        amount: leftToPay,
        sellingCcy: sendCurrency,
        buyingCcy: recipientCurrency,
        isBuyingAmt: true,
      });
    }
  };

  const onSendValuesChange = () => {
    cancelScheduledRefresh();

    if (isSameCurrencyPayment()) {
      setValue('recipientAmount', getValues().sendAmount, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      resetField('recipientAmount');
      setValue('recipientAmount', undefined);
    }

    lastUpdatedSide.current = 'send';
    lastFocusedSide.current = 'send';
  };

  const onSendAmountFocus = () => {
    setIsSendAmountFocused(true);
    lastFocusedSide.current = 'send';
  };

  const onSendAmountBlur = () => {
    setIsSendAmountFocused(false);
    onExchangeRateRefresh();
  };

  const onRecipientValuesChange = () => {
    cancelScheduledRefresh();

    if (isSameCurrencyPayment()) {
      setValue('sendAmount', getValues().recipientAmount, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      resetField('sendAmount');
      setValue('sendAmount', undefined);
    }

    lastUpdatedSide.current = 'recipient';
    lastFocusedSide.current = 'recipient';
  };

  const onRecipientAmountFocus = () => {
    setIsRecipientAmountFocused(true);
    lastFocusedSide.current = 'recipient';
  };

  const onRecipientAmountBlur = () => {
    setIsRecipientAmountFocused(false);
    onExchangeRateRefresh();
  };

  return (
    <>
      <Header>
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={translate('buyer.createPayment.paymentDetails.title')}
          layout={layout}
        />
        <Subtitle ownerState={{ layout }}>
          {translate('buyer.createPayment.paymentDetails.subtitle')}
        </Subtitle>
      </Header>
      <MainContainer layout={layout}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onContinue)}>
          <FormContent>
            <FormHeader ownerState={{ layout }}>
              <FormHeaderTitle ownerState={{ layout }}>
                {translate('buyer.createPayment.paymentDetails.leftToPayText')}{' '}
                <strong>
                  {getFormattedCurrencyValue(leftToPay, recipientCurrency)}
                </strong>
              </FormHeaderTitle>
              <PayInFullButton
                size="small"
                btnType="outlined"
                onClick={onPayInFull}
                disabled={isRefreshingRate}
              >
                {translate(
                  'buyer.createPayment.paymentDetails.payInFullButtonText'
                )}
              </PayInFullButton>
            </FormHeader>
            <FieldSets layout={layout}>
              <WalletAmountContainer>
                <RHFBBAmount
                  control={control}
                  textFieldProps={{
                    name: 'sendAmount',
                    placeholderLabel: translate(
                      'buyer.createPayment.paymentDetails.fields.sendAmount'
                    ),
                    value: String(sendAmount),
                    onFocus: onSendAmountFocus,
                    handleBlur: onSendAmountBlur,
                    onChange: onSendValuesChange,
                    isDisabled: isSendFieldDisabled,
                  }}
                  dropdownProps={{
                    name: 'sendCurrency',
                    value: sendCurrency,
                    disabled: isSendFieldDisabled,
                    'data-testid': 'send-currency-select',
                    onChange: () => {
                      onSendValuesChange();
                      onExchangeRateRefresh();
                    },
                    readOnly: isWalletPayment,
                  }}
                />
                {isWalletPayment && wallet && (
                  <Balance>
                    <strong>
                      {translate(
                        'buyer.createPayment.paymentDetails.walletBalanceText'
                      )}
                      :
                    </strong>{' '}
                    {getFormattedCurrencyValue(
                      Number(wallet.availableBalance),
                      wallet.walletCurrency
                    )}
                  </Balance>
                )}
              </WalletAmountContainer>

              {!(isWalletPayment && isSameCurrencyPayment()) && (
                <>
                  <RHFBBAmount
                    control={control}
                    textFieldProps={{
                      name: 'recipientAmount',
                      placeholderLabel: translate(
                        'buyer.createPayment.paymentDetails.fields.recipientAmount'
                      ),
                      value: String(recipientAmount),
                      onFocus: onRecipientAmountFocus,
                      handleBlur: onRecipientAmountBlur,
                      onChange: onRecipientValuesChange,
                      isDisabled: isRecipientFieldDisabled,
                    }}
                    dropdownProps={{
                      name: 'recipientCurrency',
                      value: recipientCurrency,
                      disabled: isRecipientFieldDisabled,
                      readOnly: true,
                    }}
                  />
                  <ExchangeSummary>
                    <ExchangeSummaryItem>
                      <ExchangeSummaryLabel
                        variant="body2"
                        type="normal"
                        text={`1 ${sendCurrency} =`}
                      />
                      <BBText
                        variant="body2"
                        type="normal"
                        text={`${quoteRate} ${recipientCurrency}`}
                      />
                      <RateTrackerTooltip
                        isRefreshedScheduled={isRefreshScheduled()}
                        expiryTime={deal?.rateExpiryTime}
                      >
                        <RefreshButton
                          ownerState={{ isLoading: isRefreshingRate }}
                          disabled={isRefreshingRate}
                          onClick={onExchangeRateRefresh}
                        >
                          <RotateIcon />
                        </RefreshButton>
                      </RateTrackerTooltip>
                    </ExchangeSummaryItem>
                    <ExchangeSummaryItem>
                      <ExchangeSummaryLabel
                        variant="body2"
                        type="normal"
                        text={`${translate('buyer.createPayment.paymentDetails.feeText')} =`}
                      />
                      <BBText
                        variant="body2"
                        type="normal"
                        text={getFormattedCurrencyValue(0, sendCurrency)}
                      />
                    </ExchangeSummaryItem>
                  </ExchangeSummary>
                </>
              )}
            </FieldSets>
          </FormContent>

          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <ResponsiveButton btnType="outlined" onClick={onBack} type="button">
              {translate('steppers.backBtn')}
            </ResponsiveButton>
            <ResponsiveButton
              btnType="secondary"
              type="submit"
              disabled={!canContinue}
            >
              {translate('steppers.continueBtn')}
            </ResponsiveButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'buyer-payment'}
          idValues={{
            paymentId: payment?.id.toString(),
          }}
        />
      </MainContainer>
    </>
  );
};

export default PaymentDetailsView;
