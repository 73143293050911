import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import PaymentRequestsTable from './PaymentRequestsTable/PaymentRequestsTable';
import Summary from './Summary';
import { Header } from './TransactionDetails.styles';
import { WeakText } from '../../CommonStyles/CommonStyles.styles';
import Page from '../../../organisms/Page';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { PaymentStatus } from '../../../../core/types/PaymentRequestsTypes';
// API Wrappers
import { useGetTransactionQuery } from '../../../../redux/api';
// Utils
import { getFormattedAddress } from '../../../../core/utils/Format';
import { parseNumber } from '../../../../core/utils/Parse';

type RouterParams = {
  transactionId: string;
};

const TransactionDetails = () => {
  const { transactionId } = useParams<RouterParams>();
  const { translate } = useTranslations();

  const { data, isLoading, error } = useGetTransactionQuery(
    parseNumber(transactionId) ?? skipToken
  );

  const paymentRequests = useMemo(() => {
    return (
      data?.paymentRequests?.filter(
        (paymentRequest) =>
          paymentRequest.paymentRequestStatus !== PaymentStatus.InProgress
      ) ?? []
    );
  }, [data]);

  return (
    <Page isLoading={isLoading} error={error}>
      {data && (
        <>
          <div>
            <Header>
              <BBText
                variant="h3"
                type="bold"
                text={translate('buyer.transactionDetails.title')}
              />
              <WeakText
                variant="body2"
                type="normal"
                text={getFormattedAddress(data.address)}
              />
            </Header>
            <Summary transaction={data} />
          </div>

          <PaymentRequestsTable paymentRequests={paymentRequests} />

          <WeakText
            variant="subtitle1"
            type="normal"
            text={translate('buyer.transactionDetails.footerText')}
          />
        </>
      )}
    </Page>
  );
};

export default TransactionDetails;
