import { FC } from 'react';

//Components - Atoms, Molecules, Organisms, Pages
import NoRecipientsFound from './NoRecipientsFound';
import BankAccount from './BankAccount';
import { SearchResultContainer } from './styles';
// Types
import { BankAccountDetailsResponse } from '../../../core/types/PaymentRequestsTypes';

interface BankAccountsProps {
  recipient: BankAccountDetailsResponse[] | null;
}

const BankAccounts: FC<BankAccountsProps> = (props: BankAccountsProps) => {
  const { recipient } = props;

  return (
    <SearchResultContainer>
      {recipient && recipient.length ? (
        <>
          {recipient.map((details: BankAccountDetailsResponse) => (
            <BankAccount key={details.id} details={details} />
          ))}
        </>
      ) : (
        <NoRecipientsFound />
      )}
    </SearchResultContainer>
  );
};

export default BankAccounts;
