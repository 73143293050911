import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './App.css';
import TenantProvider from './core/TenantProvider';
import Router from './router';
import RPErrorNotification from './components/organisms/BBErrorNotification';
import { errorStateReset } from './redux/modules/errorDetailsSlice';
import { AppDispatch } from './redux/store';

function App() {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(errorStateReset());
  },[]);

  return (
    <TenantProvider>
      <RPErrorNotification />
      <Router />
    </TenantProvider>
  );
}

export default App;