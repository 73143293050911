import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const getExpiresInSeconds = (expiryTime: string) => {
  return Math.max(dayjs(expiryTime).diff(new Date(), 'seconds'), 0);
};

export const useExpiryProgress = (expiryTime: string) => {
  const frame = useRef(0);
  const [secondsUntilExpiry, setSecondsUntilExpiry] = useState(
    getExpiresInSeconds(expiryTime)
  );

  useEffect(() => {
    const endTime = new Date(expiryTime).getTime();

    const calculateProgress = () => {
      const now = Date.now();

      if (now < endTime) {
        setSecondsUntilExpiry(getExpiresInSeconds(expiryTime));
        frame.current = requestAnimationFrame(calculateProgress);
      }
    };

    frame.current = requestAnimationFrame(calculateProgress);

    return () => cancelAnimationFrame(frame.current);
  }, [expiryTime]);

  return { secondsUntilExpiry };
};
