import { FC, useContext, useEffect, useState } from 'react';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import BBRadioButton from '../../atoms/BBRadioButton';
import {
  CardContainer,
  ContentWrapper,
  CountryAndNumberText,
  CurrencyRadioWrapper,
  UserDetails,
} from './styles';
// Types
import { BankAccountDetailsResponse } from '../../../core/types/PaymentRequestsTypes';
// Utils
import CurrencyOptions from '../../../core/utils/MockData/CurrencyMockData.json';
import CountryOptions from '../../../core/utils/MockData/CountryMockData.json';
import { useFormContext } from 'react-hook-form';
import { SavedRecipientFormValues } from '../../../core/types/RecipientTypes';

interface BankAccountProps {
  details: BankAccountDetailsResponse;
}

const BankAccount: FC<BankAccountProps> = (props: BankAccountProps) => {
  const {
    details: {
      id,
      bankAccountType,
      firstName,
      lastName,
      companyName,
      country,
      currency,
      valueOne,
    },
  } = props;

  const { setValue, trigger, getValues } =
    useFormContext<SavedRecipientFormValues>();

  const radioValue = getValues('recipientId') ?? null;

  const isNewAccountTypeIndividual =
    bankAccountType?.toLowerCase() === 'individual';

  const { layout } = useContext(LayoutContext);

  const [icon, setIcon] = useState('');

  const currencyDetails = CurrencyOptions.find(
    (data) =>
      data.value?.toLowerCase().trim() === currency?.trim().toLowerCase()
  );

  const countryDetails = CountryOptions.find(
    (details) =>
      details.value?.toLowerCase().trim() === country?.trim().toLowerCase()
  );

  useEffect(() => {
    const iconPath: string = currencyDetails
      ? currencyDetails.iconPath
      : CurrencyOptions[0].iconPath;
    if (iconPath) {
      import(`../../../core/CountryMetadata/${iconPath}.svg`)
        .then(({ default: currentIcon }) => {
          setIcon(currentIcon);
        })
        .catch((_) => {});
    }
  }, [currencyDetails]);

  const handleChange = () => {
    setValue('recipientId', id, { shouldTouch: true, shouldDirty: true });
    trigger();
  };

  const checked: boolean = radioValue === id;

  return (
    <CardContainer
      checked={checked}
      onClick={handleChange}
      data-testid="radio-card-container"
    >
      <ContentWrapper layout={layout}>
        <UserDetails>
          <BBText
            text={
              isNewAccountTypeIndividual
                ? `${firstName} ${lastName}`
                : companyName
            }
            type="medium"
            variant="body2"
          />
          <CountryAndNumberText
            text={`${countryDetails?.label} | ${valueOne}`}
            type="normal"
            variant="subtitle1"
          />
        </UserDetails>
        <CurrencyRadioWrapper>
          <div>
            {icon && <img loading="lazy" alt={country} src={icon} />}
            <BBText type="medium" variant="body1" text={currency} />
          </div>
          <BBRadioButton
            name="recipientId"
            value={id}
            onChange={handleChange}
            checked={checked}
          />
        </CurrencyRadioWrapper>
      </ContentWrapper>
    </CardContainer>
  );
};
export default BankAccount;
