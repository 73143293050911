import { useContext } from 'react';

// Contexts
import { LayoutContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import BBCircularProgress from '../../../../molecules/BBCircularProgress';
import { HighlightedArea, Key, KeyValues, Value } from '../../styles';
import {
  SummaryContainer,
  SummaryContent,
  ProgressSeparator,
} from './Summary.styles';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { Transaction } from '../../../../../core/types/TransactionTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
import {
  getFormattedAmount,
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../../core/utils/Format';

interface SummaryProps {
  transaction: Transaction;
}

const Summary = (props: SummaryProps) => {
  const { transaction } = props;
  const {
    percentageComplete = 0,
    leftToPay,
    paidToDate,
    purchaseValue,
    currency,
    completionDate,
  } = transaction;

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return (
    <SummaryContainer ownerState={{ layout }}>
      {isMobileLayout && (
        <BBText
          variant="body1"
          type="bold"
          text={translate('buyer.transactionDetails.mobileTitleText')}
        />
      )}

      <BBCircularProgress
        value={percentageComplete}
        text={translate('buyer.transactionDetails.summary.totalPaidText')}
        size={isMobileLayout ? 'normal' : 'small'}
      />

      {isMobileLayout && <ProgressSeparator />}

      <SummaryContent>
        <KeyValues variant={isMobileLayout ? 'vertical' : 'horizontal'}>
          <Key>
            {translate('buyer.transactionDetails.summary.totalValueText')}
          </Key>
          <Value>{getFormattedCurrencyValue(purchaseValue, currency)}</Value>
          <Key>
            {translate('buyer.transactionDetails.summary.paidToDateText')}
          </Key>
          <Value>{getFormattedCurrencyValue(paidToDate, currency)}</Value>
          <Key>
            {translate('buyer.transactionDetails.summary.completionDateText')}
          </Key>
          <Value>{getFormattedDate(completionDate)}</Value>
        </KeyValues>

        <HighlightedArea>
          <KeyValues>
            <Key>
              {translate('buyer.transactionDetails.summary.leftToPayText')}
            </Key>
            <Value>
              <BBText
                text={
                  leftToPay
                    ? `${getFormattedAmount(-leftToPay, currency)} `
                    : ' '
                }
                variant="h3"
                type="bold"
                component="span"
              />
              <BBText
                text={getFormattedValue(currency)}
                variant="body2"
                type="medium"
                component="span"
              />
            </Value>
          </KeyValues>
        </HighlightedArea>
      </SummaryContent>
    </SummaryContainer>
  );
};

export default Summary;
