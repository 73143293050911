import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import {
  WarningActions,
  WarningContainer,
  WarningIconContainer,
  WarningText,
} from '../shared/styles';
import ResponsiveButton from '../../../../organisms/ResponsiveButton';
import Page from '../../../../organisms/Page';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
import { Wallet } from '../../../../../core/types/WalletTypes';
// API Wrappers
import { useGetTransactionPbuDetailsQuery } from '../../../../../redux/api';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { getFormattedValue } from '../../../../../core/utils/Format';

interface FundingWarningViewProps {
  paymentRequest?: PaymentRequestDetails;
  wallet?: Wallet;
  onContinue: () => void;
  onBack: () => void;
}

export const FundingWarningView = (props: FundingWarningViewProps) => {
  const { onBack, onContinue, paymentRequest, wallet } = props;

  const { data: pbu, isLoading } = useGetTransactionPbuDetailsQuery(
    paymentRequest?.transactionId ?? skipToken
  );

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const {
    formState: { isSubmitting },
  } = useFormContext();

  const WarningIcon = useMemo(
    () => getIcon(tenant, 'warningIllustration'),
    [tenant]
  );

  const areFundsSufficientToCoverPaymentRequest: boolean =
    (paymentRequest?.payAmount ?? 0) < (wallet?.availableBalance ?? 0);

  return (
    <Page isLoading={isLoading}>
      <WarningContainer>
        <WarningIconContainer>
          <WarningIcon />
        </WarningIconContainer>

        <BBText
          variant="h1"
          type="bold"
          text={translate(
            'buyer.createPayment.paymentMethod.fundingWarning.title',
            { currency: paymentRequest?.currency }
          )}
        />

        <WarningText>
          <BBText
            variant="body2"
            type="medium"
            text={translate(
              'buyer.createPayment.paymentMethod.fundingWarning.description1',
              { pbuName: getFormattedValue(pbu?.pbuName) }
            )}
            component="strong"
          />
          <br />
          <br />
          {translate(
            'buyer.createPayment.paymentMethod.fundingWarning.description2'
          )}
          <br />
          <br />

          {areFundsSufficientToCoverPaymentRequest &&
            translate(
              'buyer.createPayment.paymentMethod.fundingWarning.enoughFundsMessage',
              { pbuName: getFormattedValue(pbu?.pbuName) }
            )}

          {!areFundsSufficientToCoverPaymentRequest &&
            translate(
              'buyer.createPayment.paymentMethod.fundingWarning.notEnoughFundsMessage',
              { pbuName: getFormattedValue(pbu?.pbuName) }
            )}
        </WarningText>

        <WarningActions>
          <ResponsiveButton onClick={onContinue}>
            {translate(
              'buyer.createPayment.paymentMethod.fundingWarning.acceptButtonText'
            )}
          </ResponsiveButton>
          <ResponsiveButton
            btnType="outlined"
            onClick={onBack}
            disabled={isSubmitting}
          >
            {translate(
              'buyer.createPayment.paymentMethod.fundingWarning.backButtonText'
            )}
          </ResponsiveButton>
        </WarningActions>
      </WarningContainer>
    </Page>
  );
};
