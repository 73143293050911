import { useContext } from 'react';
//Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import { StyledBBText, StyledListItemIcon } from './BBSidebarNav.styles';
//Hooks
import { useTranslations } from '../../../core/hooks';
//Types
import { MenuItemWithHandler } from './types';
//Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface MenuItemContentProps {
  item: MenuItemWithHandler;
  open: boolean;
}

export default function ListItemContent({ item, open }: MenuItemContentProps) {
  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);

  return (
    <>
      {isNotMobileLayout && item.icon && (
        <StyledListItemIcon>{<item.icon />}</StyledListItemIcon>
      )}
      {open && (
        <StyledBBText
          variant="body2"
          type="normal"
          text={translate(item.label)}
        />
      )}
    </>
  );
}
