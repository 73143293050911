// Types
import { StepsConfigType, StepType } from './index';

const TRANSACTION_STEPS: StepsConfigType[] = [
  {
    id: 0,
    textKey: 'steppers.navigation.transactionDetails',
    href: '/create-transactions/transaction-details/{transactionId}',
  },
  {
    id: 1,
    textKey: 'steppers.navigation.partyDetails',
    href: '/create-transactions/party-details/{transactionId}',
  },
  {
    id: 2,
    textKey: 'steppers.navigation.confirmation',
    href: '/create-transactions/confirmation/{transactionId}',
  },
];

const PAYMENT_REQUEST_STEPS: StepsConfigType[] = [
  {
    id: 0,
    textKey: 'steppers.navigation.requestDetails',
    href: '/payment-request/request-details/{transactionId}/{paymentRequestId}',
  },
  {
    id: 1,
    textKey: 'steppers.navigation.fundDetails',
    href: '/payment-request/account-to-be-funded/{transactionId}/{paymentRequestId}',
  },
  {
    id: 2,
    textKey: 'steppers.navigation.confirmation',
    href: '/payment-request/confirmation/{transactionId}/{paymentRequestId}',
  },
];

const BUYER_PAYMENT_STEPS: StepsConfigType[] = [
  {
    id: 0,
    textKey: 'steppers.navigation.paymentMethod',
    href: '/payments/{paymentId}/method',
  },
  {
    id: 1,
    textKey: 'steppers.navigation.paymentDetails',
    href: '/payments/{paymentId}/details',
  },
  {
    id: 2,
    textKey: 'steppers.navigation.confirmation',
    href: '/payments/{paymentId}/details',
  },
];

const DISBURSEMENT_STEPS: StepsConfigType[] = [
  {
    id: 0,
    textKey: 'steppers.navigation.disbursementDetails',
    href: '/transaction-details/{transactionId}/disbursements/{disbursementId}/details',
  },
  {
    id: 1,
    textKey: 'steppers.navigation.recipientDetails',
    href: '/transaction-details/{transactionId}/disbursements/{disbursementId}/recipient',
  },
  {
    id: 2,
    textKey: 'steppers.navigation.confirmation',
    href: '/transaction-details/{transactionId}/disbursements/{disbursementId}/confirmation',
  },
];

export const STEP_DETAILS: Record<StepType, StepsConfigType[]> = {
  'create-transaction': TRANSACTION_STEPS,
  'payment-request': PAYMENT_REQUEST_STEPS,
  'buyer-payment': BUYER_PAYMENT_STEPS,
  disbursement: DISBURSEMENT_STEPS,
};
