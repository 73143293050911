import { styled } from '@mui/material/styles';
import {
  ClickAwayListener,
  MenuList,
  MenuListProps,
  Paper,
  PopperProps,
} from '@mui/material';
import { memo, ReactNode, useEffect, useRef } from 'react';

// Contexts
import BBMenuContext from './BBMenuContext';
// Components - Atoms, Molecules, Organisms, Pages
import BBPopper from '../../atoms/BBPopper';

const StyledPaper = styled(Paper)({
  minWidth: 260,
});

const StyledMenuList = styled(MenuList)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
    padding: 0,
  };
});

interface BBMenuProps extends Omit<PopperProps, 'children'> {
  onClose: () => void;
  children: ReactNode;
}

const BBMenu = (props: BBMenuProps) => {
  const { onClose, open, children, anchorEl, ...popperProps } = props;
  const prevOpen = useRef(open);

  const onKeyDown: MenuListProps['onKeyDown'] = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose();
    }

    if (event.key === 'Escape') {
      onClose();
    }
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (
      prevOpen.current === true &&
      open === false &&
      anchorEl instanceof HTMLElement
    ) {
      anchorEl.focus();
    }

    prevOpen.current = open;
  }, [open, anchorEl]);

  return (
    <BBPopper
      placement="bottom-start"
      transition
      open={open}
      anchorEl={anchorEl}
      {...popperProps}
    >
      <BBMenuContext.Provider value={{ onClose }}>
        <StyledPaper>
          <ClickAwayListener onClickAway={onClose}>
            <StyledMenuList
              autoFocusItem={props.open}
              variant="menu"
              onKeyDown={onKeyDown}
            >
              {children}
            </StyledMenuList>
          </ClickAwayListener>
        </StyledPaper>
      </BBMenuContext.Provider>
    </BBPopper>
  );
};

export default memo(BBMenu);
