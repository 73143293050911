import {
  FC,
  FocusEvent,
  MouseEvent,
  KeyboardEvent,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment, {
  InputAdornmentProps,
} from '@mui/material/InputAdornment';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { SelectedOptionType } from '../../../core/types/SelectTypes';
import { IsExtendedSelectPopper, SizeVariants, TypeProps } from './BBSelect';
// Utils
import {
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

interface BBSelectInputProps extends IsExtendedSelectPopper {
  placeholderLabel: string;
  open: boolean;
  variant: SizeVariants['variant'];
  handleClick: (event: MouseEvent<HTMLDivElement>) => void;
  handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  currentSelectedOption: SelectedOptionType | null;
  toShrinkLabel: boolean;
  disabled: boolean;
  type?: TypeProps;
  hasError: boolean;
  readOnly: boolean;
  'data-testid'?: string;
}

interface StyledTextFieldProps extends IsExtendedSelectPopper {
  open: boolean;
  toShrinkLabel?: boolean;
  variantSize: SizeVariants['variant'];
  hasError: boolean;
  readOnly: boolean;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'open' &&
    prop !== 'toShrinkLabel' &&
    prop !== 'variantSize' &&
    prop !== 'isExtendedPopper' &&
    prop !== 'hasError' &&
    prop !== 'readOnly',
})<StyledTextFieldProps>(({
  theme,
  open,
  toShrinkLabel,
  disabled,
  variantSize,
  isExtendedPopper,
  hasError,
  readOnly,
}) => {
  const {
    colours: {
      backgrounds: { bgAlt, disabled: bgDisabled },
      borders: {
        input,
        selectionHover,
        active,
        error,
        disabled: borderDisabled,
      },
      text: { label, disabled: textDisabled },
    },
    dimensions: { radius, spacing },
    zindex: { dropdownInput },
  } = theme;

  const borderColor: string = hasError
    ? error
    : open
      ? active
      : disabled
        ? borderDisabled
        : 'transparent';

  const isSmallSize: boolean = variantSize === 'small';
  const isXSmallSize: boolean = variantSize === 'x-small';
  const isSmallOrXSmallSize: boolean = isSmallSize || isXSmallSize;

  const currentHeight: number = isSmallSize ? 48 : isXSmallSize ? 40 : 56;

  const smallSpacing: string = spacing.small + 'px';

  return {
    // Form control
    height: currentHeight,
    width: '100%',
    boxSizing: 'border-box',
    border: `${disabled || hasError ? '1px' : '1.5px'} solid transparent`,
    backgroundColor: bgAlt,
    ...(!disabled && {
      boxShadow: `${input} 0px 0px 0px 1px`,
    }),
    borderColor,
    borderRadius: radius.small,
    transition: 'all 0.3s ease-in-out 0s',
    ...(!open &&
      !disabled && {
        '&:hover': {
          borderColor: hasError ? error : selectionHover,
        },
      }),

    '&:focus-within': {
      border: `${disabled || hasError ? '1px' : '1.5px'} solid ${hasError ? error : active}`,
      boxShadow: 'none',
      ...(open && {
        borderBottomColor: 'transparent',
      }),
    },

    ...(open && {
      '&': {
        borderColor: hasError ? error : active,
        boxShadow: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomColor: 'transparent',
        ...(isExtendedPopper && {
          zIndex: dropdownInput,
          background:
            'linear-gradient(to left, #0B5FFF 0%, #FFF 0.1%, #FFF 50%, #FFF 100%)',
        }),
      },
    }),

    // Input
    '& .MuiFilledInput-root': {
      cursor: disabled || readOnly ? 'default' : 'pointer',
      backgroundColor: bgAlt,
      padding: `0 ${isSmallOrXSmallSize ? spacing.xSmall : spacing.small}px`,
      borderBottomLeftRadius: radius.small,
      borderBottomRightRadius: radius.small,

      ':before, :after': {
        borderBottom: 'none',
        transition: 'none',
      },

      '&.Mui-focused': {
        backgroundColor: bgAlt,
      },

      '&:focus-within ::selection': {
        color: 'inherit',
        background: 'transparent',
      },

      '&:hover': {
        backgroundColor: bgAlt,
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottom: 'none',
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: bgDisabled,
        color: 'transparent',
        '& .MuiAutocomplete-endAdornment': {
          svg: {
            color: textDisabled,
            '> path': {
              fill: 'currentColor',
            },
          },
        },
      },

      '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },

      '& .MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },

      '> input.MuiFilledInput-input.MuiInputBase-input': {
        cursor: disabled || readOnly ? 'default' : 'pointer',
        ...getBody2NormalStyles(theme),
        ...(!toShrinkLabel
          ? {
              padding: `${isSmallSize ? '11px' : isXSmallSize ? '6px' : '16px'} 0px`,
            }
          : {
              padding: `${isSmallSize ? '18px' : isXSmallSize ? '6px' : '22px'} 0 ${isSmallSize ? '5px' : '8px'} 0`,
            }),

        ':disabled': {
          color: textDisabled,
        },
      },
    },

    // label
    '> label': {
      ...getBody2NormalStyles(theme),
      color: label,
      ...(isXSmallSize && { top: '-8px' }),
      transform: isSmallOrXSmallSize
        ? `translate(${smallSpacing}, 13px)`
        : `translate(${smallSpacing}, ${smallSpacing})`,

      '&.MuiInputLabel-shrink': {
        ...getBodyExtraSmallNormalStyles(theme),
        color: label,
      },

      ...(toShrinkLabel
        ? {
            '&.MuiFormLabel-filled, &.Mui-focused': {
              transform: isSmallOrXSmallSize
                ? `translate(${spacing.xSmall}px, ${spacing.xxxSmall}px)`
                : `translate(${smallSpacing}, ${spacing.xxxSmall}px)`,
            },
          }
        : {
            '&.MuiFormLabel-filled, &.Mui-focused': {
              display: 'none',
            },
          }),

      '&.Mui-disabled': {
        color: textDisabled,
      },
    },
  };
});

const StyledInputAdornment = styled(InputAdornment)<InputAdornmentProps>(() => {
  return {
    img: {
      width: 18,
      height: 18,
    },
  };
});

interface IconContainerProps {
  open: boolean;
  disabled: boolean;
}

const IconContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'disabled',
})<IconContainerProps>(({ theme, open, disabled }) => {
  const {
    colours: {
      text: { disabled: textDisabled },
      icon: { active },
    },
  } = theme;

  return {
    display: 'flex',

    ':hover': {
      backgroundColor: 'transparent',
    },

    '> svg': {
      ...(open && {
        transform: 'rotate(180deg)',
      }),

      '> path': {
        fill: disabled ? textDisabled : active,
      },
    },
  };
});

const BBSelectInput: FC<BBSelectInputProps> = (props: BBSelectInputProps) => {
  const {
    placeholderLabel,
    open,
    variant,
    isExtendedPopper,
    handleClick,
    handleKeyDown,
    onBlur,
    currentSelectedOption,
    toShrinkLabel,
    disabled,
    type,
    hasError,
    readOnly = false,
    'data-testid': dataTestId,
  } = props;

  const isXSmallSize: boolean = variant === 'x-small';
  const { tenant } = useContext(TenantContext);

  const ChevronDown = useMemo(
    () => getIcon(tenant, 'navigationChevronDownIcon'),
    [tenant]
  );

  const inputRef = useRef<any>(null);

  return (
    <StyledTextField
      open={open}
      toShrinkLabel={toShrinkLabel && !isXSmallSize}
      variant="filled"
      size="medium"
      value={
        currentSelectedOption
          ? type === 'dialingCode'
            ? currentSelectedOption.value
            : currentSelectedOption.label
          : ''
      }
      label={placeholderLabel}
      disabled={disabled}
      hasError={hasError}
      variantSize={variant}
      isExtendedPopper={isExtendedPopper}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      hiddenLabel={
        (!toShrinkLabel || variant === 'x-small') &&
        currentSelectedOption?.value.length !== 0
      }
      data-testid={dataTestId ?? 'bb-select-input'}
      inputProps={{
        autoComplete: `off`, // disable autocomplete and autofill
      }}
      inputRef={inputRef}
      InputProps={{
        readOnly: true,
        startAdornment: currentSelectedOption && currentSelectedOption.icon && (
          <StyledInputAdornment position="start">
            <img
              data-testid="bb-start-adornment-img"
              loading="lazy"
              src={currentSelectedOption.icon}
              alt={currentSelectedOption.value}
            />
          </StyledInputAdornment>
        ),
        ...(!readOnly && {
          endAdornment: (
            <IconContainer
              open={open}
              disabled={disabled}
              onClick={() => inputRef?.current?.focus()}
              data-testid="bb-end-adornment-icon"
            >
              <ChevronDown />
            </IconContainer>
          ),
        }),
      }}
      readOnly={readOnly}
    />
  );
};

export default BBSelectInput;
