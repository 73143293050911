export const getGreetingMessage = (
  morningGreetingText: string,
  afternoonGreetingText: string,
  eveningGreetingText: string,
  nightGreetingText: string,
  currentHour: number
) => {
  return currentHour >= 6 && currentHour < 12 //"Good morning" for 6 AM to 12 PM
    ? morningGreetingText
    : currentHour >= 12 && currentHour < 18 //"Good afternoon" for 12 PM to 6 PM
      ? afternoonGreetingText
      : currentHour >= 18 && currentHour < 24 // "Good evening" for 6 PM to 12 AM
        ? eveningGreetingText
        : nightGreetingText; //"Good night" for 12 AM to 6 AM
};

// Get the month, day, weekday to show on the header
export const month = new Intl.DateTimeFormat(navigator.language, {
  month: 'long',
}).format(Date.now());

export const day = new Intl.DateTimeFormat(navigator.language, {
  day: 'numeric',
}).format(Date.now());

export const weekday = new Intl.DateTimeFormat(navigator.language, {
  weekday: 'long',
}).format(Date.now());

export const capitalize = (text: string) =>
  (text && text[0].toUpperCase() + text.slice(1)) || '';

export const getNameInitials = (fname: string, lname: string) =>
  `${fname && fname[0].toUpperCase()}${lname && lname[0].toUpperCase()}`;

export const getFormattedFullName = (
  fname: string,
  mname: string,
  lname: string
) => `${capitalize(fname)} ${capitalize(mname)} ${capitalize(lname)}`;

export const getOrdinal = (n: number) => {
  let ord = 'th';
  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }
  return ord;
};
