import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  styled,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import { StyledItemProps, StyledRouterLinkProps } from './types';

export const StyledBBText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textAlt },
    },
  } = theme;

  return {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&.MuiTypography-root': {
      color: textAlt,
    },
  };
});

export const NavWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: xxSmall,
    alignSelf: 'stretch',
    height: '100%',
  };
});

export const StyledNavList = styled(List)(({ theme }) => {
  const {
    dimensions: {
      spacing: { small },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: small,
    alignSelf: 'stretch',
    flexDirection: 'column',
    padding: 0,
  };
});

export const StyledListItem = styled(ListItem)(() => {
  return {
    padding: 0,
  };
});

const commonStyles = ({
  theme,
  isBuyerFlow,
}: {
  theme: any;
  isBuyerFlow?: boolean;
}) => {
  const {
    dimensions: {
      spacing: { xSmall, small },
      radius: { card },
    },
  } = theme;

  return {
    padding: xSmall,
    alignItems: 'flex-start',
    gap: small,
    alignSelf: 'stretch',
    display: 'flex',
    width: '100%',
    textDecoration: 'none',
    '&.active': {
      borderRadius: card,
      background: isBuyerFlow ? '#111928' : '#1F2A37', // Token not available
    },
    ':hover': {
      borderRadius: card,
      background: isBuyerFlow ? '#111928' : '#1F2A37', // Token not available
    },
  };
};

export const StyledRouterLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== 'isBuyerFlow',
})<StyledRouterLinkProps>(commonStyles);

export const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isBuyerFlow',
})<StyledItemProps>(commonStyles);

export const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({
  theme,
}) => {
  const {
    colours: {
      icon: { iconAlt },
    },
  } = theme;

  return {
    width: '24px',
    height: '24px',
    minWidth: '24px',
    alignSelf: 'center',
    '> svg > path': {
      fill: iconAlt,
    },
  };
});
