import { useContext, FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query/react';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import BBStepPanel from '../../../organisms/BBStepPanel/BBStepPanel';
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
import RequestForm from './RequestForm';
import PageLoader from '../../../organisms/PageLoader';
import NotFoundRedirect from '../../../organisms/NotFoundRedirect';
//Types
import { TransactionAccount } from '../../../../core/types/AccountTypes';
import {
  PaymentRequestDetails,
  PaymentRequestTypes,
} from '../../../../core/types/PaymentRequestsTypes';
import { RouterParams } from './types';
// API Wrappers
import {
  useGetAccountsByTransactionIdQuery,
  useGetPaymentRequestTypeQuery,
  useGetPaymentRequestByIdQuery,
} from '../../../../redux/api';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Utils
import { isNotFoundError } from '../../../../core/utils/Errors';

const RequestDetails: FC = () => {
  const navigate = useNavigate();

  const { transactionId, paymentRequestId } = useParams<RouterParams>();

  const { layout } = useContext(LayoutContext);

  const { translate } = useTranslations();

  const activeStep: number = 0;

  const { data, isLoading, error } = useGetPaymentRequestByIdQuery(
    paymentRequestId ? Number(paymentRequestId) : skipToken
  );

  // To fetch payment request details by id
  const paymentRequestData: PaymentRequestDetails | undefined = data;

  const { data: transactionAccountData, isLoading: transactionAccountLoading } =
    useGetAccountsByTransactionIdQuery(
      transactionId ? Number(transactionId) : skipToken
    );

  const { data: paymentRequestTypeData, isLoading: paymentRequestTypeLoading } =
    useGetPaymentRequestTypeQuery();

  const transactionAccount: TransactionAccount[] = transactionAccountData ?? [];

  const paymentTypeOptions: PaymentRequestTypes[] =
    paymentRequestTypeData ?? [];

  const handleOnContinueClick = (
    paymentRequestId: PaymentRequestDetails['id']
  ) => {
    navigate(
      `/payment-request/account-to-be-funded/${transactionId}/${paymentRequestId}`
    );
  };

  if (transactionAccountLoading || paymentRequestTypeLoading || isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={translate('paymentRequest.requestDetails.title')}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <RequestForm
          activeStep={activeStep}
          onContinue={handleOnContinueClick}
          transactionAccount={transactionAccount}
          paymentTypeOptions={paymentTypeOptions}
          transactionId={Number(transactionId)}
          paymentRequestId={Number(paymentRequestId)}
          paymentRequestData={paymentRequestData}
        />
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'payment-request'}
          idValues={{
            transactionId: transactionId,
          }}
        />
      </MainContainer>
    </>
  );
};

export default RequestDetails;
