import { ChangeEvent, KeyboardEvent, useLayoutEffect, useRef } from 'react';

//Components
import BBInput from '../../../atoms/BBInput';
import { InputProps } from '../../../atoms/BBInput/BBInput';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';

//Hooks
import usePrevious from './usePrevious';

interface PinInputProps extends InputProps {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  focus?: boolean;
}

export default function PinInput(props: PinInputProps) {
  const { focus, handleChange, handleOnKeyDown, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      }
      if (focus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [focus, prevFocus]);

  return (
    <BBInput
      inputRef={inputRef}
      {...rest}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
      endAdornment={''}
    />
  );
}
