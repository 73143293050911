import { IconButton, IconButtonProps } from '@mui/material';
import { forwardRef, memo } from 'react';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const { colours } = theme;

  return {
    padding: 0,
    color: colours.backgrounds.black,

    '&:hover': {
      backgroundColor: colours.backgrounds.activeLightest,
    },

    '&.Mui-focusVisible': {
      color: colours.backgrounds.active,
      backgroundColor: colours.backgrounds.activeLightest,
      outline: `1px solid ${colours.borders.focus}`,
      outlineOffset: 2,
    },

    '&:active': {
      backgroundColor: colours.backgrounds.active,
      color: colours.backgrounds.white,
    },

    svg: {
      width: 36,
      height: 36,

      path: {
        fill: 'currentColor',
      },
    },
  };
});

interface BBIconButtonProps extends Omit<IconButtonProps, 'disableRipple'> {}

const BBIconButton = forwardRef<HTMLButtonElement, BBIconButtonProps>(
  (props, ref) => {
    return <StyledIconButton {...props} disableRipple ref={ref} />;
  }
);

export default memo(BBIconButton);
