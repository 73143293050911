import { FC, useContext, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface VariantProps {
  variant: VariantType;
}

interface BBInlineNotificationProps extends VariantProps {
  text: string;
  onClose?: () => void;
}

export type VariantType =
  | 'error'
  | 'warning'
  | 'success'
  | 'information'
  | 'pending';

interface ContainerProps extends VariantProps {}

const variantStyles = (theme: Theme, variant: VariantType) => {
  const {
    colours: { backgrounds, icon },
  } = theme;

  const variantMap = {
    error: {
      backgroundColor: backgrounds.errorLighter,
      svgIconColor: icon.error,
    },
    warning: {
      backgroundColor: backgrounds.warningLighter,
      svgIconColor: icon.warning,
    },
    success: {
      backgroundColor: backgrounds.successLighter,
      svgIconColor: icon.success,
    },
    information: {
      backgroundColor: backgrounds.noticeLighter,
      svgIconColor: icon.notice,
    },
    pending: {
      backgroundColor: backgrounds.pendingLighter,
      svgIconColor: icon.pending,
    },
  };

  return variantMap[variant];
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<ContainerProps>(({ theme, variant }) => {
  const {
    dimensions: { spacing, radius },
  } = theme;

  const { backgroundColor, svgIconColor } = variantStyles(theme, variant);

  return {
    display: 'flex',
    gap: spacing.xxSmall,
    padding: spacing.small,
    backgroundColor,
    borderRadius: radius.small,
    alignItems: 'center',

    '> div > svg': {
      color: svgIconColor,
    },
  };
});

const DataContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.xxSmall,
    alignItems: 'center',
    flex: 1,
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const {
    colours: { text, icon },
  } = theme;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent',
    },

    '> svg > path': {
      fill: icon.icon,
    },

    '&.Mui-focusVisible': {
      outline: `${text.focus} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: icon.icon,
      },
    },
  };
});

const BBInlineNotification: FC<BBInlineNotificationProps> = (
  props: BBInlineNotificationProps
) => {
  const { text, variant, onClose } = props;

  const { tenant } = useContext(TenantContext);

  const [ErrorIcon, WarningIcon, SuccessIcon, InformationIcon, CloseIcon] =
    useMemo(
      () => [
        getIcon(tenant, 'statusWarningFilled'),
        getIcon(tenant, 'statusWarningAltFilled'),
        getIcon(tenant, 'checkmarkFilled'),
        getIcon(tenant, 'statusInformationFilled'),
        getIcon(tenant, 'navigationClose'),
      ],
      [tenant]
    );

  const icon = useMemo(() => {
    const iconsMap: Record<VariantType, JSX.Element> = {
      error: <ErrorIcon data-testid="bb-error-notification-icon" />,
      warning: <WarningIcon data-testid="bb-warning-notification-icon" />,
      success: <SuccessIcon data-testid="bb-success-notification-icon" />,
      information: (
        <InformationIcon data-testid="bb-information-notification-icon" />
      ),
      pending: <InformationIcon data-testid="bb-pending-notification-icon" />,
    };

    return iconsMap[variant];
  }, [variant]);

  return (
    <Container variant={variant}>
      <DataContainer>
        {icon}
        <BBText variant="body2" type="medium" text={text} />
      </DataContainer>
      {onClose && (
        <StyledIconButton
          onClick={onClose}
          name="bb-inline-notification-close-button"
        >
          <CloseIcon />
        </StyledIconButton>
      )}
    </Container>
  );
};

export default BBInlineNotification;
