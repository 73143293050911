import { keyframes, styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBIconButton from '../../../../atoms/BBIconButton/BBIconButton';
// Types
import { LayoutProps } from '../../../../../core/types/LayoutProps';
// Utils
import { getBody2NormalStyles } from '../../../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Header = styled('header')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  };
});

interface SubtitleOwnerState extends LayoutProps {}

export const Subtitle = styled('p')<{ ownerState: SubtitleOwnerState }>(({
  theme,
  ownerState,
}) => {
  const { colours } = theme;
  const { layout } = ownerState;

  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    color: colours.text.textWeak,
    margin: 0,
    ...getBody2NormalStyles(theme),

    ...(!isDesktopLayout && {
      textAlign: 'center',
    }),
  };
});

export const WarningContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `0 auto`,
  maxWidth: 327,
  width: '100%',
});

export const WarningIconContainer = styled('span')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    paddingBottom: spacing.small,

    svg: {
      width: 90,
      height: 90,
    },
  };
});

export const WarningText = styled('p')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    margin: 0,
    paddingTop: spacing.medium,
    textAlign: 'center',
    whiteSpace: 'pre-line',
    ...getBody2NormalStyles(theme),
  };
});

export const WarningActions = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    width: '100%',
    paddingTop: spacing.xLarge,
    flexDirection: 'column',
    gap: spacing.xSmall,
  };
});

const rotateAnimation = keyframes`
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(-360deg);
  }
`;

interface RefreshButtonOwnerState {
  isLoading: boolean;
}

export const RefreshButton = styled(BBIconButton)<{
  ownerState: RefreshButtonOwnerState;
}>(({ theme, ownerState }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;
  const { isLoading } = ownerState;

  return {
    marginLeft: spacing.xxSmall,

    '&, &:disabled': {
      color: colours.backgrounds.active,
    },

    svg: {
      width: 20,
      height: 20,
    },

    ...(isLoading && {
      animation: `1.4s linear 0s infinite normal none running ${rotateAnimation}`,
    }),
  };
});
