// Components - Atoms, Molecules, Organisms, Pages
import JoinTransactionConfirmation from './JoinTransactionConfirmation';
import Page from '../../../organisms/Page';
import Invitation from './Invitation';
// Hooks
import { useInvitations } from './useInvitations';
// Types
import { InvitationStatus } from '../../../../core/types/PaymentRequestsTypes';

const Dashboard = () => {
  const { invitations, isLoading, isFetching } = useInvitations();

  const pendingInvitation = invitations.find(
    (invitation) =>
      invitation.paymentRequest.invitationStatus === InvitationStatus.Pending
  );

  return (
    <Page isLoading={isLoading}>
      {invitations.map((invitation) => (
        <Invitation
          key={invitation.paymentRequest.id}
          invitation={invitation}
        />
      ))}

      <JoinTransactionConfirmation
        isOpen={!isFetching && Boolean(pendingInvitation)}
        invitation={pendingInvitation}
      />
    </Page>
  );
};

export default Dashboard;
