import { styled } from '@mui/material/styles';

// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Container = styled('div')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    backgroundColor: colours.backgrounds.bgAlt,
    border: `1px solid ${colours.borders.border}`,
    padding: spacing.medium,
    borderRadius: radius.medium,
  };
});

interface KeyValuesProps {
  variant?: 'horizontal' | 'vertical';
}

export const KeyValues = styled('dl', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<KeyValuesProps>(({ theme, variant = 'horizontal' }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'grid',
    margin: 0,

    ...(variant === 'horizontal' && {
      columnGap: spacing.xSmall,
      gridTemplateRows: 'auto auto',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
    }),

    ...(variant === 'vertical' && {
      '& dd:not(:last-child)': {
        marginBottom: spacing.xxSmall,
      },
    }),
  };
});

export const Key = styled('dl')(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeak,
    margin: 0,
    ...getBody2NormalStyles(theme),
  };
});

export const Value = styled('dd')(({ theme }) => {
  return {
    margin: 0,
    ...getBody2NormalStyles(theme),
  };
});

export const HighlightedArea = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing, radius },
  } = theme;

  return {
    borderRadius: radius.small,
    padding: `${spacing.xxSmall}px ${spacing.xSmall}px`,
    backgroundColor: '#f4f4f4', // No token
  };
});
