import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBModal, { SizeProps } from './BBModal';
import BBText from '../../atoms/BBText';
import BBButton from '../../atoms/BBButton';
import {
  StyledBody,
  StyledMessage,
  StyledAdditionMessage,
  StyledIcon,
  ActionsContainer,
  ActionBtnContainer,
} from './styles';

interface BBConfirmationModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  message: string;
  additionMessage: ReactNode;
  primaryBtnLabel: string;
  handlePrimaryBtnClick: () => void;
  secondaryBtnLabel: string;
  handleSecondaryBtnClick: () => void;
  size?: SizeProps['size'];
  icon?: ReactNode;
  additionalNote?: string;
}

interface StyleProps extends SizeProps {}

const StyledAdditionNote = styled(BBText, {
  shouldForwardProp: (prop) => prop !== 'size',
})<StyleProps>(({ theme, size }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isSmallSize: boolean = size === 'small';

  return {
    textAlign: 'center',
    whiteSpace: isSmallSize ? 'normal' : 'pre-line',
    marginTop: spacing.medium,
  };
});

const BBConfirmationModal: FC<BBConfirmationModalProps> = (
  props: BBConfirmationModalProps
) => {
  const {
    isOpen,
    handleClose,
    message,
    additionMessage,
    primaryBtnLabel,
    handlePrimaryBtnClick,
    secondaryBtnLabel,
    handleSecondaryBtnClick,
    size = 'normal',
    icon,
    additionalNote,
  } = props;

  const actionButtons = (
    <ActionsContainer>
      <ActionBtnContainer>
        <BBButton
          onClick={handlePrimaryBtnClick}
          size="large"
          name="primaryConfirmationModalButton"
          data-testid="bb-button-modal-confirmation-primary"
        >
          {primaryBtnLabel}
        </BBButton>
      </ActionBtnContainer>
      <ActionBtnContainer>
        <BBButton
          btnType="text"
          onClick={handleSecondaryBtnClick}
          size="medium"
          name="secondaryConfirmationModalButton"
          data-testid="bb-button-modal-confirmation-secondary"
        >
          {secondaryBtnLabel}
        </BBButton>
      </ActionBtnContainer>
    </ActionsContainer>
  );

  const body = (
    <StyledBody size={size} data-testid="modal-body">
      {icon && <StyledIcon size={size}>{icon}</StyledIcon>}
      {message && (
        <StyledMessage variant="h3" type="bold" text={message} size={size} />
      )}

      {additionMessage && (
        <StyledAdditionMessage size={size}>
          {additionMessage}
        </StyledAdditionMessage>
      )}
      {additionalNote && (
        <StyledAdditionNote
          variant="body2"
          type="bold"
          text={additionalNote}
          size={size}
        />
      )}
      {actionButtons}
    </StyledBody>
  );

  return (
    <BBModal
      isOpen={isOpen}
      handleClose={handleClose}
      body={body}
      size={size}
    />
  );
};

export default BBConfirmationModal;
