import { FC, ReactNode, useContext, useMemo } from 'react';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBIconButton from '../../atoms/BBIconButton/BBIconButton';
import { ModalContainer, StyledBackdrop, StyledModal } from './styles';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface BBModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  body: ReactNode;
  size?: SizeProps['size'];
  isSessionModal?: boolean;
}

export type SizeProps = {
  size: 'normal' | 'small';
};

interface StyledIconButtonProps {
  iconSize: SizeProps['size'];
}

const Header = styled('div')(() => {
  return {
    position: 'relative',
  };
});

const StyledIconButton = styled(BBIconButton, {
  shouldForwardProp: (prop) => prop !== 'iconSize',
})<StyledIconButtonProps>(({ iconSize }) => {
  const isSmallSize: boolean = iconSize === 'small';

  return {
    position: 'absolute',
    top: -8,
    right: -8,

    '> svg': {
      width: isSmallSize ? 26 : 36,
      height: isSmallSize ? 26 : 36,
    },
  };
});

const StyledBody = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
  };
});

const BBModal: FC<BBModalProps> = (props: BBModalProps) => {
  const { isOpen, handleClose, body, size = 'normal' } = props;

  const { tenant } = useContext(TenantContext);

  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      slots={{
        backdrop: StyledBackdrop,
      }}
    >
      <Slide
        direction="up"
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <ModalContainer data-testid="container" size={size} isOpen={isOpen}>
          {handleClose && (
            <Header>
              <StyledIconButton
                onClick={handleClose}
                iconSize={size}
                name="closeModalButton"
                data-testid="bb-button-modal-close"
              >
                <CloseIcon />
              </StyledIconButton>
            </Header>
          )}
          <StyledBody>{body}</StyledBody>
        </ModalContainer>
      </Slide>
    </StyledModal>
  );
};

export default BBModal;
