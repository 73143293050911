import { FC, ReactNode } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBModal, { SizeProps } from './BBModal';
import BBButton from '../../atoms/BBButton';
import {
  StyledBody,
  StyledMessage,
  StyledAdditionMessage,
  StyledIcon,
  ActionsContainer,
  ActionBtnContainer,
} from './styles';

interface BBSomethingWentWrongModalProps extends Partial<SizeProps> {
  isOpen: boolean;
  handleClose?: () => void;
  message: string;
  additionalMessage: string;
  primaryBtnLabel: string;
  handlePrimaryBtnClick: () => void;
  icon?: ReactNode;
}

const BBSomethingWentWrongModal: FC<BBSomethingWentWrongModalProps> = (
  props: BBSomethingWentWrongModalProps
) => {
  const {
    isOpen,
    handleClose,
    message,
    additionalMessage,
    primaryBtnLabel,
    handlePrimaryBtnClick,
    size = 'normal',
    icon,
  } = props;

  const actionButtons = (
    <ActionsContainer>
      <ActionBtnContainer>
        <BBButton
          onClick={handlePrimaryBtnClick}
          size="large"
          name="primaryConfirmationModalButton"
          data-testid="bb-button-modal-confirmation-primary"
        >
          {primaryBtnLabel}
        </BBButton>
      </ActionBtnContainer>
    </ActionsContainer>
  );

  const body = (
    <StyledBody size={size} data-testid="modal-body">
      {icon && <StyledIcon size={size}>{icon}</StyledIcon>}
      {message && (
        <StyledMessage variant="h3" type="bold" text={message} size={size} />
      )}

      {additionalMessage && (
        <StyledAdditionMessage size={size}>
          {additionalMessage}
        </StyledAdditionMessage>
      )}

      {actionButtons}
    </StyledBody>
  );

  return (
    <BBModal
      isOpen={isOpen}
      handleClose={handleClose}
      body={body}
      size={size}
    />
  );
};

export default BBSomethingWentWrongModal;
