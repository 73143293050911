import {
  forwardRef,
  InputHTMLAttributes,
  useContext,
  useId,
  useMemo,
} from 'react';

// Contexts
import { TenantContext } from '../../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  CheckIconContainer,
  CurrencyIconContainer,
  RadioInput,
  Subtitle,
  WalletContent,
  WalletItem,
} from './Wallet.styles';
import BBText from '../../../../../atoms/BBText';
import CurrencyIcon from './CurrencyIcon';
// Hooks
import { useTranslations } from '../../../../../../core/hooks/useTranslations';
// Types
import { Wallet as WalletType } from '../../../../../../core/types/WalletTypes';
// Utils
import { getIcon } from '../../../../../../core/utils/IconOrgData';
import { getFormattedCurrencyValue } from '../../../../../../core/utils/Format';

interface WalletProps extends InputHTMLAttributes<HTMLInputElement> {
  wallet: WalletType;
}

const Wallet = forwardRef<HTMLInputElement, WalletProps>((props, ref) => {
  const { wallet, ...inputProps } = props;
  const { totalBalance, availableBalance, walletCurrency, walletNumber } =
    wallet;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();
  const id = useId();

  const isDisabled: boolean = !Number(availableBalance);

  const CheckmarkIcon = useMemo(
    () => getIcon(tenant, 'checkmarkFilled'),
    [tenant]
  );

  return (
    <WalletItem>
      <RadioInput
        {...inputProps}
        id={id}
        type="radio"
        value={walletNumber}
        ref={ref}
        disabled={isDisabled}
      />
      <WalletContent htmlFor={id}>
        <CurrencyIconContainer>
          <CurrencyIcon currency={walletCurrency} />
        </CurrencyIconContainer>
        <div>
          <BBText
            variant="body2"
            type="medium"
            text={getFormattedCurrencyValue(
              Number(totalBalance),
              walletCurrency
            )}
          />
          <Subtitle
            variant="subtitle1"
            type="normal"
            text={translate(
              'buyer.createPayment.paymentMethod.wallet.available',
              {
                amount: getFormattedCurrencyValue(
                  Number(availableBalance),
                  walletCurrency
                ),
              }
            )}
          />
        </div>
        <CheckIconContainer>
          <CheckmarkIcon />
        </CheckIconContainer>
      </WalletContent>
    </WalletItem>
  );
});

export default Wallet;
