import {
  FC,
  useState,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useContext,
  useMemo,
  MouseEvent,
  Ref,
} from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { InputBaseProps } from '@mui/material';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBLoader from '../BBLoader';
import BBValidationErrorMessage from '../BBValidationErrorMessage/BBValidationErrorMessage';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import {
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

type InputTypes = 'text' | 'password' | 'email' | 'number' | 'search';

interface SizeProps {
  size: 'normal' | 'small';
}

interface TypeProps {
  type: InputTypes;
}

export interface InputProps {
  name: string;
  value: string;
  placeholderLabel: string;
  size?: SizeProps['size'];
  type?: InputTypes;
  error?: string;
  autocomplete?: 'on' | 'off';
  isDisabled?: boolean;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClear?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  inputRef?: Ref<HTMLInputElement>;
  showLoader?: boolean;
  isPopperOpen?: boolean;
  endAdornment?: InputBaseProps['endAdornment'];
  readOnly?: InputBaseProps['readOnly'];
}

interface StyledFormControlProps {
  isPopperOpen: boolean;
}

interface StyledInputAdornmentProps {
  showLoader?: boolean;
}

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'isPopperOpen',
})<StyledFormControlProps>(({ theme, error, disabled, isPopperOpen, size }) => {
  const {
    colours: {
      borders: {
        input,
        selectionHover,
        active,
        error: borderErrorColor,
        focus,
        disabled: borderDisabledColor,
      },
    },
    dimensions: { radius },
  } = theme;

  let borderColor: string = 'transparent';
  let borderHoverColor: string = selectionHover;
  let borderFocusColor: string = focus;
  let boxShadowColor: string = input;
  let boxShadowHoverFocusColor: string = 'none';
  let isBorderBottomNotApplicable: boolean = false;

  if (error) {
    borderColor = borderErrorColor;
    borderHoverColor = borderErrorColor;
    borderFocusColor = borderErrorColor;
  } else if (disabled) {
    borderColor = borderDisabledColor;
    borderHoverColor = borderDisabledColor;
  } else if (isPopperOpen) {
    borderColor = active;
    borderFocusColor = active;
    borderHoverColor = active;
    isBorderBottomNotApplicable = true;
  }

  const isSmallSize: boolean = size === 'small';

  return {
    height: isSmallSize ? 48 : 56,
    boxSizing: 'border-box',
    border: `${disabled || error ? '1px' : '1.5px'} solid transparent`,
    ...(!disabled &&
      !error && {
        boxShadow: `${boxShadowColor} 0px 0px 0px 1px`,
      }),
    borderColor: borderColor,
    borderRadius: radius.small,
    width: '100%',
    transition: 'all 0.3s ease-in-out 0s',

    '[name*=clear]': {
      display: 'none',
    },

    '&:hover': {
      borderColor: borderHoverColor,
      boxShadow: boxShadowHoverFocusColor,
    },

    '&:focus-within': {
      border: `${disabled || error ? '1px' : '1.5px'} solid ${borderFocusColor}`,
      boxShadow: boxShadowHoverFocusColor,

      '[name*=clear]': {
        display: 'inline-flex',
      },
    },

    ...(isBorderBottomNotApplicable && {
      borderBottom: 'none',
      boxShadow: 'transparent 0px 0px 0px 1px',
    }),
  };
});

const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'type',
})<SizeProps & TypeProps>(({ theme, error, size, type }) => {
  const {
    colours: {
      text: { error: textErrorColor, label, disabled },
    },
    dimensions: { spacing },
  } = theme;

  const isSmallSize: boolean = size === 'small';
  const smallSpacing: string = spacing.small + 'px';
  const isSearchInput: boolean = type === 'search';

  return {
    ...getBody2NormalStyles(theme),
    color: error ? textErrorColor : label,
    transform: isSmallSize
      ? `translate(${smallSpacing}, 13px)`
      : `translate(${smallSpacing}, ${smallSpacing})`,

    ...(isSearchInput && {
      left: 26,
      pointerEvents: 'none',

      ...(isSmallSize && {
        left: 24,
        ...getBodySmallNormalStyles(theme),
      }),
    }),

    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: error ? textErrorColor : label,

      ...(!isSearchInput && {
        ...getBodyExtraSmallNormalStyles(theme),
        transform: `translate(${smallSpacing}, ${isSmallSize ? spacing.xxxSmall + 'px' : spacing.xxSmall + 'px'})`,
      }),

      ...(isSearchInput && {
        display: 'none',
      }),
    },

    '&.Mui-disabled': {
      color: disabled,
    },
  };
});

const StyledInput = styled(FilledInput)(({ theme, size, type }) => {
  const {
    colours: {
      backgrounds: { bgAlt, disabled: bgDisabledColor },
      text: { disabled: textDisabledColor },
    },
    dimensions: {
      spacing: { xSmall, small },
    },
  } = theme;

  const isSmallSize = size === 'small';
  const isSearchInput: boolean = type === 'search';

  return {
    backgroundColor: bgAlt,
    padding: '0 16px',
    borderRadius: 'inherit',

    ':before, :after': {
      borderBottom: 'none',
      transition: 'none',
    },

    '&.Mui-focused': {
      backgroundColor: bgAlt,
    },

    '&:hover': {
      backgroundColor: bgAlt,
      '&:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },

    '&.Mui-disabled': {
      backgroundColor: bgDisabledColor,
      color: 'transparent',
    },

    '> input': {
      ...getBody2NormalStyles(theme),
      paddingLeft: 0,
      paddingTop: isSmallSize ? '18px' : '22px',
      paddingBottom: isSmallSize ? '5px' : '8px',

      ':disabled': {
        color: textDisabledColor,
      },
    },

    ...(isSearchInput && {
      padding: isSmallSize ? xSmall : small,

      '&&': {
        background: 'transparent',
      },

      '.MuiInputBase-input': {
        height: 'initial',
        padding: 0,

        '&::-webkit-search-cancel-button': {
          appearance: 'none',
        },

        ...(isSmallSize && getBodySmallNormalStyles(theme)),
      },
    }),
  };
});

const StyledInputAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'showLoader',
})<StyledInputAdornmentProps>(({ showLoader }) => {
  return {
    ...(showLoader && {
      '> div': {
        display: 'flex',
      },
    }),
  };
});

const StartInputAdornment = styled(StyledInputAdornment)(({ theme }) => {
  const { colours } = theme;

  return {
    path: {
      fill: colours.icon.icon,
    },
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const {
    colours: {
      text: { label, input, focus },
    },
  } = theme;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent',
    },

    '> svg > path': {
      fill: label,
    },

    '&.Mui-focusVisible': {
      outline: `${focus} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: input,
      },
    },
  };
});

const ClearButton = styled(StyledIconButton)(({ theme }) => {
  const { colours } = theme;

  return {
    '> svg > path': {
      fill: colours.icon.active,
    },
  };
});

const BBInput: FC<InputProps> = (props: InputProps) => {
  const {
    name,
    value,
    placeholderLabel,
    size = 'normal',
    type = 'text',
    error,
    autocomplete = 'off',
    isDisabled = false,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClear,
    onClick,
    inputRef,
    showLoader,
    isPopperOpen,
    endAdornment,
    readOnly,
  } = props;

  const { tenant } = useContext(TenantContext);

  const HiddenDataIcon = useMemo(() => getIcon(tenant, 'toolsShow'), [tenant]);
  const VisibleDataIcon = useMemo(() => getIcon(tenant, 'toolsHide'), [tenant]);
  const MagnifyingGlassIcon = useMemo(
    () => getIcon(tenant, 'magnifyingGlass'),
    [tenant]
  );
  const NavigationCloseIcon = useMemo(
    () => getIcon(tenant, 'navigationClose'),
    [tenant]
  );

  const isInputTypeNumber: boolean = type === 'number';
  const currentType: string = isInputTypeNumber ? 'text' : type;

  const [inputType, setInputType] = useState(currentType);

  const toggleInputHandler = () => {
    setInputType(inputType === 'text' ? 'password' : 'text');
  };

  const passwordIcon = () =>
    inputType === 'password' ? <HiddenDataIcon /> : <VisibleDataIcon />;

  return (
    <div>
      <StyledFormControl
        variant="filled"
        error={!!error}
        disabled={isDisabled}
        isPopperOpen={!!isPopperOpen}
        size={size === 'normal' ? 'medium' : size}
        data-testid="form-control"
        hiddenLabel
      >
        {placeholderLabel && (
          <StyledInputLabel
            htmlFor={name}
            error={!!error}
            data-testid="input-label"
            disabled={isDisabled}
            size={size}
            type={type}
          >
            {placeholderLabel}
          </StyledInputLabel>
        )}
        <StyledInput
          id={name}
          name={name}
          defaultValue={!onChange ? value : undefined}
          value={!!onChange ? value : undefined}
          type={inputType}
          size={size === 'normal' ? 'medium' : size}
          autoComplete={autocomplete}
          disabled={isDisabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onClick={onClick}
          inputRef={inputRef}
          readOnly={readOnly}
          inputProps={{
            maxLength: maxLength,
            ...(isInputTypeNumber && { inputMode: 'decimal' }),
          }}
          endAdornment={
            <StyledInputAdornment position="end" showLoader={showLoader}>
              {type.trim().toLowerCase() === 'password' && (
                <StyledIconButton
                  aria-label="toggle visibility"
                  onClick={toggleInputHandler}
                  disableRipple
                  name="toggleVisibilityButton"
                  data-testid="bb-button-toggle-visibility"
                >
                  {passwordIcon()}
                </StyledIconButton>
              )}
              {showLoader && <BBLoader type="secondary" size="small" />}
              {endAdornment}
            </StyledInputAdornment>
          }
          {...(type === 'search' && {
            startAdornment: (
              <StartInputAdornment position="start" disablePointerEvents>
                <MagnifyingGlassIcon />
              </StartInputAdornment>
            ),
            endAdornment: (
              <StyledInputAdornment position="end">
                <ClearButton
                  aria-label="clear input"
                  disableRipple
                  name="clearInput"
                  onClick={onClear}
                >
                  <NavigationCloseIcon />
                </ClearButton>
              </StyledInputAdornment>
            ),
          })}
        />
      </StyledFormControl>
      {!!error && <BBValidationErrorMessage message={error} />}
    </div>
  );
};

export default BBInput;
