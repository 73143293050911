import { useContext, FC, useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Contexts
import {
  LayoutContext,
  LanguageContext,
  TenantContext,
} from '../../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../../atoms/BBText';
import BBLink from '../../../../../atoms/BBLink';
import BBStepPanel from '../../../../../organisms/BBStepPanel/BBStepPanel';
import {
  MainContainer,
  FormContainer,
  SummaryField,
  ActionButtonsContainer,
  StyledPageTitle,
  StyledFormTitle,
  SummaryData,
} from '../../../../CommonStyles/CommonStyles.styles';
import ResponsiveButton from '../../../../../organisms/ResponsiveButton';
import { RefreshButton } from '../../shared/styles';
import RateTrackerTooltip from '../../shared/components/RateTrackerTooltip';
import { QuoteContainer } from './ConfirmationScreen.styles';
// Hooks
import { useFxRateDeal } from '../../shared/hooks/useFxRateDeal';
import { useScheduledEffectOne } from './useScheduledEffectOne';
import { useIsAfterScheduledTime } from './useIsAfterScheduledTime';
// Types
import { ConfirmationViewType } from '../Confirmation';
import {
  Payment,
  PaymentMethodType,
} from '../../../../../../core/types/PaymentTypes';
import { PaymentMethods } from '../../../../../../core/types/PaymentRequestsTypes';
import { Transaction } from '../../../../../../core/types/TransactionTypes';
import { FxDealRate, FxQuote } from '../../../../../../core/types/PricingTypes';
import { GetFxDealRateRequest } from '../../../../../../core/types/ApiTypes';
// API Wrappers
import {
  useConfirmPaymentMutation,
  useUpsertPaymentMutation,
} from '../../../../../../redux/api';
// Utils
import TranslateWrapper from '../../../../../../core/utils/TranslateWrapper';
import {
  getFormattedCurrencyValue,
  getFormattedNumericValue,
  getFormattedValue,
} from '../../../../../../core/utils/Format';
import {
  getBankAccountCountry,
  getBankAccountName,
} from '../../../../../../core/utils/BankAccount';
import { getIcon } from '../../../../../../core/utils/IconOrgData';
import { mapDealToUpsertRequest } from '../../shared/utils';
import { getTimeToDisableConfirmation } from './utils';

interface ConfirmationScreenProps {
  payment: Payment;
  transaction: Transaction;
  quote?: FxQuote;
  setViewType: (viewType: ConfirmationViewType) => void;
}

interface ListItemType {
  id: number;
  label: string;
  value: string;
}

export const StyledDisclaimer = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { active },
    },
  } = theme;

  return {
    color: active,
  };
});

const ConfirmationScreen: FC<ConfirmationScreenProps> = (
  props: ConfirmationScreenProps
) => {
  const { setViewType, payment, transaction, quote } = props;
  const {
    id,
    amountToSend,
    currencyToSend,
    amountToReceive,
    currencyToReceive,
    quotedRate,
    paymentRequest,
    paymentMethod,
  } = payment;
  const bankAccount = paymentRequest?.accountToBeFunded?.bankAccount;

  const navigate = useNavigate();

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const isSameCurrencyPayment: boolean = currencyToReceive === currencyToSend;
  const isWalletPayment: boolean = paymentMethod === PaymentMethodType.Wallet;
  const isWalletRecipient =
    paymentRequest?.accountToBeFunded?.paymentMethod === PaymentMethods.Wallet;

  const [confirmPayment, { isLoading: isConfirmingPayment }] =
    useConfirmPaymentMutation();
  const [upsertPayment, { isLoading: isUpdatingPayment }] =
    useUpsertPaymentMutation();

  const onRateRefresh = (_: GetFxDealRateRequest, deal: FxDealRate) => {
    if (!quote) {
      return;
    }

    upsertPayment({
      ...mapDealToUpsertRequest(deal),
      id,
      paymentMethod,
      paymentRequestId: paymentRequest?.id,
      amountToReceive: quote.is_buying_amt ? amountToReceive : deal.quotedPrice,
      amountToSend: quote.is_buying_amt ? deal.quotedPrice : amountToSend,
    });
  };

  const { isRefreshingRate, deal, refreshRate } = useFxRateDeal({
    onRateRefresh,
    transactionId: transaction.id,
  });

  const refreshPaymentExchangeRate = () => {
    if (!currencyToReceive || !currencyToSend || !quote) {
      return;
    }

    const amount = quote.is_buying_amt ? amountToReceive : amountToSend;

    refreshRate({
      amount: amount ?? 0,
      sellingCcy: currencyToSend,
      buyingCcy: currencyToReceive,
      isBuyingAmt: quote.is_buying_amt,
    });
  };

  useScheduledEffectOne(refreshPaymentExchangeRate, quote?.rate_expiry_time);

  const { isAfterScheduledTime: isAfterAllowedToConfirmTime } =
    useIsAfterScheduledTime(getTimeToDisableConfirmation(payment.rateExpiry));

  const RotateIcon = useMemo(() => getIcon(tenant, 'rotate'), [tenant]);

  //Translations
  const backBtnText: string = translate('steppers.backBtn');
  const continueBtnText: string = translate(
    'buyer.createPayment.confirmation.continueButtonText'
  );
  const paymentMethodText: string = isWalletPayment
    ? translate('buyer.createPayment.confirmation.paymentMethod.wallet', {
        currency: currencyToSend,
      })
    : translate('buyer.createPayment.confirmation.paymentMethod.bank');

  const headingText: string = translate(
    'buyer.createPayment.confirmation.title'
  );
  const recipientDetailsText: string = translate(
    'buyer.createPayment.confirmation.recipientDetails'
  );
  const disclaimerText: string = translate(
    'buyer.createPayment.confirmation.disclaimer'
  );

  const editText: string = translate('paymentRequest.confirmation.edit');

  const activeStep: number = 2;

  const canConfirm: boolean =
    !isConfirmingPayment &&
    !isRefreshingRate &&
    !isUpdatingPayment &&
    !isAfterAllowedToConfirmTime;

  const RECIPIENT_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('buyer.createPayment.confirmation.name'),
      value: getFormattedValue(getBankAccountName(bankAccount)),
    },
    {
      id: 1,
      label: translate('buyer.createPayment.confirmation.account'),
      value: `${getFormattedValue(getBankAccountCountry(bankAccount))} / ${getFormattedValue(bankAccount?.currency)}`,
    },
  ];

  const SAME_CURRENCY_PAYMENT_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('buyer.createPayment.confirmation.youPay'),
      value: getFormattedCurrencyValue(amountToSend, currencyToSend),
    },
    {
      id: 1,
      label: translate('buyer.createPayment.confirmation.method'),
      value: paymentMethodText,
    },
  ];

  const PAYMENT_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('buyer.createPayment.confirmation.sending'),
      value: getFormattedCurrencyValue(amountToReceive, currencyToReceive),
    },
    {
      id: 1,
      label: translate('buyer.createPayment.confirmation.youPay'),
      value: getFormattedCurrencyValue(amountToSend, currencyToSend),
    },
    {
      id: 2,
      label: translate('buyer.createPayment.confirmation.fee'),
      value: getFormattedCurrencyValue(0, currencyToSend),
    },
  ];

  const handleOnContinueClick = async () => {
    await confirmPayment(payment.id).unwrap();
    setViewType('completed-screen');
  };

  const handleOnBackClick = () => {
    navigate('../details');
  };

  const handlePaymentDetailsEditClick = () => {
    navigate('../details');
  };

  const SameCurrencyPaymentSummary = (
    <SummaryData>
      <List dense={true}>
        {SAME_CURRENCY_PAYMENT_DETAILS.map((item: ListItemType) => {
          return (
            <ListItem key={item.id}>
              <ListItemText primary={item.label} secondary={item.value} />
            </ListItem>
          );
        })}
      </List>
      <BBLink
        underline="none"
        type="medium"
        text={editText}
        onClick={handlePaymentDetailsEditClick}
      />
    </SummaryData>
  );

  const PaymentSummary = (
    <SummaryData>
      <List dense={true}>
        {PAYMENT_DETAILS.map((item: ListItemType) => {
          return (
            <ListItem key={item.id}>
              <ListItemText primary={item.label} secondary={item.value} />
            </ListItem>
          );
        })}

        <ListItem>
          <ListItemText
            primary={translate('buyer.createPayment.confirmation.rate')}
            secondary={
              <QuoteContainer>
                {`1${currencyToSend} = ${getFormattedNumericValue(deal?.quotedRate ?? quotedRate)}`}

                {/* TODO: This is a placeholder refresh button copied from the details page,
                    when actual designs are ready this will have to be replaced */}
                <RateTrackerTooltip
                  isRefreshedScheduled
                  expiryTime={deal?.rateExpiryTime ?? payment.rateExpiry}
                >
                  <RefreshButton
                    ownerState={{ isLoading: isRefreshingRate }}
                    disabled={isRefreshingRate}
                    onClick={refreshPaymentExchangeRate}
                    data-testid="refresh-exchange"
                  >
                    <RotateIcon />
                  </RefreshButton>
                </RateTrackerTooltip>
              </QuoteContainer>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={translate('buyer.createPayment.confirmation.method')}
            secondary={paymentMethodText}
          />
        </ListItem>
      </List>
      <BBLink
        underline="none"
        type="medium"
        text={editText}
        onClick={handlePaymentDetailsEditClick}
      />
    </SummaryData>
  );

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={headingText}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <FormContainer layout={layout}>
          <SummaryField>
            {isSameCurrencyPayment
              ? SameCurrencyPaymentSummary
              : PaymentSummary}
            <SummaryData>
              <StyledFormTitle
                variant="body1"
                type="bold"
                text={recipientDetailsText}
              />
              <List dense={true}>
                {!isWalletRecipient &&
                  RECIPIENT_DETAILS.map((item: ListItemType) => {
                    return (
                      <ListItem key={item.id}>
                        <ListItemText
                          primary={item.label}
                          secondary={item.value}
                        />
                      </ListItem>
                    );
                  })}

                {isWalletRecipient && (
                  <ListItem>
                    <ListItemText
                      primary={translate(
                        'buyer.createPayment.confirmation.wallet'
                      )}
                      secondary={translate(
                        'buyer.createPayment.confirmation.paymentMethod.wallet',
                        { currency: paymentRequest.currency }
                      )}
                    />
                  </ListItem>
                )}

                <ListItem>
                  <ListItemText
                    primary={translate(
                      'buyer.createPayment.confirmation.reference'
                    )}
                    secondary={getFormattedValue(transaction.fileReference)}
                  />
                </ListItem>
              </List>
            </SummaryData>
            <StyledDisclaimer
              variant="body2"
              type="normal"
              text={disclaimerText}
            />
          </SummaryField>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <ResponsiveButton
              btnType="outlined"
              type="button"
              onClick={handleOnBackClick}
            >
              {backBtnText}
            </ResponsiveButton>
            <ResponsiveButton
              btnType="secondary"
              type="button"
              onClick={handleOnContinueClick}
              disabled={!canConfirm}
            >
              {continueBtnText}
            </ResponsiveButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'buyer-payment'}
          idValues={{
            paymentId: id.toString(),
          }}
        />
      </MainContainer>
    </>
  );
};

export default ConfirmationScreen;
