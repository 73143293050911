import { useContext } from 'react';

// Contexts
import { LanguageContext } from '../TenantProvider/contexts';
// Utils
import TranslateWrapper from '../utils/TranslateWrapper';

export const useTranslations = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  return { translate };
};
