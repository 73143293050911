import { styled } from '@mui/material/styles';
import {
  DateView,
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

// Utils
import {
  getBodyExtraSmallBoldStyles,
  getBodyExtraSmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

const Layout = styled(PickersLayoutRoot)(({ theme }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;

  return {
    padding: spacing.xxxSmall,
    color: colours.text.input,

    '.MuiDateCalendar-root': {
      width: 'auto',
      height: 'auto',
      maxHeight: 'initial',
    },

    '.MuiDayCalendar-weekDayLabel': {
      height: 40,
      width: 40,
      margin: 0,
      color: 'inherit',
      ...getBodyExtraSmallBoldStyles(theme),
    },

    '.MuiDayCalendar-header, .MuiDayCalendar-weekContainer': {
      padding: `${spacing.xxxSmall}px 0`,
      gap: spacing.xxxSmall,
    },

    '.MuiDayCalendar-slideTransition': {
      minHeight: 288,
    },

    '.MuiDayCalendar-weekContainer': {
      margin: 0,
    },

    '.MuiPickersDay-root': {
      height: 40,
      width: 40,
      margin: 0,
      color: 'inherit',
      ...getBodyExtraSmallNormalStyles(theme),

      '&.MuiPickersDay-dayOutsideMonth': {
        color: colours.text.disabled,
      },

      '&:hover, &.Mui-focusVisible, &:focus': {
        backgroundColor: 'transparent',
        border: `1px solid ${colours.borders.input}`,
      },

      '&.Mui-selected': {
        '&, &:hover, &:focus': {
          border: 'none',
          backgroundColor: colours.backgrounds.active,
          color: colours.text.textAlt,
        },
      },
    },
  };
});

interface CalendarLayoutProps
  extends PickersLayoutProps<Dayjs | null, Dayjs, DateView> {}

const CalendarLayout = (props: CalendarLayoutProps) => {
  const { toolbar, tabs, content, actionBar } = usePickerLayout(props);

  return (
    <Layout className={pickersLayoutClasses.root} ownerState={props}>
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {tabs}
        {content}
      </PickersLayoutContentWrapper>
    </Layout>
  );
};

export default CalendarLayout;
