// Types
import { BankAccountDetailsResponse } from '../../types/PaymentRequestsTypes';
import { Nullable } from '../../types/UtilTypes';
// Utils
import { getCountryName } from '../CountryData/GetCountryData';
// Constants
import CountryMockData from '../MockData/CountryMockData.json';

export const getBankAccountName = (
  bankAccount?: Nullable<BankAccountDetailsResponse>
) => {
  if (!bankAccount) {
    return;
  }

  if (bankAccount.bankAccountType === 'Individual') {
    return `${bankAccount.firstName} ${bankAccount.lastName}`;
  }

  return bankAccount.companyName;
};

export const getBankAccountCountry = (
  bankAccount?: Nullable<BankAccountDetailsResponse>
) => {
  if (!bankAccount) {
    return;
  }

  return getCountryName(CountryMockData, bankAccount.country);
};
