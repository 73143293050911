import { useMemo } from 'react';

// Types
import { TransactionStatus } from '../../../core/types/TransactionTypes';
import { Sort } from './types';
// API Wrappers
import { useGetTransactionsQuery } from '../../../redux/api';
// Utils
import {
  mapSortToApiSortBy,
  mapTransactionToTransactionRow,
  searchFields,
} from './utils';
// Constants
import { DEFAULT_NUMBER_OF_TABLE_ROWS } from '../../../core/utils/Constants/Constants';

interface UseTransactionsProps {
  page: number;
  sort: Sort;
  status?: TransactionStatus;
  search?: string;
}

export const useTransactions = (props: UseTransactionsProps) => {
  const { page, sort, status, search } = props;
  const sortBy: string[] = mapSortToApiSortBy(sort);

  const { data, isFetching } = useGetTransactionsQuery({
    page,
    sortBy,
    pageSize: DEFAULT_NUMBER_OF_TABLE_ROWS,
    status,
    searchTerm: search,
    searchFields: search ? searchFields : [],
  });

  const numberOfTransactions: number = data?.totalElements ?? 0;

  const transactions = useMemo(() => {
    return data?.content?.map(mapTransactionToTransactionRow) ?? [];
  }, [data]);

  return { transactions, isFetching, numberOfTransactions };
};
