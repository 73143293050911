import { Fragment, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../../atoms/BBText';
import BBInput from '../../../../../atoms/BBInput';
import BBSelect from '../../../../../atoms/BBSelect';
import BBLink from '../../../../../atoms/BBLink';
import BBCopyToClipBoard from '../../../../../molecules/BBCopyToClipBoard/BBCopyToClipBoard';
import {
  ActionLinkWrapper,
  BankDetailsActionContainer,
  BankDetailsContainer,
  CompletionActionContainer,
  CompletionInfoContainer,
  CompletionTextWrapper,
  DisclaimerTextContainer,
  DoneButton,
  MainWrapper,
  StyledDivider,
  TextWrapper,
  WrapperWithNoGap,
} from './CompletedScreen.styles';
import Page from '../../../../../organisms/Page';
// Hooks
import { useTranslations } from '../../../../../../core/hooks';
import { useContractNote } from './useContractNote';
// Types
import {
  Payment,
  PaymentMethodType,
} from '../../../../../../core/types/PaymentTypes';
// API Wrappers
import { useGetBankAccountPaymentDetailsQuery } from '../../../../../../redux/api';
// Utils
import { getIcon } from '../../../../../../core/utils/IconOrgData';
import { getFormattedCurrencyValue } from '../../../../../../core/utils/Format';
import { getBankAccountName } from '../../../../../../core/utils/BankAccount';
import { getLocallyFormattedDate } from './utils';
// Constants
import {
  DEFAULT_COUNTRY,
  DEFAULT_PAYMENT_VALUE_CURRENCY,
} from '../../../../../../core/utils/Constants/Constants';

import { useUser } from '../../../../../../core/providers/UserProvider';

interface ListItemType {
  id: number;
  label: string;
  value: string;
}

interface CompletedScreenProps {
  payment: Payment;
}

const CompletedScreen = (props: CompletedScreenProps) => {
  const { payment } = props;
  const {
    currencyToReceive,
    amountToReceive,
    currencyToSend,
    amountToSend,
    paymentMethod,
    paymentRequest,
  } = payment;
  const bankAccount = paymentRequest?.accountToBeFunded?.bankAccount;

  const { countryOfResidence, titanCustomerNo } = useUser();

  const [fromCountry, setFromCountry] = useState(
    countryOfResidence ?? DEFAULT_COUNTRY
  );

  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const { downloadContractNote, printContractNote } = useContractNote(payment);

  const {
    data: bankAccountPaymentDetails,
    isLoading: arePaymentDetailsLoading,
  } = useGetBankAccountPaymentDetailsQuery({
    country: fromCountry,
    currency: currencyToSend ?? DEFAULT_PAYMENT_VALUE_CURRENCY,
  });

  const paymentDetails = bankAccountPaymentDetails?.searchNGOP.primaryDetails;

  const isWalletRecipient: boolean =
    paymentRequest?.accountToBeFunded?.paymentMethod === 'Wallet';
  const recipientName: string | undefined = isWalletRecipient
    ? translate('buyer.createPayment.completed.recipientWallet')
    : getBankAccountName(bankAccount);

  const Title1Text: string = translate(
    'buyer.createPayment.completed.wallet.title1',
    { amount: getFormattedCurrencyValue(amountToReceive, currencyToReceive) }
  );
  const Title2Text: string = translate(
    'buyer.createPayment.completed.wallet.title2',
    { recipientName }
  );
  const bankTransferTitle: string = translate(
    'buyer.createPayment.completed.bankTransfer.title'
  );
  /* this data will be dynamic based API response */
  const description1Text: string = translate(
    'buyer.createPayment.completed.wallet.description1',
    {
      amount: getFormattedCurrencyValue(amountToReceive, currencyToReceive),
      recipientName,
    }
  );
  const description2Text: string = translate(
    'buyer.createPayment.completed.wallet.description2'
  );
  const description3Text: string = translate(
    'buyer.createPayment.completed.wallet.description3'
  );
  const btnText: string = translate('buyer.createPayment.completed.btnText');

  const disclaimer1Text: string = translate(
    'buyer.createPayment.completed.bankTransfer.disclaimer1'
  );
  const disclaimer2Text: string = translate(
    'buyer.createPayment.completed.bankTransfer.disclaimer2'
  );
  const importantText: string = translate(
    'buyer.createPayment.completed.bankTransfer.important'
  );
  const printText: string = translate(
    'buyer.createPayment.completed.bankTransfer.print'
  );
  const downloadText: string = translate(
    'buyer.createPayment.completed.bankTransfer.download'
  );
  const amountPlaceholderText: string = translate(
    'buyer.createPayment.completed.bankTransfer.amountPlaceholder'
  );
  const countryPlaceholderText: string = translate(
    'buyer.createPayment.completed.bankTransfer.countryPlaceholder',
    { currency: currencyToSend }
  );
  const subTitleText: string = translate(
    'buyer.createPayment.completed.bankTransfer.subTitle'
  );

  //Icons
  const [CompletedPageIllustration, PrinterIcon, PDFIcon] = useMemo(
    () => [
      getIcon(tenant, 'completedPageIllustration'),
      getIcon(tenant, 'printer'),
      getIcon(tenant, 'pdf'),
    ],
    [tenant]
  );

  const handleGotoTransactionClick = () => {
    navigate(`/dashboard`);
  };

  const isWalletPayment: boolean = paymentMethod === PaymentMethodType.Wallet;
  const isSameCurrencyPayment: boolean = currencyToReceive === currencyToSend;

  const BANK_DETAILS_FOR_PAYMENT: Array<ListItemType> = [
    {
      id: 0,
      label: translate(
        'buyer.createPayment.completed.bankTransfer.accountName'
      ),
      value: paymentDetails?.primaryAccountName ?? '',
    },
    {
      id: 1,
      label: translate('buyer.createPayment.completed.bankTransfer.bankName'),
      value: paymentDetails?.primaryBankName ?? '',
    },
    {
      id: 2,
      label: translate('buyer.createPayment.completed.bankTransfer.sortCode'),
      value: paymentDetails?.primaryBankCodeNumber1 ?? '',
    },
    {
      id: 3,
      label: translate('buyer.createPayment.completed.bankTransfer.accountNo'),
      value: paymentDetails?.primaryBankCodeNumber2 ?? '',
    },
    {
      id: 4,
      label: translate('buyer.createPayment.completed.bankTransfer.reference'),
      value: titanCustomerNo ?? '',
    },
  ];

  const SameCurrencyWalletSuccessView = (
    <CompletionTextWrapper>
      <WrapperWithNoGap>
        <BBText variant="h3" type="bold" text={Title1Text} />
        <BBText variant="h3" type="bold" text={Title2Text} />
      </WrapperWithNoGap>
      <WrapperWithNoGap>
        <BBText variant="body2" type="normal" text={description3Text} />
        <BBText
          variant="body2"
          type="normal"
          text={getLocallyFormattedDate(new Date())}
        />
      </WrapperWithNoGap>
    </CompletionTextWrapper>
  );

  const WalletSuccessView = (
    <CompletionTextWrapper>
      <WrapperWithNoGap>
        <BBText variant="h3" type="bold" text={Title1Text} />
        <BBText variant="h3" type="bold" text={Title2Text} />
      </WrapperWithNoGap>
      <WrapperWithNoGap>
        <BBText variant="body2" type="normal" text={description1Text} />
        <BBText variant="body2" type="normal" text={description2Text} />
        <BBText
          variant="body2"
          type="normal"
          text={getLocallyFormattedDate(new Date())}
        />
      </WrapperWithNoGap>
    </CompletionTextWrapper>
  );

  const BankTransferSuccessView = (
    <>
      <CompletionTextWrapper>
        <BBText variant="h3" type="bold" text={bankTransferTitle} />
        <WrapperWithNoGap>
          <BBText variant="body2" type="normal" text={description1Text} />
          <BBText variant="body2" type="normal" text={description2Text} />
        </WrapperWithNoGap>
      </CompletionTextWrapper>
      <BankDetailsContainer>
        <BBInput
          name="amountDue"
          value={getFormattedCurrencyValue(amountToSend, currencyToSend)}
          placeholderLabel={amountPlaceholderText}
          readOnly
        />
        <BBText variant="body2" type="normal" text={subTitleText} />
        <BBSelect
          name="country-drop-down"
          type="country"
          placeholderLabel={countryPlaceholderText}
          variant="normal"
          isSearchable={true}
          value={fromCountry}
          onChange={setFromCountry}
        />
        <CompletionTextWrapper>
          {BANK_DETAILS_FOR_PAYMENT.map((item: ListItemType) => {
            return (
              <Fragment key={item.id}>
                <BBCopyToClipBoard label={item.label} value={item.value} />
              </Fragment>
            );
          })}
        </CompletionTextWrapper>

        <DisclaimerTextContainer>
          <TextWrapper>
            <BBText variant="body2" type="bold" text={importantText} />
            <BBText variant="body2" type="normal" text={disclaimer1Text} />
          </TextWrapper>
          <BBText variant="body2" type="normal" text={disclaimer2Text} />
        </DisclaimerTextContainer>
      </BankDetailsContainer>

      <BankDetailsActionContainer>
        <ActionLinkWrapper>
          <PrinterIcon />
          <BBLink
            underline="always"
            type="medium"
            text={printText}
            onClick={printContractNote}
          />
        </ActionLinkWrapper>

        <StyledDivider orientation="vertical" variant="middle" flexItem />
        <ActionLinkWrapper>
          <PDFIcon />
          <BBLink
            underline="always"
            type="medium"
            text={downloadText}
            onClick={downloadContractNote}
          />
        </ActionLinkWrapper>
      </BankDetailsActionContainer>
    </>
  );

  return (
    <Page isLoading={arePaymentDetailsLoading}>
      <MainWrapper layout={layout}>
        <CompletionInfoContainer>
          <CompletedPageIllustration />
          {isWalletPayment
            ? isSameCurrencyPayment
              ? SameCurrencyWalletSuccessView
              : WalletSuccessView
            : BankTransferSuccessView}
        </CompletionInfoContainer>
        <CompletionActionContainer>
          <DoneButton
            btnType="secondary"
            type="button"
            onClick={handleGotoTransactionClick}
            layout={layout}
          >
            {btnText}
          </DoneButton>
        </CompletionActionContainer>
      </MainWrapper>
    </Page>
  );
};

export default CompletedScreen;
