import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { TransactionFilterType } from '../../../core/types/TransactionTypes';
// Utils
import { TRANSACTION_FILTERS } from '../../../core/utils/Constants/Constants';

export const useFilters = () => {
  const navigate = useNavigate();
  const { translate } = useTranslations();

  const filters = useMemo(() => {
    return TRANSACTION_FILTERS.map((filter) => ({
      path: filter.path,
      value: filter.type,
      label: translate(`transactions.filters.${filter.type}`),
    }));
  }, [translate]);

  const setFilter = (value: TransactionFilterType) => {
    if (value === 'all') {
      navigate(`/dashboard`);
    } else {
      navigate(`/dashboard/${value}`);
    }
  };

  return { filters, setFilter };
};
