import { ChangeEvent, FC, useState } from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

//Components - Atoms, Molecules, Organisms, Pages
import BBCheckbox from '../../atoms/BBCheckbox';

export interface TransactionAccountOption {
  id: number;
  label: string;
}

interface PPClientMultiCheckboxProps {
  transactionAccountList: TransactionAccountOption[];
  onChange: (value: number[]) => void;
  defaultValue: number[];
}

export const ClientListWrapper = styled(Box)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
      backgrounds: { white },
    },
    dimensions: {
      spacing: { xSmall, small },
      radius,
    },
  } = theme;

  return {
    display: 'flex',
    padding: small,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: xSmall,
    alignSelf: 'stretch',
    borderRadius: radius.small,
    border: `1px solid ${borderLighter}`,
    background: white,
    '& label': {
      marginLeft: 0,
      marginRight: 0,
    },
  };
});

export const ClientListStyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { border },
    },
    dimensions: {
      spacing: { xxxSmall },
    },
  } = theme;

  return {
    borderColor: border,
    marginLeft: xxxSmall,
    marginRight: xxxSmall,
    paddingTop: xxxSmall,
    paddingBottom: xxxSmall,
  };
});

const CheckboxWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    flexFlow: 'column',
    alignSelf: 'stretch',
  };
});

const PPClientMultiCheckbox: FC<PPClientMultiCheckboxProps> = (
  props: PPClientMultiCheckboxProps
) => {
  const { transactionAccountList, onChange, defaultValue } = props;

  const [clientValues, setClientValues] = useState<number[]>(defaultValue);

  // Helper to return clients array
  const handleCheckboxChange = (clients: number[]) => {
    onChange(clients);
  };

  const handleCheckChange = (
    e: ChangeEvent<HTMLInputElement>,
    itemId: number
  ) => {
    const valueCopy = [...clientValues];

    // update checkbox value
    if (e.currentTarget.checked) {
      valueCopy.push(itemId);
    } else {
      let index = clientValues.indexOf(itemId);
      index !== -1 && valueCopy.splice(index, 1);
    }
    handleCheckboxChange(valueCopy);
    setClientValues(valueCopy);
  };

  const isCheckboxSelected = (id: number) => defaultValue.includes(id);

  return (
    <ClientListWrapper data-testid="pp-client-multicheckbox-container">
      {transactionAccountList?.map(
        (item: TransactionAccountOption, index: number) => (
          <CheckboxWrapper key={item.id}>
            <BBCheckbox
              name={`client ${item.id}`}
              checked={isCheckboxSelected(item.id)}
              label={item.label}
              type={'normal'}
              onChange={(e) => handleCheckChange(e, item.id)}
              value={isCheckboxSelected(item.id)}
            />
            {index < transactionAccountList.length - 1 && (
              <ClientListStyledDivider variant="middle" flexItem />
            )}
          </CheckboxWrapper>
        )
      )}
    </ClientListWrapper>
  );
};

export default PPClientMultiCheckbox;
