import BBText from '../../../atoms/BBText';
import { Box, List, ListItem, ListItemText, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// Components - Atoms, Molecules, Organisms, Pages
import {
  StyledFormTitle,
  SummaryData,
  WeakText,
} from '../../CommonStyles/CommonStyles.styles';
import BBLink from '../../../atoms/BBLink';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import { ListItemType, PaymentMethods } from '../Confirmation/types';
import { Disbursement } from '../../../../core/types/DisbursementTypes';
//Api wrappers
import { useGetBankAccountFieldsQuery } from '../../../../redux/api';
// Utils
import {
  getFormattedCurrencyValue,
  getFormattedValue,
} from '../../../../core/utils/Format';
import { getBankAccountField } from './utils';
import { HIDDEN_VALUE_ASTERISK } from '../../../../core/utils/Constants/Constants';
import { skipToken } from '@reduxjs/toolkit/query';

interface DisbursementSummaryDataProps {
  isEditable: boolean;
  data?: Disbursement;
}

const DataContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

export const getCustomerId = (number: string | undefined) => {
  const accountNo = number ?? '';
  return `${HIDDEN_VALUE_ASTERISK} ${accountNo.substring(accountNo.length - 4)}`;
};

export default function DisbursementSummaryData(
  props: DisbursementSummaryDataProps
) {
  const { isEditable, data } = props;

  const paymentMethod = data?.paymentMethod;

  const { getFieldValue } = getBankAccountField(data);

  const { translate } = useTranslations();
  const navigate = useNavigate();

  const countryValue = getFieldValue('country');
  const currencyValue = getFieldValue('currency');
  const { data: bankAccountFields } = useGetBankAccountFieldsQuery(
    countryValue !== '-' && currencyValue !== '-'
      ? { countryCode: countryValue, currency: currencyValue }
      : skipToken
  );

  const WALLET_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('disbursement.confirmation.walletDetails.email'),
      value: getFormattedValue(data?.paidToWallet?.crmAccount?.email),
    },
    {
      id: 1,
      label: translate('disbursement.confirmation.walletDetails.customerId'),
      value: getCustomerId(data?.paidToWallet?.crmAccount?.titanAccountNo),
    },
    {
      id: 2,
      label: translate('disbursement.confirmation.walletDetails.accountType'),
      value: getFormattedValue(data?.paidFromWallet?.walletLabel),
    },
  ];

  const DISBURSEMENT_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('disbursement.confirmation.disbursementDetails.payee'),
      value: getFormattedValue(data?.paidFromWallet?.crmAccount?.name),
    },
    {
      id: 1,
      label: translate(
        'disbursement.confirmation.disbursementDetails.paymentType'
      ),
      value: getFormattedValue(
        data?.paymentType?.toLowerCase() === 'other'
          ? data?.paymentTypeDescription
          : data?.paymentType
      ),
    },
    {
      id: 2,
      label: translate('disbursement.confirmation.disbursementDetails.amount'),
      value: getFormattedCurrencyValue(data?.amount, data?.currency),
    },
  ];

  const editText: string = translate('disbursement.confirmation.edit');

  const navigateTo = (link: string) => {
    navigate(link);
  };

  const editDetails = (link: string) =>
    isEditable ? (
      <BBLink
        underline="none"
        type="medium"
        text={editText}
        onClick={() => navigateTo(link)}
      />
    ) : null;

  const walletSummary = (
    <SummaryData>
      <StyledFormTitle
        variant="body1"
        type="bold"
        text={translate('disbursement.confirmation.walletDetails.title')}
      />
      {WALLET_DETAILS.map((item: ListItemType) => {
        return (
          <DataContainer key={item.id}>
            <WeakText type="normal" variant="body2" text={item.label} />
            <BBText type="normal" variant="body2" text={item.value} />
          </DataContainer>
        );
      })}
      {editDetails('../recipient')}
    </SummaryData>
  );

  const recipientsBankSummary = (
    <SummaryData>
      <StyledFormTitle
        variant="body1"
        type="bold"
        text={translate('disbursement.confirmation.walletDetails.title')}
      />
      <DataContainer>
        <WeakText
          type="normal"
          variant="body2"
          text={translate(
            'disbursement.confirmation.recipientDetails.recipient'
          )}
        />
        <BBText
          type="normal"
          variant="body2"
          text={
            getFieldValue('bankAccountType')?.toLowerCase() === 'company'
              ? getFieldValue('companyName')
              : `${getFieldValue('firstName')} ${getFieldValue('lastName')}`
          }
        />
      </DataContainer>
      <DataContainer>
        <WeakText
          type="normal"
          variant="body2"
          text={translate(
            'disbursement.confirmation.recipientDetails.bankName'
          )}
        />
        <BBText
          type="normal"
          variant="body2"
          text={getFieldValue('bankName')}
        />
      </DataContainer>
      {bankAccountFields?.uiBlock.uiFields.map((uiField, index: number) => (
        <DataContainer key={uiField.description + index}>
          <WeakText type="normal" variant="body2" text={uiField.description} />
          <BBText
            type="normal"
            variant="body2"
            text={getFieldValue(
              index === 0 ? 'valueOne' : index === 1 ? 'valueTwo' : 'valueThree'
            )}
          />
        </DataContainer>
      ))}
      {editDetails('../recipient')}
    </SummaryData>
  );

  const disbursementDetailsSummary = (
    <SummaryData>
      <StyledFormTitle
        variant="body1"
        type="bold"
        text={translate('disbursement.confirmation.disbursementDetails.title')}
      />
      <List dense={true}>
        {DISBURSEMENT_DETAILS.map((item: ListItemType) => {
          return (
            <ListItem key={item.id}>
              <ListItemText primary={item.label} secondary={item.value} />
            </ListItem>
          );
        })}
      </List>
      {editDetails('../details')}
    </SummaryData>
  );

  return (
    <>
      {disbursementDetailsSummary}
      {paymentMethod === PaymentMethods.Wallet
        ? walletSummary
        : paymentMethod === PaymentMethods.Bank
          ? recipientsBankSummary
          : null}
    </>
  );
}
