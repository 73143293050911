import {
  FC,
  SyntheticEvent,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../BBText';
// Types
import { TypeProps } from './BBSelect';
import {
  OptionType,
  SelectedOptionType,
} from '../../../core/types/SelectTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { extractDialingCodeValue } from '../../../core/utils/DialingData/GetDialingData';

interface StyledListItemProps {
  variant: SizeVariants['variant'];
}
export interface SizeVariants {
  variant: 'normal' | 'small' | 'x-small';
}

const StyledListItem = styled('li', {
  shouldForwardProp: (prop) =>
    prop !== 'currentSelectedOption' && prop !== 'variant',
})<StyledListItemProps>(({ variant, theme }) => {
  const { colours } = theme;

  const isXSmallSize: boolean = variant === 'x-small';

  // Adding styles over here are getting overridden by the default styles of the ListBoxComponent
  return {
    height: 40,
    '&.MuiAutocomplete-option[aria-disabled="true"]': {
      color: colours.text.textWeak,
      opacity: 0.5,
    },
    ...(isXSmallSize && {
      listStyleType: 'none',
      display: 'flex',
      cursor: 'pointer',
    }),
  };
});

interface StyledOptionContainerProps {
  variant: SizeVariants['variant'];
}

const StyledOptionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<StyledOptionContainerProps>(({ theme, variant }) => {
  const isXSmallSize: boolean = variant === 'x-small';

  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    img: {
      width: 18,
      height: 18,
      marginRight: spacing.xxSmall,

      '&[src=""]': {
        opacity: 0,
      },
    },
    div: {
      marginRight: isXSmallSize ? 10 : 15,
      minWidth: '45px',
    },
    ...(isXSmallSize && {
      '>p': {
        height: 'fit-content',
        maxWidth: '210px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }),
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      icon: { active },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    '> svg > path': {
      fill: active,
    },
  };
});

interface BBSelectItemProps {
  option: OptionType;
  currentSelectedOption: SelectedOptionType | null;
  variant: SizeVariants['variant'];
  type?: TypeProps;
  listClickHandler?: (e: SyntheticEvent, newValueObj: any) => void;
}

const BBSelectItem: FC<BBSelectItemProps> = ({
  option,
  currentSelectedOption,
  variant,
  type,
  listClickHandler,
  ...otherProps
}: BBSelectItemProps) => {
  const { tenant } = useContext(TenantContext);

  const TickIcon = useMemo(() => getIcon(tenant, 'toolsTick'), [tenant]);

  const [icon, setIcon] = useState('');

  const { value, label, iconPath } = option;

  const displayValue: string = extractDialingCodeValue(value);

  useEffect(() => {
    if (iconPath) {
      import(`../../../core/CountryMetadata/${iconPath}.svg`)
        .then(({ default: currentIcon }) => {
          setIcon(currentIcon);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }
  }, [iconPath]);

  const isSelected: boolean =
    displayValue?.trim().toLowerCase() ===
      currentSelectedOption?.value?.trim().toLowerCase() &&
    label?.trim().toLowerCase() ===
      currentSelectedOption?.label?.trim().toLowerCase();

  return (
    <StyledListItem
      // @ts-ignore
      onClick={(e) => listClickHandler(e, option)}
      variant={variant}
      {...otherProps}
      aria-selected={isSelected}
      aria-disabled={option.disabled}
      /*key={`${value}-${label}-${iconPath}`}*/
    >
      <StyledOptionContainer variant={variant}>
        {iconPath && <img loading="lazy" alt={value} src={icon} />}
        {type === 'dialingCode' && (
          <div>
            <BBText
              text={`${displayValue}`}
              variant="body2"
              type="normal"
              data-option-value={value}
            />
          </div>
        )}
        <BBText
          text={`${label}`}
          variant="body2"
          type="normal"
          data-option-value={value}
        />
      </StyledOptionContainer>
      {isSelected && (
        <IconContainer>
          <TickIcon />
        </IconContainer>
      )}
    </StyledListItem>
  );
};

export default memo(BBSelectItem);
