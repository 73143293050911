import { useContext } from 'react';

//Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';

//Components
import LoginLayout from '../LoginLayout';
import TwoFactorAuthForm from './TwoFactorAuthForm';
import { LoginContainer } from '../LoginPage.styles';

export default function TwoFactorAuthentication() {
  const { layout } = useContext(LayoutContext);

  return (
    <LoginLayout>
      <LoginContainer layout={layout} containerSize="large">
        <TwoFactorAuthForm />
      </LoginContainer>
    </LoginLayout>
  );
}
