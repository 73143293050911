import { FC, memo, useContext, MouseEvent, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import BBText from '../../atoms/BBText';
import BBSelectionSummaryCountryDetails from './BBSelectionSummaryCountryDetails';
import {
  AddressFieldTypes,
  AddressType,
} from '../../../core/types/addressLookupTypes';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import BBLink from '../../atoms/BBLink';
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';
import { CountryFieldMapping } from '../../../core/utils/AddressLookup/CountryFieldsMatrix';

interface BBSelectionSummaryProps {
  address: AddressType;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  countryCode: string;
}

type CitySuburb = 'suburbCity' | 'townCity';
type ProvinceState = 'province' | 'state' | 'county';

const SelectionSummary = styled(Box)<BoxProps>(({ theme }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
    },
    dimensions: { radius, spacing },
  } = theme;

  return {
    padding: spacing.medium,
    background: bgAlt,
    borderRadius: radius.small,
    lineHeight: '24px',
  };
});

const SelectionSummaryTitle = styled(BBText)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;
  return {
    marginBottom: spacing.xxxSmall,
  };
});

const SelectionSummaryAddress = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    color: textWeak,
  };
});

const SelectionSummaryEditAddress = styled(BBLink)(({ theme }) => {
  const {
    colours: {
      borders: { active },
    },
    dimensions: { spacing },
  } = theme;

  return {
    color: active,
    marginTop: spacing.xxSmall,
    display: 'inline-block',
  };
});

const extractAddressFields = (
  keysArray: CitySuburb[] | ProvinceState[],
  addressFields: AddressFieldTypes,
  address: AddressType
) => {
  const combinedArray: string[] = [];
  keysArray.forEach((key: CitySuburb | ProvinceState) => {
    addressFields[key].visible &&
      address[key] !== '' &&
      combinedArray.push(address[key]);
  });
  return combinedArray;
};

const BBSelectionSummary: FC<BBSelectionSummaryProps> = ({
  address,
  setIsManualEntry,
  setCountryChange,
  countryCode,
}: BBSelectionSummaryProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const addressLabel: string = translate('addressLookup.addressLabel');
  const editAddress: string = translate('addressLookup.editAddress');

  const handleEditAddressClick = (
    event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
  };

  const addressFields: AddressFieldTypes = CountryFieldMapping[countryCode]
    ? CountryFieldMapping[countryCode]
    : CountryFieldMapping['default'];

  const citySuburbArray: string[] = extractAddressFields(
    ['suburbCity', 'townCity'],
    addressFields,
    address
  );
  const provinceStateCountyArray: string[] = extractAddressFields(
    ['province', 'state', 'county'],
    addressFields,
    address
  );

  const getAddressSummary = () => {
    const addressData: string[] = [];
    addressData.push(
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      citySuburbArray.join(', '),
      provinceStateCountyArray.join(', '),
      address.postcode
    );

    const addressSummary: JSX.Element[] = [];
    addressData.forEach((data: string) => {
      if (data && data.length) {
        addressSummary.push(
          <SelectionSummaryAddress
            variant="body2"
            type="normal"
            text={`${data},`}
            key={data}
          />
        );
      }
    });
    return addressSummary;
  };

  return (
    <SelectionSummary>
      <SelectionSummaryTitle
        variant="body2"
        type="medium"
        text={addressLabel}
      />
      <>{getAddressSummary()}</>

      <BBSelectionSummaryCountryDetails countryCode={countryCode} />
      <div>
        <SelectionSummaryEditAddress
          underline="none"
          onClick={(e: MouseEvent<HTMLAnchorElement>) =>
            handleEditAddressClick(e)
          }
          onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
            e.key === ENTER_KEY && handleEditAddressClick(e);
          }}
          href=""
          type="medium"
          text={editAddress}
          data-testid="bb-link-edit-address"
        />
      </div>
    </SelectionSummary>
  );
};

export default memo(BBSelectionSummary);
