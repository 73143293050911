import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

//Components
import { RouterParams } from './types';

//Api wrappers
import { useGetWalletsToBePaidFromQuery } from '../../../../redux/api';
import {
  useGetDisbursementByIdQuery,
  useGetPaymentTypeQuery,
} from '../../../../redux/api/disbursements';

export default function useInitialCalls() {
  const { id, disbursementId } = useParams<RouterParams>();

  const {
    data: walletDetails,
    isLoading: isLoadingWallets,
    error: walletDetailsError,
  } = useGetWalletsToBePaidFromQuery(id ? Number(id) : skipToken);

  const {
    data: paymentTypes,
    isLoading: isPaymentTypeOptionsLoading,
    error: paymentTypeError,
  } = useGetPaymentTypeQuery();

  const {
    data: disbursementDetails,
    isLoading: areDisbursementDetailsLoading,
  } = useGetDisbursementByIdQuery(
    disbursementId ? Number(disbursementId) : skipToken
  );

  return {
    walletDetails: walletDetails ?? [],
    isLoadingWallets,
    paymentTypeOptions: paymentTypes ?? [],
    isPaymentTypeOptionsLoading,
    disbursementDetails,
    areDisbursementDetailsLoading,
    transactionId: id ?? '',
    disbursementId: disbursementId ?? '',
    walletDetailsError,
    paymentTypeError,
  };
}
