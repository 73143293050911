import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../../atoms/BBText';

export const WalletItem = styled('li')({
  display: 'flex',
});

export const RadioInput = styled('input')(({ theme }) => {
  return {
    appearance: 'none',
    margin: 0,
    opacity: 0,
  };
});

export const WalletContent = styled('label')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.small,
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    padding: spacing.xSmall,
    borderRadius: radius.card,
    border: `1px solid ${colours.borders.border}`,

    '[type=radio]:checked + &': {
      borderColor: colours.backgrounds.active,
      backgroundColor: colours.backgrounds.bgAlt,
    },

    '[type=radio]:disabled + &': {
      opacity: 0.6,
      cursor: 'initial',
      pointerEvents: 'none',
      userSelect: 'none',
    },
  };
});

export const CurrencyIconContainer = styled('span')({
  display: 'inline-flex',
});

export const CheckIconContainer = styled('span')(({ theme }) => {
  const { colours } = theme;

  return {
    display: 'none',
    marginLeft: 'auto',

    svg: {
      width: 20,
      height: 20,
      color: colours.backgrounds.active,
    },

    '[type=radio]:checked + label &': {
      display: 'inline-flex',
    },
  };
});

export const Subtitle = styled(BBText)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeak,
  };
});
