import * as yup from 'yup';

// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
import { useErrorTranslations } from '../../../../core/hooks';
// Utils
import { getStartOfDayDate } from './utils';
// Constants
import {
  OTHER_PAYMENT_TEXT_MAX_LENGTH,
  REGEX,
} from '../../../../core/utils/Constants/Constants';

export const useValidations = () => {
  const { translateError } = useErrorTranslations(
    'paymentRequest.requestDetails.fields'
  );
  const { translate } = useTranslations();

  let validationSchema = yup.object({
    crmAccountIds: yup.array().of(yup.number().min(1)).required(),
    // Due Date
    dueDate: yup
      .date()
      .typeError(translateError('dueDate', 'invalid'))
      .required(translateError('dueDate', 'required'))
      .min(
        getStartOfDayDate(new Date()),
        translate('paymentRequest.requestDetails.errors.futureDueDate')
      ),
    // Payment Type
    paymentType: yup
      .string()
      .trim()
      .required(translateError('paymentType', 'required')),
    // Other Payment
    paymentTypeDescription: yup.string().when('paymentType', {
      is: 'Other',
      then: (schema) =>
        schema
          .required(translateError('otherPayment', 'required'))
          .trim()
          .max(
            OTHER_PAYMENT_TEXT_MAX_LENGTH,
            translateError('otherPayment', 'maxCharacters', {
              number: OTHER_PAYMENT_TEXT_MAX_LENGTH,
            })
          )
          .matches(
            REGEX.alphaNumericString,
            translate('paymentRequest.requestDetails.errors.otherPayment')
          ),
    }),
    // Payment value
    paymentValue: yup
      .string()
      .trim()
      .required(translateError('paymentValue', 'required'))
      .matches(
        REGEX.paymentValue,
        translate('paymentRequest.requestDetails.errors.paymentValue')
      ),
    // Payment value currency
    paymentValueCurrency: yup
      .string()
      .trim()
      .required(translateError('paymentValueCurrency', 'required')),
  });

  return { validationSchema };
};
