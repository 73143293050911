import { FC } from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Utils
import { getH2BoldStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import {
  FONT_STYLE_NORMAL,
  FONT_WEIGHT_BOLD,
  LETTER_SPACING_QUARTER,
} from '../../../core/TenantProvider/tenants/currenciesdirect/getThemeConfig';

type SizeVariants = 'normal' | 'small';

interface SizeProps {
  size: SizeVariants;
}

interface BBCircularProgressProps
  extends Omit<CircularProgressProps, 'value' | 'size'>,
    Partial<SizeProps> {
  value: number;
  text: string;
}

export const Progress = styled('div')({
  position: 'relative',
});

export const ProgressCircle = styled(CircularProgress)(({ theme }) => {
  const {
    colours: { backgrounds },
  } = theme;

  return {
    color: backgrounds.bg,
  };
});

export const ProgressCircleValue = styled(CircularProgress)(({ theme }) => {
  const { colours } = theme;

  return {
    position: 'absolute',
    inset: 0,
    // NOTE: Unfortunately, MUI sets its own transform value through inline styling,
    // so have to force overwrite here with !important
    transform: 'rotate(90deg) !important',
    color: colours.backgrounds.success,

    circle: {
      strokeLinecap: 'round',
    },
  };
});

export const ProgressValue = styled('span')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    inset: 0,
  };
});

const ProgressPercentageValue = styled('h2')<SizeProps>(({ theme, size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    margin: 0,
    ...(isSmallSize
      ? getH2BoldStyles(theme)
      : {
          fontSize: 48,
          lineHeight: '56px',
          ...FONT_STYLE_NORMAL,
          ...FONT_WEIGHT_BOLD,
          ...LETTER_SPACING_QUARTER,
        }),
  };
});

export const ProgressLabel = styled(BBText)(({ theme }) => {
  const { colours } = theme;

  return {
    position: 'relative',
    top: -5,
    color: colours.text.textWeak,
  };
});

const BBCircularProgress: FC<BBCircularProgressProps> = (
  props: BBCircularProgressProps
) => {
  const { text, size = 'normal', ...rest } = props;

  const sizeValue = size === 'normal' ? 200 : 130;

  return (
    <Progress>
      <ProgressCircle
        variant="determinate"
        value={100}
        size={sizeValue}
        thickness={2.3}
      />
      <ProgressCircleValue
        variant="determinate"
        thickness={2.3}
        size={sizeValue}
        {...rest}
      />
      <ProgressValue>
        <ProgressPercentageValue
          size={size}
        >{`${props.value}%`}</ProgressPercentageValue>
        <ProgressLabel variant="body2" type="medium" text={props.text} />
      </ProgressValue>
    </Progress>
  );
};

export default BBCircularProgress;
