import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  GridEventListener,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { MutableRefObject, useState } from 'react';

// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { BBDataTableProps, GridColumn } from './types';

const getDefaultSortValue = (
  tableProps: BBDataTableProps
): GridSortItem | undefined => {
  const { sortModel, defaultSortModel, initialState } = tableProps;
  const model =
    sortModel ?? defaultSortModel ?? initialState?.sorting?.sortModel ?? [];

  return model[0];
};

const getSortableColumns = (apiRef: MutableRefObject<GridApiCommunity>) => {
  const columns = apiRef.current
    ?.getVisibleColumns?.()
    .filter((column) => column.sortable) as GridColumn[];

  return columns ?? [];
};

const getAllSortItems = (
  columns: GridColumn[],
  defaultSortItem?: GridSortItem
) => {
  const sortItems = columns.flatMap<GridSortItem>((column) => [
    { field: column.field, sort: 'asc' },
    { field: column.field, sort: 'desc' },
  ]);

  const isDefaultSortItemInList = sortItems.some((sortItem) =>
    isEqual(sortItem, defaultSortItem)
  );

  if (!defaultSortItem || isDefaultSortItemInList) {
    return sortItems;
  }

  return [defaultSortItem, ...sortItems];
};

export const useTableSort = (
  apiRef: MutableRefObject<GridApiCommunity>,
  tableProps: BBDataTableProps
) => {
  const { defaultSortModel, slotProps } = tableProps;
  const { customLabels } = slotProps?.sortDropdown ?? {};

  const { translate } = useTranslations();

  const [columns, setColumns] = useState(getSortableColumns(apiRef));
  const sortItems = getAllSortItems(columns, defaultSortModel?.[0]);

  const defaultValue = getDefaultSortValue(tableProps);
  const [value, setValue] = useState<GridSortItem | undefined>(defaultValue);

  const options = sortItems.map((sortItem) => {
    const { field, sort } = sortItem;
    const column = columns.find((column) => column.field === field);

    const columnName = customLabels?.[field] ?? column?.headerName ?? field;
    const sortType = column?.sortType ?? 'string';

    const label =
      translate(`sortDropdown.labels.${field}.${sort}`) ??
      `${columnName} (${translate(`sortDropdown.orderBy.${sortType}.${sort}`)})`;

    return { label, value: sortItem };
  });

  const onSortModelChange = (model: GridSortModel) => {
    const sortModel = isEmpty(model) ? defaultSortModel : model;

    if (isEmpty(model) && defaultSortModel) {
      apiRef.current?.setSortModel(defaultSortModel);
    }

    setValue(sortModel?.[0]);
  };

  const onChange = (sort?: GridSortItem) => {
    apiRef.current?.setSortModel(sort ? [sort] : (defaultSortModel ?? []));
  };

  const onStateChange: GridEventListener<'stateChange'> = () => {
    const currentColumns = getSortableColumns(apiRef);

    // TODO: This is a temporary workaround to make sure that table component is re-rendered
    // when columns are ready. Unfortunately, there doesn't seem to be "init"
    // or any over event to communicate this. Interestingly, the issue
    // that this hack is trying to solve only happens on a single page in the application
    // and only in the tablet mode.
    // This is definitely a candidate for review and investigation when we have more time.
    setColumns((columns) =>
      isEqual(columns, currentColumns) ? columns : currentColumns
    );
  };

  return {
    dropdownProps: { options, onChange, value },
    tableProps: { onSortModelChange, onStateChange },
  };
};
