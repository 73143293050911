import { styled } from '@mui/material/styles';

export const WalletList = styled('ul')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    padding: 0,
    margin: 0,
    listStyle: 'none',
    maxHeight: 234,
    overflowY: 'auto',

    img: {
      width: 26,
      height: 26,
    },
  };
});
