import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import { keyframes } from '@mui/material';
import { Keyframes } from '@emotion/react';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import { SizeProps } from './BBModal';
// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface ModalContainerProps extends SizeProps {
  isOpen: boolean;
}

export const StyledModal = styled(Modal)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const StyledBackdrop = styled(Backdrop)(({ theme }) => {
  const {
    colours: {
      backgrounds: { backdrop },
    },
  } = theme;

  return {
    backgroundColor: backdrop,
  };
});

const slideUpAnimation: Keyframes = keyframes`
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  40% {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;
const slideDownAnimation: Keyframes = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  60% {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
`;

export const ModalContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isOpen',
})<ModalContainerProps>(({ theme, size, isOpen }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
    },
    dimensions: { spacing, radius },
    boxShadows,
  } = theme;

  const isSmallSize: boolean = size === 'small';

  let currentSlideAnimation: string = '';
  let currentFadeAnimation: string = '';

  if (isOpen) {
    currentSlideAnimation = slideUpAnimation;
    currentFadeAnimation = 'fadeInAnimation';
  } else {
    currentSlideAnimation = slideDownAnimation;
    currentFadeAnimation = 'fadeOutAnimation';
  }

  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: bgAlt,
    boxShadow: boxShadows.small,
    borderRadius: radius.modal,
    animation: `${currentSlideAnimation} 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards,
                ${currentFadeAnimation} 0.5s forwards`,
    opacity: 0,
    boxSizing: 'border-box',
    width: `calc(100% - ${2 * spacing.small}px)`,
    maxWidth: 390,
    padding: isSmallSize ? spacing.medium : spacing.large,
    ':focus-visible': {
      outline: 0,
    },
  };
});

export const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<SizeProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  const maxWidth: string = isSmallSize ? '265px' : '560px';

  return {
    flex: 1,
    maxWidth: maxWidth,
  };
});

export const StyledMessage = styled(BBText, {
  shouldForwardProp: (prop) => prop !== 'size',
})<SizeProps>(({ size, theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;
  const isSmallSize: boolean = size === 'small';

  return {
    paddingBottom: xxSmall,
    textAlign: 'center',
    whiteSpace: 'pre-line',
    ...(isSmallSize && { width: '245px' }),
  };
});

export const StyledAdditionMessage = styled('p', {
  shouldForwardProp: (prop) => prop !== 'size',
})<SizeProps>(({ theme, size }) => {
  const { colours } = theme;

  const isSmallSize: boolean = size === 'small';

  return {
    margin: 0,
    textAlign: 'center',
    whiteSpace: isSmallSize ? 'normal' : 'pre-line',
    color: colours.text.textWeak,
    ...getBody2NormalStyles(theme),
  };
});

export const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<SizeProps>(({ theme, size }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const isSmallSize: boolean = size === 'small';

  return {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: isSmallSize ? spacing.medium : spacing.large,
  };
});

export const ActionsContainer = styled('div')(({ theme }) => {
  const {
    dimensions: {
      spacing: { medium, xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xxSmall,
    paddingTop: medium,
  };
});

export const ActionBtnContainer = styled('div')(() => {
  return {
    width: 'inherit',
    display: 'flex',
    justifyContent: 'center',

    '> button': {
      maxWidth: 326,
      minWidth: 240,
      width: '100%',
    },
  };
});
