//Types
import { VerificationProps } from './types';
//Api wrappers
import { useLazyGetWalletFromEmailAndTanQuery } from '../../../../redux/api';
import { useLazyVerifyWalletQuery } from '../../../../redux/api/disbursements';

export default function useGetVerifiesWalletDetails() {
  const [verifyAccount, { ...verifyQuery }] = useLazyVerifyWalletQuery();
  const [getWalletDetails, { ...walletQuery }] =
    useLazyGetWalletFromEmailAndTanQuery();

  const getVerifiedWallet = async ({
    email,
    customerId,
    transactionId,
  }: VerificationProps) => {
    if (email && customerId && transactionId) {
      try {
        const accountVerificationData = await verifyAccount({
          searchEmail: email,
          tanNumber: customerId,
        }).unwrap();

        if (accountVerificationData === 'Confirmed Match') {
          await getWalletDetails({
            transactionId: transactionId,
            searchEmail: email,
            tanNumber: customerId,
          }).unwrap();
          return true;
        }
        return false;
      } catch {}
    }
    return false;
  };

  return {
    getVerifiedWallet,
    verifyQuery,
    walletQuery,
  };
}
