import { FC, useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Navigate, useNavigate, useParams } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import AvailableFundDetails from '../../../organisms/AvailableFundDetails';
import DeleteDisbursementConfirmation from './DeleteDisbursementConfirmation';
import ResponsiveButton from '../../../organisms/ResponsiveButton';
import DisbursementSummaryData from '../ComponentUtils/DisbursementSummaryData';
import {
  ActionButtonsContainer,
  MainContainer,
  StyledPageTitle,
  SummaryContainer,
  SummaryField,
  WeakText,
} from '../../CommonStyles/CommonStyles.styles';
import Page from '../../../organisms/Page';
// Hooks
import { useInitialLoading, useTranslations } from '../../../../core/hooks';
// Types
import { DisbursementStatus } from '../../../../core/types/DisbursementTypes';
// API Wrappers
import {
  useDeleteDisbursementMutation,
  useGetDisbursementByIdQuery,
} from '../../../../redux/api/disbursements';
import { useGetTransactionQuery } from '../../../../redux/api';
// Utils
import {
  getFormattedAddress,
  getTrimmedLowercaseValue,
} from '../../../../core/utils/Format';
import { isAllowedStatus } from '../../../../core/utils/AllowedDisbursementStatus';

export type RouterParams = {
  id: string;
  disbursementId: string;
};

const TitleContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});

const ViewDisbursementDetails: FC = () => {
  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);
  const { id, disbursementId } = useParams<RouterParams>();
  const navigate = useNavigate();

  const {
    data: disbursementData,
    isLoading: isDisbursementLoading,
    isFetching: isDisbursementFetching,
    error,
  } = useGetDisbursementByIdQuery(
    disbursementId ? Number(disbursementId) : skipToken
  );

  const {
    data: transactionData,
    isLoading: isTransactionLoading,
    isFetching: isTransactionFetching,
  } = useGetTransactionQuery(id ? Number(id) : skipToken);

  const [deleteDisbursement, { isLoading: isDeletingDisbursement }] =
    useDeleteDisbursementMutation();

  const { isInitiallyLoading } = useInitialLoading({
    isFetching: isDisbursementFetching || isTransactionFetching,
    isLoading: isDisbursementLoading || isTransactionLoading,
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  if (disbursementData && !isAllowedStatus(disbursementData?.status)) {
    return <Navigate to={`/transaction-details/${id}`} replace />;
  }

  const goBack = () => navigate(`/transaction-details/${id}`);

  const onDelete = async () => {
    try {
      setIsDeleteConfirmationModalVisible(false);
      await deleteDisbursement({
        transactionId: Number(id),
        disbursementId: Number(disbursementId),
      }).unwrap();
      goBack();
    } catch {}
  };

  const isDeleteDisabled =
    getTrimmedLowercaseValue(disbursementData?.status) !==
      DisbursementStatus.Ready.toLowerCase() || isDeletingDisbursement;

  return (
    <Page isLoading={isInitiallyLoading} error={error}>
      <TitleContainer>
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={translate('disbursement.viewDetails.title')}
        />
        <WeakText
          variant="body2"
          type="normal"
          text={getFormattedAddress(transactionData?.address)}
        />
      </TitleContainer>
      <MainContainer layout={layout}>
        <SummaryContainer layout={layout}>
          <SummaryField>
            <DisbursementSummaryData
              isEditable={false}
              data={disbursementData}
            />
          </SummaryField>
          <ActionButtonsContainer activeStep={1} layout={layout}>
            <ResponsiveButton
              btnType="outlined"
              type="button"
              onClick={goBack}
              disabled={isDeletingDisbursement}
            >
              {translate('common.backBtn')}
            </ResponsiveButton>
            <ResponsiveButton
              btnType="destructive"
              type="button"
              onClick={() => setIsDeleteConfirmationModalVisible(true)}
              disabled={isDeleteDisabled}
            >
              {translate('common.deleteBtn')}
            </ResponsiveButton>
          </ActionButtonsContainer>
        </SummaryContainer>
        <AvailableFundDetails transactionId={id} />
      </MainContainer>

      <DeleteDisbursementConfirmation
        isOpen={isDeleteConfirmationModalVisible}
        onDelete={onDelete}
        onMaybeLater={() => setIsDeleteConfirmationModalVisible(false)}
      />
    </Page>
  );
};

export default ViewDisbursementDetails;
