import { Box, styled } from '@mui/material';

//Components
import { LayoutProps } from '../../../../core/types/LayoutProps';

//Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

export const Section = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);
  const {
    dimensions: {
      spacing: { xSmall, small },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isMobileLayout ? xSmall : small,
    alignSelf: 'stretch',
    position: 'relative',
  };
});

export const SectionHeader = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xxSmall,
  };
});
