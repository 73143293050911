import { Box } from '@mui/material';
import { useContext } from 'react';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import BBLink from '../../atoms/BBLink';
import LoginForm from './LoginForm';
import LoginLayout from './LoginLayout';
import { LoginContainer, TextWithLinkWrapper } from './LoginPage.styles';
import { WeakText } from '../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
//Utils
import { getEnvSpecificConfigProperty } from '../../../core/utils/EnvSpecificConfigProperties';

export default function LoginPage() {
  const { translate } = useTranslations();

  const { layout } = useContext(LayoutContext);

  return (
    <LoginLayout>
      <LoginContainer layout={layout}>
        <Box>
          {/* TODO: Need to show the api error message */}
          {/* <BBNotification
            variant="error"
            icon={true}
            text={
              'Please re-enter your correct credentials. If you are not registered with us already, please click here to register with us.'
            }
            className="error-notification"
          /> */}
          <BBText
            variant="h2"
            type="bold"
            text={translate('login.title')}
            className="header"
          />
          <WeakText
            variant="body2"
            type="normal"
            className="subtitle"
            text={translate('login.subTitle')}
          />
          <TextWithLinkWrapper>
            <BBText
              text={translate('login.or')}
              variant="body2"
              type="normal"
            />
            <BBLink
              text={translate('login.createAnAccount')}
              href={getEnvSpecificConfigProperty('landingPageURL')}
              variant="body2"
              type="bold"
              target="_blank"
            />
          </TextWithLinkWrapper>
        </Box>

        <LoginForm />
      </LoginContainer>
    </LoginLayout>
  );
}
