import { FC, useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBIconButton from '../../atoms/BBIconButton/BBIconButton';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import {
  getBodyExtraSmallNormalStyles,
  getBody2NormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface BBCopyToClipBoardProps {
  label: string;
  value: string;
}
const CopyToClipBoardContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { bg },
      text: { label, input },
    },
    dimensions: {
      spacing: { xSmall, smallMedium },
      radius,
    },
  } = theme;
  return {
    display: 'flex',
    padding: `${xSmall}px ${smallMedium}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: radius.medium,
    background: bg,
    '> .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'normal',
      '> .MuiListItem-root': {
        '> .MuiListItemText-root': {
          margin: 0,
          '> .MuiListItemText-primary': {
            ...getBodyExtraSmallNormalStyles(theme),
            color: label,
          },
          '> .MuiListItemText-secondary': {
            ...getBody2NormalStyles(theme),
            color: input,
          },
        },
        '> .MuiListItemSecondaryAction-root': {
          right: '12px',
        },
      },
    },
  };
});

const StyledBBIconButton = styled(BBIconButton)(({ theme }) => {
  const {
    dimensions: {
      spacing: { small },
    },
  } = theme;
  return {
    '&:hover, :active': {
      backgroundColor: 'transparent',
    },
    '> svg': {
      height: `${small + 2}px`,
      width: `${small + 2}px`,
    },
  };
});

const BBCopyToClipBoard: FC<BBCopyToClipBoardProps> = (
  props: BBCopyToClipBoardProps
) => {
  const { label, value } = props;
  const [isCopied, setIsCopied] = useState(false);

  const { tenant } = useContext(TenantContext);

  const ContentCopyIcon = useMemo(() => getIcon(tenant, 'fileCopy'), [tenant]);

  const copyTextToClipboard = async (value: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(value);
    }
  };

  // onClick handler function for the copy button
  const handleCopyClick = (value: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(value)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CopyToClipBoardContainer data-testid="bb-copy-to-clipboard-container">
      <List dense={false} disablePadding>
        <ListItem
          disableGutters
          disablePadding
          secondaryAction={
            <Tooltip title={isCopied ? 'Copied' : 'Copy'} placement="bottom">
              <StyledBBIconButton
                name="btn-copy-to-clipboard"
                onClick={() => handleCopyClick(value)}
                edge="end"
                aria-label="copy"
              >
                <ContentCopyIcon />
              </StyledBBIconButton>
            </Tooltip>
          }
        >
          <ListItemText primary={label} secondary={value} />
        </ListItem>
      </List>
    </CopyToClipBoardContainer>
  );
};

export default BBCopyToClipBoard;
