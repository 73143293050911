import { ComponentProps } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBDataTable, { GridSortModel } from '../../../organisms/BBDataTable';
// Hooks
import { useColumns } from './useColumns';
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { TransactionRow } from './types';
import { OrderByType, Sort, SortByType } from '../types';
// Constants
import { DEFAULT_NUMBER_OF_TABLE_ROWS } from '../../../../core/utils/Constants/Constants';

interface TransactionsTableProps
  extends Pick<ComponentProps<typeof BBDataTable>, 'loading' | 'rowCount'> {
  transactions: TransactionRow[];
  page: number;
  onPageChange: (page: number) => void;
  sort: Sort;
  onSortChange: (sortBy?: Sort) => void;
}

const TransactionsTable = (props: TransactionsTableProps) => {
  const {
    transactions,
    page,
    onPageChange,
    sort,
    onSortChange,
    ...tableProps
  } = props;
  const { columns } = useColumns();
  const { translate } = useTranslations();

  const onSortModelChange = (model: GridSortModel) => {
    const [sort] = model;

    if (!sort || sort.field === 'createdDate') {
      onSortChange();
      return;
    }

    onSortChange({
      sortBy: sort.field as SortByType,
      orderBy: sort.sort as OrderByType,
    });
  };

  return (
    <BBDataTable
      columnVisibilityModel={{ createdDate: false }}
      columns={columns}
      rows={transactions}
      rowHeight={70}
      {...tableProps}
      paginationMode="server"
      sortingMode="server"
      paginationModel={{ page, pageSize: DEFAULT_NUMBER_OF_TABLE_ROWS }}
      onPaginationModelChange={({ page }) => onPageChange(page)}
      sortModel={[{ field: sort.sortBy, sort: sort.orderBy }]}
      onSortModelChange={onSortModelChange}
      defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
      slotProps={{
        sortDropdown: {
          customLabels: {
            address: translate('transactions.sort.address'),
            completionDate: translate('transactions.sort.completionDate'),
          },
        },
      }}
    />
  );
};

export default TransactionsTable;
