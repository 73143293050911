import PPStatus, { StatusType } from '../../../../atoms/PPStatus';
// Hooks
import { useTranslations } from '../../../../../core/hooks';
// Types
import { DisbursementStatus } from '../../../../../core/types/DisbursementTypes';

const completionPaymentStatusToStatusMapping: Record<
  DisbursementStatus,
  StatusType
> = {
  [DisbursementStatus.Pending]: 'pending',
  [DisbursementStatus.Ready]: 'open',
  [DisbursementStatus.Processing]: 'pending',
  [DisbursementStatus.Completed]: 'completed',
  [DisbursementStatus.Failed]: 'cancelled',
  [DisbursementStatus.Cancelled]: 'cancelled',
};

interface CompletionPaymentStatusProps {
  status: DisbursementStatus;
}

const CompletionPaymentStatus = (props: CompletionPaymentStatusProps) => {
  const { status } = props;

  const { translate } = useTranslations();

  return (
    <PPStatus status={completionPaymentStatusToStatusMapping[status]}>
      {translate(`statuses.disbursement.${status.toLowerCase()}`)}
    </PPStatus>
  );
};

export default CompletionPaymentStatus;
