import { styled } from '@mui/material/styles';

//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface ButtonContainerProps extends LayoutProps {}
interface SectionContainerProps extends LayoutProps {}

export const MainContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
  };
});

export const SectionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layout',
})<SectionContainerProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    minWidth: 327,
    maxWidth: 480,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xLarge,
    padding: isMobileLayout ? 0 : `${spacing.xxxLarge}px ${spacing.xLarge}px`,
    boxSizing: 'border-box',
  };
});

export const ContentContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.medium,
    alignSelf: 'stretch',
  };
});

export const DescriptionWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing, radius },
    colours: {
      backgrounds: { bg },
    },
  } = theme;

  return {
    display: 'flex',
    padding: spacing.medium,
    flexDirection: 'column',
    gap: spacing.smallMedium,
    alignSelf: 'stretch',
    borderRadius: radius.small,
    background: bg,
  };
});

export const StyledSpan = styled('span')(() => {
  return {
    '> p.MuiTypography-root': {
      display: 'inline',
    },
    'a > p.MuiTypography-root': {
      display: 'inline',
    },
  };
});

export const CheckboxContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing, radius },
    colours: {
      borders: { border },
      backgrounds: { bgAlt },
    },
  } = theme;

  return {
    padding: spacing.medium,
    borderRadius: radius.small,
    border: `1px solid ${border}`,
    background: bgAlt,
  };
});

export const ButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layout',
})<ButtonContainerProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacing.xSmall,
    width: isMobileLayout ? '100%' : 327,
  };
});

export const StyledUl = styled('ul')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    margin: 0,
    paddingLeft: spacing.medium,
  };
});
