import { FC } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBHeader from '../../molecules/BBHeader';
import BBSideBarNav from '../../molecules/BBSideBarNav';

interface BBMobileMenuProps {
  fullName: string;
  initials: string;
  customerId: string;
  isBuyerFlow: boolean;
}

// TODO - removed the test case for this component intensionally,
// was facing few issues and consuming too much time, we will revisit test case later

const BBMobileMenu: FC<BBMobileMenuProps> = (props: BBMobileMenuProps) => {
  const { fullName, customerId, initials, isBuyerFlow } = props;

  return (
    <>
      <BBHeader
        fullName={fullName}
        initials={initials}
        customerId={customerId}
      />
      <BBSideBarNav open={true} isBuyerFlow={isBuyerFlow} />
    </>
  );
};

export default BBMobileMenu;
