import * as yup from 'yup';

export const useValidations = () => {
  const validations = yup
    .object({
      otp: yup
        .array()
        .of(
          yup
            .string()
            .matches(/^[0-9]/)
            .required()
        )
        .length(5),
    })
    .required();
  return { validations };
};
