import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

// Components - Atoms, Molecules, Organisms, Pages
import ConfirmationScreen from './ConfirmationScreen';
import CompletedScreen from './CompletedScreen';
// API Wrappers
import { useGetPaymentRequestByIdQuery } from '../../../../redux/api';

export type ConfirmationViewType = 'confirmation-screen' | 'completed-screen';

export type RouterParams = {
  transactionId: string;
  paymentRequestId: string;
};

const Confirmation: FC = () => {
  const [viewType, setViewType] = useState<ConfirmationViewType>(
    'confirmation-screen'
  );

  let currentView: JSX.Element;

  const { transactionId, paymentRequestId } = useParams<RouterParams>();

  const { isFetching, isLoading, data, error } = useGetPaymentRequestByIdQuery(
    paymentRequestId ? Number(paymentRequestId) : skipToken
  );

  useEffect(() => {
    if (viewType !== 'confirmation-screen') {
      setViewType('confirmation-screen');
    }
  }, []);

  switch (viewType) {
    case 'confirmation-screen':
      currentView = (
        <ConfirmationScreen
          setViewType={setViewType}
          isFetching={isFetching}
          isLoading={isLoading}
          data={data}
          error={error}
        />
      );
      break;
    case 'completed-screen':
      currentView = (
        <CompletedScreen data={data} transactionId={transactionId ?? ''} />
      );
      break;
    default:
      currentView = <></>;
  }

  return <>{currentView}</>;
};

export default Confirmation;
