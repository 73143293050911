import { forwardRef, InputHTMLAttributes } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { WalletList } from './Wallets.styles';
import Wallet from './Wallet';
// Types
import { Wallet as WalletType } from '../../../../../../core/types/WalletTypes';

interface WalletsProps extends InputHTMLAttributes<HTMLInputElement> {
  wallets: WalletType[];
}

const Wallets = forwardRef<HTMLInputElement, WalletsProps>((props, ref) => {
  const { wallets, ...inputProps } = props;

  return (
    <WalletList>
      {wallets.map((wallet) => (
        <Wallet
          key={wallet.walletNumber}
          wallet={wallet}
          {...inputProps}
          ref={ref}
        />
      ))}
    </WalletList>
  );
});

export default Wallets;
