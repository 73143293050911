import { FC, useContext } from 'react';

//Hooks
import { useTranslations } from '../../../../core/hooks';
import useInitialCalls from './useInitialCalls';

//Components
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
import BBStepPanel from '../../../organisms/BBStepPanel/BBStepPanel';
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
import DetailsForm from './DetailsForm';
import Page from '../../../organisms/Page';

const DisbursementDetails: FC = () => {
  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const {
    walletDetails,
    isLoadingWallets,
    paymentTypeOptions,
    isPaymentTypeOptionsLoading,
    disbursementDetails,
    areDisbursementDetailsLoading,
    transactionId,
    walletDetailsError,
    paymentTypeError,
    disbursementId,
  } = useInitialCalls();

  const isLoading =
    isPaymentTypeOptionsLoading || areDisbursementDetailsLoading;

  const error = walletDetailsError || paymentTypeError;

  const activeStep: number = 0;

  return (
    <Page isLoading={isLoading} error={error}>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={translate('disbursement.disbursementDetails.title')}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <DetailsForm
          activeStep={activeStep}
          disbursementDetails={disbursementDetails}
          paymentTypeOptions={paymentTypeOptions}
          walletDetails={walletDetails ?? []}
          transactionId={transactionId}
          isLoadingWallets={isLoadingWallets}
          disbursementId={disbursementId}
        />
        <BBStepPanel
          activeStep={activeStep}
          stepperType="disbursement"
          idValues={{
            transactionId: transactionId,
            disbursementId: disbursementId,
          }}
        />
      </MainContainer>
    </Page>
  );
};

export default DisbursementDetails;
