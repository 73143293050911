import { ElementType, memo, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../BBText';
// Types
import { TypographyTypes } from '../../../core/types/TypographyTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'text'
  | 'destructive';

interface StyledButtonProps extends ButtonProps {
  btnType?: ButtonTypes;
  disableHover?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'btnType' &&
    prop !== 'startIconType' &&
    prop !== 'endIconType' &&
    prop !== 'disableHover',
})<StyledButtonProps>(({ theme, btnType, disableHover, size }) => {
  const isPrimaryBtn: boolean = btnType === 'primary';
  const isSecondaryBtn: boolean = btnType === 'secondary';
  const isOutlinedBtn: boolean = btnType === 'outlined';
  const isDestructiveBtn: boolean = btnType === 'destructive';

  const isLargeSize: boolean = size === 'large';
  const isMediumSize: boolean = size === 'medium';

  const {
    colours: {
      buttons: {
        primary: primaryButton,
        secondary: secondaryButton,
        outline: outlinedButton,
        text: textButton,
        destructive: destructiveButton,
      },
    },
    dimensions: {
      radius: { btn: buttonBorderRadius },
      spacing,
    },
  } = theme;

  const xxSmallSpacing: string = spacing.xxSmall + 'px';
  const xSmallSpacing: string = spacing.xSmall + 'px';
  const smallSpacing: string = spacing.small + 'px';
  const mediumSpacing: string = spacing.medium + 'px';
  const largeSpacing: string = spacing.large + 'px';

  let backgroundColor: string = outlinedButton.bg;
  let backgroundHoverColor: string = outlinedButton.hover.bg;
  let backgroundFocusColor: string = 'inherit';
  let color: string = textButton.text;
  let hoverColor: string = textButton.hover.text;
  let focusColor: string = textButton.focus.text;
  let focusBorderColor: string = textButton.focus.border;
  if (isPrimaryBtn) {
    backgroundColor = primaryButton.bg;
    backgroundHoverColor = primaryButton.hover.bg;
    backgroundFocusColor = primaryButton.focus.bg;
    color = primaryButton.text;
    hoverColor = primaryButton.hover.text;
    focusColor = primaryButton.focus.text;
    focusBorderColor = primaryButton.focus.focusBorder;
  } else if (isSecondaryBtn) {
    backgroundColor = secondaryButton.bg;
    backgroundHoverColor = secondaryButton.hover.bg;
    backgroundFocusColor = secondaryButton.focus.bg;
    focusBorderColor = secondaryButton.focus.focusBorder;
    color = secondaryButton.text;
    hoverColor = secondaryButton.hover.text;
    focusColor = secondaryButton.focus.text;
  } else if (isOutlinedBtn) {
    backgroundFocusColor = outlinedButton.focus.bg;
    color = outlinedButton.text;
    hoverColor = outlinedButton.hover.text;
    focusColor = outlinedButton.focus.text;
    focusBorderColor = outlinedButton.focus.focusBorder;
  } else if (isDestructiveBtn) {
    backgroundColor = destructiveButton.bg;
    backgroundHoverColor = destructiveButton.hover.bg;
    backgroundFocusColor = destructiveButton.focus.bg;
    color = destructiveButton.text;
    hoverColor = destructiveButton.hover.text;
    focusColor = destructiveButton.focus.text;
    focusBorderColor = destructiveButton.focus.focusBorder;
  }

  return {
    padding: isLargeSize
      ? `${smallSpacing} ${largeSpacing}`
      : isMediumSize
        ? `${xSmallSpacing} ${mediumSpacing}`
        : `${xxSmallSpacing} ${mediumSpacing}`,
    background: backgroundColor,
    borderRadius: buttonBorderRadius,
    ...(isSecondaryBtn && {
      border: 'none',
    }),
    ...(isOutlinedBtn && {
      boxShadow: `${outlinedButton.border}  0px 0px 0px 1px`,

      '&, &:disabled': {
        border: 'none',
      },
    }),

    '.MuiTypography-root': {
      color: color,
    },

    '&:disabled': {
      background: backgroundColor,
      opacity: 0.5,
    },

    '.MuiButton-icon': {
      '> svg > path': {
        fill: 'currentColor',
      },
    },

    '.MuiButton-endIcon': {
      marginLeft: spacing.xSmall,
      ...(isOutlinedBtn && { color: outlinedButton.icon }),
      '& > span > div': {
        display: 'flex',
      },
    },

    '&:hover': {
      boxShadow: 'none',
      ...(!disableHover
        ? {
            background: backgroundHoverColor,
          }
        : {
            background: backgroundColor,
          }),
      ...(isSecondaryBtn && {
        border: 'none',
      }),
      ...(isOutlinedBtn && {
        boxShadow: `${outlinedButton.hover.border}  0px 0px 0px 1px`,
        border: 'none',
      }),
      '.MuiTypography-root': {
        color: hoverColor,
      },
    },

    '&.Mui-focusVisible': {
      boxShadow: 'none',
      outline: `2px solid ${focusBorderColor}`,
      ...((isPrimaryBtn || isSecondaryBtn) && {
        outlineOffset: 2,
      }),

      '.MuiTypography-root': {
        color: focusColor,
      },

      background: backgroundFocusColor,
    },
  };
});

interface IconContainerProps {
  btnType?: ButtonTypes;
}

const IconContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'btnType',
})<IconContainerProps>(({ theme, btnType }) => {
  const {
    colours: {
      buttons: {
        primary: primaryButton,
        secondary: secondaryButton,
        outline: outlinedButton,
        text: textButton,
      },
    },
  } = theme;

  const COLOUR_BUTTON_PRIMARY_ICON: string = primaryButton.icon;
  const COLOUR_BUTTON_SECONDARY_ICON: string = secondaryButton.icon;
  const COLOUR_BUTTON_OUTLINED_ICON: string = outlinedButton.icon;
  const COLOUR_BUTTON_TEXT_ICON: string = textButton.icon;

  return {
    ...(btnType === 'primary' && { color: COLOUR_BUTTON_PRIMARY_ICON }),
    ...(btnType === 'secondary' && { color: COLOUR_BUTTON_SECONDARY_ICON }),
    ...(btnType === 'outlined' && { color: COLOUR_BUTTON_OUTLINED_ICON }),
    ...(btnType === 'text' && { color: COLOUR_BUTTON_TEXT_ICON }),
    display: 'flex',

    '> svg > path': {
      fill: 'currentColor',
    },
  };
});

export type BBButtonProps<Component extends ElementType> = StyledButtonProps &
  ButtonProps<Component, { component?: Component }> & {
    children: string;
    startIconType?: string;
    endIconType?: string;
    disableHover?: boolean;
  };

const BBButton = <Component extends ElementType>(
  props: BBButtonProps<Component>
) => {
  const {
    size = 'large',
    btnType = 'primary',
    startIconType,
    endIconType,
    disableHover = false,
    ...rest
  } = props;

  const buttonTextVariant = size === 'large' ? 'body1' : 'body2';
  const buttonTextType: TypographyTypes = `button-${size}`;
  const { tenant } = useContext(TenantContext);

  const StartArrowIcon = useMemo(() => getIcon(tenant, 'backArrow'), [tenant]);
  const EndArrowIcon = useMemo(() => getIcon(tenant, 'forwardArrow'), [tenant]);

  return (
    <StyledButton
      variant={
        btnType === 'outlined'
          ? 'outlined'
          : btnType === 'text'
            ? 'text'
            : 'contained'
      }
      size={size}
      btnType={btnType}
      startIcon={
        startIconType && (
          <IconContainer btnType={btnType}>
            <StartArrowIcon data-testid={`bb-icon-${startIconType}`} />
          </IconContainer>
        )
      }
      endIcon={
        endIconType && (
          <IconContainer btnType={btnType}>
            {/* <BBLoader type="inverse" /> */}
            <EndArrowIcon data-testid={`bb-icon-${endIconType}`} />
          </IconContainer>
        )
      }
      data-testid="bb-button"
      disableHover={disableHover}
      disableRipple
      {...rest}
    >
      <BBText
        variant={buttonTextVariant}
        type={buttonTextType}
        text={props.children}
      />
    </StyledButton>
  );
};

export default memo(BBButton);
