// Types
import { Account } from '../AccountTypes';
import { NewAccountType } from '../RecipientTypes';
import { Payment } from '../PaymentTypes';

export enum PaymentStatus {
  Cancelled = 'Cancelled',
  Sent = 'Sent',
  InProgress = 'In Progress',
  Completed = 'Completed',
  AwaitingFunds = 'Awaiting Funds',
}

export enum PaymentMethods {
  Wallet = 'Wallet',
  Bank = 'Manual Bank Transfer',
}

export enum InvitationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  MayBeLater = 'MayBeLater',
}

export enum PaymentRequestType {
  ReservationDeposit = 'Reservation Deposit',
  Deposit = 'Deposit',
  FundsForCompletion = 'Funds for Completion',
  Other = 'Other',
}

export enum PaymentTrackingStatus {
  ON_TRACK = 'ON_TRACK',
  OFF_TARGET = 'OFF_TARGET',
  LATE = 'LATE',
}

export type PaymentMethod = `${PaymentMethods}`;

export interface PaymentRequestDetails {
  accountToBeFunded: AccountToBeFundedDetails | null;
  createdDate: string;
  crmAccount?: Account[];
  crmAccountIds?: number[];
  currency?: string;
  dueDate: string;
  fraudAlert: boolean | null;
  id: number;
  invitationStatus?: InvitationStatus;
  leftToPay?: number;
  paidToDate?: number;
  payAmount: number;
  paymentRequestStatus: PaymentStatus;
  paymentTrackingStatus: PaymentTrackingStatus;
  paymentType: PaymentRequestType;
  paymentTypeDescription: string;
  percentageComplete?: number;
  transactionId?: number;
  updatedDate: string;
  payments: Omit<Payment, 'paymentRequest'>[];
}

export interface PaymentRequestTypes {
  value: string;
  label: string;
}

export interface GetBankAccountFieldsRequestData {
  countryCode: string;
  currency: string;
}

export interface BankAccountFieldsResponse {
  callType: string;
  uiBlock: UIBlock;
}

interface UIBlock {
  id: string;
  uiFields: AccountField[];
}

export interface AccountField {
  id: string;
  displayId: string;
  description: string;
  validation: string;
  type: string | null;
}

export interface BankAccountFields {
  bankAccountType: NewAccountType;
  firstName: string;
  lastName: string;
  companyName: string;
  bankName: string | null;
  branchAddress: string | null;
  country: string;
  currency: string;
  valueOne: string | null;
  valueTwo: string | null;
  valueThree: string | null;
  bankStreet?: string;
  bankCity?: string;
  bankPostZip?: string;
}

export interface AddBankAccountRequest extends BankAccountFields {}

export interface BankAccountDetailsResponse extends BankAccountFields {
  id: number;
}

export interface AccountToBeFundedDetails {
  id: number;
  bankAccount: BankAccountDetailsResponse | null;
  paymentMethod: PaymentMethod;
}

export interface UpdateAccountToBeFundedRequest {
  bankAccount: BankAccountRequestData | null;
  paymentMethod: PaymentMethod;
  paymentRequestId: number;
}

interface BankAccountRequestData {
  id: number;
}

export interface UpdateAccountToBeFundedResponse {
  bankAccount: BankAccountRequestData;
  id: number;
  paymentMethod: PaymentMethod;
}

export interface UpdateFraudAlertRequest {
  createdDate: string;
  crmAccountIds?: number[];
  currency?: string;
  dueDate: string;
  fraudAlert: boolean | null;
  id: number;
  invitationStatus?: InvitationStatus;
  payAmount: number;
  paymentType: string;
  paymentTypeDescription: string;
  transactionId?: number;
  updatedDate: string;
}

export type TransactionDetailsFilterType = 'request' | 'completion';

export interface TransactionDetailsFilter {
  key: TransactionDetailsFilterType;
  disabled: boolean;
}
