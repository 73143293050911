import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Contexts
import { LayoutContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import {
  Container,
  HighlightedArea,
  Key,
  KeyValues,
  Value,
} from '../../styles';
import {
  MakePaymentButton,
  Progress,
  SummaryContainer,
} from './Summary.styles';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import {
  PaymentMethods,
  PaymentRequestDetails,
  PaymentStatus,
} from '../../../../../core/types/PaymentRequestsTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
import {
  getFormattedAmount,
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../../core/utils/Format';
import { getRecipientDescription } from './utils';

interface SummaryProps {
  paymentRequest: PaymentRequestDetails;
}

const Summary = (props: SummaryProps) => {
  const { paymentRequest } = props;
  const {
    payAmount,
    currency,
    dueDate,
    accountToBeFunded,
    leftToPay,
    paidToDate,
    percentageComplete,
    paymentRequestStatus,
  } = paymentRequest;

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const isWalletRecipient: boolean =
    accountToBeFunded?.paymentMethod === PaymentMethods.Wallet;
  const canMakePayment: boolean =
    paymentRequestStatus === PaymentStatus.AwaitingFunds ||
    paymentRequestStatus === PaymentStatus.Sent;

  return (
    <SummaryContainer ownerState={{ layout }}>
      <Container>
        <KeyValues variant={isMobileLayout ? 'vertical' : 'horizontal'}>
          <Key>
            {translate('buyer.paymentRequestDetails.summary.totalValueText')}
          </Key>
          <Value>{getFormattedCurrencyValue(payAmount, currency)}</Value>
          <Key>
            {translate('buyer.paymentRequestDetails.summary.paidToDateText')}
          </Key>
          <Value>{getFormattedCurrencyValue(paidToDate, currency)}</Value>
          <Key>
            {translate('buyer.paymentRequestDetails.summary.dueDateText')}
          </Key>
          <Value>{getFormattedDate(dueDate)}</Value>
        </KeyValues>
        <Progress value={percentageComplete ?? 0} />
        <HighlightedArea>
          <KeyValues>
            <Key>
              {translate('buyer.paymentRequestDetails.summary.leftToPayText')}
            </Key>
            <Value>
              <BBText
                text={`-${getFormattedAmount(leftToPay, currency)}`}
                variant="h3"
                type="bold"
                component="span"
              />
              <BBText
                text={` ${getFormattedValue(currency)}`}
                variant="body2"
                type="medium"
                component="span"
              />
            </Value>
          </KeyValues>
        </HighlightedArea>
      </Container>
      <Container>
        <KeyValues variant="vertical">
          <Key>
            {translate('buyer.paymentRequestDetails.summary.recipientText')}
          </Key>
          <Value>
            {accountToBeFunded && (
              <>
                {isWalletRecipient &&
                  translate('buyer.paymentRequestDetails.wallet', { currency })}

                {!isWalletRecipient &&
                  getRecipientDescription(accountToBeFunded)}
              </>
            )}
          </Value>
        </KeyValues>

        {canMakePayment && (
          <MakePaymentButton
            size="small"
            component={Link}
            to="payments/new"
            ownerState={{ layout }}
          >
            {translate(
              'buyer.paymentRequestDetails.summary.makePaymentButtonText'
            )}
          </MakePaymentButton>
        )}
      </Container>
    </SummaryContainer>
  );
};

export default Summary;
