//Contexts
import { useFormContext } from 'react-hook-form';

//Components
import {
  AvailableBalance,
  CardContainer,
  WalletCurrencyWrapper,
  WalletDetails,
} from './RadioCard.styles';
import BBText from '../../../atoms/BBText';
import { DisbursementDetailsFormValues } from './types';
import BBRadioButton from '../../../atoms/BBRadioButton';

//Utils
import { getFormattedCurrencyValue } from '../../../../core/utils/Format';
import { useTranslations } from '../../../../core/hooks';
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';
import CurrencyIcon from '../../Buyer/CreatePayment/PaymentMethod/Wallets/CurrencyIcon';

interface RadioCardProps {
  id: string | null;
  walletDetails: WalletWithUserDetails;
}

export default function RadioCard(props: RadioCardProps) {
  const { translate } = useTranslations();

  const { id, walletDetails } = props;

  const { setValue, trigger, getValues } =
    useFormContext<DisbursementDetailsFormValues>();
  const radioValue = getValues('walletId');

  const disabled = Number(walletDetails.availableBalance) <= 0;

  const handleChange = () => {
    if (!disabled) {
      setValue('walletId', id, { shouldTouch: true, shouldDirty: true });
      trigger();
    }
  };

  const checked: boolean = radioValue === id;
  return (
    <CardContainer
      checked={checked}
      disabled={disabled}
      onClick={handleChange}
      data-testid="radio-card-container"
    >
      <WalletCurrencyWrapper>
        <CurrencyIcon currency={walletDetails.currency} />
        <WalletDetails>
          <BBText
            text={`${walletDetails.crmAccount.name} | ${getFormattedCurrencyValue(Number(walletDetails.totalBalance), walletDetails.currency)}`}
            type="medium"
            variant="body2"
          />
          <AvailableBalance
            text={translate(
              'disbursement.disbursementDetails.fields.walletCardSubtitle',
              {
                availableBalance: getFormattedCurrencyValue(
                  Number(walletDetails.availableBalance),
                  walletDetails.currency
                ),
              }
            )}
            type="normal"
            variant="subtitle1"
          />
        </WalletDetails>
      </WalletCurrencyWrapper>
      {checked && (
        <BBRadioButton
          name={`walletId_${id}`}
          value={id}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
        />
      )}
    </CardContainer>
  );
}
