import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export type SizeProps =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-huge';

export interface TypeProps {
  type: 'primary' | 'secondary' | 'inverse';
}

interface BBLoaderProps
  extends Pick<CircularProgressProps, 'variant' | 'value'> {
  size?: SizeProps;
  type?: TypeProps['type'];
  className?: string;
}

const LoaderContainer = styled('div')(() => {
  return {
    position: 'relative',
  };
});

const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'type',
})<TypeProps>(({ theme, type }) => {
  const {
    colours: {
      icon: { accent, active, iconAlt },
    },
  } = theme;

  const color: string =
    type === 'primary' ? accent : type === 'secondary' ? active : iconAlt;

  return {
    color: color,

    '> svg > circle.MuiCirculaBBrogress-circle': {
      strokeLinecap: 'round',
    },
  };
});

const BBLoader: FC<BBLoaderProps> = (props: BBLoaderProps) => {
  const {
    size = 'x-small',
    type = 'primary',
    className,
    ...progressProps
  } = props;

  const getLoaderSize = useMemo(() => {
    switch (size) {
      case 'x-small':
        return 18;

      case 'small':
        return 24;

      case 'medium':
        return 36;

      case 'large':
        return 56;

      case 'x-large':
        return 72;

      case 'xx-huge':
        return 80;
    }
  }, [size]);

  return (
    <LoaderContainer className={className}>
      <StyledCircularProgress
        size={getLoaderSize}
        type={type}
        thickness={4}
        {...progressProps}
      />
    </LoaderContainer>
  );
};

export default BBLoader;
