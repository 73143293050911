import { createContext, useContext } from 'react';

interface BBMenuContextValue {
  onClose: () => void;
}

const BBMenuContext = createContext<BBMenuContextValue | null>(null);

export const useMenuContext = () => {
  const value = useContext(BBMenuContext);

  if (!value) {
    throw new Error('useMenuContext must be used within a BBMenuContext');
  }

  return value;
};

export default BBMenuContext;
