import { Box, styled } from '@mui/material';

//Components
import BBText from '../../../atoms/BBText';

interface CardContainerProps {
  checked: boolean;
  disabled: boolean;
}

export const CardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'checked' && prop !== 'disabled',
})<CardContainerProps>(({ theme, checked, disabled }) => {
  const {
    colours: {
      backgrounds: { bgAlt, activeLight },
      text: { disabled: textDisabled },
      borders: { border, active },
    },
    dimensions: { spacing, radius },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    border: checked ? `1px solid ${active}` : `1px solid ${border}`,
    backgroundColor: checked ? activeLight : bgAlt,
    padding: spacing.xSmall,
    borderRadius: radius.small,
    cursor: 'pointer',
    img: {
      width: 26,
      height: 26,
    },
    ...(disabled && {
      opacity: 0.6,
      color: textDisabled,
      cursor: 'not-allowed',
      'p:nth-of-type(2)': {
        color: textDisabled,
      },
    }),
  };
});

export const WalletCurrencyWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    overflowWrap: 'anywhere',
    gap: spacing.small,
  };
});

export const AvailableBalance = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    color: textWeak,
    fontWeight: 450,
  };
});

export const WalletDetails = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});
