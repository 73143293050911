import { createAsyncThunk } from '@reduxjs/toolkit';

// API Wrapper
import axiosWrapper from './axiosWrapper';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import { clearTokens } from '../../core/utils/AuthUtils';

const getProfileDetails = createAsyncThunk(
  'propertypay-services/getProfileDetails',
  async (_, thunkAPI) => {
    try {
      const response = await axiosWrapper(
        'getProfileDetails',
        'GET',
        thunkAPI,
        null
      );
      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      if (Number(error?.status || error?.response?.status) >= 500) {
        clearTokens();
        return thunkAPI.rejectWithValue({
          error: 'Something went wrong. please login again.',
        });
      }
      clearTokens();
      return thunkAPI.rejectWithValue({
        error: error?.response?.data?.response_description,
      });
    }
  }
);

export default getProfileDetails;
