import { styled } from '@mui/material/styles';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

const Container = styled('header')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing.xSmall}px ${spacing.xxSmall}px`,
  };
});

const NavigationButton = styled('button')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  return {
    display: 'inline-flex',
    appearance: 'none',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: colours.backgrounds.activeLightest,
    padding: spacing.xxxSmall,
    borderRadius: radius.medium,

    svg: {
      width: 24,
      height: 24,

      path: {
        fill: colours.icon.active,
      },
      // TODO: Styles for other button states are not ready yet.
    },
  };
});

interface CalendarHeaderProps extends PickersCalendarHeaderProps<Dayjs> {}

const CalendarHeader = (props: CalendarHeaderProps) => {
  const { currentMonth, onMonthChange } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const [ChevronLeftIcon, ChevronRightIcon] = useMemo(
    () => [
      getIcon(tenant, 'navigationChevronLeftIcon'),
      getIcon(tenant, 'navigationChevronRightIcon'),
    ],
    [tenant]
  );

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month'), 'left');

  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, 'month'), 'right');

  return (
    <Container>
      <NavigationButton
        onClick={selectPreviousMonth}
        title={translate('calendar.previousMonth')}
        aria-label={translate('calendar.previousMonth')}
      >
        <ChevronLeftIcon />
      </NavigationButton>
      <BBText
        variant="subtitle1"
        type="bold"
        text={currentMonth.format('MMMM YYYY')}
      />
      <NavigationButton
        onClick={selectNextMonth}
        title={translate('calendar.nextMonth')}
        aria-label={translate('calendar.nextMonth')}
      >
        <ChevronRightIcon />
      </NavigationButton>
    </Container>
  );
};

export default CalendarHeader;
