import * as yup from 'yup';

// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
import { useErrorTranslations } from '../../../../core/hooks';
// Types
import { AddressFieldKeys } from '../../../../core/types/addressLookupTypes';
// Utils
import {
  Countries,
  CountryFieldMapping,
} from '../../../../core/utils/AddressLookup/CountryFieldsMatrix';
import { getTomorrowDate } from './utils';
// Constants
import {
  FILE_REFERENCE_MAX_LENGTH,
  REGEX,
} from '../../../../core/utils/Constants/Constants';

const shouldValidateField = (
  selectedCountry: Countries | string,
  field: AddressFieldKeys
) => {
  const addressFields = CountryFieldMapping[selectedCountry]
    ? CountryFieldMapping[selectedCountry]
    : CountryFieldMapping['default'];

  return addressFields && addressFields[field].visible;
};

export const useValidations = () => {
  const { translateError } = useErrorTranslations(
    'createTransaction.transactionDetails.fields'
  );
  const { translate } = useTranslations();

  const getAddressErrorMessage = (field: string) =>
    translate(`addressLookup.error.${field}`);

  let validationSchema = yup.object({
    // File reference
    fileReference: yup
      .string()
      .optional()
      .max(
        FILE_REFERENCE_MAX_LENGTH,
        translateError('fileReference', 'maxCharacters', {
          number: FILE_REFERENCE_MAX_LENGTH,
        })
      ),
    // Completion Date
    completionDate: yup
      .date()
      .typeError(translateError('completionDate', 'invalid'))
      .required(translateError('completionDate', 'required'))
      .min(
        getTomorrowDate(),
        translate(
          'createTransaction.transactionDetails.errors.futureCompletionDate'
        )
      ),
    // Country
    country: yup
      .string()
      .trim()
      .required(translateError('country', 'required')),
    // Address
    address: yup.object().shape({
      addressLine1: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'addressLine1'),
        then: (schema) =>
          schema.required(getAddressErrorMessage('addressLine1')),
      }),
      addressLine2: yup.string().optional(),
      addressLine3: yup.string().optional(),
      suburbCity: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'suburbCity'),
        then: (schema) => schema.required(getAddressErrorMessage('suburbCity')),
      }),
      province: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'province'),
        then: (schema) => schema.required(getAddressErrorMessage('province')),
      }),
      townCity: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'townCity'),
        then: (schema) => schema.required(getAddressErrorMessage('townCity')),
      }),
      county: yup.string().optional(),
      state: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'state'),
        then: (schema) => schema.required(getAddressErrorMessage('state')),
      }),
      postcode: yup.string().when('country', {
        is: (country: string) => shouldValidateField(country, 'postcode'),
        then: (schema) => schema.required(getAddressErrorMessage('postcode')),
      }),
    }),
    // Purchase value
    purchaseValue: yup
      .string()
      .trim()
      .required(translateError('purchaseValue', 'required'))
      .matches(
        REGEX.paymentValue,
        translate('createTransaction.transactionDetails.errors.purchaseValue')
      ),
    // Purchase value currency
    purchaseValueCurrency: yup
      .string()
      .trim()
      .required(translateError('purchaseValueCurrency', 'required')),
  });

  return { validationSchema };
};
