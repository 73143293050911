import { OrgImageMapping, ImageObject } from '../../types/ImageOrgTypes';
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
import { CDImageObject } from '../../TenantProvider/tenants/currenciesdirect/cdImages';
import { TENANTS } from '../Constants/Constants';

export const IMAGE_ORG_MAPPING: OrgImageMapping = {
  currenciesdirect: CDImageObject,
};

export const getImage = (tenant: OrganizationCodeTypes | undefined, name: keyof ImageObject) => {
  return tenant
    ? IMAGE_ORG_MAPPING[tenant][name]
    : IMAGE_ORG_MAPPING[TENANTS.CURRENCIES_DIRECT][name];
};
