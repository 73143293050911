import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Contexts
import {
  LanguageContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../../atoms/BBText';
import BBLink from '../../atoms/BBLink';
import BBLogoWrapper from '../../organisms/BBLogoWrapper';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Constants
import {
  FOOTER_LINKS_CONFIG_ARRAY,
  REDPIN_COMPANY_LINK,
} from '../../../core/utils/Constants/Constants';

export interface FooterLinkConfigType {
  textKey: string;
  hrefKey: string;
}

const FooterContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignSelf: 'stretch',
  };
});

const StyledPoweredByText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeakest },
    },
  } = theme;

  return {
    color: textWeakest,
  };
});

const StyledBBLogoWrapper = styled(BBLogoWrapper)(() => {
  return {
    width: '108px',
    height: '36px',
  };
});

const LinksContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    paddingTop: xxSmall,
  };
});

const StyledBBLink = styled(BBLink)(({ theme }) => {
  const {
    colours: {
      text: { textWeakest },
    },
  } = theme;

  return {
    color: textWeakest,
    ':hover': {
      color: textWeakest,
    },
  };
});

const BBFooter: FC = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);

  return (
    <FooterContainer data-testid="bb-footer-container">
      {isNotMobileLayout && (
        <>
          <StyledPoweredByText
            variant="subtitle2"
            type="normal"
            text={translate('sidebar.footer.poweredBy')}
          />
          <StyledBBLogoWrapper
            logoVariant={'logo'}
            link={REDPIN_COMPANY_LINK}
            target={'_blank'}
          />
        </>
      )}
      <LinksContainer>
        {FOOTER_LINKS_CONFIG_ARRAY.map((linkDetails, index) => (
          <StyledBBLink
            key={`${linkDetails.textKey}-${index}`}
            variant={'subtitle1'}
            type={'normal'}
            text={translate(linkDetails.textKey)}
            href={linkDetails.hrefKey}
            underline={'none'}
            target="_blank"
            data-testid="bb-link-footer"
          />
        ))}
      </LinksContainer>
    </FooterContainer>
  );
};

export default BBFooter;
