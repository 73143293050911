import { Box, Divider, LinkProps, styled } from '@mui/material';
import { Link } from 'react-router-dom';
//Components
import BBText from '../../atoms/BBText';
//Types
import { LayoutProps } from '../../../core/types/LayoutProps';
//Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface LinkWithId extends LinkProps {
  isLabelId: boolean;
}

interface HeaderContainerProps extends LayoutProps {}

interface StyledUserNameProps extends LayoutProps {}

export const HeaderContainer = styled(Box)<HeaderContainerProps>(({
  theme,
  layout,
}) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const {
    colours: {
      backgrounds: { bg, activeDark },
    },
    dimensions: {
      spacing: { xSmall, small, xLarge, xxxLarge },
      radius: { card },
    },
  } = theme;

  return {
    display: 'flex',
    alignSelf: 'stretch',
    ...(isMobileLayout
      ? {
          backgroundColor: activeDark,
          padding: `${small}px ${xxxLarge}px ${small}px ${small}px`,
          alignItems: 'center',
          gap: small,
          borderRadius: card,
          border: ' 1px solid #1F2A37', // Token not available
        }
      : {
          justifyContent: 'space-between',
          alignContent: 'center',
          flexWrap: 'wrap',
          backgroundColor: bg,
          padding: `${xSmall}px ${xLarge}px`,
          height: '80px',
          minHeight: '80px',
          boxSizing: 'border-box',
        }),
  };
});

export const GreetingContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto 0',
    gap: xSmall,
    svg: {
      cursor: 'pointer',
    },
  };
});

export const StyledGreetingText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;

  return {
    color: text,
  };
});

export const StyledDateText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    color: textWeak,
    margin: 'auto 0',
  };
});

export const UserInfoContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { small },
    },
  } = theme;

  return {
    display: 'flex',
    gap: small,
  };
});

export const StyledUserName = styled(BBText)<StyledUserNameProps>(({
  theme,
  layout,
}) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const {
    colours: {
      text: { textWeak, textAlt },
    },
  } = theme;

  return {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color: isMobileLayout ? textAlt : textWeak,
    flex: 1,
    margin: 'auto 0',
  };
});

export const UserAvatar = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
      borders: { borderLighter },
      text: { active },
    },
    dimensions: {
      radius: { circle },
    },
  } = theme;

  return {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: circle,
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    width: '40px',
    height: '40px',
    border: `1px solid ${borderLighter}`,
    backgroundColor: bgAlt,
    h6: {
      color: active,
    },
  };
});

export const MobileGreetingContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
  };
});

export const StyledUserIdText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeaker },
    },
  } = theme;

  return {
    color: textWeaker,
  };
});

export const StyledNavDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
    dimensions: { spacing },
  } = theme;
  return {
    color: text,
    margin: `${spacing.xxxSmall}px ${spacing.xxSmall}px`,
    background: text,
    transform: 'rotate(22deg)',
  };
});

export const StyledList = styled('ul')(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;
  return {
    display: 'flex',
    color: text,
    padding: 0,
  };
});

export const LinkText = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isIdLabel',
})<LinkWithId>(({ theme, isLabelId }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;
  return {
    color: text,
    ...(isLabelId && {
      maxWidth: '16%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };
});
