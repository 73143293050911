import { FC, MouseEvent, KeyboardEvent, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBLink from '../../atoms/BBLink';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Constants
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';

interface BBEnterManualAddressLinkProps extends IsMobileOptimisedProps {
  handleManualAddressClick: (
    event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => void;
}

interface IsMobileOptimisedProps {
  isMobileOptimised?: boolean;
}

interface EnterAddressManuallyProps extends BoxProps, IsMobileOptimisedProps {}

const EnterAddressManually = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileOptimised',
})<EnterAddressManuallyProps>(({ theme, isMobileOptimised }) => {
  const {
    colours: {
      backgrounds: { activeLightest },
    },
    dimensions: { spacing, radius },
  } = theme;

  return {
    padding: `${spacing.xSmall}px ${spacing.small}px`,
    background: activeLightest,
    borderRadius: `0px 0px ${radius.small}px ${radius.small}px`,
    display: 'flex',
    alignItems: 'center',
    ...(!isMobileOptimised && {
      marginTop: '16px',
    }),
  };
});

const BBEnterManualAddressLink: FC<BBEnterManualAddressLinkProps> = (
  props: BBEnterManualAddressLinkProps
) => {
  const { handleManualAddressClick, isMobileOptimised = false } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const enterAddressManually: string = translate(
    'addressLookup.enterAddressManually'
  );

  return (
    <EnterAddressManually
      isMobileOptimised={isMobileOptimised}
      data-testid="bb-link-container"
    >
      <BBLink
        underline="hover"
        onClick={(e: MouseEvent<HTMLAnchorElement>) =>
          handleManualAddressClick(e)
        }
        type="medium"
        text={enterAddressManually}
        onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
          e.key === ENTER_KEY && handleManualAddressClick(e);
        }}
        href=""
        data-testid="bb-link-enter-address-manually"
      />
    </EnterAddressManually>
  );
};

export default BBEnterManualAddressLink;
