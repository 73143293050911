// Utils
import { IGNORED_BANK_ACCOUNT_COUNTRIES } from '../../../core/utils/Constants/Constants';
import { getCountryOptionsFromAPI } from '../../../core/utils/CountryData/GetCountryData';
// Constants
import CountryMockData from '../../../core/utils/MockData/CountryMockData.json';

export const getSupportedCountryOptions = () => {
  const data = CountryMockData.filter(
    (country) => !IGNORED_BANK_ACCOUNT_COUNTRIES.includes(country.value)
  );

  return getCountryOptionsFromAPI(data);
};
