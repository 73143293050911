import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
import BBLink from '../../atoms/BBLink';
import BBText from '../../atoms/BBText';
import BBInput from '../../atoms/BBInput';
import ResponsiveButton from '../../organisms/ResponsiveButton';
import { FormInputContainer, TextWithLinkWrapper } from './LoginPage.styles';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { LoginFormValues } from './types';
//API wrappers
import { useLoginMutation } from '../../../redux/api/auth';

export default function LoginForm() {
  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);
  const { validations } = useValidations();
  const navigate = useNavigate();

  const [login] = useLoginMutation();

  const form = useForm<LoginFormValues>({
    defaultValues: {
      emailAddress: '',
      password: '',
    },
    resolver: yupResolver(validations),
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = form;

  const onSubmit = async (data: LoginFormValues) => {
    try {
      const result = await login(data).unwrap();
      if ('uuid' in result) {
        navigate(`/login/2fa/`, {
          state: { userId: result.uuid },
        });
      }
    } catch {}
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputContainer layout={layout}>
          <ControllerElementWrapper
            name="emailAddress"
            control={control}
            placeholderLabel={translate('login.emailAddress')}
            component={BBInput}
            type="email"
          />

          <ControllerElementWrapper
            name="password"
            type="password"
            control={control}
            placeholderLabel={translate('login.password')}
            component={BBInput}
          />
          <TextWithLinkWrapper>
            <BBText
              text={translate('login.forgotPassword')}
              variant="body2"
              type="normal"
            />
            <BBLink
              text={translate('login.resetLinkText')}
              href="#"
              variant="body2"
              type="normal"
              target="_blank"
              data-testid="bb-link-reset-password"
            />
          </TextWithLinkWrapper>
        </FormInputContainer>

        <ResponsiveButton
          type="submit"
          btnType="secondary"
          disabled={!isValid || isSubmitting}
          className="submitButton"
        >
          {translate('login.submitButtonText')}
        </ResponsiveButton>
      </form>
    </FormProvider>
  );
}
