import { useSearchParams } from 'react-router-dom';

// Types
import { Nullable } from '../../../core/types/UtilTypes';
import {
  OrderByType,
  orderByValues,
  Sort,
  SortByType,
  sortByValues,
} from './types';

const defaultSort: Sort = {
  sortBy: 'createdDate',
  orderBy: 'desc',
};

const parseSortByValue = (value: Nullable<string>): SortByType => {
  if (value && sortByValues.includes(value as SortByType)) {
    return value as SortByType;
  }

  return defaultSort.sortBy;
};

const parseOrderByValue = (value: Nullable<string>): OrderByType => {
  if (value && orderByValues.includes(value as OrderByType)) {
    return value as OrderByType;
  }

  return defaultSort.orderBy;
};

export const useSort = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy: SortByType = parseSortByValue(searchParams.get('sortBy'));
  const orderBy: OrderByType = parseOrderByValue(searchParams.get('orderBy'));
  const sort: Sort = { sortBy, orderBy };

  const setSort = (sort?: Sort) => {
    if (!sort) {
      searchParams.delete('sortBy');
      searchParams.delete('orderBy');
    } else {
      searchParams.set('sortBy', sort.sortBy);
      searchParams.set('orderBy', sort.orderBy);
    }

    setSearchParams(searchParams);
  };

  return { sort, setSort };
};
