import { CurrenciesFlagMapping } from '../../CountryMetadata/referenceData/CurrenciesFlagMapping';
import { OptionType } from '../../types/SelectTypes';
import CurrencyOptions from '../MockData/CurrencyMockData.json';

export const getCurrencyOptions = (): OptionType[] | [] => {
  return CurrencyOptions;
};

export const getCurrencyDropdownOptions = (currenciesData: any) => {
  const flagData: { [name: string]: string } = CurrenciesFlagMapping;
  return currenciesData.reduce((dropDown: OptionType[], data: any) => {
    if (data.active) {
      const flagIcon: string = data?.code || '';
      const dropdownValue = {
        value: data.code,
        label: data.code,
        iconPath: flagData[flagIcon],
      };
      return [...dropDown, dropdownValue];
    } else {
      return dropDown;
    }
  }, []);
};
