import { skipToken } from '@reduxjs/toolkit/query';
import { useRef } from 'react';

// Types
import { FxQuote } from '../../../../../../core/types/PricingTypes';
import { Nullable } from '../../../../../../core/types/UtilTypes';
// API Wrappers
import { useGetQuoteQuery } from '../../../../../../redux/api';

export const usePaymentInitialQuote = (
  token?: Nullable<string>,
  transactionId?: number
) => {
  // NOTE: Unfortunately, in this scenario we need to retrieve a single property
  // about a payment that is currently stored in another resource, and, technically,
  // we need it only once. This hook is just a work around to make sure we make
  // request to that separate resource only once, even if it changes in the future,
  // we don't care, so this is a hacky way to opt out of the declarative nature
  // of how queries work
  const initiallyLoadedQuote = useRef<FxQuote | undefined>();

  const { data: quote, ...query } = useGetQuoteQuery(
    initiallyLoadedQuote.current || !token || !transactionId
      ? skipToken
      : { token, transactionId }
  );

  if (!initiallyLoadedQuote.current && quote) {
    initiallyLoadedQuote.current = quote;
  }

  return {
    quote: initiallyLoadedQuote.current,
    ...query,
  };
};
