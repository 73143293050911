import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

export const MainContainer = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);

  const {
    dimensions: {
      spacing: { medium },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'flex-start',
    gap: medium,
    alignSelf: 'stretch',
    flexDirection: 'row',
    width: isMobileLayout ? '100%' : '521px',
  };
});

export const StyledPageTitle = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;

  return {
    color: text,
    alignSelf: 'stretch',
  };
});

export const Card = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);

  const {
    dimensions: {
      spacing: { xxxSmall },
    },
    colours: {
      borders: { border },
    },
  } = theme;

  return {
    display: 'flex',
    minWidth: isMobileLayout ? '100%' : '327px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    borderRadius: xxxSmall,
    border: `1px solid ${border}`,
    alignSelf: 'stretch',
  };
});

export const CardBody = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { small, medium },
    },
    colours: {
      text: { textWeak, black },
    },
  } = theme;

  return {
    display: 'flex',
    padding: medium,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: small,
    alignSelf: 'stretch',
    '> ul': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'stretch',
      gap: small,
      '> li': {
        padding: 0,
        '> div': {
          margin: 0,
          '> span': {
            ...getBody2NormalStyles(theme),
            color: textWeak,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '> p': {
            ...getBody2NormalStyles(theme),
            color: black,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },
  };
});

export const StyledSpan = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    '> p.MuiTypography-root': {
      display: 'inline',
      color: textWeak,
    },
    'a > p.MuiTypography-root': {
      display: 'inline',
    },
  };
});
