import { useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { StepIconProps } from '@mui/material/StepIcon';

//Context
import { TenantContext } from '../../../core/TenantProvider/contexts';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface StepIconRootProps {
  ownerState: { completed?: boolean; active?: boolean };
}
const StepIconRoot = styled(Box)<StepIconRootProps>(({ ownerState }) => {
  return {
    '> svg': {
      width: 26, // Token not available
      height: 26, // Token not available
    },
    flexShrink: 0,
    flexWrap: 'wrap',
    display: 'flex',
    '& span.MuiStepLabel-vertical': {
      padding: 0,
    },
    ...(ownerState.active && {
      '> svg': {
        width: 26, // Token not available
        height: 26, // Token not available
      },
    }),
    ...(ownerState.completed && {
      '> svg': {
        width: 26, // Token not available
        height: 26, // Token not available
      },
    }),
  };
});

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const { tenant } = useContext(TenantContext);

  //Icons
  const [StepperCompletedIcon, StepperActiveIcon, StepperInActiveIcon] =
    useMemo(
      () => [
        getIcon(tenant, 'completed'),
        getIcon(tenant, 'active'),
        getIcon(tenant, 'inactive'),
      ],
      [tenant]
    );

  const icons: { [index: string]: React.ReactElement } = {
    1: <StepperCompletedIcon />,
    2: <StepperActiveIcon />,
    3: <StepperInActiveIcon />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {active ? icons[2] : completed ? icons[1] : icons[3]}
    </StepIconRoot>
  );
};

export default CustomStepIcon;
