import * as yup from 'yup';

// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
import { useErrorTranslations } from '../../../../core/hooks';

// Constants
import {
  OTHER_PAYMENT_TEXT_MAX_LENGTH,
  REGEX,
} from '../../../../core/utils/Constants/Constants';

export const useValidations = () => {
  const { translateError } = useErrorTranslations(
    'disbursement.disbursementDetails.fields'
  );
  const { translate } = useTranslations();

  let validations = yup.object({
    walletId: yup.string().nullable().required(),
    paymentType: yup
      .string()
      .trim()
      .required(translateError('paymentType', 'required')),
    paymentTypeDescription: yup.string().when('paymentType', {
      is: 'Other',
      then: (schema) =>
        schema
          .trim()
          .required(translateError('otherPayment', 'required'))
          .max(
            OTHER_PAYMENT_TEXT_MAX_LENGTH,
            translateError('otherPayment', 'maxCharacters', {
              number: OTHER_PAYMENT_TEXT_MAX_LENGTH,
            })
          )
          .matches(
            REGEX.alphaNumericString,
            translate('disbursement.disbursementDetails.errors.otherPayment')
          ),
    }),
    paymentValue: yup
      .string()
      .trim()
      .required(translateError('paymentValue', 'required'))
      .matches(
        REGEX.paymentValue,
        translate('disbursement.disbursementDetails.errors.paymentValue')
      ),
    paymentValueCurrency: yup
      .string()
      .trim()
      .required(translateError('paymentValueCurrency', 'required')),
  });

  return { validations };
};
