import isEqual from 'lodash/isEqual';
import { styled } from '@mui/material/styles';
import { memo, useContext, useMemo, useRef, useState, MouseEvent } from 'react';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBMenu, { BBMenuItem } from '../BBMenu';
// Utils
import { getBody2MediumStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

const Container = styled('span')(({ theme }) => {
  return {
    display: 'inline-block',
    overflow: 'hidden',
    maxWidth: '100%',
  };
});

const LinkText = styled('span')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Link = styled('a')(({ theme }) => {
  const {
    dimensions: { spacing },
    colours,
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%',
    gap: spacing.xxxSmall,
    color: colours.text.active,
    textDecoration: 'none',
    ...getBody2MediumStyles(theme),

    svg: {
      height: 16,
      width: 16,
      color: colours.icon.active,
      flexShrink: 0,
    },
  };
});

interface Option<Value> {
  value: Value;
  label: string;
}

interface BBTextLinkProps<Value> {
  options: Option<Value>[];
  value: Value;
  onChange: (event: MouseEvent<HTMLLIElement>, selectedValue: Value) => void;
}

const BBTextLink = <Value,>(props: BBTextLinkProps<Value>) => {
  const { options, value, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { tenant } = useContext(TenantContext);
  const ref = useRef<HTMLAnchorElement | null>(null);

  const currentOption: Option<Value> | undefined = options.find((option) =>
    isEqual(option.value, value)
  );

  const ChevronDownIcon = useMemo(
    () => getIcon(tenant, 'navigationChevronDownIcon'),
    [tenant]
  );

  return (
    <Container>
      <Link
        href="#"
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          setIsOpen(true);
        }}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <LinkText>{currentOption?.label}</LinkText>
        <ChevronDownIcon />
      </Link>

      <BBMenu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={ref.current}
      >
        {options.map((option, index) => (
          <BBMenuItem
            key={`${option.label}-${index}`}
            onClick={(event) => onChange(event, option.value)}
            selected={option === currentOption}
          >
            {option.label}
          </BBMenuItem>
        ))}
      </BBMenu>
    </Container>
  );
};

export default (memo as <T>(component: T) => T)(BBTextLink);
