import { useSelector } from 'react-redux';

// Components - Atoms, Molecules, Organisms, Pages
import { BBSnackbar } from '../../molecules/BBSnackbar';
// RTK Slice
import { getErrorDetails } from '../../../redux/modules/errorDetailsSlice';

const BBErrorNotification = () => {
  const { apiError, errorMessage } = useSelector(getErrorDetails);

  return <BBSnackbar hasError={apiError} message={errorMessage} />;
};

export default BBErrorNotification;
