import { useContext, FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Context
import { LayoutContext } from '../../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../../atoms/BBButton';
import BBLink from '../../../../atoms/BBLink';
import BBStepPanel from '../../../../organisms/BBStepPanel/BBStepPanel';
import PageLoader from '../../../../organisms/PageLoader';
import NotFoundRedirect from '../../../../organisms/NotFoundRedirect';
import DisplayAccountToBeFundedDetails from './DisplayAccountToBeFundedDetails';
import RequestDetailsSummaryData from '../../ComponentUtils/RequestDetailsSummaryData';
import {
  MainContainer,
  FormContainer,
  SummaryField,
  ActionButtonsContainer,
  StyledPageTitle,
  SummaryData,
} from '../../../CommonStyles/CommonStyles.styles';
// Hooks
import { useInitialLoading } from '../../../../../core/hooks/useInitialLoading';
import { useTranslations } from '../../../../../core/hooks/useTranslations';
//Types
import { ConfirmationViewType, RouterParams } from '../Confirmation';
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
//Utils
import { isNotFoundError } from '../../../../../core/utils/Errors';
// API Wrappers
import { useConfirmPaymentRequestMutation } from '../../../../../redux/api';

interface ConfirmationScreenProps {
  setViewType: (viewType: ConfirmationViewType) => void;
  isFetching: boolean;
  isLoading: boolean;
  data?: PaymentRequestDetails;
  error: any;
}

const ConfirmationScreen: FC<ConfirmationScreenProps> = (
  props: ConfirmationScreenProps
) => {
  const { setViewType, isFetching, isLoading, data, error } = props;

  const navigate = useNavigate();
  const { transactionId, paymentRequestId } = useParams<RouterParams>();

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const { isInitiallyLoading } = useInitialLoading({ isFetching, isLoading });

  const [confirmPaymentRequest, { isLoading: isConfirmationRequestLoading }] =
    useConfirmPaymentRequestMutation();

  //Translations
  const backBtnText: string = translate('common.backBtn');
  const confirmBtnText: string = translate('common.confirmBtn');

  const headingText: string = translate('paymentRequest.confirmation.heading');
  const editText: string = translate('paymentRequest.confirmation.edit');

  const activeStep: number = 2;

  const accountToBeFundedScreen = 'account-to-be-funded';
  const requestDetailsScreen = 'request-details';

  const handleOnContinueClick = async () => {
    if (!paymentRequestId) {
      return;
    }

    const { id } = await confirmPaymentRequest(
      Number(paymentRequestId)
    ).unwrap();
    id && setViewType('completed-screen');
  };

  const navigateTo = (screen: string) => {
    navigate(`/payment-request/${screen}/${transactionId}/${paymentRequestId}`);
  };

  if (isInitiallyLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={headingText}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <FormContainer layout={layout}>
          <SummaryField>
            <SummaryData>
              <RequestDetailsSummaryData data={data} />
              <BBLink
                underline="none"
                type="medium"
                text={editText}
                onClick={() => navigateTo(requestDetailsScreen)}
              />
            </SummaryData>
            <SummaryData>
              <DisplayAccountToBeFundedDetails data={data} />
              <BBLink
                underline="none"
                type="medium"
                text={editText}
                onClick={() => navigateTo(accountToBeFundedScreen)}
              />
            </SummaryData>
          </SummaryField>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              size="medium"
              btnType="outlined"
              type="button"
              onClick={() => navigateTo(accountToBeFundedScreen)}
            >
              {backBtnText}
            </BBButton>
            <BBButton
              btnType="secondary"
              size="medium"
              type="button"
              onClick={handleOnContinueClick}
              disabled={isConfirmationRequestLoading}
            >
              {confirmBtnText}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'payment-request'}
          idValues={{
            transactionId: transactionId,
            paymentRequestId: paymentRequestId,
          }}
        />
      </MainContainer>
    </>
  );
};

export default ConfirmationScreen;
