import { Pagination, PaginationItem, PaginationProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useContext, useMemo } from 'react';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Utils
import { NUMBER_OF_PAGES_WITHOUT_ARROWS } from '../../../core/utils/Constants/Constants';
import { getBody2MediumStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface BBPaginationProps
  extends Omit<PaginationProps, 'size' | 'variant' | 'shape'> {}

const StyledPagination = styled(Pagination)(({ theme }) => {
  const {
    colours,
    dimensions: { radius, spacing },
  } = theme;

  return {
    '.MuiPagination-ul': {
      gap: 2,
    },

    '.MuiPaginationItem-root': {
      color: 'unset',
      alignItems: 'center',
      justifyContent: 'center',
      height: spacing.xLarge,
      width: spacing.xLarge,
      padding: spacing.xxSmall,
      margin: 0,
      ...getBody2MediumStyles(theme),
    },

    '.MuiButtonBase-root': {
      border: '1px solid transparent',
      borderRadius: radius.small,
      transition: 'none',

      '&:hover': {
        borderColor: colours.borders.selectionHover,
      },

      '&.Mui-focusVisible': {
        backgroundColor: colours.backgrounds.activeLightest,
        borderColor: colours.borders.focus,
      },

      '&.Mui-selected': {
        '&, &:hover, &.Mui-focusVisible': {
          color: colours.text.textAlt,
          backgroundColor: colours.backgrounds.active,
          borderColor: 'transparent',
        },
      },
    },

    '.MuiPaginationItem-previousNext': {
      '&.Mui-disabled': {
        opacity: 1,
        color: colours.text.disabled,
      },

      svg: {
        height: 20,
        width: 20,
      },
    },

    '.MuiPaginationItem-ellipsis': {
      color: colours.text.disabled,
    },
  };
});

const BBPagination = (props: BBPaginationProps) => {
  const { count = 1 } = props;
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const shouldDisplayArrows: boolean =
    count > NUMBER_OF_PAGES_WITHOUT_ARROWS && !isMobileLayoutApplicable(layout);

  const [ArrowRightIcon, ArrowLeftIcon] = useMemo(
    () => [
      getIcon(tenant, 'navigationChevronRightIcon'),
      getIcon(tenant, 'navigationChevronLeftIcon'),
    ],
    [tenant]
  );

  return (
    <StyledPagination
      hideNextButton={!shouldDisplayArrows}
      hidePrevButton={!shouldDisplayArrows}
      {...props}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: ArrowLeftIcon,
            next: ArrowRightIcon,
          }}
          {...item}
        />
      )}
    />
  );
};

export default memo(BBPagination);
