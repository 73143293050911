import styled from '@emotion/styled';
import { Box } from '@mui/material';

// Contexts
import { useContext, useMemo } from 'react';
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../BBText';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';

const NoOptionsContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;


  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4,
    paddingTop: '40px',
    '> p:first-child': {
      color: text
    }
  };
});

const BBMobileOptimisedNoOptionUI = () => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const TransactionIcon = useMemo(() => getIcon(tenant, 'transactionIcon'), [tenant]);

  const headingText: string = translate('selectDropdown.noResultsLabel');

  return (
    <NoOptionsContainer data-testid="bb-no-options-container">
      <TransactionIcon />
      <BBText variant="body2" type="medium" text={headingText} />
    </NoOptionsContainer>
  );
};

export default BBMobileOptimisedNoOptionUI;
