// Components - Atoms, Molecules, Organisms, Pages
import BBDataTable from '../../../../organisms/BBDataTable';
import CustomFooterComponent, {
  CustomFooterComponentProps,
} from './CustomFooterComponent';
// Hooks
import { useColumns } from './useColumns';
// Types
import { CompletionPaymentsDetails } from '../types';

interface CompletionPaymentsTableProps {
  transactionId: number;
  completionPaymentsData: CompletionPaymentsDetails[];
  totalAmount: string;
}

const CompletionPaymentsTable = (props: CompletionPaymentsTableProps) => {
  const { completionPaymentsData, transactionId, totalAmount } = props;

  const { columns } = useColumns(transactionId);

  return (
    <BBDataTable
      columnVisibilityModel={{ createdDate: false }}
      columns={columns}
      rows={completionPaymentsData}
      slots={{
        footer: CustomFooterComponent,
      }}
      slotProps={{
        footer: {
          totalAmount: totalAmount,
          transactionId: transactionId,
        } as Partial<CustomFooterComponentProps>,
      }}
      hideFooter={false}
      hideFooterPagination={true}
      defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
    />
  );
};

export default CompletionPaymentsTable;
