import { ReactNode } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import PageLoader from '../../../components/organisms/PageLoader';
// Validation Schema or files in the same folder
import { UserContext } from './UserContext';
// API Wrappers
import { useGetProfileQuery } from '../../../redux/api';
//utils
import { clearTokens } from '../../utils/AuthUtils';

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider = (props: UserProviderProps) => {
  const { data, isFetching, isLoading, error } = useGetProfileQuery();

  //redirecting to login page on profile errors
  if (!isFetching && error) {
    clearTokens();
  }

  if (isLoading || !data) {
    // TODO: This needs to be replaced with a proper full screen loader when it's created
    // in the project
    return <PageLoader />;
  }

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
