import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../../atoms/BBButton';
import BBLinearProgressBar from '../../../../atoms/BBLinearProgressBar';
// Types
import { LayoutProps } from '../../../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';

interface SummaryContainerOwnerState extends LayoutProps {}

export const SummaryContainer = styled('section')<{
  ownerState: SummaryContainerOwnerState;
}>(({ theme, ownerState }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const { layout } = ownerState;

  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    display: 'grid',
    gap: spacing.medium,

    ...(isDesktopLayout && {
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
    }),
  };
});

export const Progress = styled(BBLinearProgressBar)(({ theme }) => {
  const { colours } = theme;

  return {
    backgroundColor: '#eaeaea', // No token
    marginBottom: 0,

    '.MuiLinearProgress-bar': {
      backgroundColor: colours.backgrounds.success,
    },
  };
});

interface MakePaymentButtonOwnerSate extends LayoutProps {}

export const MakePaymentButton = styled(BBButton)<{
  ownerState: MakePaymentButtonOwnerSate;
}>(({ ownerState }) => {
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  if (isMobileLayout) {
    return {};
  }

  return {
    maxWidth: 220,
  };
});
