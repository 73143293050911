import { createContext } from 'react';

import { OrganizationCodeTypes } from '../../types/OrganizationTypes';

type TenantContext = {
  settings: object;
  tenant: OrganizationCodeTypes | undefined;
  updateTenant: (tenant: OrganizationCodeTypes) => void;
};

export default createContext<TenantContext>({
  settings: {},
  tenant: undefined,
  updateTenant: () => {},
});
