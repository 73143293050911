import { FC, memo } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Types
import { TypographyTypes } from '../../../core/types/TypographyTypes';
// Utils
import getTypographyStyles from '../../../core/utils/GetTypographyStyles';

type LinkTypes = 'bold' | 'medium' | 'normal';

interface BBLinkWithChildren extends LinkProps {
  children: React.ReactNode;
}

interface BBLinkWithText extends LinkProps {
  text: string;
  type?: LinkTypes;
}

type BBLinkProps = BBLinkWithChildren | BBLinkWithText;

const StyledBBLink = styled(Link)(({ theme, variant, type }) => {
  const {
    colours: {
      text: { active, focus },
    },
  } = theme;

  return {
    ...getTypographyStyles(variant, type as TypographyTypes, theme),
    color: active,
    textDecorationColor: active,

    '&:hover': {
      color: active,
      cursor: 'pointer',
    },

    '&.Mui-focusVisible': {
      outline: `${focus} solid 1px`,
      outlineOffset: 1,
      color: focus,
      borderRadius: 4,
    },
  };
});

const BBLink: FC<BBLinkProps> = (props: BBLinkProps) => {
  const {
    variant = 'body2',
    underline = 'hover',
    onClick,
    children,
    ...otherProps
  } = props;

  const linkText: string = 'text' in props ? props.text : '';
  const linkType: LinkTypes =
    'type' in props && props.type ? (props.type as LinkTypes) : 'bold';

  return (
    <StyledBBLink
      data-testid={'bb-link'}
      underline={underline}
      variant={variant}
      type={linkType}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      {...otherProps}
    >
      {children}
      {linkText}
    </StyledBBLink>
  );
};

export default memo(BBLink);
