// Types
import { UpsertPaymentRequest } from '../../../../../core/types/ApiTypes';
import { Payment } from '../../../../../core/types/PaymentTypes';
import { FormValues } from './types';
// Utils
import { createApiValidationErrorsMapper } from '../../../../../core/utils/Errors';
// Constants
import {
  DEFAULT_FORMAT_VALUE,
  DEFAULT_PAYMENT_VALUE_CURRENCY,
} from '../../../../../core/utils/Constants/Constants';

export const getPaymentErrors = createApiValidationErrorsMapper<
  Payment,
  FormValues
>({
  amountToSend: 'sendAmount',
  currencyToSend: 'sendCurrency',
  amountToReceive: 'recipientAmount',
  currencyToReceive: 'recipientCurrency',
});

export const mapPaymentToFormValues = (
  payment: Payment
): Partial<FormValues> => {
  return {
    sendAmount: payment.amountToSend ?? undefined,
    sendCurrency: payment.currencyToSend ?? DEFAULT_PAYMENT_VALUE_CURRENCY,
    recipientAmount: payment.amountToReceive ?? undefined,
    recipientCurrency:
      payment.paymentRequest?.currency ??
      payment.currencyToReceive ??
      undefined,
  };
};

export const mapFormValuesToUpsertRequest = (
  values: FormValues
): Partial<UpsertPaymentRequest> => {
  return {
    amountToSend: values.sendAmount,
    currencyToSend: values.sendCurrency,
    amountToReceive: values.recipientAmount,
    currencyToReceive: values.recipientCurrency,
  };
};

export const getPaymentRecipientName = (payment: Payment) => {
  const bankAccount = payment.paymentRequest?.accountToBeFunded?.bankAccount;

  if (!bankAccount) {
    return;
  }

  if (bankAccount.bankAccountType === 'Individual') {
    return `${bankAccount.firstName} ${bankAccount.lastName}`;
  }

  return bankAccount.companyName ?? '';
};

export const getFormattedQuote = (quote?: number) => {
  return quote ?? DEFAULT_FORMAT_VALUE;
};
