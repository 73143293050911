//Types
import { Disbursement } from '../../../../core/types/DisbursementTypes';
import { PaymentMethod } from '../../../../core/types/PaymentRequestsTypes';
import { Nullable } from '../../../../core/types/UtilTypes';
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';
import { mapApiAccountTypeValue } from '../../PaymentRequest/AccountToBeFunded/utils';
import { PaymentMethods } from '../Confirmation/types';
import { RecipientDetailsFormValues } from './types';

export const defaultValues: RecipientDetailsFormValues = {
  accountType: null,
  email: '',
  customerId: '',
  customerFound: false,
  recipientId: null,
  newAccountType: 'Individual',
  firstName: '',
  lastName: '',
  companyName: '',
  countryCode: '',
  currency: 'EUR',
  bankDetails: {},
  bankDetailsValidationRules: {},
};

export const mapDisbursementDataToFormValue = (
  data: Disbursement
): RecipientDetailsFormValues => {
  let walletData = {};
  if (data.paymentMethod === PaymentMethods.Wallet) {
    walletData = {
      email: data?.paidToWallet?.crmAccount?.email || '',
      customerId: data?.paidToWallet?.crmAccount?.titanAccountNo || '',
      customerFound: true,
    };
  }
  return {
    ...defaultValues,
    ...walletData,
    accountType: mapApiAccountTypeValue(data.paymentMethod),
    recipientId: data.bankAccount?.id ?? null,
  };
};

const verifyIds = (id: string) => {
  if (!id) {
    return null;
  }
  return !isNaN(Number(id)) ? Number(id) : null;
};

export const mapFormValuesToDisbursementUpsertRequest = (
  formData: RecipientDetailsFormValues,
  walletDetails: Nullable<WalletWithUserDetails>,
  transactionId: string,
  disbursementId: string
) => {
  const isAccountTypeWallet = formData.accountType === 'wallet';
  const paymentMethod = isAccountTypeWallet
    ? PaymentMethods.Wallet
    : PaymentMethods.Bank;
  const bankAccountId =
    !isAccountTypeWallet && formData.recipientId ? formData.recipientId : null;

  const disbursementApiData = {
    id: verifyIds(disbursementId),
    transaction: { id: verifyIds(transactionId) },
    paymentMethod: paymentMethod,
  };

  if (isAccountTypeWallet) {
    return {
      ...disbursementApiData,
      paidToWallet: walletDetails,
      payToEmail: formData?.email || '',
      payToTitanAccountNumber: formData?.customerId || '',
    };
  }

  return {
    ...disbursementApiData,
    bankAccount: bankAccountId
      ? {
          id: bankAccountId,
        }
      : null,
  };
};

export const createAccountDisbursementUpsertRequest = (
  paymentMethod: PaymentMethod,
  bankAccountId: number | null,
  transactionId: string,
  disbursementId: string
) => {
  return {
    id: verifyIds(disbursementId),
    transaction: { id: verifyIds(transactionId) },
    paymentMethod: paymentMethod,
    bankAccount: bankAccountId
      ? {
          id: bankAccountId,
        }
      : null,
  };
};

export const mapFormValuesToFraudAlertRequest = (data: Disbursement) => {
  return {
    id: verifyIds(data?.id?.toString() || ''),
    transaction: { id: verifyIds(data?.transaction?.id?.toString() || '') },
    fraudAlert: true,
  };
};
