import { FC } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import PaymentRequestsTable from './PaymentsRequestTable/PaymentsRequestTable';
import NoRecordsFound from '../NoRecordsFound';
// Types
import { PaymentRequests } from '../PaymentRequest/PaymentsRequestTable/types';

interface PaymentRequestProps {
  id: string | undefined;
  payments: PaymentRequests[];
  isPaymentsAvailable: boolean;
}

const PaymentRequest: FC<PaymentRequestProps> = (
  props: PaymentRequestProps
) => {
  const { id, payments, isPaymentsAvailable } = props;

  return (
    <>
      {isPaymentsAvailable ? (
        <PaymentRequestsTable payments={payments} transactionId={Number(id)} />
      ) : (
        <NoRecordsFound type="request" />
      )}
    </>
  );
};

export default PaymentRequest;
