import { FC, memo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import BBLink from '../../atoms/BBLink';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBLogo from '../../atoms/BBLogo';
// Types
import { IconObject } from '../../../core/types/IconOrgTypes';

interface BBLogoWrapperProps {
  logoVariant: keyof IconObject;
  link: string;
  target?: string;
}

const StyledIconLink = styled(BBLink)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    gap: '10px', // Token not available,
    cursor: 'pointer',
  };
});

const BBLogoWrapper: FC<BBLogoWrapperProps> = (props: BBLogoWrapperProps) => {
  const { logoVariant, link, target = '_self' } = props;

  const { tenant } = useContext(TenantContext);

  return (
    <StyledIconLink
      data-testid="bb-link-logo"
      href={link}
      underline={'none'}
      target={target}
    >
      <BBLogo logoVariant={logoVariant} tenant={tenant} />
    </StyledIconLink>
  );
};

export default memo(BBLogoWrapper);
