import range from 'lodash/range';
import printjs from 'print-js';
import type { PDFPageProxy } from 'pdfjs-dist';

const printPageStyle = `
  body > div {
    position: absolute;
    inset: 0;
  }

  div > div {
    height: 100%;
  }

  img {
    height: 100%;
    display: block;
  }
`;

const importPdfJsModule = async () => {
  const pdfjs = await import('pdfjs-dist');

  pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

  return pdfjs;
};

export const getLocallyFormattedDate = (date: Date) => {
  return date.toLocaleDateString(undefined, { dateStyle: 'long' });
};

export const downloadContractNoteData = (data: string) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = `data:application/pdf;base64,${data}`;
  downloadLink.download = 'contract-note.pdf';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};

const convertPdfPageToImage = async (page: PDFPageProxy) => {
  const canvas = document.createElement('canvas');
  const viewport = page.getViewport({ scale: 2 });
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Was not able to get canvas context');
  }

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  await page.render({ canvasContext: context, viewport }).promise;

  const dataUrl = canvas.toDataURL();

  canvas.remove();

  return dataUrl;
};

const createImagesFromPdf = async (data: string) => {
  const pdfjs = await importPdfJsModule();
  const pdf = await pdfjs.getDocument({ data: atob(data) }).promise;

  const promisedImages = range(pdf.numPages).map(async (pageNumber) => {
    const page = await pdf.getPage(pageNumber + 1);

    return convertPdfPageToImage(page);
  });

  return Promise.all(promisedImages);
};

export const printContractNoteData = async (data: string) => {
  const images = await createImagesFromPdf(data);

  printjs({
    printable: images,
    type: 'image',
    documentTitle: '',
    style: printPageStyle,
  });
};
