import { useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

// Components - Atoms, Molecules, Organisms, Pages
import BBInput from '../../atoms/BBInput';
// Constants
import { SEARCH_DEBOUNCE_TIME_IN_MILLISECONDS } from '../../../core/utils/Constants/Constants';

interface BBSearchProps {
  value: string;
  onChange: (value: string) => void;
  name: string;
  placeholderLabel: string;
  size?: 'normal' | 'small';
}

const BBSearch = (props: BBSearchProps) => {
  const [value, setValue] = useState(props.value);

  const onDebouncedChange = useDebounceCallback(
    props.onChange,
    SEARCH_DEBOUNCE_TIME_IN_MILLISECONDS
  );

  const onValueChange = (searchValue: string) => {
    setValue(searchValue);
    onDebouncedChange(searchValue);
  };

  return (
    <BBInput
      name={props.name}
      placeholderLabel={props.placeholderLabel}
      type="search"
      size={props.size}
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      onClear={() => onValueChange('')}
    />
  );
};

export default BBSearch;
