import { Link, useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import { BackButton } from './PaymentRequestDetails.styles';
import PaymentsTable from './PaymentsTable/PaymentsTable';
import Summary from './Summary';
import Page from '../../../organisms/Page';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { PaymentStatus } from '../../../../core/types/PaymentTypes';
// API Wrappers
import { useGetPaymentRequestByIdQuery } from '../../../../redux/api';
// Utils
import { parseNumber } from '../../../../core/utils/Parse';

type RouterParams = {
  paymentRequestId: string;
};

const PaymentRequestDetails = () => {
  const { paymentRequestId } = useParams<RouterParams>();
  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const { data, isLoading, error } = useGetPaymentRequestByIdQuery(
    parseNumber(paymentRequestId) ?? skipToken
  );

  const payments = useMemo(() => {
    return (
      data?.payments.filter(
        (payment) => payment.paymentStatus !== PaymentStatus.Draft
      ) ?? []
    );
  }, [data]);

  const hasPayments: boolean = payments.length > 0;

  return (
    <Page isLoading={isLoading} error={error}>
      <BBText
        variant="h3"
        type="bold"
        text={translate('buyer.paymentRequestDetails.title', {
          name: data?.paymentTypeDescription,
        })}
      />
      {data && <Summary paymentRequest={data} />}
      {hasPayments && <PaymentsTable payments={payments} />}
      <BackButton
        ownerState={{ layout }}
        btnType="outlined"
        component={Link}
        to={`/transactions/${data?.transactionId}`}
      >
        {translate('buyer.paymentRequestDetails.backButtonText')}
      </BackButton>
    </Page>
  );
};

export default PaymentRequestDetails;
