import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../atoms/BBButton';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface TitleSectionOwnerState {
  layout: LayoutSize;
}

export const TitleSection = styled('section')<{
  ownerState: TitleSectionOwnerState;
}>(({ theme, ownerState }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing.large,
    gap: spacing.xxSmall,
    flexWrap: 'wrap',

    ...(isMobileLayout && { flexDirection: 'column' }),
  };
});

interface TabsSectionOwnerState {
  layout: LayoutSize;
}

export const TabsSection = styled('section')<{
  ownerState: TabsSectionOwnerState;
}>(({ theme, ownerState }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const { layout } = ownerState;

  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    display: 'flex',
    alignItems: 'center',

    ...(isDesktopLayout && { gap: spacing.xxSmall }),
    ...(!isDesktopLayout && {
      gap: spacing.xSmall,
      flexDirection: 'column',
    }),
  };
});

interface SearchContainerOwnerState {
  layout: LayoutSize;
}

export const SearchContainer = styled('div')<{
  ownerState: SearchContainerOwnerState;
}>(({ ownerState }) => {
  const { layout } = ownerState;

  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    width: '100%',
    ...(isDesktopLayout && { maxWidth: 350 }),
  };
});

interface CreateTransactionButtonOwnerState extends LayoutProps {}

export const CreateTransactionButton = styled(BBButton)<{
  ownerState: CreateTransactionButtonOwnerState;
}>(({ ownerState }) => {
  const { layout } = ownerState;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    width: '100%',
    ...(!isMobileLayout && { maxWidth: 220 }),
  };
});
