import { Grow, Popper, PopperProps } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';

const StyledPopper = styled(Popper)(({ theme }) => {
  const { zindex } = theme;

  return {
    zIndex: zindex.popper,

    '&[data-popper-placement*="bottom"]': {
      '.MuiPopper-arrow': {
        top: -10,
      },
    },

    '&[data-popper-placement*="top"]': {
      '.MuiPopper-arrow': {
        bottom: -10,
      },
    },

    '&[data-popper-placement*="right"]': {
      '.MuiPopper-arrow': {
        left: -6,
      },
    },

    '&[data-popper-placement*="left"]': {
      '.MuiPopper-arrow': {
        right: -6,
      },
    },
  };
});

const Container = styled('div')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
    boxShadows,
  } = theme;

  return {
    position: 'relative',
    borderRadius: radius.medium,
    boxShadow: boxShadows.small,

    '.MuiPaper-root': {
      boxSizing: 'border-box',
      position: 'relative',
      backgroundColor: colours.backgrounds.bgAlt,
      padding: spacing.small,
      borderRadius: radius.medium,
      boxShadow: 'none',
    },
  };
});

const Arrow = styled('div')(({ theme }) => {
  const { colours, boxShadows } = theme;

  return {
    '&::before': {
      display: 'inline-block',
      content: '""',
      width: 12,
      height: 12,
      position: 'relative',
      backgroundColor: colours.backgrounds.bgAlt,
      boxShadow: boxShadows.small,
      transform: 'rotate(45deg)',
    },
  };
});

interface BBPopperProps extends PopperProps {}

const BBPopper = (props: BBPopperProps) => {
  const { children, modifiers, ...popperProps } = props;
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  // NOTE: Unfortunately, there is an issue in how MUI implements popper component
  // which causes undesired behaviour with the date picker. This seems to happen
  // every time a parent containing popper component instance re-renders, which
  // in result re-creates the modifiers array and then something just doesn't
  // seem to work right within the MUI popper implementation itself.
  // Memoising modifiers helps, however this means that whenever a component uses
  // BBPopper, it needs to memoise its own modifiers before passing them as a prop.
  // Although, this has been reported through multiple github issues, not sure
  // if it's planned to be fixed yet. For instance, here is one of the raised issues
  // https://github.com/mui/material-ui/issues/33413
  const popperModifiers = useMemo(() => {
    return [
      { name: 'arrow', options: { element: arrowRef, padding: 16 } },
      { name: 'offset', options: { offset: [0, 10] } },
      ...(modifiers ?? []),
    ];
  }, [arrowRef, modifiers]);

  return (
    <StyledPopper {...popperProps} modifiers={popperModifiers}>
      {(childProps) => (
        <Grow
          {...childProps.TransitionProps}
          style={{
            transformOrigin:
              childProps.placement === 'bottom-start'
                ? 'left top'
                : 'left bottom',
          }}
        >
          <Container>
            <Arrow
              ref={setArrowRef}
              className="MuiPopper-arrow"
              data-testid="MuiPopper-arrow"
            />
            {typeof children === 'function' ? children(childProps) : children}
          </Container>
        </Grow>
      )}
    </StyledPopper>
  );
};

export default memo(BBPopper);
