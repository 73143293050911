import { styled } from '@mui/material/styles';

// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const RemoveButton = styled('button')(({ theme }) => {
  const { colours } = theme;

  return {
    appearance: 'none',
    fontFamily: 'inherit',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    color: colours.text.textWeaker,
    ...getBody2NormalStyles(theme),

    '&:disabled': {
      opacity: 0.3,
    },
  };
});
