import { useCallback, useEffect, useRef } from 'react';
import { useUnmount } from 'usehooks-ts';

export const useScheduledCallback = <Data>(callback: (data: Data) => void) => {
  const frame = useRef(0);
  const isScheduled = useRef(false);
  const scheduledCallback = useRef(callback);

  useEffect(() => {
    scheduledCallback.current = callback;
  }, [callback]);

  const cancel = useCallback(() => {
    isScheduled.current = false;

    cancelAnimationFrame(frame.current);
  }, []);

  const scheduleAt = useCallback(
    (data: Data, time: string) => {
      cancel();

      const endTime = new Date(time);

      const checkTime = () => {
        if (new Date() >= endTime) {
          isScheduled.current = false;

          scheduledCallback.current(data);
          return;
        }

        frame.current = requestAnimationFrame(checkTime);
      };

      isScheduled.current = true;
      frame.current = requestAnimationFrame(checkTime);
    },
    [cancel]
  );

  const isActive = useCallback(() => {
    return isScheduled.current;
  }, []);

  useUnmount(cancel);

  return { cancel, scheduleAt, isActive };
};
