// Types
import {
  GetWalletsRequest,
  GetWalletsResponse,
} from '../../core/types/ApiTypes';
import { AvailableBalanceDetails } from '../../core/types/AvailableBalanceTypes';
import { WalletWithUserDetails } from '../../core/types/WalletTypes';
// API Wrappers
import { api } from './api';

const walletsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWallets: builder.query<GetWalletsResponse, GetWalletsRequest>({
      query: ({ titanAccountNumber, transactionId }) =>
        `propertypay-services/wallet/${titanAccountNumber}/transaction/${transactionId}`,
      providesTags: [{ type: 'Wallets', id: 'LIST' }],
    }),

    getWalletsToBePaidFrom: builder.query<WalletWithUserDetails[], number>({
      query: (id) => `propertypay-services/wallet/transaction/${id}`,
      providesTags: [{ type: 'Wallets', id: 'LIST' }],
    }),

    getAvailableBalance: builder.query<AvailableBalanceDetails, number>({
      query: (id) =>
        `propertypay-services/wallet/transaction/${id}/confirm-available-balances`,
      providesTags: [{ type: 'AvailableBalance', id: 'LIST' }],
    }),

    getWalletFromEmailAndTan: builder.query({
      query: (data) => {
        const { transactionId, searchEmail, tanNumber } = data;
        return `propertypay-services/wallet/transaction/${transactionId}/isMatchEmailAndTan/${searchEmail}/${tanNumber}`;
      },
      providesTags: (_result, error, data) => [
        { type: 'WalletDetailsFromTan', id: data.tanNumber },
      ],
    }),
  }),
});

export const {
  useGetWalletsQuery,
  useGetWalletsToBePaidFromQuery,
  useGetAvailableBalanceQuery,
  useLazyGetWalletFromEmailAndTanQuery,
} = walletsApi;
