import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BankAccounts from '../BankAccounts';
import BBSearch from '../../molecules/BBSearch/BBSearch';
import Accordion, { AccordionDetails, AccordionSummary } from '../Accordion';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { BankAccountDetailsResponse } from '../../../core/types/PaymentRequestsTypes';
import { AccordionDefaultProps } from '../../../core/types/AccordionTypes';
import { AccountType } from '../../../core/types/RecipientTypes';
// API Wrappers
import { useGetBankAccountsQuery } from '../../../redux/api';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { IGNORED_BANK_ACCOUNT_COUNTRIES } from '../../../core/utils/Constants/Constants';

interface SavedRecipientsAccordionProps
  extends AccordionDefaultProps<AccountType> {}

const SavedRecipientsAccordion: FC<SavedRecipientsAccordionProps> = (
  props: SavedRecipientsAccordionProps
) => {
  const { expanded, handleChange } = props;

  const { translate } = useTranslations();
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const isMobileLayout = isMobileLayoutApplicable(layout);

  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState<
    BankAccountDetailsResponse[]
  >([]);

  const { data } = useGetBankAccountsQuery();

  const bankAccounts = useMemo(() => {
    return data?.filter(
      (account) => !IGNORED_BANK_ACCOUNT_COUNTRIES.includes(account.country)
    );
  }, [data]);

  useEffect(() => {
    if (bankAccounts) {
      setSearchResult(bankAccounts);
    }
  }, [bankAccounts]);

  const PersonUserGroupIcon = useMemo(
    () => getIcon(tenant, 'personUserGroup'),
    [tenant]
  );

  const accountType = 'saved-recipient';

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearch(value);
      const normalisedSearchTerm = value.trim().toLowerCase();
      const filteredValues: BankAccountDetailsResponse[] =
        bankAccounts && bankAccounts.length
          ? bankAccounts.filter(
              ({
                firstName,
                lastName,
                companyName,
                valueOne,
                valueTwo,
                valueThree,
              }) => {
                const fullName = `${firstName} ${lastName}`.toLowerCase();
                return [
                  fullName,
                  companyName,
                  valueOne,
                  valueTwo,
                  valueThree,
                ].some((filteredVAlue) =>
                  filteredVAlue?.toLowerCase().includes(normalisedSearchTerm)
                );
              }
            )
          : [];

      setSearchResult(filteredValues);
    },
    [bankAccounts]
  );

  return (
    <Accordion
      expanded={expanded === accountType}
      onChange={handleChange(accountType)}
    >
      <AccordionSummary
        aria-controls="saved-recipients-content"
        id="saved-recipients-header"
        icon={<PersonUserGroupIcon />}
        title={translate('savedRecipient.title')}
        subtitle={translate('savedRecipient.subTitle')}
      />
      <AccordionDetails>
        <BBSearch
          value={search}
          onChange={handleSearchChange}
          name="search-recipient"
          size={isMobileLayout ? 'small' : 'normal'}
          placeholderLabel={translate('savedRecipient.searchLabel')}
        />
        <BankAccounts recipient={searchResult} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SavedRecipientsAccordion;
