import {
  FC,
  useState,
  MouseEvent,
  KeyboardEvent,
  SyntheticEvent,
  FocusEvent,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { AutocompleteValue, FilterOptionsState } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

// Contexts
import {
  LanguageContext,
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBSelectItem from './BBSelectItem';
import BBSelectInput from './BBSelectInput';
import FullScreenDialog from '../FullScreenDialog';
import FullScreenDialogAutocomplete from './FullScreenDialogAutocomplete';
import BBValidationErrorMessage from '../BBValidationErrorMessage/BBValidationErrorMessage';
// Types
import {
  OptionType,
  SelectedOptionType,
} from '../../../core/types/SelectTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getCurrencyOptions } from '../../../core/utils/CurrencyData';
import { getDialingOptions } from '../../../core/utils/DialingData';
import { isMobileOrTabletDevice } from '../../../core/utils/CheckMobileOrTabletDevice';
import { extractDialingCodeValue } from '../../../core/utils/DialingData/GetDialingData';
import { getCountryOptionsFromAPI } from '../../../core/utils/CountryData/GetCountryData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
// Constants
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';
import CountryMockData from '../../../core/utils/MockData/CountryMockData.json';

export interface SizeVariants {
  variant: 'normal' | 'small' | 'x-small';
}

export type TypeProps = 'country' | 'currency' | 'dialingCode' | 'state';

interface BBSelectPropsWithType extends BaseBBSelectProps {
  type: TypeProps;
}

interface BBSelectPropsWithOptions extends BaseBBSelectProps {
  options: readonly OptionType[] | [];
}

interface ExtendedSelectPopperProps {
  popperWidth?: number;
}

interface IsExtendedPopperProps {
  isExtendedPopper?: boolean;
}

export interface IsExtendedSelectPopper extends IsExtendedPopperProps {
  isFixedHeight?: boolean;
}

interface IsSearchableProps {
  isSearchable?: boolean;
}

interface ErrorProps {
  error?: string;
}

interface BaseBBSelectProps
  extends ExtendedSelectPopperProps,
    IsExtendedSelectPopper,
    IsSearchableProps,
    ErrorProps {
  name: string;
  placeholderLabel: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  variant?: SizeVariants['variant'];
  toShrinkLabel?: boolean;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: Function;
  shouldUseCustomFilter?: boolean;
  showMaxOptions?: boolean;
  isMobileOptimised?: boolean;
  readOnly?: boolean;
  'data-testid'?: string;
}

interface StyledAutocompleteProps
  extends AutocompleteProps<any, any, any, any>,
    IsSearchableProps,
    IsExtendedPopperProps {
  variant?: SizeVariants['variant'];
}

interface StyledPopperProps extends IsSearchableProps, IsExtendedPopperProps {}

interface MainPopperProps
  extends ExtendedSelectPopperProps,
    IsExtendedSelectPopper,
    IsSearchableProps,
    ErrorProps {
  width: number;
}

interface StyledInputProps extends IsSearchableProps, IsExtendedSelectPopper {
  variant?: SizeVariants['variant'];
}

interface PopperComponentProps
  extends IsSearchableProps,
    IsExtendedSelectPopper {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

export type BBSelectProps = BBSelectPropsWithType | BBSelectPropsWithOptions;

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) =>
    prop !== 'variant' &&
    prop !== 'isSearchable' &&
    prop !== 'isExtendedPopper',
})<StyledAutocompleteProps>(({ isExtendedPopper }) => {
  return {
    width: '100%',
    ...(isExtendedPopper && {
      borderTopRightRadius: 'inherit',
    }),
  };
});

const StyledPopper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isSearchable' && prop !== 'isExtendedPopper',
})<StyledPopperProps>(({ theme, isSearchable, isExtendedPopper }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
    },
  } = theme;

  return {
    backgroundColor: bgAlt,
    ...(!isSearchable && {
      marginTop: '-21px',
    }),
    borderTop: 'none',
    borderBottomRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    ...(!isExtendedPopper && {
      width: 'inherit !important',
    }),
  };
});

interface StyledListBoxProps {
  isFixedHeight: boolean;
  showMaxOptions: boolean;
  variant: SizeVariants['variant'];
}

const StyledListBox = styled('ul', {
  shouldForwardProp: (prop) =>
    prop !== 'isFixedHeight' && prop !== 'showMaxOptions' && prop !== 'variant',
})<StyledListBoxProps>(({ theme, isFixedHeight, showMaxOptions, variant }) => {
  const isSmallSize: boolean = variant === 'small';

  const {
    colours: {
      backgrounds: { bgLighter, active },
      text: { textAlt },
      scroll: { thumbBackground, trackBackground },
    },
    dimensions: {
      spacing: { xxxSmall, small, xSmall, xxSmall },
    },
    zindex: { scrollBarThumb },
  } = theme;

  const smallSpacing: string = small + 'px';
  const xSmallSpacing: string = xSmall + 'px';
  const xxxSmallSpacing: string = xxxSmall + 'px';

  const maxHeight: string = isFixedHeight
    ? 'none'
    : showMaxOptions
      ? '190px'
      : '120px';

  return {
    '&.MuiAutocomplete-listbox ': {
      padding: isSmallSize
        ? `${xxxSmallSpacing} ${xSmallSpacing} ${xSmallSpacing}`
        : `${xxxSmallSpacing} ${smallSpacing} ${smallSpacing}`,
      maxHeight: maxHeight,
      overflow: isFixedHeight ? 'hidden' : 'auto',

      '&::-webkit-scrollbar': {
        width: 10,
        height: 6,
      },
      '&::-webkit-scrollbar-thumb': {
        background: thumbBackground,
        borderRadius: 8,
        zIndex: scrollBarThumb,
        height: 30,
      },
      '&::-webkit-scrollbar-track': {
        background: trackBackground,
        width: 24,
      },
      '& li.MuiAutocomplete-option': {
        justifyContent: 'space-between',
        minHeight: 40,
        height: 'auto',
        padding: isSmallSize ? xxSmall : `${xSmallSpacing} ${smallSpacing}`,
      },

      '& li.Mui-focused': {
        backgroundColor: bgLighter, // TODO: Need to check
      },

      '& li.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: active,
        borderRadius: xxxSmall,
        '& .MuiTypography-root': {
          color: textAlt,
        },
        '&.Mui-focused': {
          backgroundColor: active,
        },
      },
    },
  };
});

const DropdownContainer = styled('div')(() => {
  return {
    position: 'relative',
    width: '100%',
  };
});

const MainPopper = styled(Popper, {
  shouldForwardProp: (prop) =>
    prop !== 'popperWidth' &&
    prop !== 'isExtendedPopper' &&
    prop !== 'width' &&
    prop !== 'isSearchable' &&
    prop !== 'error',
})<MainPopperProps>(({
  theme,
  isExtendedPopper,
  popperWidth,
  width,
  error,
}) => {
  const {
    colours: {
      borders: { active, error: borderErrorColor },
    },
    dimensions: {
      spacing: { xxxSmall },
    },
    zindex: { dropdownPopper },
  } = theme;

  const xxxSmallSpacing = xxxSmall + 'px';

  return {
    border: `1.5px solid ${error ? borderErrorColor : active}`,
    zIndex: dropdownPopper,
    boxSizing: 'border-box',
    ...(isExtendedPopper
      ? {
          ...(popperWidth && {
            width: `${popperWidth}px !important`,
          }),
          borderRadius: `0 ${xxxSmallSpacing} ${xxxSmallSpacing} ${xxxSmallSpacing}`,
          inset: '-1.5px auto auto 0 !important',
        }
      : {
          width: 'inherit',
          borderRadius: `0 0 ${xxxSmallSpacing} ${xxxSmallSpacing}`,
          borderTop: 'none',
          marginTop: '-2px !important',
        }),
  };
});

const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) =>
    prop !== 'isSearchable' &&
    prop !== 'isExtendedPopper' &&
    prop !== 'variant',
})<StyledInputProps>(({ theme, isSearchable, isExtendedPopper, variant }) => {
  const isSmallSize: boolean = variant === 'small';

  const {
    colours: {
      borders: { input },
      backgrounds: { bgAlt },
      text: { label },
    },
    dimensions: {
      spacing: { small, xxxSmall, xSmall },
    },
  } = theme;

  const smallSpacing: string = small + 'px';
  const xSmallSpacing: string = xSmall + 'px';

  return {
    padding: isSearchable
      ? isExtendedPopper
        ? smallSpacing
        : `${xxxSmall}px ${isSmallSize ? xSmallSpacing : smallSpacing} ${smallSpacing}`
      : 0,

    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: bgAlt,
    '& input': {
      ...(!isSearchable && { display: 'none' }),
      ...getBody2NormalStyles(theme),
      height: isSearchable ? 24 : 0,
      borderRadius: xxxSmall,
      backgroundColor: bgAlt,
      padding: isSearchable ? xSmall : 0,
      border: isSearchable ? `1px solid ${input}` : 'none',

      '&:: placeholder': {
        ...getBody2NormalStyles(theme),
        color: label,
        paddingLeft: 28,
      },

      '&::-moz-placeholder': {
        color: label,
        paddingLeft: 28,
      },
    },
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { label },
    },
    dimensions: {
      spacing: { xSmall },
    },
  } = theme;

  return {
    position: 'absolute',
    paddingLeft: xSmall,
    display: 'flex',
    alignItems: 'center',

    '> svg > path': {
      fill: label,
    },
  };
});

const BBSelect: FC<BBSelectProps> = (props: BBSelectProps) => {
  const {
    name,
    placeholderLabel,
    toShrinkLabel = true,
    onBlur,
    variant = 'normal',
    value,
    onChange,
    popperWidth,
    isExtendedPopper = false,
    isSearchable = true,
    disabled = false,
    shouldUseCustomFilter = false,
    isFixedHeight = false,
    error,
    showMaxOptions = false,
    isMobileOptimised = false,
    readOnly = false,
    'data-testid': dataTestId,
  } = props;

  let timeout: ReturnType<typeof setTimeout>;

  const isXSmallSize: boolean = variant === 'x-small';
  const typeProp = { ...('type' in props && { type: props.type }) };

  const options = useMemo(() => {
    return 'options' in props ? props.options : [];
  }, [props]);

  const dialingCodeData = getDialingOptions();

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const SearchIcon = useMemo(
    () => getIcon(tenant, 'magnifyingGlass'),
    [tenant]
  );

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const searchLabel: string = translate('selectDropdown.searchLabel');
  const noResultsLabel: string = translate('selectDropdown.noResultsLabel');

  const isDialingCodeType: boolean = typeProp.type === 'dialingCode';
  const isMobileSmallLayout: boolean = layout === 'mobileSM';

  const [openState, setOpen] = useState(false);
  const [anchorElState, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [currentSelectedOption, setCurrentSelectedOption] =
    useState<SelectedOptionType | null>(null);

  const selectOptions = useMemo(() => {
    switch (typeProp.type) {
      case 'country':
        return options.length > 0
          ? options
          : getCountryOptionsFromAPI(CountryMockData);
      case 'currency':
        return options.length > 0 ? options : getCurrencyOptions();
      case 'dialingCode':
        return dialingCodeData;
      default:
        return options;
    }
  }, [typeProp.type, options, dialingCodeData]);

  const isMobileOrTabletDevices: boolean = !!isMobileOrTabletDevice.any();

  const shouldShowMobileOptimisedLayout: boolean = useMemo(() => {
    if (isMobileOptimised) {
      return true;
    } else {
      switch (typeProp.type) {
        case 'country':
        case 'currency':
        case 'dialingCode':
        case 'state':
          return true;
        default:
          return false;
      }
    }
  }, [typeProp.type, isMobileOptimised]);

  useEffect(() => {
    if (!isMobileOrTabletDevices) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (selectOptions && value && value.length > 0) {
      const preSelectedOption =
        selectOptions &&
        selectOptions.find(
          (option: { value: string }) => option.value === value
        );

      if (preSelectedOption && preSelectedOption.iconPath) {
        import(
          `../../../core/CountryMetadata/${preSelectedOption.iconPath}.svg`
        )
          .then(({ default: icon }) => {
            const preSelOption = isDialingCodeType
              ? {
                  ...preSelectedOption,
                  value: extractDialingCodeValue(preSelectedOption.value),
                }
              : preSelectedOption;
            const updatedOption = {
              ...preSelOption,
              icon,
            };
            setCurrentSelectedOption(updatedOption);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCurrentSelectedOption(preSelectedOption || null);
      }
    } else {
      setCurrentSelectedOption(null);
    }
  }, [value]);

  const handleResize = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleClickAway();
    }, 100);
  };

  const toggleDropdown = useCallback(
    (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
      if (!disabled) {
        if (openState) {
          setAnchorEl(null);
          setOpen(false);
        } else {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }
      }
    },
    [disabled, openState]
  );

  const handleClick = (event: MouseEvent<HTMLDivElement>) =>
    !readOnly && toggleDropdown(event);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.key === ENTER_KEY && toggleDropdown(event);
  };

  const handleClickAway = () => {
    if (anchorElState) {
      anchorElState.focus();
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const onChangeHandler = (
    e: SyntheticEvent,
    newValueObj: AutocompleteValue<any, any, any, any>
  ) => {
    onChange && onChange(newValueObj.value);
    setOpen(false);
    setAnchorEl(null);
    if (newValueObj && newValueObj.iconPath) {
      import(`../../../core/CountryMetadata/${newValueObj.iconPath}.svg`)
        .then(({ default: icon }) => {
          const valueObj = isDialingCodeType
            ? {
                ...newValueObj,
                value: extractDialingCodeValue(newValueObj.value),
              }
            : newValueObj;
          const updatedOption = {
            ...valueObj,
            icon,
          };
          setCurrentSelectedOption(updatedOption);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCurrentSelectedOption(newValueObj);
    }
  };

  const CustomPopper = (popperProps: PopperComponentProps) => {
    const { disablePortal, anchorEl, open, ...other } = popperProps;
    return (
      <StyledPopper
        {...other}
        isSearchable={isSearchable}
        isExtendedPopper={isExtendedPopper}
      />
    );
  };

  const filterOptions = (
    currentOptions: any,
    state: FilterOptionsState<OptionType>
  ): OptionType[] => {
    const optionList: OptionType[] = currentOptions as OptionType[];
    const searchValue: string = state.inputValue.trim().toLowerCase();

    if (searchValue) {
      const filteredOptions: OptionType[] = [];
      optionList.forEach((option: OptionType) => {
        if (
          option.label?.trim().toLowerCase().includes(searchValue) ||
          option.value?.trim().toLowerCase().includes(searchValue)
        )
          filteredOptions.push(option);
      });
      return filteredOptions;
    }
    return optionList;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DropdownContainer>
        <BBSelectInput
          onBlur={onBlur}
          placeholderLabel={placeholderLabel}
          open={openState}
          variant={variant}
          handleClick={handleClick}
          handleKeyDown={handleKeyDown}
          currentSelectedOption={currentSelectedOption}
          isExtendedPopper={isExtendedPopper}
          toShrinkLabel={toShrinkLabel}
          disabled={disabled}
          {...typeProp}
          hasError={!!error}
          readOnly={readOnly}
          data-testid={dataTestId}
        />
        {!!error && <BBValidationErrorMessage message={error} />}
        {isMobileSmallLayout && shouldShowMobileOptimisedLayout ? (
          <FullScreenDialog open={openState}>
            <FullScreenDialogAutocomplete
              handleClose={handleClickAway}
              selectOptions={selectOptions}
              currentSelectedOption={currentSelectedOption}
              onChangeHandler={onChangeHandler}
              shouldUseCustomFilter={shouldUseCustomFilter}
              placeholder={searchLabel}
              {...typeProp}
            />
          </FullScreenDialog>
        ) : (
          <MainPopper
            open={openState}
            anchorEl={anchorElState}
            placement="bottom-start"
            width={anchorElState ? anchorElState.clientWidth : 300}
            isExtendedPopper={isExtendedPopper}
            popperWidth={popperWidth}
            isSearchable={isSearchable}
            error={error}
            disablePortal={true} //The `children` will be under the DOM hierarchy of the parent component.
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: 'document',
                  padding: 10,
                  fallbackPlacements: ['bottom-start'],
                },
              },
            ]}
          >
            <StyledAutocomplete
              isSearchable={isSearchable}
              isExtendedPopper={isExtendedPopper}
              variant={variant}
              filterOptions={shouldUseCustomFilter ? filterOptions : undefined}
              id={`bb-select-${name}`}
              open={openState}
              value={isSearchable ? null : currentSelectedOption} // value is passed as null when dropdown is searchable to avoid filling the search field with selected value whenever currentSelectionOption is not null.
              onChange={onChangeHandler}
              options={selectOptions}
              getOptionLabel={(option) =>
                option !== ''
                  ? isDialingCodeType
                    ? option.value
                    : option.label
                  : ''
              }
              isOptionEqualToValue={(option, currentSelectionOption) =>
                option.value === currentSelectionOption?.value
              }
              noOptionsText={noResultsLabel}
              clearIcon={null}
              componentsProps={{
                paper: {
                  sx: {
                    padding: '0px',
                    border: 'none',
                    boxShadow: 'none',
                    ...(isXSmallSize && {
                      '& ul': {
                        padding: `4px ${isFixedHeight ? '4' : '0'}px 4px 4px !important`,
                      },
                    }),
                  },
                },
              }}
              PopperComponent={CustomPopper}
              ListboxComponent={(listBoxProps) => (
                <StyledListBox
                  {...listBoxProps}
                  isFixedHeight={isFixedHeight}
                  showMaxOptions={showMaxOptions}
                  variant={variant}
                />
              )}
              renderOption={(renderOptionProps, option) => {
                return (
                  <BBSelectItem
                    {...renderOptionProps}
                    option={option}
                    currentSelectedOption={currentSelectedOption}
                    key={`${option.label}-${option.value}-${option.iconPath}`}
                    variant={variant}
                    {...typeProp}
                  />
                );
              }}
              renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                  <StyledInput
                    data-testid="bb-select-search-field"
                    variant={variant}
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: !isSearchable,
                    }}
                    // If the device is a mobile or a tablet device,
                    // then the search field should not be focusable
                    autoFocus={!isMobileOrTabletDevices && isSearchable}
                    placeholder={searchLabel}
                    isSearchable={isSearchable}
                    isExtendedPopper={isExtendedPopper}
                    startAdornment={
                      !params.inputProps.value &&
                      isSearchable && (
                        <IconContainer>
                          <SearchIcon />
                        </IconContainer>
                      )
                    }
                  />
                );
              }}
            />
          </MainPopper>
        )}
      </DropdownContainer>
    </ClickAwayListener>
  );
};

export default BBSelect;
