import { Tab, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';

// Utils
import {
  getBody2ButtonMediumStyles,
  getBody2NormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

type TabTypes = 'square' | 'line' | 'button';
interface StyledTabProps {
  type?: TabTypes;
}

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledTabProps>(({ theme, type }) => {
  const {
    dimensions: { spacing, radius },
    colours,
  } = theme;

  const isLineTab: boolean = type === 'line';

  return {
    ...(isLineTab
      ? {
          color: colours.text.textWeak,
          padding: `${spacing.xxSmall}px ${spacing.xxSmall}px ${spacing.small}px ${spacing.xxSmall}px`,
          ...getBody2NormalStyles(theme),
          borderBottom: '2px solid transparent', // To avoid the jittering when hovering over a tab
        }
      : {
          color: colours.buttons.outline.text,
          border: `1px solid ${colours.borders.borderLighter}`,
          backgroundColor: colours.backgrounds.white,
          borderRadius: radius.small,
          padding: `${spacing.xSmall}px ${spacing.medium}px`,
          ...getBody2ButtonMediumStyles(theme),
        }),
    minHeight: 'initial',
    minWidth: 'initial',
    textTransform: 'initial',

    '&:hover': {
      ...(isLineTab
        ? {
            borderBottom: `2px solid ${colours.borders.selectionHover}`,
            overflow: 'hidden', // To avoid the jittering when hovering over a tab
          }
        : {
            borderColor: colours.backgrounds.activeDark,
            backgroundColor: colours.buttons.outline.hover.bg,
          }),
      color: colours.buttons.outline.hover.text,
    },

    '&.Mui-focusVisible': {
      ...(isLineTab
        ? {
            borderBottom: `2px solid ${colours.borders.selectionHover}`,
            backgroundColor: colours.backgrounds.activeLightest,
            border: `1px solid ${colours.borders.focus}`,
          }
        : {
            borderColor: colours.buttons.secondary.focus.focusBorder,
            backgroundColor: colours.buttons.outline.focus.bg,
          }),
      color: colours.buttons.outline.focus.text,
      boxShadow: `inset 0 0 0 1px ${colours.backgrounds.white}`,
    },

    '&.Mui-selected': {
      ...(isLineTab
        ? {
            color: colours.text.text,
            borderBottom: `2px solid ${colours.borders.active}`,
            // This is a work around to make it look bold and not make the tab shift when selected,
            textShadow: `0 0 0.5px ${colours.text.text}, 0 0 0.5px ${colours.text.text}`,
          }
        : {
            color: colours.buttons.secondary.text,
            backgroundColor: colours.backgrounds.activeDark,
            borderColor: colours.backgrounds.activeDark,
          }),
      boxShadow: 'none',
    },

    '&.Mui-disabled': {
      color: colours.text.textWeak,
      opacity: 0.5,
    },
  };
});

interface BBTabProps
  extends Omit<TabProps, 'disableRipple' | 'disableFocusRipple'> {
  type?: 'square' | 'line' | 'button';
}

const BBTab = (props: BBTabProps) => {
  const { type = 'square', ...rest } = props;

  return <StyledTab type={type} {...rest} disableRipple />;
};

export default memo(BBTab);
