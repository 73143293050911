import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Redux
import { ReduxState } from '../../core/types/ReduxStoreTypes';
import { initialState } from '../initialState';
import { RESET_ERROR_DETAILS } from '../ActionTypeConstants';

export const getServicesErrorObj = createAsyncThunk(
  'errorObject',
  async (error: any, thunkAPI) => {
    console.log('getServicesErrorObj called', error);
    const {
      response: { status },
    } = error || {};

    // Separate check added for custom error types, which will be sending a custom error object
    const shouldDispatchError: boolean = status >= 400;

    if (shouldDispatchError) {
      thunkAPI.dispatch(
        showErrorNotification({
          error: true,
          message: error?.response?.data?.response_description || 'Error',
          errorCode: error?.response?.status,
        })
      );
      setTimeout(() => {
        thunkAPI.dispatch(errorStateReset());
      }, 6000);
    }
  }
);

export const resetErrorDetails = createAction<string>(RESET_ERROR_DETAILS);

const errorDetailsSlice = createSlice({
  name: 'errorDetails',
  initialState: initialState.errorDetails,
  reducers: {
    showErrorNotification(state, action) {
      state.apiError = action.payload.error;
      state.errorMessage = action.payload.message;
      state.errorCode = action.payload.errorCode?.toString();
    },
    errorStateReset: () => initialState.errorDetails,
  },
  extraReducers: (builder) => {
    builder.addCase(resetErrorDetails, () => initialState.errorDetails);
  },
});

export const getErrorDetails = (state: ReduxState) => state.errorDetails;

export const { showErrorNotification, errorStateReset } =
  errorDetailsSlice.actions;

export default errorDetailsSlice.reducer;
