import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

export const LoaderContainer = styled('div')({
  textAlign: 'center',
});

// TODO: This will need to be replaced with a proper page loader when
// it's ready
const PageLoader = () => {
  return (
    <LoaderContainer>
      <CircularProgress data-testid="page-loader" />
    </LoaderContainer>
  );
};

export default memo(PageLoader);
