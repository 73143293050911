import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../atoms/BBButton';
import BBText from '../../../atoms/BBText';
// Types
import { LayoutSize } from '../../../../core/types/LayoutTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

interface InvitationContainerOwnerState {
  layout: LayoutSize;
}

export const InvitationContainer = styled('div')<{
  ownerState: InvitationContainerOwnerState;
}>(({ theme, ownerState }) => {
  const { layout } = ownerState;
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    alignItems: isMobileLayout ? 'start' : 'center',
    justifyContent: 'space-between',
    gap: spacing.small,
    backgroundColor: colours.backgrounds.bg,
    borderRadius: radius.small,
    flexDirection: isMobileLayout ? 'column' : 'row',

    ...(isDesktopLayout && { padding: spacing.large }),
    ...(isTabletLayout && { padding: spacing.medium }),
    ...(isMobileLayout && { padding: spacing.small }),
  };
});

export const InvitationTitleSummary = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
  };
});

export const InvitationContent = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});

interface InvitationButtonOwnerState {
  layout: LayoutSize;
}

export const InvitationButton = styled(BBButton)<{
  ownerState: InvitationButtonOwnerState;
}>(({ ownerState }) => {
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    flexShrink: 0,
    ...(!isMobileLayout && { width: 220 }),
    ...(isMobileLayout && { width: '100%' }),
  };
});

export const InvitationText = styled(BBText)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeak,
  };
});
