import { useEffect, useState } from 'react';

// Constants
import CurrencyOptions from '../../../../../../core/utils/MockData/CurrencyMockData.json';

interface CurrencyIconProps {
  currency: string;
}

const getCurrencyIconPath = (currency: string) => {
  return CurrencyOptions.find((option) => option.value === currency)?.iconPath;
};

const CurrencyIcon = (props: CurrencyIconProps) => {
  const { currency } = props;
  const [iconPath, setIconPath] = useState<string | undefined>();

  useEffect(() => {
    const path = getCurrencyIconPath(currency);

    if (path) {
      import(`../../../../../../core/CountryMetadata/${path}.svg`)
        .then((imported) => setIconPath(imported.default))
        .catch(() => {});
    }
  }, [currency]);

  if (!iconPath) {
    return null;
  }

  return <img src={iconPath} alt={currency} loading="lazy" />;
};

export default CurrencyIcon;
