import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';
import Box from '@mui/material/Box';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import Page from '../../../organisms/Page';
import RequestDetailsSummaryData from '../ComponentUtils/RequestDetailsSummaryData';
import PaymentProgress from './PaymentProgress';
import AccountToFundData from './AccountToFundData';
import BBButton from '../../../atoms/BBButton';
import {
  MainContainer,
  StyledPageTitle,
  SummaryContainer,
  SummaryData,
  SummaryField,
  WeakText,
} from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { PaymentStatus } from '../../../../core/types/PaymentRequestsTypes';
// API Wrappers
import {
  useGetPaymentRequestByIdQuery,
  useGetTransactionQuery,
} from '../../../../redux/api';
// Utils
import {
  getFormattedAddress,
  getFormattedValue,
} from '../../../../core/utils/Format';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

export type RouterParams = {
  transactionId: string;
  paymentRequestId: string;
};

const TitleContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});

const ActionButtonsContainer = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    alignSelf: 'stretch',
    gap: spacing.small,

    '> button': {
      width: isMobileLayout ? '100%' : '50%',
    },
  };
});

const ViewPaymentRequestDetails: FC = () => {
  const { transactionId, paymentRequestId } = useParams<RouterParams>();
  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();
  const navigate = useNavigate();

  const {
    data: paymentRequestData,
    isLoading: paymentRequestLoading,
    error: paymentRequestError,
  } = useGetPaymentRequestByIdQuery(
    paymentRequestId ? Number(paymentRequestId) : skipToken
  );

  const {
    data: transactionRequestData,
    isLoading: transactionRequestLoading,
    error: transactionRequestError,
  } = useGetTransactionQuery(transactionId ? Number(transactionId) : skipToken);

  if (paymentRequestData?.paymentRequestStatus === PaymentStatus.InProgress) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Page
      isLoading={paymentRequestLoading || transactionRequestLoading}
      error={paymentRequestError || transactionRequestError}
    >
      <TitleContainer>
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={getFormattedValue(
            paymentRequestData?.paymentType?.toLowerCase() === 'other'
              ? paymentRequestData.paymentTypeDescription
              : paymentRequestData?.paymentType
          )}
        />

        <WeakText
          variant="body2"
          type="normal"
          text={getFormattedAddress(transactionRequestData?.address) ?? ''}
        />
      </TitleContainer>
      <MainContainer layout={layout}>
        <SummaryContainer layout={layout}>
          <SummaryField>
            <SummaryData>
              <RequestDetailsSummaryData data={paymentRequestData} />
            </SummaryData>
            <SummaryData>
              <AccountToFundData data={paymentRequestData} />
            </SummaryData>
          </SummaryField>

          <ActionButtonsContainer layout={layout}>
            <BBButton
              size="medium"
              btnType="outlined"
              type="button"
              onClick={() => navigate(`/transaction-details/${transactionId}`)}
            >
              {translate('common.backBtn')}
            </BBButton>
          </ActionButtonsContainer>
        </SummaryContainer>
        <PaymentProgress data={paymentRequestData} />
      </MainContainer>
    </Page>
  );
};

export default ViewPaymentRequestDetails;
