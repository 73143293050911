import React from 'react';
import { parseNumber } from '../../../../../../core/utils/Parse';
import {
  useGetPaymentQuery,
  useGetPaymentRequestByIdQuery,
} from '../../../../../../redux/api';
import { skipToken } from '@reduxjs/toolkit/query';

export default function usePayments(
  paymentId?: string,
  paymentRequestId?: string
) {
  const paymentQuery = useGetPaymentQuery(parseNumber(paymentId) ?? skipToken);

  const paymentRequestDataId =
    paymentRequestId ?? paymentQuery?.data?.paymentRequest?.id.toString();

  const paymentRequestQuery = useGetPaymentRequestByIdQuery(
    parseNumber(paymentRequestDataId) ?? skipToken
  );

  return { paymentQuery, paymentRequestQuery };
}
