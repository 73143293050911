// Types
import {
  ConfirmPaymentResponse,
  UpsertPaymentRequest,
  UpsertPaymentResponse,
} from '../../core/types/ApiTypes';
import { Payment } from '../../core/types/PaymentTypes';
// API Wrappers
import { api } from './api';

const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPayment: builder.query<Payment, Payment['id']>({
      query: (id) => `propertypay-services/payments/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Payments', id }],
    }),

    upsertPayment: builder.mutation<
      UpsertPaymentResponse,
      UpsertPaymentRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/payments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, _error, data) => [
        ...(data.id ? [{ type: 'Payments' as const, id: data.id }] : []),
        ...(result?.paymentRequest?.id
          ? [{ type: 'PaymentRequests' as const, id: result.paymentRequest.id }]
          : []),
      ],
    }),

    confirmPayment: builder.mutation<ConfirmPaymentResponse, Payment['id']>({
      query: (id) => ({
        url: `propertypay-services/payments/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: (result, _error, id) => [
        { type: 'Payments', id },
        ...(result?.paymentRequest?.id
          ? [{ type: 'PaymentRequests' as const, id: result.paymentRequest.id }]
          : []),
      ],
    }),
  }),
});

export const {
  useGetPaymentQuery,
  useUpsertPaymentMutation,
  useConfirmPaymentMutation,
} = paymentsApi;
