// Components - Atoms, Molecules, Organisms, Pages
import BBDataTable from '../../../../organisms/BBDataTable';
// Hooks
import { useColumns } from './useColumns';
// Types
import { PaymentRequests } from './types';

interface PaymentRequestsTableProps {
  payments: PaymentRequests[];
  transactionId: number;
}

const PaymentRequestsTable = (props: PaymentRequestsTableProps) => {
  const { payments, transactionId } = props;

  const { columns } = useColumns(transactionId);

  return (
    <BBDataTable
      columnVisibilityModel={{ createdDate: false }}
      columns={columns}
      rows={payments}
      hideFooter={true}
      defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
    />
  );
};

export default PaymentRequestsTable;
