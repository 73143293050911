import { STEP_DETAILS } from './StepperData';

export interface StepsConfigType {
  textKey: string;
  id: number;
  href: string;
}

export type StepType =
  | 'create-transaction'
  | 'payment-request'
  | 'buyer-payment'
  | 'disbursement';

export interface IdsValueType {
  transactionId?: string;
  paymentRequestId?: string;
  disbursementId?: string;
  paymentId?: string;
}

export const generateSteps = (stepType: StepType, idValues: IdsValueType) => {
  const steps: StepsConfigType[] =
    stepType in STEP_DETAILS ? STEP_DETAILS[stepType] : [];
  return steps.map((step: StepsConfigType) => {
    let updatedHref = step.href;
    Object.entries(idValues).forEach(([key, value]) => {
      updatedHref = updatedHref.replace(`{${key}}`, value ?? '');
    });
    return {
      ...step,
      href: updatedHref,
    };
  });
};
