// Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
import { PaymentRow } from './types';

export const mapPaymentToPaymentRow = (
  payment: PaymentRequestDetails['payments'][number]
): PaymentRow => {
  const {
    id,
    paymentMethod,
    amountToReceive,
    currencyToReceive,
    crmAccount,
    paymentStatus,
    createdDate,
    paidDate,
  } = payment;

  return {
    id,
    status: paymentStatus,
    paymentType: paymentMethod,
    amount: amountToReceive,
    currency: currencyToReceive,
    payer: crmAccount?.name,
    createdDate,
    datePaid: paidDate,
  };
};
