import { memo, ReactNode, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MaterialAccordionSummary, {
  AccordionSummaryProps as MaterialAccordionSummaryProps,
} from '@mui/material/AccordionSummary';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface StyledAccordionSummaryProps extends MaterialAccordionSummaryProps {
  isExpandable?: boolean;
}

const StyledAccordionSummary = styled(MaterialAccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isExpandable',
})<StyledAccordionSummaryProps>(({ theme, isExpandable = true }) => {
  const {
    dimensions: { spacing, radius },
    colours,
  } = theme;

  return {
    padding: spacing.xSmall,
    borderRadius: radius.small,

    '&, &.Mui-expanded': {
      minHeight: 'initial',
    },

    '&.Mui-focusVisible': {
      backgroundColor: 'inherit',
    },

    '> .MuiAccordionSummary-content': {
      margin: 0,
    },
    '> .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },

    '> .MuiAccordionSummary-expandIconWrapper': {
      color: isExpandable ? colours.backgrounds.active : 'transparent',
      '> svg': {
        width: 20,
        height: 20,
      },
    },
    '> .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: colours.backgrounds.active,
      transform: isExpandable ? 'rotate(90deg)' : 'none',
    },
  };
});

export const SummaryContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.small,
    flex: '1 0 0',
  };
});

export const IconContainer = styled('div')(({ theme }) => {
  const {
    colours: { icon },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    color: icon.active,

    svg: {
      width: 26,
      height: 26,
    },
  };
});

export const TitleContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
  };
});

export const StyledSubTitle = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;

  return {
    color: text.textWeak,
  };
});

interface AccordionSummaryProps
  extends Omit<StyledAccordionSummaryProps, 'children'> {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
}

const AccordionSummary = (props: AccordionSummaryProps) => {
  const {
    isExpandable = true,
    icon,
    title,
    subtitle,
    ...accordionProps
  } = props;

  const { tenant } = useContext(TenantContext);

  const ExpandIcon = useMemo(
    () => getIcon(tenant, isExpandable ? 'rightChevron' : 'checkmarkFilled'),
    [tenant, isExpandable]
  );

  return (
    <StyledAccordionSummary
      expandIcon={<ExpandIcon />}
      {...accordionProps}
      isExpandable={isExpandable}
    >
      <SummaryContainer>
        {icon && <IconContainer>{icon}</IconContainer>}
        <TitleContainer>
          <BBText variant="body2" type="medium" text={title} />

          {subtitle && (
            <StyledSubTitle variant="subtitle1" type="normal" text={subtitle} />
          )}
        </TitleContainer>
      </SummaryContainer>
    </StyledAccordionSummary>
  );
};

export default memo(AccordionSummary);
