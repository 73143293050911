import BBText from '../../atoms/BBText';
import { LinkText, StyledList, StyledNavDivider } from './BBHeader.styles';
//Hooks
import useHeaderNav from './useHeaderNav';

type HeaderProps = {
  pageHeaderType?: string;
};

export default function HeaderNav(props: HeaderProps) {
  const { pageHeaderType } = props;
  const navigations = useHeaderNav(pageHeaderType);

  return (
    <StyledList>
      {navigations.map((item: any, index: number) => {
        const isLast = index === navigations.length - 1;
        const isLabelId = !isNaN(Number(item.label));
        return (
          <>
            {item.path ? (
              <LinkText key={index} to={item.path} isLabelId={isLabelId}>
                {item.label}
              </LinkText>
            ) : (
              <BBText
                key={index}
                variant="body2"
                type="normal"
                text={item.label}
              />
            )}
            {!isLast && (
              <StyledNavDivider
                key={index}
                orientation="vertical"
                variant="middle"
                flexItem
              />
            )}
          </>
        );
      })}
    </StyledList>
  );
}
