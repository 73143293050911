import Cookies from 'js-cookie';

//Components and Utils
import { SMSVerificationSuccessResponse } from '../../types/ApiTypes';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  refreshTokenExpiry,
} from '../Constants/Constants';

export const setTokens = (results: SMSVerificationSuccessResponse) => {
  const { access_token, refresh_token, expires_in } = results;
  const accessTokenExpiry = (expires_in / 60 + 1) / 1440; // 10 mins to days

  Cookies.set(ACCESS_TOKEN_KEY, access_token, {
    expires: accessTokenExpiry,
    secure: true,
    sameSite: 'strict',
  });
  Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
    expires: refreshTokenExpiry,
    secure: true,
    sameSite: 'strict',
  });
};

export const clearTokens = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
  window.location.href = '/login';
};

export const getAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return Cookies.get(REFRESH_TOKEN_KEY);
};
