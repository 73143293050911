import { styled } from '@mui/material/styles';

// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

export const FormControl = styled('div')({
  width: '100%',
});

interface OwnerState extends LayoutProps {}

export const HorizontalFormControl = styled('div')<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const { layout } = ownerState;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    width: '100%',
    gap: spacing.small,
    ...(isMobileLayout && { flexDirection: 'column' }),

    '> *': {
      flexGrow: 1,
    },
  };
});

export const CountryCodeControlContainer = styled('div')<{
  ownerState: OwnerState;
}>(({ ownerState }) => {
  const { layout } = ownerState;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    ...(!isMobileLayout && { maxWidth: 150 }),
  };
});
