import { useSearchParams } from 'react-router-dom';

// Types
import { Nullable } from '../../../core/types/UtilTypes';

const parsePageValue = (value: Nullable<string>) => {
  if (!value) {
    return 0;
  }

  const page = parseInt(value);

  if (Number.isNaN(page)) {
    return 0;
  }

  return page;
};

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page: number = parsePageValue(searchParams.get('page'));

  const setPage = (page: number) => {
    if (!page) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', String(page));
    }
    setSearchParams(searchParams);
  };

  return { page, setPage };
};
