import { FC, useContext, useMemo } from 'react';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import BBIconButton from '../../../../atoms/BBIconButton/BBIconButton';
import { WeakText } from '../../../CommonStyles/CommonStyles.styles';
import {
  ModalContainer,
  StyledBackdrop,
  StyledModal,
} from '../../../../molecules/BBModal/styles';
// Hooks
import { useTranslations } from '../../../../../core/hooks';
// Types
import { DownloadDisbursementType } from '../../../../../core/types/DisbursementTypes';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';

interface DownloadDisbursementModalProps {
  open: boolean;
  onDownload: (value: DownloadDisbursementType) => void;
  onClose: () => void;
}

const CloseIconContainer = styled('div')(() => {
  return {
    position: 'absolute',
    top: 40,
    right: 40,
  };
});

const StyledIconButton = styled(BBIconButton)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.icon.iconAlt,

    ':hover': {
      backgroundColor: 'transparent',
    },

    '&.Mui-focusVisible': {
      color: colours.icon.iconAlt,
      outline: `1px solid ${colours.borders.border}`,
      backgroundColor: 'transparent',
    },

    '> svg': {
      width: 40,
      height: 40,
    },
  };
});

const StyledBody = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  };
});

const Container = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  };
});

const StyledDownloadButton = styled(IconButton)(({ theme }) => {
  const {
    dimensions: { spacing, radius },
    colours,
  } = theme;

  return {
    padding: `${spacing.xxSmall}px ${spacing.small}px`,
    border: `1px solid ${colours.borders.input}`,
    borderRadius: radius.small,
    backgroundColor: colours.backgrounds.bgAlt,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: spacing.xSmall,
    minHeight: 60,
    color: colours.text.text,

    ':hover': {
      backgroundColor: 'transparent',
    },
  };
});

const DownloadDisbursementModal: FC<DownloadDisbursementModalProps> = (
  props: DownloadDisbursementModalProps
) => {
  const { open, onClose, onDownload } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const baseTranslationKey =
    'transactionDetails.completionPayments.downloadDisbursementsModal';

  const [CloseIcon, FileDocumentPDFIcon] = useMemo(
    () => [
      getIcon(tenant, 'navigationClose'),
      getIcon(tenant, 'fileDocumentPDF'),
    ],
    [tenant]
  );

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      slots={{
        backdrop: StyledBackdrop,
      }}
    >
      <>
        <CloseIconContainer>
          <StyledIconButton
            onClick={onClose}
            name="closeModalButton"
            data-testid="bb-button-modal-close"
          >
            <CloseIcon />
          </StyledIconButton>
        </CloseIconContainer>
        <Slide
          direction="up"
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <ModalContainer data-testid="container" isOpen={open} size="normal">
            <StyledBody>
              <Container>
                <BBText
                  variant="h3"
                  type="bold"
                  text={translate(`${baseTranslationKey}.title`)}
                />
                <WeakText
                  variant="body2"
                  type="normal"
                  text={translate(`${baseTranslationKey}.message`)}
                />
              </Container>
              <Container>
                <StyledDownloadButton
                  onClick={() => onDownload('source-of-funds')}
                  data-testid="download-source-of-funds"
                >
                  <FileDocumentPDFIcon />
                  <BBText
                    variant="body2"
                    type="medium"
                    text={translate(`${baseTranslationKey}.sourceOfFunds`)}
                  />
                </StyledDownloadButton>
                <StyledDownloadButton
                  onClick={() => onDownload('completion-schedule')}
                  data-testid="download-completion-schedule"
                >
                  <FileDocumentPDFIcon />
                  <BBText
                    variant="body2"
                    type="medium"
                    text={translate(`${baseTranslationKey}.completionSchedule`)}
                  />
                </StyledDownloadButton>
              </Container>
            </StyledBody>
          </ModalContainer>
        </Slide>
      </>
    </StyledModal>
  );
};

export default DownloadDisbursementModal;
