import sortBy from 'lodash/sortBy';

// Types
import { Transaction } from '../../../core/types/TransactionTypes';
import { Account } from '../../../core/types/AccountTypes';
import { Address } from '../../../core/types/AddressTypes';
import { TransactionRow } from './TransactionsTable/types';
import { Sort, SortByType, Paths } from './types';

// Utils
import {
  getFormattedDate,
  getFormattedValue,
} from '../../../core/utils/Format';
import { DEFAULT_FORMAT_VALUE } from '../../../core/utils/Constants/Constants';

export const formatAddress = (address?: Address) => {
  if (!address) {
    return DEFAULT_FORMAT_VALUE;
  }

  return [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.townCity,
  ]
    .filter(Boolean)
    .join(', ');
};

const formatAccounts = (accounts: Account[] = []) => {
  return sortBy(accounts, 'id')
    .map((account) => account.name)
    .join(', ');
};

export const mapTransactionToTransactionRow = (
  transaction: Transaction
): TransactionRow => {
  const {
    id,
    address,
    fileReference,
    completionDate,
    crmAccounts,
    transactionStatus,
  } = transaction;

  return {
    id,
    address: formatAddress(address),
    ref: getFormattedValue(fileReference),
    partyName: formatAccounts(crmAccounts),
    status: transactionStatus,
    completionDate: getFormattedDate(completionDate),
  };
};

const sortByApiMapping: Record<SortByType, Paths<Transaction>[]> = {
  address: [
    'address.addressLine1',
    'address.addressLine2',
    'address.addressLine3',
    'address.townCity',
  ],
  completionDate: ['completionDate'],
  createdDate: ['createdDate'],
  partyName: ['crmAccounts.name'],
};

export const searchFields: Paths<Transaction>[] = [
  ...sortByApiMapping.address,
  ...sortByApiMapping.partyName,
  ...sortByApiMapping.completionDate,
  'transactionStatus',
  'fileReference',
];

export const mapSortToApiSortBy = (sort: Sort) => {
  return sortByApiMapping[sort.sortBy].map((sortBy) => {
    return `${sortBy},${sort.orderBy}`;
  });
};
