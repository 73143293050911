import { styled } from '@mui/material/styles';

export const AccordionContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    width: '100%',
  };
});
