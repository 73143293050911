import { memo } from 'react';
import { TabList, TabListProps } from '@mui/lab';
import { styled } from '@mui/material/styles';

type TabListTypes = 'square' | 'line' | 'button';
interface StyledTabListProps {
  type?: TabListTypes;
}

const StyledTabList = styled(TabList, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledTabListProps>(({ theme, type }) => {
  const {
    dimensions: { spacing },
    colours: { borders },
  } = theme;

  const isLineTabList: boolean = type === 'line';

  return {
    minHeight: 'initial',
    overflow: 'initial',
    ...(isLineTabList && {
      borderBottom: `2px solid ${borders.border}`,
      '.MuiTabs-fixed': {
        bottom: '-2px',
      },
    }),

    '.MuiTabs-indicator': {
      display: 'none',
    },

    '.MuiTabs-flexContainer': {
      gap: isLineTabList ? spacing.small : spacing.xxxSmall,
    },
  };
});

interface BBTabListProps extends TabListProps {
  type?: TabListTypes;
}

const BBTabList = (props: BBTabListProps) => {
  const { type = 'square', ...rest } = props;

  return <StyledTabList type={type} {...rest} />;
};

export default memo(BBTabList);
