import { useContext, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

//Context
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBStepPanel from '../../../organisms/BBStepPanel/BBStepPanel';
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
import PartiesForm from './PartiesForm';
import PageLoader from '../../../organisms/PageLoader';
import NotFoundRedirect from '../../../organisms/NotFoundRedirect';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// API Wrappers
import { useGetTransactionQuery } from '../../../../redux/api';
//Utils
import { isNotFoundError } from '../../../../core/utils/Errors';

type RouterParams = {
  id: string;
};

interface PartyDetailsProps {}

const PartyDetails: FC<PartyDetailsProps> = (props: PartyDetailsProps) => {
  const { id } = useParams<RouterParams>();
  const navigate = useNavigate();

  const { translate } = useTranslations();

  const { layout } = useContext(LayoutContext);

  const { data, isLoading, error } = useGetTransactionQuery(
    id ? Number(id) : skipToken
  );

  const activeStep: number = 1;

  const handleOnContinueClick = () => {
    navigate(`/create-transactions/confirmation/${id}`);
  };

  const handleOnBackClick = () => {
    navigate(`/create-transactions/transaction-details/${id}`);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={translate('createTransaction.partyDetails.title')}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <PartiesForm
          transactionId={Number(id)}
          activeStep={activeStep}
          transaction={data}
          onBack={handleOnBackClick}
          onContinue={handleOnContinueClick}
        />
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'create-transaction'}
          idValues={{
            transactionId: id,
          }}
        />
      </MainContainer>
    </>
  );
};

export default PartyDetails;
