import { createContext, RefObject } from 'react';

type ContentRefContext = {
  ref: RefObject<HTMLDivElement> | null;
  updateRef: (ref: RefObject<HTMLDivElement>) => void;
};

export default createContext<ContentRefContext>({
  ref: null,
  updateRef: (ref: RefObject<HTMLDivElement>) => {}
});
