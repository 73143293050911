export const downloadPdf = (data: string, fileName: string) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = `data:application/pdf;base64,${data}`;
  downloadLink.download = `${fileName}.pdf`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};
