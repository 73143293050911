import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { GridColumn } from '../../../../organisms/BBDataTable';
import TableRowAction from '../../../../organisms/TableRowAction';
import PaymentRequestStatus from '../../../../organisms/PaymentRequestStatus/PaymentRequestStatus';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { PaymentRequests } from './types';
import { PaymentStatus } from '../../../../../core/types/PaymentRequestsTypes';

const getColumnTranslationKey = (value: string) =>
  `transactionDetails.paymentRequest.columns.${value}`;

const getActionTranslationKey = (value: string) =>
  `transactionDetails.paymentRequest.actions.${value}`;

export const useColumns = (transactionId: number) => {
  const { translate } = useTranslations();

  const columns = useMemo<GridColumn<PaymentRequests>[]>(() => {
    return [
      {
        field: 'payment',
        headerName: translate(getColumnTranslationKey('payment')),
        flex: 1,
        minWidth: 450,
      },
      {
        field: 'amount',
        headerName: translate(getColumnTranslationKey('amount')),
        flex: 1,
        minWidth: 150,
        maxWidth: 250,
        sortType: 'number',
      },
      {
        field: 'status',
        headerName: translate(getColumnTranslationKey('status')),
        sortable: false,
        flex: 1,
        minWidth: 110,
        maxWidth: 150,
        renderCell: ({ row }) => {
          return <PaymentRequestStatus status={row.status} />;
        },
      },
      {
        field: 'dueDate',
        headerName: translate(getColumnTranslationKey('dueDate')),
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
        sortType: 'date',
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: 100,
        renderCell: ({ row }) => {
          const isTransactionStatusDraft: boolean =
            row.status?.toLowerCase() ===
            PaymentStatus.InProgress?.toLowerCase();
          const page = isTransactionStatusDraft
            ? `request-details`
            : `view-details`;
          return (
            <TableRowAction
              to={`/payment-request/${page}/${transactionId}/${row.id}`}
            >
              {translate(
                getActionTranslationKey(
                  isTransactionStatusDraft ? 'finish' : 'view'
                )
              )}
            </TableRowAction>
          );
        },
      },
      { field: 'createdDate' },
    ];
  }, [translate, transactionId]);

  return { columns };
};
