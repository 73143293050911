import { useContext, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../atoms/BBButton';
import BBInput, { InputProps } from '../../../atoms/BBInput/BBInput';
import BBDatePicker from '../../../organisms/BBDatePicker';
import BBSelect, { BBSelectProps } from '../../../atoms/BBSelect/BBSelect';
import { RHFBBAddressLookup } from '../../../molecules/BBAddressLookup';
import {
  FormContainer,
  FieldSets,
  ActionButtonsContainer,
  Section,
  FormControl,
  StyledFormTitle,
} from '../../CommonStyles/CommonStyles.styles';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import RHFBBAmount from '../../../molecules/BBAmount/RHFBBAmount';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { TransactionDetailsFormValues } from './types';
import { Transaction } from '../../../../core/types/TransactionTypes';
// API Wrappers
import {
  useUpsertAddressMutation,
  useUpsertTransactionMutation,
} from '../../../../redux/api';
// Utils
import {
  getAddressErrors,
  getTomorrowDate,
  getTransactionErrors,
  mapFormValuesToAddressUpsertRequest,
  mapFormValuesToTransactionUpsertRequest,
  mapTransactionToFormValues,
} from './utils';
// Constants
import {
  DEFAULT_COUNTRY,
  DEFAULT_PURCHASE_VALUE_CURRENCY,
  NON_DEFAULT_PURCHASE_VALUE_CURRENCY,
} from '../../../../core/utils/Constants/Constants';

const defaultValues: TransactionDetailsFormValues = {
  fileReference: '',
  completionDate: null,
  country: DEFAULT_COUNTRY,
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    suburbCity: '',
    province: '',
    townCity: '',
    county: '',
    state: '',
    postcode: '',
  },
  purchaseValue: '',
  purchaseValueCurrency: DEFAULT_PURCHASE_VALUE_CURRENCY,
};

interface TransactionFormProps {
  activeStep: number;
  onContinue: (id: Transaction['id']) => void;
  transaction?: Transaction;
}

const TransactionForm = (props: TransactionFormProps) => {
  const { activeStep, onContinue, transaction } = props;
  const transactionId = useRef<Transaction['id'] | undefined>(transaction?.id);

  const { layout } = useContext(LayoutContext);

  const { translate } = useTranslations();
  const { validationSchema } = useValidations();
  const [upsertTransaction, { error: upsertTransactionError }] =
    useUpsertTransactionMutation();
  const [upsertAddress, { error: upsertAddressError }] =
    useUpsertAddressMutation();

  const apiErrors = useMemo(() => {
    return {
      ...getTransactionErrors(upsertTransactionError, upsertAddressError),
      address: getAddressErrors(upsertAddressError),
    };
  }, [upsertAddressError, upsertTransactionError]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<TransactionDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: transaction
      ? mapTransactionToFormValues(transaction)
      : defaultValues,
    errors: apiErrors,
  });

  const formValues: TransactionDetailsFormValues = watch();

  const countryValue: string = watch('country');

  const textFieldProps: InputProps = {
    name: 'purchaseValue',
    placeholderLabel: translate(
      'createTransaction.transactionDetails.fields.purchaseValue'
    ),
    value: formValues.purchaseValue,
    type: 'number',
  };

  const dropdownProps: BBSelectProps = {
    name: 'purchaseValueCurrency',
    placeholderLabel: '',
    value: formValues.purchaseValueCurrency,
    type: 'currency',
    toShrinkLabel: false,
    isSearchable: false,
  };

  const getSectionHeading = (text: string) => {
    return <StyledFormTitle variant="body1" type="medium" text={text} />;
  };

  const handleCountryChange = (_: string) => {
    setValue('address', defaultValues.address);
  };

  const onSubmit = async (data: TransactionDetailsFormValues) => {
    try {
      const transactionData = {
        ...mapFormValuesToTransactionUpsertRequest(data),
        id: transactionId.current,
      };

      const { id } = await upsertTransaction(transactionData).unwrap();
      transactionId.current = id;

      const address = {
        ...mapFormValuesToAddressUpsertRequest(data),
        transactionId: transactionId.current,
        id: transaction?.address?.id,
      };

      await upsertAddress(address).unwrap();

      onContinue(id);
    } catch {}
  };

  // This code block will set the purchaseValueCurrency as EUR when country is Spain and GBP for any other country
  useEffect(() => {
    if (countryValue === DEFAULT_COUNTRY) {
      setValue('purchaseValueCurrency', DEFAULT_PURCHASE_VALUE_CURRENCY);
    } else {
      setValue('purchaseValueCurrency', NON_DEFAULT_PURCHASE_VALUE_CURRENCY);
    }
  }, [countryValue, setValue]);

  return (
    <>
      <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
        <FieldSets layout={layout}>
          <Section layout={layout}>
            {getSectionHeading(
              translate(
                'createTransaction.transactionDetails.transactionSectionHeading'
              )
            )}
            <FormControl>
              <ControllerElementWrapper
                name="fileReference"
                control={control}
                placeholderLabel={translate(
                  'createTransaction.transactionDetails.fields.fileReference'
                )}
                component={BBInput}
                value={formValues.fileReference}
              />
            </FormControl>
            <FormControl>
              <ControllerElementWrapper
                name="completionDate"
                control={control}
                label={translate(
                  'createTransaction.transactionDetails.fields.completionDate'
                )}
                component={BBDatePicker}
                value={formValues.completionDate}
                minDate={getTomorrowDate()}
              />
            </FormControl>
          </Section>
          <Section layout={layout}>
            {getSectionHeading(
              translate(
                'createTransaction.transactionDetails.propertyAddressSectionHeading'
              )
            )}
            <FormControl>
              <ControllerElementWrapper
                name="country"
                control={control}
                value={formValues.country}
                placeholderLabel={translate(
                  'createTransaction.transactionDetails.fields.country'
                )}
                component={BBSelect}
                size="normal"
                type="country"
                handleChange={(value: string) => handleCountryChange(value)}
              />
            </FormControl>
            <FormControl>
              <RHFBBAddressLookup
                control={control}
                selectedCountry={formValues.country}
                addressValue={formValues.address}
                setValue={setValue}
                trigger={trigger}
              />
            </FormControl>
            <FormControl>
              <RHFBBAmount
                control={control}
                textFieldProps={textFieldProps}
                dropdownProps={dropdownProps}
              />
            </FormControl>
          </Section>
        </FieldSets>
        <ActionButtonsContainer activeStep={activeStep} layout={layout}>
          <BBButton
            btnType="secondary"
            size="medium"
            disabled={!isValid || isSubmitting}
            type="submit"
          >
            {translate('steppers.saveContinueBtn')}
          </BBButton>
        </ActionButtonsContainer>
      </FormContainer>

      {isDirty && !isSubmitting && <NavigationBlocker />}
    </>
  );
};

export default TransactionForm;
