// Types
import {
  AccountToBeFundedDetails,
  AddBankAccountRequest,
  PaymentMethod,
  PaymentMethods,
  PaymentRequestDetails,
  UpdateAccountToBeFundedRequest,
  UpdateFraudAlertRequest,
} from '../../../../core/types/PaymentRequestsTypes';
import { AccountType } from '../../../../core/types/RecipientTypes';
import { AccountToBeFundedFormValues } from './types';

export const defaultValues: AccountToBeFundedFormValues = {
  accountType: null,
  recipientId: null,
  newAccountType: 'Individual',
  firstName: '',
  lastName: '',
  companyName: '',
  countryCode: '',
  currency: 'EUR',
  bankDetails: {},
  bankDetailsValidationRules: {},
};

export const mapFormValuesToAddBankAccountRequest = (
  values: AccountToBeFundedFormValues
): AddBankAccountRequest => {
  const {
    newAccountType,
    firstName,
    lastName,
    companyName,
    countryCode,
    currency,
    bankDetails,
  } = values;

  const bankFields: string[] = [];
  for (const [_key, value] of Object.entries(bankDetails)) {
    value && bankFields.push(value);
  }

  const isIndividualAccount = newAccountType?.toLowerCase() === 'individual';

  return {
    bankAccountType: newAccountType,
    firstName: isIndividualAccount ? firstName : '',
    lastName: isIndividualAccount ? lastName : '',
    companyName: isIndividualAccount ? '' : companyName,
    bankName: null,
    branchAddress: null,
    country: countryCode,
    currency,
    valueOne: bankFields[0] ? bankFields[0] : '',
    valueTwo: bankFields[1] ? bankFields[1] : '',
    valueThree: bankFields[2] ? bankFields[2] : '',
  };
};

export const mapAccountToBeFundedDataToFormValue = (
  data: AccountToBeFundedDetails
): AccountToBeFundedFormValues => {
  return {
    ...defaultValues,
    accountType: mapApiAccountTypeValue(data.paymentMethod),
    recipientId: data.bankAccount?.id ?? null,
  };
};

export const mapApiAccountTypeValue = (
  paymentMethod: AccountToBeFundedDetails['paymentMethod']
): AccountType | null => {
  if (paymentMethod === PaymentMethods.Wallet) {
    return 'wallet';
  } else if (paymentMethod === PaymentMethods.Bank) {
    return 'saved-recipient';
  }
  return null;
};

export const mapFormValueToAccountToBeFundedRequest = (
  data: AccountToBeFundedFormValues,
  paymentRequestData: PaymentRequestDetails | undefined
): UpdateAccountToBeFundedRequest | null => {
  if (paymentRequestData) {
    const isAccountTypeWallet = data.accountType === 'wallet';
    const paymentMethod = isAccountTypeWallet
      ? PaymentMethods.Wallet
      : PaymentMethods.Bank;
    const bankAccountId =
      !isAccountTypeWallet && data.recipientId ? data.recipientId : null;

    return createAccountToBeFundedRequestData(
      paymentMethod,
      bankAccountId,
      paymentRequestData.id
    );
  }
  return null;
};

export const createAccountToBeFundedRequestData = (
  paymentMethod: PaymentMethod,
  bankAccountId: number | null,
  paymentRequestId: number
): UpdateAccountToBeFundedRequest => {
  return {
    bankAccount: bankAccountId
      ? {
          id: bankAccountId,
        }
      : null,
    paymentMethod: paymentMethod,
    paymentRequestId: paymentRequestId,
  };
};

export const mapFormValuesToFraudAlertRequest = (
  paymentRequestData: PaymentRequestDetails
): UpdateFraudAlertRequest => {
  return {
    createdDate: paymentRequestData.createdDate,
    crmAccountIds: paymentRequestData.crmAccountIds,
    currency: paymentRequestData.currency,
    dueDate: paymentRequestData.dueDate,
    fraudAlert: true,
    id: paymentRequestData.id,
    invitationStatus: paymentRequestData.invitationStatus,
    payAmount: paymentRequestData.payAmount,
    paymentType: paymentRequestData.paymentType,
    paymentTypeDescription: paymentRequestData.paymentTypeDescription,
    transactionId: paymentRequestData.transactionId,
    updatedDate: paymentRequestData.updatedDate,
  };
};
