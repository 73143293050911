// Types
import { Account } from '../../../../core/types/AccountTypes';
import {
  BankAccountDetailsResponse,
  PaymentRequestDetails,
} from '../../../../core/types/PaymentRequestsTypes';
// Constants
import { DEFAULT_FORMAT_VALUE } from '../../../../core/utils/Constants/Constants';

export const getPayeeNames = (crmAccounts: Account[]): string => {
  return crmAccounts
    ? crmAccounts.map((account) => account.name).join(', ')
    : DEFAULT_FORMAT_VALUE;
};

export const getBankAccountField = (
  data: PaymentRequestDetails | undefined
) => {
  const getFieldValue = (field: keyof BankAccountDetailsResponse) =>
    data &&
    data.accountToBeFunded &&
    data.accountToBeFunded.bankAccount &&
    data.accountToBeFunded.bankAccount?.[field]
      ? String(data.accountToBeFunded.bankAccount[field])
      : DEFAULT_FORMAT_VALUE;

  return { getFieldValue };
};
