import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

//Components
import PinInput from './PinInput';

const length = 5;

interface PinFieldProps {
  value: string[];
  onChange: (value: string[]) => void;
}

export default function PinField(props: PinFieldProps) {
  const { value, onChange } = props;
  const otpValues: string[] = value;

  const [activeInput, setActiveInput] = useState(0);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      onChange(otp);
    },
    [onChange]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      if (!!val) {
        changeCodeAtFocus(val);
        focusNextInput();
      }
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          // Move to previous input field on press of backspace or delete
          e.preventDefault();
          changeCodeAtFocus('');
          focusPrevInput();
          break;
        }
        case 'ArrowLeft': {
          // Move to previous input box
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          // Move to next input box
          e.preventDefault();
          focusNextInput();
          break;
        }
        case 'ArrowDown':
        case 'ArrowUp': {
          // Prevent change of input value of type number on up and down arrow hit
          e.preventDefault();
          break;
        }
        default: {
          // Allow only numbers
          if (pressedKey.match(/^[^0-9]$/)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [changeCodeAtFocus, focusNextInput, focusPrevInput]
  );

  return (
    <>
      {Array(length)
        .fill('')
        .map((_, index) => {
          return (
            <PinInput
              key={`pin-input-${index}`}
              name={`pin_${index}`}
              placeholderLabel=""
              type="number"
              value={otpValues[index]}
              maxLength={1}
              focus={activeInput === index}
              onFocus={handleOnFocus(index)}
              handleChange={handleOnChange}
              handleOnKeyDown={handleOnKeyDown}
            />
          );
        })}
    </>
  );
}
