import currency from 'currency.js';

// Types
import {
  Disbursement,
  DisbursementStatus,
} from '../../../../core/types/DisbursementTypes';
import { CompletionPaymentsDetails } from './types';
import { ConfirmWalletBalance } from '../../../../core/types/AvailableBalanceTypes';
// Utils
import {
  getFormattedCurrencyValue,
  getTrimmedLowercaseValue,
} from '../../../../core/utils/Format';
import { isAllowedStatus } from '../../../../core/utils/AllowedDisbursementStatus';

type FormattedCompletionPayment = {
  formattedData: CompletionPaymentsDetails[];
  totalAmount: string;
  hasRecordsInReadyState: boolean;
};

export const getFormattedCompletionPaymentsData = (
  data: Disbursement[] | undefined
): FormattedCompletionPayment => {
  let totalAmount = currency(0);
  // The total amount currency is not passed from the backend and hence
  // using the first currency from the data object after filtering pending status
  let currencyValue = '';
  let hasRecordsInReadyState: boolean = false;

  const formattedData =
    data
      ?.filter(({ status }) => isAllowedStatus(status))
      .map(
        (
          {
            id,
            paidFromWallet,
            amount,
            currency: disbursementCurrency,
            status,
            bankAccount,
            createdDate,
          },
          index: number
        ) => {
          totalAmount = totalAmount.add(amount);
          if (index === 0) {
            currencyValue = disbursementCurrency;
          }
          if (
            !hasRecordsInReadyState &&
            getTrimmedLowercaseValue(status) ===
              getTrimmedLowercaseValue(DisbursementStatus.Ready)
          ) {
            hasRecordsInReadyState = true;
          }
          return {
            id: id as number,
            beneficiary: paidFromWallet?.crmAccount?.name || '',
            amount: getFormattedCurrencyValue(amount, disbursementCurrency),
            status,
            bankCode: bankAccount?.valueOne || '',
            createdDate: createdDate,
          };
        }
      ) || [];

  return {
    formattedData: formattedData,
    totalAmount: getFormattedCurrencyValue(totalAmount.value, currencyValue),
    hasRecordsInReadyState,
  };
};

export const areAllFundsAvailable = (
  walletBalanceList?: ConfirmWalletBalance[]
) => {
  const hasUnavailableFunds = walletBalanceList?.find(
    (walletDetails) => walletDetails.areFundsAvailable === false
  );

  return !!hasUnavailableFunds;
};
