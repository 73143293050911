import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { LayoutSize } from '../../../core/types/LayoutTypes';
// Utils
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface ActionButtonsContainerProps {
  activeStep: number;
  layout?: LayoutSize;
}

export const MainContainer = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';
  const {
    dimensions: {
      spacing: { medium, xLarge, xxLarge },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: isDesktopLayout ? 'flex-start' : 'center',
    gap: isDesktopLayout ? xxLarge : isTabletLayout ? xLarge : medium,
    alignSelf: 'stretch',
    flexDirection: isDesktopLayout ? 'row' : 'column-reverse',
  };
});

export const FormContainer = styled('form')<LayoutProps>(({
  layout,
  theme,
}) => {
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';
  const {
    dimensions: {
      spacing: { medium, xLarge, xxxLarge },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: isDesktopLayout ? xxxLarge : isTabletLayout ? xLarge : medium,
    flex: 1,
    alignSelf: 'stretch',
  };
});

export const SummaryContainer = styled(Box)<LayoutProps>(({
  layout,
  theme,
}) => {
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';
  const {
    dimensions: {
      spacing: { medium, xLarge, xxxLarge },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: isDesktopLayout ? xxxLarge : isTabletLayout ? xLarge : medium,
    flex: 1,
    alignSelf: 'stretch',
  };
});

export const FieldSets = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';
  const {
    dimensions: {
      spacing: { small, medium, large },
      radius,
    },
    colours: { backgrounds, borders },
  } = theme;

  return {
    display: 'flex',
    padding: isDesktopLayout ? large : isTabletLayout ? medium : small,
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: radius.medium,
    border: `1px solid ${borders.border}`,
    background: backgrounds.bg,
    gap: isDesktopLayout ? large : medium,
  };
});

export const Section = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);
  const {
    dimensions: {
      spacing: { xSmall, small },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: isMobileLayout ? xSmall : small,
    alignSelf: 'stretch',
    position: 'relative',
  };
});

export const ActionButtonsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'activeStep' && prop !== 'layout',
})<ActionButtonsContainerProps>(({ theme, activeStep, layout }) => {
  const {
    dimensions: {
      spacing: { xxSmall, large, xSmall },
    },
  } = theme;
  const isInitialStep = activeStep === 0;
  const isMobileLayout: boolean = layout
    ? isMobileLayoutApplicable(layout)
    : false;
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: isMobileLayout ? xSmall : `${xxSmall}px ${large}px`,
    alignSelf: isInitialStep && !isMobileLayout ? 'self-end' : 'stretch',
    flexWrap: 'nowrap',
    flexDirection: isMobileLayout ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
    '& button': {
      ...(isMobileLayout && {
        width: '100%',
      }),
      ...(isDesktopLayout && {
        minWidth: '248px',
        width: '100%',
      }),
      ...(isTabletLayout && {
        minWidth: '262px',
        width: '100%',
      }),
    },
  };
});

export const StyledPageTitle = styled(BBText)<Partial<LayoutProps>>(({
  theme,
  layout,
}) => {
  const isDesktopLayout = layout === 'desktop';
  const {
    colours: {
      text: { text },
    },
  } = theme;

  return {
    color: text,
    alignSelf: 'stretch',
    ...(layout &&
      !isDesktopLayout && {
        textAlign: 'center',
      }),
  };
});

export const StyledFormTitle = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;

  return {
    color: text,
    alignSelf: 'stretch',
  };
});

export const SummaryField = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { medium },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: medium,
    flex: 2,
    alignSelf: 'stretch',
  };
});

export const SummaryData = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { small, medium },
      radius,
    },
    colours: {
      backgrounds: { bg },
      text: { textWeak, black },
    },
  } = theme;

  return {
    display: 'flex',
    padding: medium,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: small,
    alignSelf: 'stretch',
    borderRadius: radius.medium,
    background: bg,
    '> ul': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'stretch',
      gap: small,
      '> li': {
        padding: 0,
        '> div': {
          margin: 0,
          '> span': {
            ...getBody2NormalStyles(theme),
            color: textWeak,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '> p': {
            ...getBody2NormalStyles(theme),
            color: black,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },
  };
});

export const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
  } = theme;
  return {
    color: borderLighter,
    backgroundColor: borderLighter,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: 0,
  };
});

export const PartyTitleWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  };
});

export const FormControl = styled('div')({
  width: '100%',
});

export const StyledListTitle = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    color: textWeak,
  };
});

export const WeakText = styled(BBText)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeak,
  };
});

export const WeakerText = styled(BBText)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeaker,
  };
});

export const CenterizedContent = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing.medium,
  };
});
