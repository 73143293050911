import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import BBTextLink from '../BBTextLink';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { SortDropdownProps } from './types';

export const SortContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    gap: spacing.xxxSmall,
    padding: `${spacing.xSmall}px ${spacing.xxSmall}px`,
    width: '100%',
  };
});

const SortDropdown = (props: SortDropdownProps) => {
  const { options, value, onChange } = props;

  const { translate } = useTranslations();

  return (
    <SortContainer>
      <BBText
        variant="body2"
        type="normal"
        text={translate('sortDropdown.title')}
      />
      <BBTextLink
        value={value}
        options={options}
        onChange={(_, value) => onChange(value)}
      />
    </SortContainer>
  );
};

export default SortDropdown;
