import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { GridColumn } from '../../../../organisms/BBDataTable';
import PaymentStatus from './PaymentStatus';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { PaymentRow } from './types';
import { PaymentMethodType } from '../../../../../core/types/PaymentTypes';
// Utils
import {
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../../core/utils/Format';

const getColumnTranslationKey = (value: string) =>
  `buyer.paymentRequestDetails.payments.columns.${value}`;

export const useColumns = () => {
  const { translate } = useTranslations();

  const columns = useMemo<GridColumn<PaymentRow>[]>(() => {
    return [
      {
        field: 'paymentType',
        headerName: translate(getColumnTranslationKey('paymentType')),
        flex: 1,
        minWidth: 295,
        valueFormatter: (value: PaymentMethodType) =>
          translate(
            `buyer.paymentRequestDetails.payments.types.${value.toLowerCase()}`
          ),
      },
      {
        field: 'status',
        headerName: translate(getColumnTranslationKey('status')),
        flex: 1,
        minWidth: 130,
        maxWidth: 150,
        renderCell: ({ row }) => <PaymentStatus status={row.status} />,
      },
      {
        field: 'amount',
        headerName: translate(getColumnTranslationKey('amount')),
        flex: 1,
        minWidth: 120,
        maxWidth: 150,
        sortType: 'number',
        valueFormatter: (_, row) =>
          getFormattedCurrencyValue(row.amount, row.currency),
      },
      {
        field: 'payer',
        headerName: translate(getColumnTranslationKey('payer')),
        flex: 1,
        minWidth: 190,
        maxWidth: 200,
        valueFormatter: getFormattedValue,
      },
      {
        field: 'datePaid',
        headerName: translate(getColumnTranslationKey('datePaid')),
        flex: 1,
        minWidth: 110,
        maxWidth: 300,
        sortType: 'date',
        valueFormatter: getFormattedDate,
      },
      { field: 'createdDate' },
    ];
  }, [translate]);

  return { columns };
};
