import { useWindowSize } from 'usehooks-ts';
import { LayoutSize } from '../types/LayoutTypes';

// Phones: xs = 365px -576px
// Tablets: sm = 576px+
// Large Tablets: md = 768px+
// Large Desktops: lg = 992px+
// Extra Large Desktops: xl = 1200px+

const useLayoutSize = (): LayoutSize => {
  const { width } = useWindowSize();

  if (width <= 365) {
    return 'mobileSM';
  }

  if (width > 365 && width <= 576) {
    return 'mobile';
  }

  if (width > 576 && width <= 992) {
    return 'tablet';
  }

  return 'desktop';
};

export default useLayoutSize;
