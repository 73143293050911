import { styled } from '@mui/material/styles';

//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface CardContainerProps {
  checked: boolean;
}

interface ContentWrapperProps extends LayoutProps {}

export const SearchResultContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      scroll: { thumbBackground, trackBackground },
    },
    dimensions: { spacing },
    zindex: { scrollBarThumb },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    maxHeight: 260,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 10,
      height: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      background: thumbBackground,
      borderRadius: 8,
      zIndex: scrollBarThumb,
      height: 30,
    },
    '&::-webkit-scrollbar-track': {
      background: trackBackground,
      width: 24,
    },
  };
});

export const NoRecipientsContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { radius, spacing },
    colours: {
      backgrounds: { bg },
    },
  } = theme;

  return {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: radius.small,
    backgroundColor: bg,
    padding: `${spacing.medium}px 0`,
    '> div': {
      width: '100%',
      maxWidth: 280,
      textAlign: 'center',
    },
  };
});

export const StyledDescription = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;

  return {
    color: text.textWeaker,
  };
});

export const CardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'checked',
})<CardContainerProps>(({ theme, checked }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
      borders: { border, active },
    },
    dimensions: { spacing, radius },
  } = theme;

  return {
    border: checked ? `2px solid ${active}` : `1px solid ${border}`,
    backgroundColor: bgAlt,
    padding: spacing.small,
    borderRadius: radius.small,
    cursor: 'pointer',
  };
});

export const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layout',
})<ContentWrapperProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isNotMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: isNotMobileLayout ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: isNotMobileLayout ? 'flex-start' : 'center',
    rowGap: spacing.small,
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  };
});

export const UserDetails = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    overflowWrap: 'anywhere',
  };
});

export const CountryAndNumberText = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;
  return {
    color: text.textWeak,
  };
});

export const CurrencyRadioWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing.small,

    '> div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.xxSmall,

      '> img': {
        width: 18,
        height: 18,
      },
    },
  };
});
