// Types
import { Account } from '../../core/types/AccountTypes';
import {
  UpdateAccountPaymentRequestRequest,
  UpdateAccountPaymentRequestResponse,
} from '../../core/types/ApiTypes';
import { AccountPaymentRequest } from '../../core/types/AccountPaymentRequestTypes';
// API Wrappers
import { api } from './api';

const accountPaymentRequestsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountPaymentRequests: builder.query<
      AccountPaymentRequest[],
      Account['id']
    >({
      query: (id) =>
        `propertypay-services/crmaccount-paymentrequests/pending/account/${id}`,
      providesTags: [{ type: 'AccountPaymentRequests', id: 'LIST' }],
    }),

    updateAccountPaymentRequest: builder.mutation<
      UpdateAccountPaymentRequestResponse,
      UpdateAccountPaymentRequestRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/crmaccount-paymentrequests',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'AccountPaymentRequests', id: 'LIST' },
        { type: 'AccountPaymentRequests', id: data.id },
      ],
    }),
  }),
});

export const {
  useGetAccountPaymentRequestsQuery,
  useUpdateAccountPaymentRequestMutation,
} = accountPaymentRequestsApi;
