import { FC, useContext, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import Page from '../../../organisms/Page';
import CompletionPaymentsTable from './CompletionPaymentsTable/CompletionPaymentsTable';
import SendDisbursement from './SendDisbursement';
import AvailableFundDetails from '../../../organisms/AvailableFundDetails';
import BBInlineNotification from '../../../molecules/BBInlineNotification';
import NoRecordsFound from '../NoRecordsFound';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { DisbursementStatus } from '../../../../core/types/DisbursementTypes';
// API Wrapper
import { useGetDisbursementsByTransactionIdQuery } from '../../../../redux/api';
// Utils
import { getFormattedCompletionPaymentsData } from './utils';
import {
  getFormattedDate,
  getTrimmedLowercaseValue,
} from '../../../../core/utils/Format';

interface CompletionPaymentsProps {
  id: string | undefined;
  userNames: string;
  disbursementStatus?: DisbursementStatus;
  dateOfDisbursement?: string;
}

const ContentWrapper = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isDesktopLayout
      ? spacing.large
      : isTabletLayout
        ? spacing.medium
        : spacing.small,
  };
});

const TableWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  };
});

const SummaryWrapper = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: {
      spacing: { small },
    },
  } = theme;

  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    display: 'flex',
    flexDirection: isDesktopLayout ? 'row' : 'column',
    alignItems: isDesktopLayout ? 'flex-start' : 'stretch',
    alignContent: 'stretch',
    gap: small,
    flexWrap: 'wrap',
  };
});

const CompletionPayments: FC<CompletionPaymentsProps> = (
  props: CompletionPaymentsProps
) => {
  const { id, userNames, disbursementStatus, dateOfDisbursement } = props;

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const { isLoading, isFetching, data } =
    useGetDisbursementsByTransactionIdQuery(id ? Number(id) : skipToken);

  const completionPaymentsData = useMemo(() => {
    return getFormattedCompletionPaymentsData(data);
  }, [data]);

  const { formattedData, totalAmount, hasRecordsInReadyState } =
    completionPaymentsData;

  const areFundsDisbursed =
    getTrimmedLowercaseValue(disbursementStatus) ===
    getTrimmedLowercaseValue(DisbursementStatus.Completed);

  return (
    <Page isLoading={isLoading || isFetching}>
      {formattedData && formattedData.length > 0 ? (
        <ContentWrapper layout={layout}>
          <TableWrapper>
            {areFundsDisbursed && dateOfDisbursement && (
              <BBInlineNotification
                variant="success"
                text={translate(
                  'transactionDetails.completionPayments.paymentsDisbursed',
                  {
                    date: getFormattedDate(dateOfDisbursement),
                  }
                )}
              />
            )}

            <CompletionPaymentsTable
              completionPaymentsData={formattedData}
              transactionId={Number(id)}
              totalAmount={totalAmount}
            />
          </TableWrapper>

          {!areFundsDisbursed && (
            <SummaryWrapper layout={layout}>
              <AvailableFundDetails transactionId={id} />
              <SendDisbursement
                transactionId={id}
                totalAmount={totalAmount}
                userNames={userNames}
                hasRecordsInReadyState={hasRecordsInReadyState}
              />
            </SummaryWrapper>
          )}

          <BBText
            variant="subtitle1"
            type="normal"
            text={translate('transactionDetails.completionPayments.note')}
          />
        </ContentWrapper>
      ) : (
        <NoRecordsFound type="completion" />
      )}
    </Page>
  );
};

export default CompletionPayments;
