import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  ActionButtonsContainer,
  CenterizedContent,
  FormContainer,
  MainContainer,
  StyledPageTitle,
} from '../../../CommonStyles/CommonStyles.styles';
import BBStepPanel from '../../../../organisms/BBStepPanel';
import { AccordionContainer } from './PaymentMethodView.styles';
import Accordion, {
  AccordionDetails,
  AccordionSummary,
} from '../../../../organisms/Accordion';
import Wallets from './Wallets';
import BBText from '../../../../atoms/BBText';
import { Header, Subtitle } from '../shared/styles';
import BBLoader from '../../../../atoms/BBLoader';
import ResponsiveButton from '../../../../organisms/ResponsiveButton';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
import { useWallets } from '../shared/hooks/useWallets';
// Types
import { FormValues, RouterParams } from './types';
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { getFormattedCurrencyValue } from '../../../../../core/utils/Format';

interface PaymentMethodViewProps {
  paymentRequest?: PaymentRequestDetails;
  onContinue: (data: FormValues) => void;
  transactionId?: number;
}

const PaymentMethodView = (props: PaymentMethodViewProps) => {
  const { onContinue, paymentRequest, transactionId } = props;

  const { paymentId } = useParams<RouterParams>();

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();
  const {
    formState: { isValid, isSubmitting },
    setValue,
    watch,
    resetField,
    register,
    handleSubmit,
  } = useFormContext<FormValues>();

  const type = watch('type');

  const [WalletIcon, BankIcon] = useMemo(
    () => [getIcon(tenant, 'wallet'), getIcon(tenant, 'bank')],
    [tenant]
  );

  const { wallets, isLoading: isLoadingWallets } = useWallets(transactionId);

  const activeStep: number = 0;
  const hasWallets: boolean = Boolean(wallets.length);
  const canContinue: boolean = isValid && !isSubmitting;

  const onAccordionChange = (id: FormValues['type'], expanded: boolean) => {
    const currentAccordionItem = expanded ? id : undefined;

    setValue('type', currentAccordionItem, {
      shouldValidate: true,
      shouldDirty: true,
    });
    resetField('walletId');
  };

  return (
    <>
      <Header>
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={translate('buyer.createPayment.paymentMethod.title')}
          layout={layout}
        />
        <Subtitle ownerState={{ layout }}>
          {translate('buyer.createPayment.paymentMethod.subtitle1')}{' '}
          <BBText
            variant="body2"
            type="bold"
            component="strong"
            text={getFormattedCurrencyValue(
              paymentRequest?.payAmount,
              paymentRequest?.currency
            )}
          />{' '}
          {translate('buyer.createPayment.paymentMethod.subtitle2')}
        </Subtitle>
      </Header>
      <MainContainer layout={layout}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onContinue)}>
          <AccordionContainer>
            <Accordion
              expanded={type === 'wallet'}
              onChange={(_, expanded) => onAccordionChange('wallet', expanded)}
            >
              <AccordionSummary
                id="wallet"
                title={translate(
                  'buyer.createPayment.paymentMethod.wallet.title'
                )}
                subtitle={translate(
                  'buyer.createPayment.paymentMethod.wallet.subtitle'
                )}
                icon={<WalletIcon />}
              />
              <AccordionDetails>
                {isLoadingWallets && (
                  <CenterizedContent>
                    <BBLoader />
                  </CenterizedContent>
                )}

                {!isLoadingWallets && (
                  <>
                    {!hasWallets && (
                      <CenterizedContent>
                        <BBText
                          variant="body2"
                          type="bold"
                          text={translate(
                            `buyer.createPayment.paymentMethod.noWalletsText`
                          )}
                        />
                      </CenterizedContent>
                    )}

                    {hasWallets && (
                      <Wallets {...register('walletId')} wallets={wallets} />
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={type === 'bankAccount'}
              onChange={(_, expanded) =>
                onAccordionChange('bankAccount', expanded)
              }
            >
              <AccordionSummary
                id="bankAccount"
                isExpandable={false}
                title={translate(
                  'buyer.createPayment.paymentMethod.bankAccount.title'
                )}
                subtitle={translate(
                  'buyer.createPayment.paymentMethod.bankAccount.subtitle'
                )}
                icon={<BankIcon />}
              />
            </Accordion>
          </AccordionContainer>

          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <ResponsiveButton
              btnType="secondary"
              type="submit"
              disabled={!canContinue}
            >
              {translate('common.continueBtn')}
            </ResponsiveButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'buyer-payment'}
          idValues={{
            paymentId: paymentId,
          }}
        />
      </MainContainer>
    </>
  );
};

export default PaymentMethodView;
