import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import TableRowAction from '../../../organisms/TableRowAction';
import Details from './Details';
import TransactionStatus from './TransactionStatus';
import { GridColumn } from '../../../organisms/BBDataTable';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { TransactionRow } from './types';
import { TransactionStatus as TransactionStatusType } from '../../../../core/types/TransactionTypes';

const getColumnTranslationKey = (value: string) =>
  `transactions.columns.${value}`;

const getActionTranslationKey = (value: string) =>
  `transactions.actions.${value}`;

export const useColumns = () => {
  const { translate } = useTranslations();

  const columns = useMemo<GridColumn<TransactionRow>[]>(() => {
    return [
      {
        field: 'address',
        headerName: translate(getColumnTranslationKey('details')),
        flex: 1,
        minWidth: 450,
        renderCell: ({ row }) => {
          return <Details transaction={row} />;
        },
        valueGetter: (_, row) => row.address,
      },
      {
        field: 'partyName',
        headerName: translate(getColumnTranslationKey('partyName')),
        flex: 1,
        minWidth: 150,
        maxWidth: 250,
      },
      {
        field: 'status',
        headerName: translate(getColumnTranslationKey('status')),
        sortable: false,
        flex: 1,
        minWidth: 110,
        maxWidth: 150,
        renderCell: ({ row }) => {
          return <TransactionStatus status={row.status} />;
        },
      },
      {
        field: 'completionDate',
        headerName: translate(getColumnTranslationKey('completionDate')),
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
        sortType: 'date',
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: 100,
        renderCell: ({ row }) => {
          const { status } = row;

          if (status === TransactionStatusType.Draft) {
            return (
              <TableRowAction
                to={`/create-transactions/transaction-details/${row.id}`}
              >
                {translate(getActionTranslationKey('finish'))}
              </TableRowAction>
            );
          }

          return (
            <TableRowAction to={`/transaction-details/${row.id}`}>
              {translate(getActionTranslationKey('view'))}
            </TableRowAction>
          );
        },
      },
      { field: 'createdDate' },
    ];
  }, [translate]);

  return { columns };
};
