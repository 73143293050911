import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import Accordion, {
  AccordionDetails,
  AccordionSummary,
} from '../../../../organisms/Accordion';
import BBInput from '../../../../atoms/BBInput';
import ControllerElementWrapper from '../../../../organisms/ControllerWrapper';
import BBLoader from '../../../../atoms/BBLoader';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
import useGetVerifiesWalletDetails from '../useGetVerifiesWalletDetails';
// Types
import { RecipientDetailsFormValues } from '../types';
import { AccordionDefaultProps } from '../../../../../core/types/AccordionTypes';
import { WalletWithUserDetails } from '../../../../../core/types/WalletTypes';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import {
  ConfirmationWrapper,
  DetailsContainer,
  FormWrapper,
  StyledBBText,
} from '../styles';
import { AccountType } from '../../../../../core/types/RecipientTypes';

interface WalletToWalletAccordionProps
  extends AccordionDefaultProps<AccountType> {
  transactionId?: string;
  setWalletDetails: (data: WalletWithUserDetails) => void;
}

const WalletToWalletAccordion: FC<WalletToWalletAccordionProps> = (
  props: WalletToWalletAccordionProps
) => {
  const { expanded, handleChange, transactionId, setWalletDetails } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const { getVerifiedWallet, verifyQuery, walletQuery } =
    useGetVerifiesWalletDetails();

  const walletDetails = walletQuery?.data;
  const isLoadingWallet = verifyQuery?.isFetching || walletQuery?.isFetching;

  const [ArrowRightIcon, ConfirmedIcon, NotFoundIcon] = useMemo(
    () => [
      getIcon(tenant, 'arrowRight'),
      getIcon(tenant, 'statusCheckmarkOutlineIcon'),
      getIcon(tenant, 'statusError'),
    ],
    [tenant]
  );

  useEffect(() => {
    if (walletDetails?.length) {
      setWalletDetails(walletDetails[0]);
    }
  }, [walletDetails]);

  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<RecipientDetailsFormValues>();

  const [email = '', customerId = '', customerFound] = watch([
    'email',
    'customerId',
    'customerFound',
  ]);

  const accountType = 'wallet';

  const baseTranslationKey: string =
    'disbursement.recipientDetails.walletToWallet';

  const handleOnBlur = async () => {
    const isValidUser = await getVerifiedWallet({
      email,
      customerId,
      transactionId: transactionId ?? '',
    });
    setValue('customerFound', isValidUser, { shouldValidate: true });
  };

  return (
    <Accordion
      expanded={expanded === accountType}
      onChange={handleChange(accountType)}
    >
      <AccordionSummary
        aria-controls="wallet-content"
        id="wallet-header"
        icon={<ArrowRightIcon />}
        title={translate(`${baseTranslationKey}.title`)}
        subtitle={translate(`${baseTranslationKey}.subTitle`)}
      />
      <AccordionDetails>
        <DetailsContainer>
          <FormWrapper>
            <ControllerElementWrapper
              name={`email`}
              control={control}
              placeholderLabel={translate(baseTranslationKey + '.fields.email')}
              component={BBInput}
              isDisabled={isSubmitting}
              handleBlur={handleOnBlur}
            />
            <ControllerElementWrapper
              name={`customerId`}
              control={control}
              placeholderLabel={translate(
                baseTranslationKey + '.fields.customerId'
              )}
              component={BBInput}
              isDisabled={isSubmitting}
              handleBlur={handleOnBlur}
            />
          </FormWrapper>
          <ConfirmationWrapper customerFound={customerFound}>
            {isLoadingWallet && <BBLoader />}
            {!isLoadingWallet && (
              <>
                {customerFound ? <ConfirmedIcon /> : <NotFoundIcon />}
                <StyledBBText
                  variant="subtitle1"
                  type="normal"
                  text={translate(
                    `${baseTranslationKey}.${customerFound ? 'customerFound' : 'customerNotFound'}`
                  )}
                />
              </>
            )}
          </ConfirmationWrapper>
        </DetailsContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default WalletToWalletAccordion;
