import isEqual from 'lodash/isEqual';

// Types
import { Account } from '../../../../core/types/AccountTypes';
import {
  UpdateAccountRequest,
  UpdateAccountResponse,
  UpdateResult,
  UpdateThreeFaRequest,
} from '../../../../core/types/ApiTypes';
import {
  IndexedErrors,
  IndexedParty,
  IndexedResult,
  Party,
  PartyFieldError,
} from './types';
import {
  ApiErrorWithDetails,
  ErrorWithResults,
} from '../../../../core/types/ErrorTypes';
import { Transaction } from '../../../../core/types/TransactionTypes';
// Utils
import { isBadRequestError } from '../../../../core/utils/Errors';

const accountToPartyFieldMapping: Partial<Record<keyof Account, keyof Party>> =
  {
    name: 'fullName',
    email: 'email',
    contactNo: 'mobileNumber',
    contactCountryCode: 'countryCode',
  };

export const mapAccountUpdateRequestToParty = (
  account: UpdateAccountRequest
): Party => {
  const { id, name, email, contactNo, contactCountryCode } = account;

  return {
    id,
    fullName: name,
    email,
    mobileNumber: contactNo,
    countryCode: contactCountryCode,
  };
};

export const mapPartyToAccountUpdateRequest = (
  party: Party
): UpdateAccountRequest => {
  const { id, fullName, email, mobileNumber, countryCode } = party;

  return {
    id,
    name: fullName,
    email,
    contactNo: mobileNumber,
    contactCountryCode: countryCode,
  };
};

export const getUpdateResultsFromMutation = async (
  mutation: Promise<UpdateResult<UpdateAccountResponse>[]>
) => {
  try {
    const result = await mutation;

    return result;
  } catch (error) {
    return (error as ErrorWithResults<UpdateAccountResponse>).results;
  }
};

export const getPartiesToUpdate = (
  submittedParties: Party[],
  previousParties: Party[]
) => {
  // NOTE: We need to preserve original indexes of the party fields, so that
  // we can map individual update responses back to relevant item in the field
  // array
  const indexedParties = submittedParties.map((party, index) => ({
    party,
    index,
  }));

  return indexedParties.filter(({ party }) => {
    const previousParty = previousParties.find(({ id }) => id === party.id);
    const hasAnyChanges = !isEqual(party, previousParty);
    const isNewParty = !party.id;

    return isNewParty || hasAnyChanges;
  });
};

const isSuccessfulResult = (
  result: IndexedResult
): result is IndexedResult<{ data: UpdateAccountResponse }> => {
  return 'data' in result.result;
};

const isFailedResult = (
  result: IndexedResult
): result is IndexedResult<{
  error: ErrorWithResults<UpdateAccountResponse>;
}> => {
  return 'error' in result.result;
};

const mapApiErrorToPartyFieldError = (error: ApiErrorWithDetails) => {
  return {
    field: accountToPartyFieldMapping[error.message as keyof Account],
    message: error.details,
  };
};

export const getPartyUpdatesFromAccountUpdates = (
  results: UpdateResult<UpdateAccountResponse>[],
  parties: IndexedParty[]
) => {
  const indexedResults = results.map((result, index) => ({
    result,
    index: parties[index].index,
  }));

  const updated = indexedResults
    .filter(isSuccessfulResult)
    .map<IndexedParty>(({ result, index }) => ({
      party: mapAccountUpdateRequestToParty(result.data),
      index: index,
    }));

  const failed = indexedResults
    .filter(isFailedResult)
    .filter((result) => isBadRequestError(result.result.error))
    .map<IndexedErrors>(({ result, index }) => ({
      errors: (result.error.data as ApiErrorWithDetails[]).map(
        mapApiErrorToPartyFieldError
      ) as PartyFieldError[],
      index: index,
    }));

  return { updated, failed };
};

export const areResultsSuccessful = (
  results: UpdateResult<UpdateAccountResponse>[]
) => {
  return results.every((result) => !result.error);
};

export const mapFormValuesToThreeFaUpdateRequest = (
  transactionDetails: Transaction,
  threeFa: boolean | null
): UpdateThreeFaRequest => {
  return {
    id: transactionDetails.id,
    fileReference: transactionDetails.fileReference,
    completionDate: transactionDetails.completionDate,
    currency: transactionDetails.currency,
    purchaseValue: transactionDetails.purchaseValue,
    transactionStatus: transactionDetails.transactionStatus,
    threeFa: threeFa ?? false,
  };
};
