// Types
import { Account } from '../AccountTypes';
import { Address } from '../AddressTypes';
import { DisbursementStatus } from '../DisbursementTypes';
import { PaymentRequestDetails } from '../PaymentRequestsTypes';
import { Nullable } from '../UtilTypes';

export type TransactionFilterType =
  | 'all'
  | 'open'
  | 'completed'
  | 'cancelled'
  | 'draft';

export enum TransactionStatus {
  Draft = 'Draft',
  Open = 'Open',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  InProgress = 'In Progress',
}

export interface TransactionFilter {
  path: string;
  status?: TransactionStatus;
  type: TransactionFilterType;
}

export interface Transaction {
  createdDate: string;
  updatedDate: string;
  id: number;
  crmAccounts?: Account[];
  fileReference?: string;
  paymentRequests?: PaymentRequestDetails[];
  purchaseValue?: number;
  currency?: string;
  completionDate?: string;
  address?: Address;
  settlementAccount?: string;
  transactionStatus: TransactionStatus;
  percentageComplete?: number;
  leftToPay?: number;
  paidToDate?: number;
  threeFa: Nullable<boolean>;
  disbursementStatus?: DisbursementStatus; // Should be updated once the correct type is received from API team
  dateOfDisbursement?: string;
}

export interface PbuDetails {
  pbuName: string;
}

export enum ThreePaValue {
  Yes = 'Yes',
  No = 'No',
  Partial = 'Partial',
}

interface InvitationEntity {
  createdDate: string;
  updatedDate: string;
  id: number;
  invitationIssued: Nullable<string>;
  invitationIssuedDate: Nullable<string>;
  invitationAccepted: Nullable<string>;
  invitationAcceptedDate: Nullable<string>;
  docusignEnvelopeId: Nullable<string>;
  docusignEnvelopeStatus: Nullable<string>;
  thirdPartyAuthorisationRequested: boolean;
  thirdPartyAuthorisationGranted: boolean;
  thirdPartyAuthorisationGrantedDate: Nullable<string>;
  transactionId: number;
  crmAccountId: number;
}

export interface ThreePaDetails {
  threePa: ThreePaValue;
  invitationEntity: InvitationEntity[];
}
