import { useRef } from 'react';

// Hooks
import { useScheduledEffect } from './useScheduledEffect';

export const useScheduledEffectOne = (effect: () => void, time?: string) => {
  const scheduledTime = useRef(time);

  useScheduledEffect(effect, scheduledTime.current);
};
