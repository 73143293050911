// Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
import { PaymentRequests } from './types';
// Utils
import {
  getFormattedCurrencyValue,
  getFormattedDate,
} from '../../../../../core/utils/Format';

export const mapPaymentToPaymentRequests = (
  payments: PaymentRequestDetails,
  currency: string
): PaymentRequests => {
  const {
    id,
    paymentTypeDescription,
    payAmount,
    paymentRequestStatus,
    dueDate,
    createdDate,
  } = payments;

  return {
    id,
    payment: paymentTypeDescription,
    amount: getFormattedCurrencyValue(payAmount, currency),
    status: paymentRequestStatus,
    dueDate: getFormattedDate(dueDate),
    createdDate,
  };
};
