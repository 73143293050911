import { FC, memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../../atoms/BBText';
// Types
import { CountryDataInfo } from '../../../core/types/CountryDataTypes';
// Utils
import { getCountryOptionByCode } from '../../../core/utils/CountryData';
import CountryMockData from '../../../core/utils/MockData/CountryMockData.json';

const StyledOptionContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: spacing.xxxSmall,
    img: {
      width: 22,
      height: 22,
    },
  };
});

const StyledBBText = styled(BBText)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    marginLeft: spacing.xxSmall,
  };
});

interface BBSelectionSummaryCountryDetailsProps {
  countryCode: string;
}

const BBSelectionSummaryCountryDetails: FC<
  BBSelectionSummaryCountryDetailsProps
> = ({ countryCode }: BBSelectionSummaryCountryDetailsProps) => {
  const option: CountryDataInfo | undefined = getCountryOptionByCode(
    CountryMockData,
    countryCode
  );

  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (option && option.iconPath) {
      import(`../../../core/CountryMetadata/${option.iconPath}.svg`)
        .then(({ default: currentIcon }) => {
          setIcon(currentIcon);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }
  }, [option]);

  return (
    <>
      {option && (
        <StyledOptionContainer>
          {option.iconPath && icon && (
            <img loading="lazy" alt={option?.value} src={icon} />
          )}
          {option.label && (
            <StyledBBText
              text={`${option.label}`}
              variant="body2"
              type="normal"
            />
          )}
        </StyledOptionContainer>
      )}
    </>
  );
};

export default memo(BBSelectionSummaryCountryDetails);
