import { styled } from '@mui/material/styles';

// Utils
import { getBodySmallBoldStyles } from '../../../../../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Message = styled('span')(({ theme }) => {
  return {
    display: 'inline-block',
    textAlign: 'center',

    strong: {
      ...getBodySmallBoldStyles(theme),
    },
  };
});
