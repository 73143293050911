import {
  Disbursement,
  DisbursementBankAccountFields,
} from '../../../../core/types/DisbursementTypes';
import { DEFAULT_FORMAT_VALUE } from '../../../../core/utils/Constants/Constants';

export const getBankAccountField = (data: Disbursement | undefined) => {
  const account = data && (data.bankAccount as DisbursementBankAccountFields);

  const getFieldValue = (field: keyof DisbursementBankAccountFields) =>
    account && account[field] ? String(account[field]) : DEFAULT_FORMAT_VALUE;

  return { getFieldValue };
};
