import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import {
  StyledFormTitle,
  WeakText,
} from '../../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import {
  PaymentMethods,
  PaymentRequestDetails,
} from '../../../../../core/types/PaymentRequestsTypes';
// API Wrappers
import { useGetBankAccountFieldsQuery } from '../../../../../redux/api';
// Utils
import { getBankAccountField } from '../../ComponentUtils/utils';
import { getCountryName } from '../../../../../core/utils/CountryData/GetCountryData';
// Constants
import CountryOptions from '../../../../../core/utils/MockData/CountryMockData.json';
import { DEFAULT_FORMAT_VALUE } from '../../../../../core/utils/Constants/Constants';

interface DisplayAccountToBeFundedDetailsProps {
  data?: PaymentRequestDetails;
}

const DataContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

const DisplayAccountToBeFundedDetails: FC<
  DisplayAccountToBeFundedDetailsProps
> = (props: DisplayAccountToBeFundedDetailsProps) => {
  const { data } = props;

  const { translate } = useTranslations();

  const { getFieldValue } = getBankAccountField(data);

  const countryValue = getFieldValue('country');
  const currencyValue = getFieldValue('currency');

  const { data: bankAccountFields } = useGetBankAccountFieldsQuery(
    countryValue !== '-' && currencyValue !== '-'
      ? { countryCode: countryValue, currency: currencyValue }
      : skipToken
  );

  const cdWalletSummary = (
    <>
      <StyledFormTitle
        variant="body1"
        type="medium"
        text={translate('paymentRequest.confirmation.fundedTitle')}
      />
      <StyledFormTitle
        variant="body2"
        type="normal"
        text={translate(
          'paymentRequest.confirmation.fundedAccountCDWalletTitle'
        )}
      />
    </>
  );

  const savedRecipientsSummary = (
    <>
      <StyledFormTitle
        variant="body1"
        type="medium"
        text={translate('paymentRequest.confirmation.fundedTitle')}
      />
      <StyledFormTitle
        variant="body2"
        type="normal"
        text={translate('paymentRequest.confirmation.fundedAccountTitle')}
      />
      <DataContainer>
        <BBText
          type="medium"
          variant="body1"
          text={
            getFieldValue('bankAccountType')?.toLowerCase() === 'company'
              ? getFieldValue('companyName')
              : `${getFieldValue('firstName')} ${getFieldValue('lastName')}`
          }
        />
        <BBText
          type="normal"
          variant="body2"
          text={
            getCountryName(CountryOptions, getFieldValue('country')) ??
            DEFAULT_FORMAT_VALUE
          }
        />
      </DataContainer>

      <DataContainer>
        <WeakText
          type="normal"
          variant="body2"
          text={translate('paymentRequest.confirmation.currency')}
        />
        <BBText
          type="normal"
          variant="body2"
          text={getFieldValue('currency')}
        />
      </DataContainer>

      {bankAccountFields?.uiBlock.uiFields.map((uiField, index: number) => (
        <DataContainer key={uiField.id}>
          <WeakText type="normal" variant="body2" text={uiField.description} />
          <BBText
            type="normal"
            variant="body2"
            text={getFieldValue(
              index === 0 ? 'valueOne' : index === 1 ? 'valueTwo' : 'valueThree'
            )}
          />
        </DataContainer>
      ))}
    </>
  );

  return data?.accountToBeFunded?.paymentMethod === PaymentMethods.Wallet
    ? cdWalletSummary
    : data?.accountToBeFunded?.paymentMethod === PaymentMethods.Bank
      ? savedRecipientsSummary
      : null;
};

export default DisplayAccountToBeFundedDetails;
