import dayjs from 'dayjs';

// Types
import { TransactionAccount } from '../../../../core/types/AccountTypes';
import { UpsertPaymentRequestsRequest } from '../../../../core/types/ApiTypes';
import {
  PaymentRequestDetails,
  PaymentRequestType,
} from '../../../../core/types/PaymentRequestsTypes';
import { RequestDetailsFormValues, TransactionAccountOption } from './types';
// Utils
import { createApiValidationErrorsMapper } from '../../../../core/utils/Errors';
// Constants
import { DEFAULT_PAYMENT_VALUE_CURRENCY } from '../../../../core/utils/Constants/Constants';

export const mapAccountsResponseFromAPI = (
  crmAccountIds: TransactionAccount[]
): TransactionAccountOption[] => {
  return crmAccountIds.map((accountDataValue) => {
    return {
      id: accountDataValue.id,
      label: accountDataValue.name,
    };
  });
};

export const getPaymentRequestsErrors = (PaymentRequestsError?: unknown) => ({
  ...createApiValidationErrorsMapper<
    PaymentRequestDetails,
    RequestDetailsFormValues
  >({
    crmAccountIds: 'crmAccountIds',
    paymentType: 'paymentType',
    paymentTypeDescription: 'paymentTypeDescription',
    payAmount: 'paymentValue',
    currency: 'paymentValueCurrency',
    dueDate: 'dueDate',
  })(PaymentRequestsError),
});

export const mapFormValuesToPaymentRequestsUpsertRequest = (
  values: RequestDetailsFormValues
): UpsertPaymentRequestsRequest => {
  const {
    crmAccountIds,
    paymentType,
    paymentTypeDescription,
    paymentValue,
    paymentValueCurrency,
  } = values;

  const dueDate = values.dueDate ?? new Date();

  return {
    crmAccountIds,
    paymentType: paymentType as PaymentRequestType,
    paymentTypeDescription:
      paymentType === 'Other' ? paymentTypeDescription : paymentType,
    payAmount: parseFloat(paymentValue),
    currency: paymentValueCurrency,
    dueDate: getEndOfDayDate(dueDate).toISOString(),
  };
};

export const mapPaymentRequestsToFormValues = (
  paymentRequest: PaymentRequestDetails
): RequestDetailsFormValues => {
  const {
    crmAccount,
    dueDate,
    paymentType,
    paymentTypeDescription,
    payAmount,
    currency,
  } = paymentRequest;

  let crmAccountIds = crmAccount && crmAccount.map((item) => item.id);

  return {
    crmAccountIds: crmAccountIds ?? [],
    dueDate: dueDate ? new Date(dueDate) : null,
    paymentType: paymentType,
    paymentTypeDescription: paymentTypeDescription,
    paymentValue: String(payAmount) ?? '',
    paymentValueCurrency: currency ?? DEFAULT_PAYMENT_VALUE_CURRENCY,
  };
};

export const getStartOfDayDate = (date: Date) => {
  return dayjs(date).startOf('day').toDate();
};

export const getEndOfDayDate = (date: Date) => {
  return dayjs(date).endOf('day').toDate();
};
