// Types
import { Payment } from '../../../../../../core/types/PaymentTypes';
// API Wrappers
import { useDownloadContractNoteMutation } from '../../../../../../redux/api';
// Utils
import { downloadContractNoteData, printContractNoteData } from './utils';

export const useContractNote = (payment: Payment) => {
  const { currencyToSend, amountToSend } = payment;

  const [downloadContractNoteMutation] = useDownloadContractNoteMutation();

  const getContractNoteData = async () => {
    if (!currencyToSend || !amountToSend) {
      return;
    }

    const { documentAsbyteArray } = await downloadContractNoteMutation(
      payment.id
    ).unwrap();

    return documentAsbyteArray;
  };

  const downloadContractNote = async () => {
    const data = await getContractNoteData();

    if (data) {
      downloadContractNoteData(data);
    }
  };

  const printContractNote = async () => {
    const data = await getContractNoteData();

    if (data) {
      printContractNoteData(data);
    }
  };

  return { downloadContractNote, printContractNote };
};
