import { styled } from '@mui/material/styles';
import { ComponentProps } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';

export const Text = styled(BBText)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

interface CellTextProps extends ComponentProps<typeof BBText> {}

const CellText = (props: CellTextProps) => {
  const { text, ...textProps } = props;

  return <Text {...textProps} text={text} title={text} />;
};

export default CellText;
