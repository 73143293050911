import { colours, dimensions } from './themeToken';

const { size, radius } = dimensions;

export const coloursAlias = {
  brandDark: colours.brand.dark,
  brandDefault: colours.brand.default,
  brandMedium: colours.brand.medium,
  brandFair: colours.brand.fair,
  brandLight: colours.brand.lighter,
  brandLightest: colours.brand.lightest,
  neutralBlack: colours.neutral.black,
  neutralDefault: colours.neutral.default,
  neutralMedium: colours.neutral.medium,
  neutralFair: colours.neutral.fair,
  neutralLight: colours.neutral.light,
  neutralLighter: colours.neutral.lighter,
  neutralLightest: colours.neutral.lightest,
  neutralWhite: colours.neutral.white,
  error: colours.status.error.default,
  errorLight: colours.status.error.light,
  errorLighter: colours.status.error.lighter,
  success: colours.status.success.default,
  successLighter: colours.status.success.lighter,
  warning: colours.status.warning.default,
  warningLighter: colours.status.warning.lighter,
  pending: colours.status.pending.default,
  pendingLighter: colours.status.pending.lighter,
  notice: colours.status.notice.default,
  noticeLighter: colours.status.notice.lighter,
  accent: colours.accent.default,
  accentAlt: colours.accent.alt,
};

export const dimensionsAlias = {
  spacing: {
    xxxSmall: size.xxxSmall,
    xxSmall: size.xxSmall,
    xSmall: size.xSmall,
    small: size.small,
    smallMedium: size.smallMedium,
    medium: size.medium,
    large: size.large,
    xLarge: size.xLarge,
    xxLarge: size.xxLarge,
    xxxLarge: size.xxxLarge,
    huge: size.huge,
    xHuge: size.xHuge,
    xxHuge: size.xxHuge,
    xxxHuge: size.xxxHuge,
    gigantic: size.gigantic,
    xGigantic: size.xGigantic,
    xxGigantic: size.xxGigantic,
  },
  radius: {
    select: radius.small,
    input: radius.small,
    card: radius.small,
    modal: radius.large,
    circle: radius.circle,
    small: radius.small,
    medium: radius.medium,
    btn: radius.btn,
    xSmall: radius.xSmall,
  },
};
