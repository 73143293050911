import { countryMetadata } from '../../CountryMetadata/CountryMetadata';
import { CountryDataInfo } from '../../types/CountryDataTypes';
import { CountryMetadata } from '../../types/CountryMetadataTypes';
import { OptionType } from '../../types/SelectTypes';

export const getCountryOptions = (): OptionType[] | [] => {
  return countryMetadata.map((countryData: CountryMetadata) => {
    return {
      value: countryData.value,
      label: countryData.label,
      iconPath: countryData.icon,
    };
  });
};

export const getCountryOptionByCode = (countryData: CountryDataInfo[], countryCode: string): CountryDataInfo | undefined => {
  return countryData.find(
    (data: CountryDataInfo) => data.value.trim().toLowerCase() === countryCode.trim().toLowerCase()
  );
};

export const getCountryName = (
  countryData: CountryDataInfo[],
  countryCode: string
) => {
  const selectedCountry: CountryDataInfo | undefined = countryData.find(
    (countryDataValue: CountryDataInfo) => {
      return countryCode === countryDataValue.value;
    }
  );
  return selectedCountry ? selectedCountry.label : '';
};

export const getCountryOptionsFromAPI = (
  countryData: CountryDataInfo[]
): OptionType[] => {
  return countryData.map((countryDataValue) => {
    return {
      value: countryDataValue.value,
      label: countryDataValue.label,
      iconPath: countryDataValue.iconPath,
    };
  });
};
