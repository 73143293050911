import { useEffect, useState } from 'react';

// Hooks
import { useScheduledEffect } from './useScheduledEffect';

export const useIsAfterScheduledTime = (time?: string) => {
  const [isAfterScheduledTime, setIsAfterScheduledTime] = useState(false);

  useEffect(() => {
    setIsAfterScheduledTime(time ? new Date() >= new Date(time) : false);
  }, [time]);

  useScheduledEffect(() => {
    setIsAfterScheduledTime(true);
  }, time);

  return { isAfterScheduledTime };
};
