import axios, { AxiosResponse } from 'axios';
import { getAccessToken } from '../../core/utils/AuthUtils';

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ObjectMapping = { [key: string]: string };

const SERVICE_TO_URL_MAPPING: ObjectMapping = {
  getProfileDetails: '/propertypay-services/profile/',
};

const getURLForService = (type: string, dataToInsert: any) => {
  let currentServiceURL: string = SERVICE_TO_URL_MAPPING[type];

  if (dataToInsert) {
    for (const key in dataToInsert) {
      currentServiceURL = currentServiceURL.replace(
        `{${key}}`,
        dataToInsert[key]
      );
    }
  }

  return currentServiceURL;
};

const axiosWrapper = async (
  type: string,
  method: MethodType,
  thunkAPI: any,
  payload?: any,
  dataToInsert?: any
) => {
  const hasPayload: boolean =
    payload && (method === 'POST' || method === 'PUT');
  const currentURL: string = getURLForService(type, dataToInsert);
  const { getState } = thunkAPI;
  const { userLoginDetails } = getState();

  const response: Promise<AxiosResponse> = new Promise((resolve, reject) => {
    const accessToken = getAccessToken();
    axios({
      url: currentURL,
      method,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
      ...(hasPayload && {
        data: payload,
      }),
    })
      .then((apiResponse) => {
        resolve(apiResponse);
      })
      .catch(async (apiError) => {
        reject(apiError);
      });
  });

  return response;
};

export default axiosWrapper;
