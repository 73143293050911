import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';

export const FormContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  };
});

export const FormWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  };
});

export const StyledSubTitle = styled(BBText)(({ theme }) => {
  const {
    colours: { text },
  } = theme;

  return {
    color: text.textWeak,
  };
});
