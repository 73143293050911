import { FC, useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import BBSomethingWentWrongModal from '../../../../molecules/BBModal/BBSomethingWentWrongModal';

interface SomethingWentWrongModalProps {
  isOpen: boolean;
  onContinue: () => void;
}

const SomethingWentWrongModal: FC<SomethingWentWrongModalProps> = (
  props: SomethingWentWrongModalProps
) => {
  const { isOpen, onContinue } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const ImportantNoticeLightIllustrationIcon = useMemo(
    () => getIcon(tenant, 'importantNoticeLightIllustration'),
    [tenant]
  );

  const baseTranslationKey =
    'transactionDetails.completionPayments.somethingWentWrongModal';

  return (
    <BBSomethingWentWrongModal
      isOpen={isOpen}
      icon={<ImportantNoticeLightIllustrationIcon />}
      message={translate(`${baseTranslationKey}.title`)}
      additionalMessage={translate(`${baseTranslationKey}.message`)}
      primaryBtnLabel={translate(`${baseTranslationKey}.continueButton`)}
      handlePrimaryBtnClick={onContinue}
    />
  );
};

export default SomethingWentWrongModal;
