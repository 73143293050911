import { FC } from 'react';
import { styled } from '@mui/material/styles';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

//Components - Atoms, Molecules, Organisms, Pages
import BBRadioButton from './BBRadioButton';
// Types
import { TypographyStyleObject } from '../../../core/types/TypographyTypes';
// Utils
import {
  getBody2NormalStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export interface RadioOption {
  label: string;
  value: string;
}

export type Sizes = 'small' | 'normal';

interface RadioButtonGroupProps extends RadioGroupProps {
  options: RadioOption[];
  size?: Sizes;
  disabled?: boolean;
}

interface StyledFormControlLabelProps {
  size: Sizes;
}

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.small,
  };
});

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'size',
})<StyledFormControlLabelProps>(({ theme, size }) => {
  const {
    dimensions: { spacing },
    colours: {
      text: { disabled },
    },
  } = theme;

  const typography: TypographyStyleObject =
    size === 'small'
      ? getBodySmallNormalStyles(theme)
      : getBody2NormalStyles(theme);

  return {
    margin: 0,
    gap: spacing.xSmall,

    '> span:nth-of-type(2)': {
      ...typography,
    },

    '&.Mui-disabled': {
      color: disabled,
    },
  };
});

const BBRadioButtonGroup: FC<RadioButtonGroupProps> = (
  props: RadioButtonGroupProps
) => {
  const { name, value, onChange, options, size = 'normal', disabled } = props;

  return (
    <StyledRadioGroup row name={name} value={value} onChange={onChange}>
      {options &&
        options.length > 0 &&
        options.map((option: RadioOption) => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            control={<BBRadioButton size={size} />}
            label={option.label}
            labelPlacement="end"
            data-testid={`radio-label-${option.value}`}
            size={size}
            disabled={disabled}
          />
        ))}
    </StyledRadioGroup>
  );
};

export default BBRadioButtonGroup;
