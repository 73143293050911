import { FC, useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBConfirmationModal from '../../../../molecules/BBModal/BBConfirmationModal';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';

interface DisburseFundsConfirmationProps {
  isOpen: boolean;
  totalAmount: string;
  userNames: string;
  onMaybeLater: () => void;
  onDisburseFunds: () => void;
}

const DisburseFundsConfirmation: FC<DisburseFundsConfirmationProps> = (
  props: DisburseFundsConfirmationProps
) => {
  const { isOpen, totalAmount, userNames, onMaybeLater, onDisburseFunds } =
    props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const ImportantNoticeIllustrationIcon = useMemo(
    () => getIcon(tenant, 'importantNoticeIllustration'),
    [tenant]
  );

  const baseTranslationKey =
    'transactionDetails.completionPayments.disburseFundsModal';

  return (
    <BBConfirmationModal
      isOpen={isOpen}
      icon={<ImportantNoticeIllustrationIcon />}
      message={translate(`${baseTranslationKey}.title`)}
      additionMessage={translate(`${baseTranslationKey}.message`, {
        amount: totalAmount,
        users: userNames,
      })}
      additionalNote={translate(`${baseTranslationKey}.note`)}
      primaryBtnLabel={translate(`${baseTranslationKey}.acceptButtonText`)}
      secondaryBtnLabel={translate(`${baseTranslationKey}.cancelButtonText`)}
      handlePrimaryBtnClick={onDisburseFunds}
      handleSecondaryBtnClick={onMaybeLater}
    />
  );
};

export default DisburseFundsConfirmation;
