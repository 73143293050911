import { FC, useContext, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// Contexts
import {
  LanguageContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBSideBar from '../../organisms/BBSideBar';
import BBMobileMenuContainer from '../BBMobileMenuContainer';
// Types
import { AppDispatch } from '../../../redux/store';
import { LayoutSize } from '../../../core/types/LayoutTypes';
// RTK Slice
import getProfileDetails from '../../../redux/api/getProfileDetails';
// API Wrappers
import { getUserProfileDetails } from '../../../redux/modules/userProfileDetailsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import {
  getNameInitials,
  getFormattedFullName,
} from '../../../core/utils/GetGreetingMessage';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import BBHeaderContainer from '../../molecules/BBHeader/BBHeaderContainer';

interface FullLayoutProps {
  children: ReactNode;
  pageHeaderType?: string;
  isBuyerFlow?: boolean;
}
interface ContentProps {
  layout: LayoutSize;
}

const Container = styled(Box)(() => {
  return {
    display: 'flex',
    height: '100vh',
  };
});

const ContentContainer = styled(Box)(() => {
  return {
    flexGrow: 1,
    overflowX: 'auto',
  };
});

const Content = styled(Box)<ContentProps>(({ layout, theme }) => {
  const {
    dimensions: {
      spacing: { small, medium, large, xLarge, xHuge, xxHuge },
    },
  } = theme;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: isDesktopLayout
      ? `${large}px ${xLarge}px 108px ${xLarge}px` // Token not available
      : isTabletLayout
        ? `${medium}px ${large}px ${xxHuge}px ${large}px`
        : `112px ${medium}px ${xHuge}px ${medium}px`, // Token not available
    maxWidth: '1360px',

    ...(isDesktopLayout && { gap: large }),
    ...(isTabletLayout && { gap: medium }),
    ...(isMobileLayout && { gap: small }),
  };
});

const FullLayout: FC<FullLayoutProps> = (props: FullLayoutProps) => {
  const { children, pageHeaderType, isBuyerFlow } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const dispatch: AppDispatch = useDispatch();

  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);

  const { firstName, middleName, lastName, titanCustomerNo } = useSelector(
    getUserProfileDetails
  );

  //Translations
  const IDText: string = translate('header.idLabel', {
    customerId: titanCustomerNo,
  });

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  return (
    <Container>
      {isNotMobileLayout ? (
        <>
          <BBSideBar isBuyerFlow={isBuyerFlow} />
          <ContentContainer>
            <BBHeaderContainer pageHeaderType={pageHeaderType} />
            <Content layout={layout}>{children}</Content>
          </ContentContainer>
        </>
      ) : (
        <>
          <BBMobileMenuContainer
            fullName={getFormattedFullName(firstName, middleName, lastName)}
            initials={getNameInitials(firstName, lastName)}
            customerId={IDText}
            isBuyerFlow={isBuyerFlow}
          />
          <ContentContainer>
            <Content layout={layout}>{children}</Content>
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

export default FullLayout;
