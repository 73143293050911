import {
  useContext,
  MouseEvent,
  KeyboardEvent,
  useState,
  HTMLAttributes,
} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

// Contexts
import { LayoutContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import BBLink from '../../../../atoms/BBLink';
import DownloadDisbursementModal from '../CompletionPaymentsModal/DownloadDisbursementModal';
// Hooks
import { useTranslations } from '../../../../../core/hooks';
// Types
import { LayoutProps } from '../../../../../core/types/LayoutProps';
import { DownloadDisbursementType } from '../../../../../core/types/DisbursementTypes';
// API Wrappers
import { useDownloadDisbursementContractNotesMutation } from '../../../../../redux/api';
// Utils
import { getBodySmallNormalStyles } from '../../../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { downloadPdf } from '../../../../../core/utils/DownloadFile/downloadPdf';

export interface CustomFooterComponentProps
  extends HTMLAttributes<HTMLDivElement> {
  totalAmount?: string;
  transactionId?: number;
}

const FooterContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { bg },
    },
    dimensions: {
      spacing: { xxSmall, xSmall, small },
      radius,
    },
  } = theme;

  return {
    display: 'flex',
    padding: `${xSmall}px ${small}px`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: xxSmall,
    alignSelf: 'stretch',
    borderRadius: radius.small,
    background: bg,
    marginTop: small,
  };
});

const RowWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxxSmall, xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: `${xxxSmall}px ${xxSmall}px`,
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  };
});

const TextColumn = styled(Box)<LayoutProps>(({ layout }) => {
  const isDesktopLayout = layout === 'desktop';

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: isDesktopLayout ? 426 : '100%',
  };
});

const TotalColumn = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flex: '1 0 0',
    alignItems: 'center',
    alignContent: 'center',
    gap: spacing.xxxSmall,
    flexWrap: 'wrap',
  };
});

const TextContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xSmall,
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
    dimensions: {
      spacing: { xxxSmall },
    },
  } = theme;
  return {
    backgroundColor: borderLighter,
    width: '1px',
    marginTop: xxxSmall,
    marginBottom: xxxSmall,
  };
});

const StyledBBLink = styled(BBLink)(({ theme }) => {
  return {
    ...getBodySmallNormalStyles(theme),
  };
});

const CustomFooterComponent = (props: CustomFooterComponentProps) => {
  const { totalAmount, transactionId } = props;

  const { layout } = useContext(LayoutContext);

  const { translate } = useTranslations();

  const [showDownloadDisbursementModal, setShowDownloadDisbursementModal] =
    useState(false);

  const [downloadDisbursement, { isLoading }] =
    useDownloadDisbursementContractNotesMutation();

  const handleDownloadDisbursement = async (
    disbursementType: DownloadDisbursementType
  ) => {
    try {
      setShowDownloadDisbursementModal(false);
      const data = await downloadDisbursement({
        transactionId: Number(transactionId),
        type: disbursementType,
      }).unwrap();

      data?.length > 0 &&
        data.forEach((details, index) => {
          details.documentAsbyteArray &&
            downloadPdf(
              details.documentAsbyteArray,
              `${disbursementType}-${index + 1}`
            );
        });
    } catch {}
  };

  return (
    <>
      <FooterContainer>
        <RowWrapper>
          <TextColumn layout={layout}>
            <BBText variant="body2" type="bold" text={'Total'} />
          </TextColumn>
          <TotalColumn>
            <TextContainer>
              <BBText variant="body2" type="bold" text={totalAmount ?? ''} />
              <StyledDivider orientation="vertical" variant="middle" flexItem />
              <StyledBBLink
                underline="none"
                type="medium"
                text={translate(
                  'transactionDetails.completionPayments.download'
                )}
                href=""
                onClick={(
                  event:
                    | MouseEvent<HTMLAnchorElement>
                    | KeyboardEvent<HTMLAnchorElement>
                ) => {
                  event.preventDefault();
                  !isLoading && setShowDownloadDisbursementModal(true);
                }}
              />
              {isLoading && <CircularProgress size={18} />}
            </TextContainer>
          </TotalColumn>
        </RowWrapper>
      </FooterContainer>
      <DownloadDisbursementModal
        open={showDownloadDisbursementModal}
        onDownload={handleDownloadDisbursement}
        onClose={() => setShowDownloadDisbursementModal(false)}
      />
    </>
  );
};
export default CustomFooterComponent;
