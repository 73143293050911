import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

//Contexts
import {
  LanguageContext,
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import { WeakText } from '../CommonStyles/CommonStyles.styles';
//Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { TransactionDetailsFilterType } from '../../../core/types/PaymentRequestsTypes';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface NoRecordsFoundProps {
  type: TransactionDetailsFilterType;
}

const OuterContainer = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing, radius },
    colours: {
      backgrounds,
      borders: { border },
    },
  } = theme;

  const isDesktopLayout = layout === 'desktop';

  return {
    backgroundColor: backgrounds.bgAlt,
    borderRadius: radius.small,
    padding: isDesktopLayout ? spacing.medium : spacing.small,
    border: `1px solid ${border}`,
  };
});

const Container = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing, radius },
    colours: { backgrounds },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    padding: isDesktopLayout
      ? spacing.large
      : isTabletLayout
        ? spacing.medium
        : spacing.small,
    gap: spacing.large,
    alignItems: 'center',
    backgroundColor: backgrounds.bg,
    borderRadius: radius.small,
  };
});

const TextContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  };
});

const NoRecordsFound: FC<NoRecordsFoundProps> = (
  props: NoRecordsFoundProps
) => {
  const { type } = props;

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  //Icons
  const NoRecordsIcon = useMemo(() => getIcon(tenant, 'noRecords'), [tenant]);
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const textElement = (
    <TextContainer>
      <BBText
        variant="h3"
        type="medium"
        text={translate('transactionDetails.noRecordsFound.title')}
      />
      <WeakText
        variant="body2"
        type="normal"
        text={translate(
          `transactionDetails.noRecordsFound.${type === 'request' ? 'requestPaymentsDescription' : 'completionPaymentsDescription'}`
        )}
      />
    </TextContainer>
  );

  return (
    <>
      {isMobileLayout ? (
        <Container layout={layout}>{textElement}</Container>
      ) : (
        <OuterContainer layout={layout}>
          <Container layout={layout}>
            <NoRecordsIcon />
            {textElement}
          </Container>
        </OuterContainer>
      )}
    </>
  );
};

export default NoRecordsFound;
