import { useMemo } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { GridColumn } from '../../../../organisms/BBDataTable';
import TableRowAction from '../../../../organisms/TableRowAction';
import CompletionPaymentStatus from './CompletionPaymentStatus';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { CompletionPaymentsDetails } from '../types';

const getColumnTranslationKey = (value: string) =>
  `transactionDetails.completionPayments.columns.${value}`;

const getActionTranslationKey = (value: string) =>
  `transactionDetails.completionPayments.actions.${value}`;

export const useColumns = (transactionId: number) => {
  const { translate } = useTranslations();

  const columns = useMemo<GridColumn<CompletionPaymentsDetails>[]>(() => {
    return [
      {
        field: 'beneficiary',
        headerName: translate(getColumnTranslationKey('beneficiary')),
        flex: 1,
        minWidth: 450,
      },
      {
        field: 'amount',
        headerName: translate(getColumnTranslationKey('amount')),
        flex: 1,
        minWidth: 150,
        maxWidth: 250,
        sortType: 'number',
      },
      {
        field: 'status',
        headerName: translate(getColumnTranslationKey('status')),
        sortable: false,
        flex: 1,
        minWidth: 110,
        maxWidth: 150,
        renderCell: ({ row }) => {
          return <CompletionPaymentStatus status={row.status} />;
        },
      },
      {
        field: 'bankCode',
        headerName: translate(getColumnTranslationKey('bankCode')),
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
        sortType: 'date',
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: 100,
        renderCell: ({ row }) => {
          return (
            <TableRowAction
              to={`/transaction-details/${transactionId}/disbursements/${row.id}/view-details`}
            >
              {translate(getActionTranslationKey('view'))}
            </TableRowAction>
          );
        },
      },
      { field: 'createdDate' },
    ];
  }, [translate, transactionId]);

  return { columns };
};
