import { ThemeTokenTypes } from '../../../types/ThemeConfigTypes/ThemeTokenTypes';

export const colours: ThemeTokenTypes = {
  brand: {
    darker: '#0d1420',
    dark: '#111928',
    default: '#E40051',
    medium: '#ff2b76',
    fair: '#a6c8ff',
    light: '#d0e2ff',
    lighter: '#edf5ff',
    lightest: '#edf5ff',
  },
  accent: {
    default: '#e40051',
    alt: '#ff2b76',
  },
  neutral: {
    black: '#000000',
    default: '#0a0a0a',
    medium: '#525252',
    fair: '#6f6f6f',
    light: '#a8a8a8',
    lighter: '#dadada',
    lightest: '#f4f4f4',
    white: '#ffffff',
  },
  status: {
    error: {
      default: '#da1e28',
      light: '#ffd8d8',
      lighter: '#fff1f1',
    },
    notice: {
      default: '#0f62fe',
      lighter: '#d0e2ff',
    },
    success: {
      default: '#31c48d',
      lighter: '#b0f5db',
    },
    warning: {
      default: '#fce96a',
      lighter: '#fff8e1',
    },
    pending: {
      default: '#fce96a',
      lighter: '#ffffff',
    },
  },
};

export const dimensions = {
  size: {
    xxxSmall: 4,
    xxSmall: 8,
    xSmall: 12,
    small: 16,
    smallMedium: 20,
    medium: 24,
    large: 32,
    xLarge: 40,
    xxLarge: 48,
    xxxLarge: 56,
    huge: 64,
    xHuge: 72,
    xxHuge: 80,
    xxxHuge: 88,
    gigantic: 120,
    xGigantic: 160,
    xxGigantic: 192,
  },
  radius: {
    none: 0,
    xSmall: 2,
    small: 4,
    medium: 8,
    large: 12,
    xLarge: 16,
    btn: 60,
    circle: 100,
  },
};

export const fontFaces = {
  bold: {
    fontName: 'CircularXX',
    fileName: 'CircularXXWeb-Bold.woff',
    fontWeight: '700',
  },
  medium: {
    fontName: 'CircularXX',
    fileName: 'CircularXXWeb-Medium.woff',
    fontWeight: '500',
  },
  book: {
    fontName: 'CircularXX',
    fileName: 'CircularXXWeb-Book.woff',
    fontWeight: '400',
  },
  regular: {
    fontName: 'CircularXX',
    fileName: 'CircularXXWeb-Regular.woff',
    fontWeight: '300',
  },
};
