import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//Context
import {
  LayoutContext,
  LanguageContext,
  TenantContext,
} from '../../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import BBButton from '../../../../atoms/BBButton';
import { WeakText } from '../../../CommonStyles/CommonStyles.styles';
import {
  CompletionActionContainer,
  CompletionInfoContainer,
  CompletionTextWrapper,
  MainWrapper,
} from './CompletedScreen.styles';
//Utils
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../../../core/utils/IconOrgData';

type RouterParams = {
  id: string;
};

const CompletedScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams<RouterParams>();

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const TitleText: string = translate('createTransaction.completed.title');
  const DescriptionText: string = translate(
    'createTransaction.completed.description'
  );
  const btnText: string = translate('createTransaction.completed.btnText');

  //Icons
  const [CompletedPageIllustration] = useMemo(
    () => [getIcon(tenant, 'completedPageIllustration')],
    [tenant]
  );

  const handleGotoTransactionClick = () => {
    navigate(`/transaction-details/${id}`);
  };

  return (
    <MainWrapper layout={layout}>
      <CompletionInfoContainer>
        <CompletedPageIllustration />
        <CompletionTextWrapper>
          <BBText variant="h3" type="bold" text={TitleText} />
          <WeakText variant="body2" type="normal" text={DescriptionText} />
        </CompletionTextWrapper>
      </CompletionInfoContainer>
      <CompletionActionContainer layout={layout}>
        <BBButton
          btnType="secondary"
          size="medium"
          type="button"
          onClick={handleGotoTransactionClick}
        >
          {btnText}
        </BBButton>
      </CompletionActionContainer>
    </MainWrapper>
  );
};

export default CompletedScreen;
