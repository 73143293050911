// Types
import { Account } from '../AccountTypes';
import { PaymentRequestDetails } from '../PaymentRequestsTypes';
import { Nullable } from '../UtilTypes';

export enum PaymentStatus {
  Draft = 'Draft',
  Processing = 'Processing',
  Cancelled = 'Cancelled',
  Sent = 'Sent',
  Received = 'Received',
}

export enum PaymentMethodType {
  Wallet = 'Wallet',
  ManualBankTransfer = 'Manual Bank Transfer',
}

export interface Payment {
  id: number;
  paymentRequest?: PaymentRequestDetails;
  paymentMethod: PaymentMethodType;
  crmAccount?: Account;
  amountToSend?: number;
  currencyToSend?: string;
  amountToReceive?: number;
  currencyToReceive?: string;
  capturedDate?: string;
  walletId?: string;
  exchangeRate?: number;
  token?: Nullable<string>;
  rateExpiry?: string;
  quotedRate?: number;
  quotedPrice?: number;
  paymentStatus: PaymentStatus;
  createdDate: string;
  updatedDate: string;
  paidDate?: string;
}
