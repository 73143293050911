import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';

// Types
import { Sizes } from './BBRadioGroup';

interface RadioButtonProps extends Omit<RadioProps, 'size'> {
  size?: Sizes;
}

const StyledRadio = styled(Radio)(({ theme, size }) => {
  const {
    colours: {
      backgrounds: { bgAlt, disabled: bgDisabled },
      borders,
      text,
    },
  } = theme;

  const isSmallSize: boolean = size === 'small';
  const dimensions: number = isSmallSize ? 16 : 20;

  return {
    padding: 0,
    backgroundColor: bgAlt,

    '> span > svg': {
      width: dimensions,
      height: dimensions,
    },

    '&:not(.Mui-checked)': {
      color: borders.input,
      ': hover': {
        color: borders.selectionHover,
      },
    },

    '&.Mui-checked': {
      color: borders.active,
      ': hover': {
        color: borders.active,
      },
    },

    '&:not(.Mui-checked).Mui-focusVisible': {
      color: borders.input,
      outline: `1.5px solid ${borders.focus}`,
    },

    '&:not(.Mui-checked).Mui-focusVisible + span': {
      color: borders.focus,
    },

    '&.Mui-checked.Mui-focusVisible': {
      outline: `1.5px solid ${borders.active}`,
    },

    '&.Mui-checked.Mui-focusVisible + span': {
      color: text.focus,
    },

    '&.Mui-disabled': {
      color: borders.disabled,
      backgroundColor: bgDisabled,
    },
  };
});

const BBRadioButton: FC<RadioButtonProps> = (props: RadioButtonProps) => {
  const { size = 'normal', ...otherProps } = props;

  return (
    <StyledRadio
      disableRipple
      size={size === 'normal' ? 'medium' : size}
      {...otherProps}
    />
  );
};

export default BBRadioButton;
