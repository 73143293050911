import {
  UpsertAddressRequest,
  UpsertAddressResponse,
} from '../../core/types/ApiTypes';
import { api } from './api';

const addressesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    upsertAddress: builder.mutation<
      UpsertAddressResponse,
      UpsertAddressRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/addresses',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'Transactions', id: data.transactionId },
      ],
    }),
  }),
});

export const { useUpsertAddressMutation } = addressesApi;
