import dayjs from 'dayjs';

// Types
import { Address } from '../../../../core/types/AddressTypes';
import {
  UpsertAddressRequest,
  UpsertTransactionRequest,
} from '../../../../core/types/ApiTypes';
import {
  Transaction,
  TransactionStatus,
} from '../../../../core/types/TransactionTypes';
import { TransactionDetailsFormValues } from './types';
// Utils
import { createApiValidationErrorsMapper } from '../../../../core/utils/Errors';
// Constants
import {
  DEFAULT_COUNTRY,
  DEFAULT_PURCHASE_VALUE_CURRENCY,
} from '../../../../core/utils/Constants/Constants';

export const getTransactionErrors = (
  transactionError?: unknown,
  addressError?: unknown
) => ({
  ...createApiValidationErrorsMapper<Transaction, TransactionDetailsFormValues>(
    {
      fileReference: 'fileReference',
      completionDate: 'completionDate',
      purchaseValue: 'purchaseValue',
      currency: 'purchaseValueCurrency',
    }
  )(transactionError),
  ...createApiValidationErrorsMapper<Address, TransactionDetailsFormValues>({
    countryCode: 'country',
  })(addressError),
});

export const getAddressErrors = createApiValidationErrorsMapper<
  Address,
  TransactionDetailsFormValues['address']
>({
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressLine3: 'addressLine3',
  suburbCity: 'suburbCity',
  province: 'province',
  townCity: 'townCity',
  county: 'county',
  state: 'state',
  postcode: 'postcode',
});

export const mapFormValuesToTransactionUpsertRequest = (
  values: TransactionDetailsFormValues
): UpsertTransactionRequest => {
  const { fileReference, purchaseValue, purchaseValueCurrency } = values;

  const completionDate = values.completionDate ?? new Date();

  return {
    fileReference,
    completionDate: completionDate.toISOString(),
    currency: purchaseValueCurrency,
    purchaseValue: parseFloat(purchaseValue),
    transactionStatus: TransactionStatus.Draft,
  };
};

export const mapFormValuesToAddressUpsertRequest = (
  values: TransactionDetailsFormValues
): UpsertAddressRequest => {
  const { country, address } = values;

  return { countryCode: country, ...address };
};

export const mapTransactionToFormValues = (
  transaction: Transaction
): TransactionDetailsFormValues => {
  const { address, fileReference, completionDate, purchaseValue, currency } =
    transaction;

  return {
    fileReference: fileReference ?? '',
    completionDate: completionDate ? new Date(completionDate) : null,
    purchaseValue: String(purchaseValue) ?? '',
    purchaseValueCurrency: currency ?? DEFAULT_PURCHASE_VALUE_CURRENCY,
    country: address?.countryCode ?? DEFAULT_COUNTRY,
    address: {
      addressLine1: address?.addressLine1 ?? '',
      addressLine2: address?.addressLine2 ?? '',
      addressLine3: address?.addressLine3 ?? '',
      suburbCity: address?.suburbCity ?? '',
      province: address?.province ?? '',
      townCity: address?.townCity ?? '',
      county: address?.county ?? '',
      state: address?.state ?? '',
      postcode: address?.postcode ?? '',
    },
  };
};

export const getTomorrowDate = () => {
  return dayjs().add(1, 'day').startOf('day').toDate();
};
