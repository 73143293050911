import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { styled } from '@mui/material/styles';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import CDWalletAccordion from './CDWalletAccordion/CDWalletAccordion';
import NewRecipientAccordion from '../../../organisms/NewRecipientAccordion';
import BBButton from '../../../atoms/BBButton';
import ProtectingFromFraud from '../../../organisms/ProtectingFromFraud';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
import SavedRecipientsAccordion from '../../../organisms/SavedRecipientsAccordion/SavedRecipientsAccordion';
import {
  ActionButtonsContainer,
  FormContainer,
} from '../../CommonStyles/CommonStyles.styles';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { AccountToBeFundedFormValues } from './types';
import {
  AccountField,
  AccountToBeFundedDetails,
  AddBankAccountRequest,
  PaymentMethods,
  PaymentRequestDetails,
} from '../../../../core/types/PaymentRequestsTypes';
import { AccountType } from '../../../../core/types/RecipientTypes';
// API Wrappers
import {
  useAddBankAccountMutation,
  useGetBankAccountFieldsQuery,
  useUpdateAccountToBeFundedMutation,
  useUpdateFraudAlertMutation,
} from '../../../../redux/api';
// Utils
import {
  defaultValues,
  mapFormValuesToAddBankAccountRequest,
  mapAccountToBeFundedDataToFormValue,
  mapApiAccountTypeValue,
  mapFormValueToAccountToBeFundedRequest,
  createAccountToBeFundedRequestData,
  mapFormValuesToFraudAlertRequest,
} from './utils';

interface AccountToBeFundedProps {
  activeStep: number;
  transactionId: string;
  paymentRequestId: string;
  showProtectingFromFraudSection: boolean;
  setShowProtectingFromFraudSection: (value: boolean) => void;
  paymentRequestData?: PaymentRequestDetails;
}

export const FormContent = styled('section')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    alignSelf: 'stretch',
    gap: spacing.xSmall,
  };
});

const AccountToBeFundedForm: FC<AccountToBeFundedProps> = (
  props: AccountToBeFundedProps
) => {
  const {
    activeStep,
    transactionId,
    paymentRequestId,
    showProtectingFromFraudSection,
    setShowProtectingFromFraudSection,
    paymentRequestData,
  } = props;

  const accountToBeFundedData: AccountToBeFundedDetails | null | undefined =
    paymentRequestData?.accountToBeFunded;

  const { translate } = useTranslations();
  const navigate = useNavigate();
  const { layout } = useContext(LayoutContext);

  const { validationSchema } = useValidations();

  const expandedAccordionValue: AccountType | null = accountToBeFundedData
    ? mapApiAccountTypeValue(accountToBeFundedData?.paymentMethod)
    : null;

  const [expanded, setExpanded] = useState<AccountType | null>(
    expandedAccordionValue
  );

  const [addBankAccountRequest] = useAddBankAccountMutation();
  const [updateAccountToBeFunded] = useUpdateAccountToBeFundedMutation();
  const [updateFraudAlert] = useUpdateFraudAlertMutation();

  const form = useForm<AccountToBeFundedFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: accountToBeFundedData
      ? mapAccountToBeFundedDataToFormValue(accountToBeFundedData)
      : defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    trigger,
    register,
    unregister,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = form;

  const formValues: AccountToBeFundedFormValues = watch();

  const {
    data: bankAccountFieldsData,
    isFetching,
    error: bankAccountFieldsError,
  } = useGetBankAccountFieldsQuery(
    formValues.countryCode && formValues.currency
      ? { countryCode: formValues.countryCode, currency: formValues.currency }
      : skipToken
  );

  const disabledSubmitButton: boolean =
    !isValid || isSubmitting || isFetching || !!bankAccountFieldsError;

  const uiFields: AccountField[] | undefined =
    bankAccountFieldsData?.uiBlock.uiFields;

  useEffect(() => {
    uiFields?.forEach((field: AccountField) => {
      register(`bankDetails.${field.displayId}`, { value: '' });
      register(`bankDetailsValidationRules.${field.displayId}`, {
        value: field.validation,
      });
    });

    return () => {
      uiFields?.forEach((field: AccountField) => {
        unregister(`bankDetails.${field.displayId}`);
        unregister(`bankDetailsValidationRules.${field.displayId}`);
      });
    };
  }, [uiFields, register, unregister]);

  const onSubmit = async (formData: AccountToBeFundedFormValues) => {
    try {
      if (showProtectingFromFraudSection && paymentRequestData) {
        // Submit fraud alert data
        const fraudAlertData =
          mapFormValuesToFraudAlertRequest(paymentRequestData);
        const responseData = await updateFraudAlert(fraudAlertData).unwrap();
        responseData && onContinue();
      } else if (formData.accountType === 'new-recipient') {
        // Save newly added bank
        const bankAccountData: AddBankAccountRequest =
          mapFormValuesToAddBankAccountRequest(formData);
        const { id: bankAccountId } =
          await addBankAccountRequest(bankAccountData).unwrap();
        if (bankAccountId && paymentRequestData) {
          // Save account to be funded data
          const accountToBeFundedData = createAccountToBeFundedRequestData(
            PaymentMethods.Bank,
            bankAccountId,
            paymentRequestData.id
          );
          const { id: accountToBeFundedId } = await updateAccountToBeFunded(
            accountToBeFundedData
          ).unwrap();

          // Reset add new recipient fields after successful save
          // Make saved accordion tab as expanded
          reset({
            ...defaultValues,
            accountType: 'saved-recipient',
            recipientId: bankAccountId,
          });
          setExpanded('saved-recipient');
          accountToBeFundedId && setShowProtectingFromFraudSection(true);
        }
      } else {
        // Save account to be funded data
        const updateAccountToBeFundedData =
          mapFormValueToAccountToBeFundedRequest(formData, paymentRequestData);
        if (updateAccountToBeFundedData) {
          const responseData = await updateAccountToBeFunded(
            updateAccountToBeFundedData
          ).unwrap();
          responseData && onContinue();
        }
      }
    } catch {}
  };

  const handleOnBackClick = () => {
    navigate(
      `/payment-request/request-details/${transactionId}/${paymentRequestId}`
    );
  };

  const onContinue = () => {
    navigate(
      `/payment-request/confirmation/${transactionId}/${paymentRequestId}`
    );
  };

  const handleAccordionChange =
    (panel: AccountType) => (_: SyntheticEvent, newExpanded: boolean) => {
      const panelValue: AccountType | null = newExpanded ? panel : null;
      setExpanded(panelValue);
      setValue('accountType', panelValue, {
        shouldTouch: true,
        shouldDirty: true,
      });
      trigger();
    };

  return (
    <>
      <FormProvider {...form}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          {!showProtectingFromFraudSection ? (
            <>
              <FormContent>
                <CDWalletAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                />
                <SavedRecipientsAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                />
                <NewRecipientAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                />
              </FormContent>
              <ActionButtonsContainer activeStep={activeStep} layout={layout}>
                <BBButton
                  size="medium"
                  btnType="outlined"
                  type="button"
                  onClick={handleOnBackClick}
                >
                  {translate('steppers.backBtn')}
                </BBButton>
                <BBButton
                  btnType="secondary"
                  size="medium"
                  type="submit"
                  disabled={disabledSubmitButton}
                >
                  {translate('steppers.continueBtn')}
                </BBButton>
              </ActionButtonsContainer>
            </>
          ) : (
            <ProtectingFromFraud
              setShowProtectingFromFraudSection={
                setShowProtectingFromFraudSection
              }
              disabledSubmitButton={disabledSubmitButton}
              type="paymentRequest"
            />
          )}
        </FormContainer>
      </FormProvider>
      {isDirty && !isSubmitting && <NavigationBlocker />}
    </>
  );
};

export default AccountToBeFundedForm;
