//Components
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import BBText from '../../atoms/BBText';

//Utils
import { ContentLayoutProps, ImageLayoutProps } from './types';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

export const Container = styled(Box)(() => {
  return {
    display: 'flex',
    height: '100vh',
  };
});

export const Sidebar = styled(Box)<ContentLayoutProps>(({ layout, theme }) => {
  const {
    colours: {
      backgrounds: { activeDark },
    },
    dimensions: {
      spacing: { xSmall, medium, large, xLarge },
    },
  } = theme;

  const isDesktopLayout = layout === 'desktop';

  return {
    '& .footer': {
      display: 'flex',
      gap: isDesktopLayout ? medium : xSmall,
      position: 'fixed',
      bottom: xLarge,
    },
    backgroundColor: activeDark,
    position: 'relative',
    maxWidth: isDesktopLayout ? '336px' : '216px',
    minWidth: '216px',
    padding: `${large}px ${large}px 0 ${large}px`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };
});

export const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutHeight',
})<ContentLayoutProps>(({ layout, theme, layoutHeight }) => {
  const {
    dimensions: {
      spacing: { medium, xLarge, xxLarge, xxxHuge },
    },
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isLowerInHeight: boolean = layoutHeight === 'lowHeight';

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: isMobileLayout
      ? `${xxxHuge}px ${medium}px 0 ${medium}px`
      : `${isLowerInHeight ? '10vh' : '148px'} ${xxLarge}px ${xLarge}px ${xxLarge}px`,
    justifyContent: 'center',
    position: 'relative',
    overflowX: 'auto',
  };
});

export const StyledPoweredByText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeakest },
    },
  } = theme;

  return {
    color: textWeakest,
  };
});

export const StyledBBLogoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutHeight',
})<ContentLayoutProps>(({ layout, layoutHeight }) => {
  const isDesktopLayout = layout === 'desktop';
  const isLowerInHeight: boolean = layoutHeight === 'lowHeight';

  return {
    '& a': {
      '> svg': {
        width: isDesktopLayout ? (isLowerInHeight ? '80px' : '108px') : '80px',
        height: isDesktopLayout
          ? isLowerInHeight
            ? '29.6px'
            : '36px'
          : '29.6px',
      },
    },
  };
});

export const StyledTrustpilotIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutHeight',
})<ContentLayoutProps>(({ layout, layoutHeight }) => {
  const isDesktopLayout = layout === 'desktop';
  const isLowerInHeight: boolean = layoutHeight === 'lowHeight';

  return {
    '> svg': {
      width: isDesktopLayout ? (isLowerInHeight ? '90px' : '131px') : '90px',
      height: isDesktopLayout
        ? isLowerInHeight
          ? '39.6px'
          : '60px'
        : '39.6px',
    },
  };
});

export const ContentFooterWrapper = styled(Box)<ContentLayoutProps>(({
  layout,
  theme,
}) => {
  const {
    colours: {
      text: { textWeak },
    },
    dimensions: {
      spacing: { xLarge, xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: xLarge,
    width: '100%',
    maxWidth: '302px',
    '& a': {
      color: textWeak,
    },
  };
});

export const StyledImage = styled(Box)<ImageLayoutProps>(({
  layout,
  src,
  theme,
}) => {
  return {
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '877px',
    width: '1158px',
    position: 'relative',
    left: '-28%',
    top: '-17%',
    zIndex: 1,
    transform: 'rotate(14.421deg)',
  };
});

export const StyledImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutHeight',
})<ContentLayoutProps>(({ layout, theme, layoutHeight }) => {
  const isLowerInHeight: boolean = layoutHeight === 'lowHeight';

  return {
    ...(isLowerInHeight && { display: 'none' }),
    height: '65vh',
    width: '674px',
    position: 'relative',
    top: '7%',
    left: '-57%',
    transform: 'rotate(-14.421deg)',
    overflow: 'hidden',
  };
});
