import { FC, memo, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import {
  LanguageContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Types
import { AddressItem } from '../../../core/types/addressLookupTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
// Constants
import {
  TEXT_OR_CONTAINER_REQUIRED,
  ADDRESS,
} from '../../../core/utils/Constants/Constants';

interface BBAddressDetailsProps {
  entry: AddressItem;
}

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      icon: { active },
    },
  } = theme;

  return {
    '> svg > path': {
      fill: active,
    },
  };
});

const BBAddressDetails: FC<BBAddressDetailsProps> = ({
  entry,
}: BBAddressDetailsProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const noResultsFound: string = translate('addressLookup.noResultsFound');

  const { tenant } = useContext(TenantContext);

  const ChevronRightIcon = useMemo(
    () => getIcon(tenant, 'navigationChevronRightIcon'),
    [tenant]
  );

  if (entry?.Description === TEXT_OR_CONTAINER_REQUIRED) {
    return <span data-testid="bb-no-results-found-text">{noResultsFound}</span>;
  } else {
    const addressText = entry?.Text ? entry?.Text + ', ' : '';
    const addressDescription = entry?.Description ? entry?.Description : '';

    const addressDetails = (
      <span>
        {addressText} {addressDescription}
      </span>
    );
    return (
      <>
        {addressDetails}
        {entry?.Type !== ADDRESS && (
          <IconContainer data-testid="bb-right-chevron-icon">
            {<ChevronRightIcon />}
          </IconContainer>
        )}
      </>
    );
  }
};

export default memo(BBAddressDetails);
