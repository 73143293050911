import { setupListeners } from '@reduxjs/toolkit/query/react';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import errorDataReducer from './modules/errorDetailsSlice';
import profileDataReducer from './modules/userProfileDetailsSlice';
import { api } from './api';
import { rtkQueryErrorLogger } from './api/rtkQueryErrorLogger';

const rootReducer = combineReducers({
  errorDetails: errorDataReducer,
  userProfileDetails: profileDataReducer,
  [api.reducerPath]: api.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
  });
};

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
