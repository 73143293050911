import { FC } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import BBLoaderModal from '../../../../molecules/BBModal/BBLoaderModal';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';

interface DisburseFundsInProgressProps {
  isOpen: boolean;
}

const DisburseFundsInProgress: FC<DisburseFundsInProgressProps> = (
  props: DisburseFundsInProgressProps
) => {
  const { isOpen } = props;

  const { translate } = useTranslations();

  return (
    <BBLoaderModal
      isOpen={isOpen}
      message={translate(
        'transactionDetails.completionPayments.disburseFundsInProgressModal.title'
      )}
      additionMessage={translate(
        'transactionDetails.completionPayments.disburseFundsInProgressModal.message'
      )}
    />
  );
};

export default DisburseFundsInProgress;
