import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBTooltip from '../../../../../../atoms/BBTooltip';

export const Tooltip = styled(BBTooltip)(({ theme }) => {
  return {
    '.MuiTooltip-tooltip': {
      maxWidth: 290,
    },
  };
});

export const Container = styled('span')({
  display: 'inline-flex',
});
