import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

// Types
import {
  DeleteAccountRequest,
  UpdateAccountRequest,
  UpdateAccountResponse,
  UpdateResult,
} from '../../core/types/ApiTypes';
import { TransactionAccount } from '../../core/types/AccountTypes';
import { Transaction } from '../../core/types/TransactionTypes';
import { ErrorWithResults } from '../../core/types/ErrorTypes';
// API Wrappers
import { api } from './api';

const accountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateAccounts: builder.mutation<
      UpdateResult<UpdateAccountResponse>[],
      UpdateAccountRequest[]
    >({
      queryFn: async (accounts, _api, _extraOptions, baseQuery) => {
        const promisesToUpdateAccounts = accounts.map((account) => {
          return baseQuery({
            url: '/propertypay-services/accounts',
            method: 'POST',
            body: account,
          }) as MaybePromise<UpdateResult<UpdateAccountResponse>>;
        });

        const results = (await Promise.all(promisesToUpdateAccounts)).map(
          ({ meta, ...result }) => result
        );
        const firstErrorResult = results.find((result) =>
          Boolean(result.error)
        );

        if (firstErrorResult) {
          return {
            error: {
              ...firstErrorResult.error,
              results,
            } as ErrorWithResults<UpdateAccountResponse>,
          };
        }

        return { data: results };
      },
      invalidatesTags: (_result, _error, requests) => [
        { type: 'Transactions', id: 'LIST' },
        ...requests.map((request) => ({
          type: 'Transactions' as const,
          id: request.transactionId,
        })),
      ],
    }),

    deleteAccount: builder.mutation<null, DeleteAccountRequest>({
      query: (data) => ({
        url: `propertypay-services/accounts/${data.accountId}/transaction/${data.transactionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'Transactions', id: request.transactionId },
      ],
    }),

    getAccountsByTransactionId: builder.query<
      TransactionAccount[],
      Transaction['id']
    >({
      query: (id) => `propertypay-services/accounts/transaction/${id}`,
    }),
  }),
});

export const {
  useUpdateAccountsMutation,
  useDeleteAccountMutation,
  useGetAccountsByTransactionIdQuery,
} = accountsApi;
