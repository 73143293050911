import React from 'react';

import { LANG_CODE_MAPPING } from '../../utils/Constants/Constants';

type LanguageContext = {
  language: string | undefined,
  translations: {[key: string]: string } | null,
  setLanguage: Function
}

export default React.createContext<LanguageContext>({
  language: LANG_CODE_MAPPING['EN'],
  translations: {},
  setLanguage: () => {}
});
