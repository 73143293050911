import { BankAccountFields, PaymentMethod } from '../PaymentRequestsTypes';
import { Nullable } from '../UtilTypes';
import { WalletWithUserDetails } from '../WalletTypes';

export interface PaymentOptionTypes {
  value: string;
  label: string;
}

//TODO: common types needed for disbursement and payment requests
export interface DisbursementBankAccountFields extends BankAccountFields {
  id: number;
}

export interface AddBankAccountRequest extends BankAccountFields {}

export interface BankAccountRequestData {
  id: number;
}

export type TransactionType = {
  id: number | null;
};

export enum DisbursementStatus {
  Pending = 'Pending',
  Ready = 'Ready',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

export interface Disbursement {
  id: Nullable<number>;
  transaction: TransactionType;
  amount: number;
  currency: string;
  bankAccount: Nullable<DisbursementBankAccountFields>;
  paymentMethod: PaymentMethod;
  paidFromWallet: WalletWithUserDetails;
  paidToWallet: Nullable<WalletWithUserDetails>;
  payToEmail: string;
  payToTitanAccountNumber: string;
  instructionNumber: string;
  instructionId: string;
  valueDate: string;
  status: DisbursementStatus;
  fraudAlert: boolean;
  paymentType: string;
  paymentTypeDescription: string;
  createdDate: string;
  updatedDate: string;
}

export interface DisbursementRequest
  extends Partial<Omit<Disbursement, 'bankAccount'>> {
  bankAccount?: Nullable<BankAccountRequestData>;
}

export type BankAccountConfirmation = 'Confirmed Match' | 'No Match';

export interface VerifyWalletRequest {
  searchEmail: string;
  tanNumber: string;
}

export type VerifyWalletResponse = BankAccountConfirmation;

export interface DeleteDisbursementRequest {
  transactionId: number;
  disbursementId: number;
}

export type DownloadDisbursementType =
  | 'source-of-funds'
  | 'completion-schedule';

export interface DownloadDisbursementRequest {
  transactionId: number;
  type: DownloadDisbursementType;
}

export interface ConfirmRequest extends DeleteDisbursementRequest {}
