// Hooks
import { useWallets } from '../shared/hooks/useWallets';
// Types
import { Payment } from '../../../../../core/types/PaymentTypes';

export const useWallet = (id?: Payment['walletId'], transactionId?: number) => {
  const { wallets, ...query } = useWallets(transactionId);
  const wallet = wallets.find((wallet) => wallet.walletNumber === id);

  return { ...query, wallet };
};
