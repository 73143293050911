import {
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Routes,
} from 'react-router-dom';

// Components - Atoms, Molecules, Organisms, Pages
import BBLayout from '../components/organisms/BBLayout';
import FullLayout from '../components/organisms/FullLayout';
import DashboardPage from '../components/pages/DashboardPage';
import LandingPage from '../components/pages/LandingPage';
import TransactionDetails from '../components/pages/CreateTransactions/TransactionDetails';
import PartyDetails from '../components/pages/CreateTransactions/PartyDetails';
import Confirmation from '../components/pages/CreateTransactions/Confirmation';
import { TransactionDetails as ViewTransactionDetails } from '../components/pages/TransactionDetails';
import RequestDetails from '../components/pages/PaymentRequest/RequestDetails';
import AccountToBeFunded from '../components/pages/PaymentRequest/AccountToBeFunded';
import { Confirmation as PaymentRequestConfirmation } from '../components/pages/PaymentRequest/Confirmation';
import ViewPaymentRequestDetails from '../components/pages/PaymentRequest/ViewPaymentRequestDetails';
import BuyerDashboard from '../components/pages/Buyer/Dashboard';
import BuyerTransactionDetails from '../components/pages/Buyer/TransactionDetails';
import BuyerPaymentRequestDetails from '../components/pages/Buyer/PaymentRequestDetails';
import BuyerCreatePaymentMethod from '../components/pages/Buyer/CreatePayment/PaymentMethod';
import BuyerCreatePaymentDetails from '../components/pages/Buyer/CreatePayment/PaymentDetails';
import BuyerCreatePaymentConfirmation from '../components/pages/Buyer/CreatePayment/Confirmation';
import LoginPage from '../components/pages/LoginPage';
import DisbursementDetails from '../components/pages/Disbursement/DisbursementDetails';
import RecipientDetails from '../components/pages/Disbursement/RecipientDetails';
import DisbursementConfirmation from '../components/pages/Disbursement/Confirmation';
import ViewDisbursementDetails from '../components/pages/Disbursement/ViewDisbursementDetails';
import TwoFactorAuthentication from '../components/pages/LoginPage/TwoFactorAuthentication';
import RequireAccess from '../components/organisms/RequireAccess';
import ContactUs from '../components/pages/ContactUs';
import Settings from '../components/pages/Settings';
// Types
import { RoleType } from '../core/types/AccountTypes';
// Constants
import {
  PageHeaders,
  TRANSACTION_FILTERS,
} from '../core/utils/Constants/Constants';

import UserProvider from '../core/providers/UserProvider';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/" element={<Navigate to={`/dashboard`} replace={true} />} />
      {/* TODO: This is a generic handler for all "not found" errors. For now,
          it just redirects to the dashboard page */}
      <Route path="/not-found" element={<Navigate to="/" replace />} />

      {/* Login */}
      <Route
        path="/login"
        element={
          <BBLayout>
            <Outlet />
          </BBLayout>
        }
      >
        <Route index element={<LoginPage />} />
        <Route path="2fa" element={<TwoFactorAuthentication />} />
      </Route>

      <Route
        path="*"
        handle={'parent router'}
        element={
          <UserProvider>
            <BBLayout>
              <RequireAccess roles={[RoleType.SettlementAgent]}>
                <Routes>
                  {/* Dashboard */}
                  <Route
                    path="dashboard"
                    element={
                      <FullLayout>
                        <Outlet />
                      </FullLayout>
                    }
                  >
                    {TRANSACTION_FILTERS.map((filter) => (
                      <Route
                        key={filter.type}
                        path={filter.path}
                        element={<DashboardPage filter={filter} />}
                      />
                    ))}
                    <Route path="*" element={<Navigate to="" replace />} />
                  </Route>

                  {/* Transaction details */}
                  <Route path={`transaction-details`}>
                    <Route
                      path=":id"
                      element={
                        <FullLayout pageHeaderType={PageHeaders.Transactions}>
                          <ViewTransactionDetails />
                        </FullLayout>
                      }
                    />
                    <Route
                      path=":id/disbursements"
                      element={
                        <FullLayout pageHeaderType={PageHeaders.Disbursements}>
                          <Outlet />
                        </FullLayout>
                      }
                    >
                      <Route path="new" element={<DisbursementDetails />} />
                      <Route
                        path=":disbursementId/view-details"
                        element={<ViewDisbursementDetails />}
                      />
                      <Route path=":disbursementId">
                        <Route
                          path="details"
                          element={<DisbursementDetails />}
                        />
                        <Route
                          path="recipient"
                          element={<RecipientDetails />}
                        />
                        <Route
                          path="confirmation"
                          element={<DisbursementConfirmation />}
                        />
                        <Route
                          index
                          element={<Navigate to="details" replace />}
                        />
                      </Route>
                    </Route>
                  </Route>

                  {/* Create transactions */}
                  <Route
                    path="create-transactions"
                    element={
                      <FullLayout
                        pageHeaderType={PageHeaders.CreateTransactions}
                      >
                        <Outlet />
                      </FullLayout>
                    }
                  >
                    <Route
                      path={`transaction-details/:id?`}
                      element={<TransactionDetails />}
                    />
                    <Route
                      path={`party-details/:id`}
                      element={<PartyDetails />}
                    />
                    <Route
                      path={`confirmation/:id`}
                      element={<Confirmation />}
                    />
                  </Route>

                  {/* Payment request */}
                  <Route
                    path="/payment-request"
                    element={
                      <FullLayout pageHeaderType={PageHeaders.PaymentRequests}>
                        <Outlet />
                      </FullLayout>
                    }
                  >
                    <Route
                      path="request-details/:transactionId/:paymentRequestId?"
                      element={<RequestDetails />}
                    />
                    <Route
                      path="account-to-be-funded/:transactionId/:paymentRequestId"
                      element={<AccountToBeFunded />}
                    />
                    <Route
                      path="confirmation/:transactionId/:paymentRequestId"
                      element={<PaymentRequestConfirmation />}
                    />
                    <Route
                      path="view-details/:transactionId/:paymentRequestId"
                      element={<ViewPaymentRequestDetails />}
                    />
                  </Route>

                  <Route
                    path="/contact"
                    element={
                      <FullLayout>
                        <ContactUs />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="/settings"
                    element={
                      <FullLayout>
                        <Settings />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="*"
                    element={<Navigate to="/not-found" replace />}
                  />
                </Routes>
              </RequireAccess>

              <RequireAccess roles={[RoleType.Buyer]}>
                <Routes>
                  <Route
                    path="dashboard"
                    element={
                      <FullLayout isBuyerFlow={true}>
                        <BuyerDashboard />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="transactions/:transactionId"
                    element={
                      <FullLayout
                        pageHeaderType={PageHeaders.Buyer}
                        isBuyerFlow={true}
                      >
                        <BuyerTransactionDetails />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="payment-requests/:paymentRequestId"
                    element={
                      <FullLayout
                        pageHeaderType={PageHeaders.BuyerPaymentRequests}
                        isBuyerFlow={true}
                      >
                        <BuyerPaymentRequestDetails />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="payment-requests/:paymentRequestId/payments/new"
                    element={
                      <FullLayout
                        pageHeaderType={PageHeaders.BuyerPaymentRequests}
                        isBuyerFlow={true}
                      >
                        <BuyerCreatePaymentMethod />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="payments/:paymentId"
                    element={
                      <FullLayout
                        pageHeaderType={PageHeaders.BuyerPaymentRequests}
                        isBuyerFlow={true}
                      >
                        <Outlet />
                      </FullLayout>
                    }
                  >
                    <Route
                      path="method"
                      element={<BuyerCreatePaymentMethod />}
                    />
                    <Route
                      path="details"
                      element={<BuyerCreatePaymentDetails />}
                    />
                    <Route
                      path="confirmation"
                      element={<BuyerCreatePaymentConfirmation />}
                    />
                  </Route>

                  <Route
                    path="/contact"
                    element={
                      <FullLayout isBuyerFlow={true}>
                        <ContactUs />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="/settings"
                    element={
                      <FullLayout isBuyerFlow={true}>
                        <Settings isBuyerFlow={true} />
                      </FullLayout>
                    }
                  />

                  <Route
                    path="*"
                    element={<Navigate to="/not-found" replace />}
                  />
                </Routes>
              </RequireAccess>
            </BBLayout>
          </UserProvider>
        }
      />
    </>
  )
);

const Main = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default Main;
