import { ReduxState } from '../core/types/ReduxStoreTypes';

export const initialState: ReduxState = {
  userProfileDetails: {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    contactNo: '',
    titanCustomerNo: '',
    userProfileDetailsLoading: 'idle',
    error: '',
  },
  errorDetails: {
    apiError: false,
    errorMessage: '',
    errorCode: '',
  },
};
