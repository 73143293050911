// Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
import { PaymentRequestRow } from './types';
// Utils
import { getBankAccountName } from '../../../../../core/utils/BankAccount';

export const mapPaymentRequestToPaymentRequestRow = (
  paymentRequest: PaymentRequestDetails
): PaymentRequestRow => {
  return {
    id: paymentRequest.id,
    paymentType: paymentRequest.paymentType,
    paymentTypeDescription: paymentRequest.paymentTypeDescription,
    status: paymentRequest.paymentRequestStatus,
    amount: paymentRequest.payAmount,
    currency: paymentRequest.currency,
    recipient: getBankAccountName(
      paymentRequest.accountToBeFunded?.bankAccount
    ),
    recipientType: paymentRequest.accountToBeFunded?.paymentMethod,
    dueDate: paymentRequest.dueDate,
    createdDate: paymentRequest.createdDate,
  };
};
