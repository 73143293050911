import { FC, memo } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { TypographyTypes } from '../../../core/types/TypographyTypes';
import getTypographyStyles from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface StyledTextProps extends TypographyProps {
  type: TypographyTypes;
}

const StyledText = styled(Typography)<StyledTextProps>(({
  theme,
  variant,
  type,
}) => {
  return getTypographyStyles(variant, type, theme);
});

interface BBTextProps extends StyledTextProps {
  text: string;
}

const BBText: FC<BBTextProps> = (props: BBTextProps) => {
  return (
    <StyledText data-testid="bb-text" {...props}>
      {props.text}
    </StyledText>
  );
};

export default memo(BBText);
