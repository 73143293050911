import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  CompletionActionContainer,
  CompletionInfoContainer,
  CompletionTextWrapper,
  MainWrapper,
  StyledDescription,
} from './CompletedScreen.styles';
import BBText from '../../../../atoms/BBText';
import BBButton from '../../../../atoms/BBButton';
import Page from '../../../../organisms/Page';
//Hooks
import { useInitialLoading, useTranslations } from '../../../../../core/hooks';
//Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { CompletedScreenProps, ConfirmationRouterParams } from '../types';
import { getFormattedValue } from '../../../../../core/utils/Format';

export default function CompletedScreen(props: CompletedScreenProps) {
  const { data, isFetching, isLoading, error } = props;
  const { translate } = useTranslations();
  const navigate = useNavigate();

  const { id } = useParams<ConfirmationRouterParams>();

  const { isInitiallyLoading } = useInitialLoading({ isFetching, isLoading });

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const btnText: string = translate('paymentRequest.completed.btnText');

  const CompletedPageIllustration = useMemo(
    () => getIcon(tenant, 'completedPageIllustration'),
    [tenant]
  );

  const handleGotoTransactionClick = () => {
    navigate(`/transaction-details/${id}`);
  };

  return (
    <Page isLoading={isInitiallyLoading} error={error}>
      <MainWrapper layout={layout}>
        <CompletionInfoContainer>
          <CompletedPageIllustration />
          <CompletionTextWrapper>
            <BBText
              variant="h3"
              type="bold"
              text={translate('disbursement.completed.title')}
            />
            <StyledDescription
              variant="body2"
              type="normal"
              text={translate('disbursement.completed.description', {
                name: getFormattedValue(data?.paidFromWallet?.crmAccount?.name),
              })}
            />
          </CompletionTextWrapper>
        </CompletionInfoContainer>
        <CompletionActionContainer layout={layout}>
          <BBButton
            btnType="secondary"
            size="medium"
            type="button"
            onClick={handleGotoTransactionClick}
          >
            {btnText}
          </BBButton>
        </CompletionActionContainer>
      </MainWrapper>
    </Page>
  );
}
