import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from '@reduxjs/toolkit';
import '@reduxjs/toolkit/query';

// Types
import { FetchError, RTKQueryError } from '../../core/types/ErrorTypes';
// RTK Slice
import {
  errorStateReset,
  showErrorNotification,
} from '../modules/errorDetailsSlice';
// API Wrappers
import { apiError } from './api';
// Utils
import { isFieldsValidationError } from '../../core/utils/Errors';

type ErrorAction = PayloadAction<RTKQueryError> | PayloadAction<FetchError>;

const buildError = (action: ErrorAction) => {
  const { payload } = action;

  if ('error' in payload) {
    return {
      message: payload.error,
      errorCode: payload.status,
    };
  }

  if (Array.isArray(payload.data)) {
    return {
      message: payload.data[0]?.message || payload.data[0]?.errorMessage,
      errorCode: payload.status,
    };
  }

  if ('code' in payload.data) {
    return {
      message: payload.data.description,
      errorCode: payload.data.code,
    };
  }

  if ('error' in payload.data && typeof payload.data.error === 'object') {
    return {
      message: payload.data?.error?.errorMessage,
      errorCode: payload.status,
    };
  }

  return {
    message: payload.data.error,
    errorCode: payload.status,
  };
};

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      (isRejectedWithValue(action) || apiError.match(action)) &&
      !isFieldsValidationError(action.payload)
    ) {
      api.dispatch(
        showErrorNotification({
          error: true,
          ...buildError(action as ErrorAction),
        })
      );

      // TODO: Copying this for now from the errorDetailsSlice.ts. However, this
      // behaviour in incorrect, before starting a new timer, previous one should
      // be cancelled. It's unclear at the moment where previous timer id should
      // be stored in the current redux structure. Once clarified, this code
      // should be revisited
      setTimeout(() => {
        api.dispatch(errorStateReset());
      }, 6000);
    }

    return next(action);
  };
