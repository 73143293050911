// Types
import { UpsertPaymentRequest } from '../../../../../core/types/ApiTypes';
import { FxDealRate } from '../../../../../core/types/PricingTypes';

export const mapDealToUpsertRequest = (
  deal?: FxDealRate
): Partial<UpsertPaymentRequest> => {
  if (!deal) {
    return {};
  }

  return {
    token: deal.token,
    quotedRate: deal.quotedRate,
    quotedPrice: deal.quotedPrice,
    rateExpiry: new Date(deal.rateExpiryTime).toISOString(),
    exchangeRate: deal.liveRate,
  };
};
