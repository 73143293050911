import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';

interface ConfirmationWrapperProps {
  customerFound: boolean;
}

export const FormContent = styled('form')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    alignSelf: 'stretch',
    gap: spacing.xxSmall,
  };
});

export const FormWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    flex: 1,
    alignSelf: 'stretch',
  };
});

export const DetailsContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    alignSelf: 'stretch',
    gap: spacing.xSmall,
  };
});

export const ConfirmationWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customerFound',
})<ConfirmationWrapperProps>(({ theme, customerFound }) => {
  const {
    dimensions: { spacing },
    colours: {
      backgrounds: { success, error },
    },
  } = theme;

  return {
    display: 'flex',
    gap: spacing.xxSmall,
    color: customerFound ? success : error,
  };
});

export const StyledBBText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;
  return {
    color: text,
  };
});
