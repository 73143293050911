import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import ResponsiveButton from '../../../organisms/ResponsiveButton';
// Types
import { LayoutSize } from '../../../../core/types/LayoutTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

interface BackButtonOwnerState {
  layout: LayoutSize;
}

export const BackButton = styled(ResponsiveButton)<{
  ownerState: BackButtonOwnerState;
}>(({ ownerState }) => {
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  if (isMobileLayout) {
    return {};
  }

  return {
    maxWidth: 262,
  };
});
