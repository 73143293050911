import { useEffect, useRef } from 'react';

export const useScheduledEffect = (effect: () => void, time?: string) => {
  const frame = useRef(0);
  const scheduledEffect = useRef(effect);

  useEffect(() => {
    scheduledEffect.current = effect;
  }, [effect]);

  useEffect(() => {
    if (!time) {
      return;
    }

    const endTime = new Date(time);

    const checkTime = () => {
      if (new Date() >= endTime) {
        scheduledEffect.current();
        return;
      }

      frame.current = requestAnimationFrame(checkTime);
    };

    frame.current = requestAnimationFrame(checkTime);

    return () => cancelAnimationFrame(frame.current);
  }, [time]);
};
