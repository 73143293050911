import { FC, useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import NewRecipientForm from './NewRecipientForm';
import Accordion, { AccordionDetails, AccordionSummary } from '../Accordion';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { SVGComponent } from '../../../core/types/IconOrgTypes';
import { AccountType } from '../../../core/types/RecipientTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { AccordionDefaultProps } from '../../../core/types/AccordionTypes';

interface NewRecipientAccordionProps
  extends AccordionDefaultProps<AccountType> {}

const NewRecipientAccordion: FC<NewRecipientAccordionProps> = (
  props: NewRecipientAccordionProps
) => {
  const { expanded, handleChange } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const [AddAltIcon]: SVGComponent[] = useMemo(
    () => [getIcon(tenant, 'addAlt')],
    [tenant]
  );

  const accountType = 'new-recipient';

  return (
    <Accordion
      expanded={expanded === accountType}
      onChange={handleChange(accountType)}
    >
      <AccordionSummary
        aria-controls="new-recipients-content"
        id="new-recipients-header"
        icon={<AddAltIcon />}
        title={translate('newRecipient.title')}
        subtitle={translate('newRecipient.subTitle')}
      />
      <AccordionDetails>
        <NewRecipientForm />
      </AccordionDetails>
    </Accordion>
  );
};

export default NewRecipientAccordion;
