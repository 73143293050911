import { FC, useState, useRef, SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import { VariableSizeList as List } from 'react-window';

// Components - Atoms, Molecules, Organisms, Pages
import BBSelectItem from './BBSelectItem';
import FullScreenAutoCompleteInput from './FullScreenAutoCompleteInput';
import BBMobileOptimisedNoOptionUI from '../BBMobileOptimisedNoOptionUI';
// Types
import {
  OptionType,
  SelectedOptionType,
} from '../../../core/types/SelectTypes';
import { TypeProps } from './BBSelect';

interface FullScreenDialogAutocompleteProps {
  handleClose: () => void;
  selectOptions: any;
  onChangeHandler?: (e: SyntheticEvent, newValueObj: any) => void;
  currentSelectedOption: SelectedOptionType | null;
  type?: TypeProps;
  shouldUseCustomFilter?: boolean;
  placeholder: string;
}

const StyledListWrapper = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
      text: { text, textWeak },
      scroll: { thumbBackground, trackBackground },
    },
    zindex: { scrollBarThumb },
    dimensions: { radius },
  } = theme;

  return {
    backgroundColor: bgAlt,
    borderTop: 'none',
    borderRadius: 'inherit',
    '>div::-webkit-scrollbar': {
      width: 10,
      height: 6,
    },
    '>div::-webkit-scrollbar-thumb': {
      background: thumbBackground,
      borderRadius: 8,
      zIndex: scrollBarThumb,
      height: 30,
    },
    '>div::-webkit-scrollbar-track': {
      background: trackBackground,
      width: 24,
    },
    li: {
      justifyContent: 'space-between',
      height: 'fit-content',
      padding: '12px 16px',
      borderRadius: radius.small,
      border: `1px solid ${trackBackground}`,
    },
    'li[aria-selected="true"]': {
      backgroundColor: text,
      borderRadius: radius.small,
      '& .MuiTypography-root': {
        color: textWeak,
      },
    },
  };
});

const StyledList = styled(List)(({ theme }) => {
  return {
    padding: '16px 0px !important',
    '> div': {
      position: 'relative',
      width: '90% !important',
      margin: '0px auto',
    },
  };
});

const FullScreenDialogAutocomplete: FC<FullScreenDialogAutocompleteProps> = (
  props: FullScreenDialogAutocompleteProps
) => {
  const {
    handleClose,
    selectOptions,
    onChangeHandler,
    currentSelectedOption,
    type,
    placeholder,
  } = props;

  const [currentInputValue, setCurrentInputValue] = useState('');

  const rowRef = useRef<any>(null);
  const listRef = useRef<any>(null);

  const closeDropdown = () => {
    setCurrentInputValue('');
    handleClose();
  };

  const handleInput = (currentValue: string) => {
    setCurrentInputValue(currentValue);
  };

  const handleCloseIconClick = () => {
    if (currentInputValue) {
      setCurrentInputValue('');
    } else {
      handleClose();
    }
  };

  const Row = (rowProps: any) => {
    const { index, style } = rowProps;

    return (
      <div ref={rowRef} style={style}>
        {dropDownListUI[index]}
      </div>
    );
  };

  const filterOptions = (currentOptions: any, inputValue: string) => {
    const optionList: OptionType[] = currentOptions as OptionType[];
    const searchValue: string = inputValue.trim().toLowerCase();

    if (searchValue) {
      const filteredOptions: OptionType[] = [];
      optionList.forEach((option: OptionType) => {
        if (
          option.label?.trim().toLowerCase().includes(searchValue) ||
          option.value?.trim().toLowerCase().includes(searchValue)
        )
          filteredOptions.push(option);
      });
      return filteredOptions;
    }
    return optionList;
  };

  const dropDownListUI: JSX.Element[] = filterOptions(
    selectOptions,
    currentInputValue.length ? currentInputValue : ''
  ).map((option: OptionType) => (
    <BBSelectItem
      option={option}
      currentSelectedOption={currentSelectedOption}
      key={`${option.label}-${option.value}-${option.iconPath}`}
      variant="x-small"
      type={type}
      // @ts-ignore
      listClickHandler={onChangeHandler}
    />
  ));

  const isDropDownListUIAvailable: number = dropDownListUI.length;

  return (
    <>
      <FullScreenAutoCompleteInput
        handleClose={closeDropdown}
        handleCloseIconClick={handleCloseIconClick}
        handleInput={handleInput}
        placeholder={placeholder}
      />
      {isDropDownListUIAvailable ? (
        <StyledListWrapper data-testid="bb-styled-list-wrapper">
          <StyledList
            ref={listRef}
            height={window.innerHeight - 95}
            itemCount={dropDownListUI.length}
            itemSize={() => 56}
            width="100%"
          >
            {Row}
          </StyledList>
        </StyledListWrapper>
      ) : (
        <BBMobileOptimisedNoOptionUI />
      )}
    </>
  );
};

export default FullScreenDialogAutocomplete;
