import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query/react';

// Components - Atoms, Molecules, Organisms, Pages
import { BBRadioGroup } from '../../atoms/BBRadioButton';
import ControllerElementWrapper from '../ControllerWrapper';
import BBInput from '../../atoms/BBInput';
import BBSelect from '../../atoms/BBSelect';
import { FormWrapper, FormContainer, StyledSubTitle } from './styles';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { NewRecipientFormValues } from '../../../core/types/RecipientTypes';
import {
  AccountField,
  BankAccountFieldsResponse,
} from '../../../core/types/PaymentRequestsTypes';
// API Wrappers
import { useGetBankAccountFieldsQuery } from '../../../redux/api';
// Utils
import { getSupportedCountryOptions } from './utils';

const countryOptions = getSupportedCountryOptions();

const NewRecipientForm: FC = () => {
  const {
    control,
    watch,
    register,
    formState: { isSubmitting },
  } = useFormContext<NewRecipientFormValues>();

  const { countryCode, newAccountType, currency, bankDetails } = watch();

  const { translate } = useTranslations();

  const { data, isLoading } = useGetBankAccountFieldsQuery(
    countryCode && currency
      ? { countryCode: countryCode, currency: currency }
      : skipToken
  );

  const isNewAccountTypeIndividual: boolean =
    newAccountType?.toLowerCase() === 'individual';
  const isCountrySelected: boolean = countryCode !== '';

  const baseTranslationKey: string = 'newRecipient.fields';

  const radioOptions = [
    {
      label: translate(baseTranslationKey + '.individualAccountText'),
      value: 'Individual',
    },
    {
      label: translate(baseTranslationKey + '.companyAccountText'),
      value: 'Company',
    },
  ];

  const getBankAccountFields = (fieldData: BankAccountFieldsResponse) => {
    const {
      uiBlock: { uiFields },
    } = fieldData;
    const fieldsToDisplay: JSX.Element[] = uiFields?.map(
      (fieldDetails: AccountField) => {
        const fieldName: string = `bankDetails.${fieldDetails.displayId}`;
        register(`bankDetails.${fieldDetails.displayId}`, { value: '' });
        return (
          <ControllerElementWrapper
            key={fieldName}
            name={fieldName}
            control={control}
            placeholderLabel={fieldDetails.description}
            value={bankDetails[fieldDetails.displayId] ?? ''}
            component={BBInput}
            isDisabled={isSubmitting}
          />
        );
      }
    );
    return fieldsToDisplay;
  };

  return (
    <FormContainer>
      <FormWrapper>
        <ControllerElementWrapper
          name="newAccountType"
          control={control}
          value={newAccountType}
          component={BBRadioGroup}
          options={radioOptions}
          disabled={isSubmitting}
        />
        {isNewAccountTypeIndividual ? (
          <>
            <ControllerElementWrapper
              name={`firstName`}
              control={control}
              placeholderLabel={translate(baseTranslationKey + '.firstName')}
              component={BBInput}
              isDisabled={isSubmitting}
            />
            <ControllerElementWrapper
              name={`lastName`}
              control={control}
              placeholderLabel={translate(baseTranslationKey + '.lastName')}
              component={BBInput}
              isDisabled={isSubmitting}
            />
          </>
        ) : (
          <ControllerElementWrapper
            name={'companyName'}
            control={control}
            placeholderLabel={translate(baseTranslationKey + '.companyName')}
            component={BBInput}
            isDisabled={isSubmitting}
          />
        )}

        <ControllerElementWrapper
          name={'countryCode'}
          control={control}
          value={countryCode}
          placeholderLabel={translate(baseTranslationKey + '.country')}
          component={BBSelect}
          disabled={isSubmitting}
          size="normal"
          type="country"
          options={countryOptions}
        />
        {isCountrySelected && (
          <ControllerElementWrapper
            name={'currency'}
            control={control}
            value={currency}
            placeholderLabel={translate(baseTranslationKey + '.currency')}
            component={BBSelect}
            disabled={isSubmitting}
            size="normal"
            type="currency"
          />
        )}
      </FormWrapper>
      {isCountrySelected && (
        <FormWrapper>
          <StyledSubTitle
            text={translate('newRecipient.bankDetailsLabel')}
            type="medium"
            variant="body1"
          />
          {!isLoading && data && getBankAccountFields(data)}
        </FormWrapper>
      )}
    </FormContainer>
  );
};

export default NewRecipientForm;
