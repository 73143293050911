import { GridEventListener } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { MutableRefObject, useState } from 'react';

const parsePadding = (value: string) => {
  const padding = parseFloat(value);

  if (Number.isNaN(padding)) {
    return 0;
  }

  return padding;
};

const getAvailableRowWidth = (apiRef: MutableRefObject<GridApiCommunity>) => {
  const firstRowId = apiRef.current.getRowIdFromRowIndex(0);
  const row = apiRef.current.getRowElement(firstRowId);

  if (!row) {
    return;
  }

  const { paddingLeft, paddingRight } = getComputedStyle(row);

  return (
    row.clientWidth - parsePadding(paddingLeft) - parsePadding(paddingRight)
  );
};

export const useResponsiveLayout = (
  apiRef: MutableRefObject<GridApiCommunity>
) => {
  const [numberOfResponsiveCells, setNumberOfResponsiveCells] = useState(0);

  const onTableResize: GridEventListener<'debouncedResize'> = (size) => {
    if (!apiRef.current) {
      return;
    }

    const availableWidth = getAvailableRowWidth(apiRef) ?? size.width;
    const columns = apiRef.current?.getVisibleColumns() ?? [];

    const { count } = columns.reduceRight(
      (acc, column) => {
        const total = acc.total + (column.minWidth ?? 0);

        if (total > availableWidth) {
          return acc;
        }

        return { count: acc.count + 1, total };
      },
      { count: 0, total: 0 }
    );

    setNumberOfResponsiveCells(columns.length - count);
  };

  return { numberOfResponsiveCells, onTableResize };
};
