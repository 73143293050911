import { FC, useState, useEffect, useMemo, useContext } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Contexts
import {
  TenantContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBFooter from '../../molecules/BBFooter';
import BBSideBarNav from '../../molecules/BBSideBarNav';
import BBLogoWrapper from '../../organisms/BBLogoWrapper';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';
// Constants
import {
  PROPERTY_PAY_LINK,
  DRAWER_WIDTH,
  DRAWER_MINI_WIDTH,
} from '../../../core/utils/Constants/Constants';
interface BBSideBarProps {
  isBuyerFlow?: boolean;
}
interface MenuTogglerProps {
  open: boolean;
  isBuyerFlow?: boolean;
}

interface MuiDrawerProps extends DrawerProps {
  menuOverScreen: boolean;
}

const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isBuyerFlow',
})<MenuTogglerProps>(({ theme, open, isBuyerFlow }) => {
  const {
    colours: {
      backgrounds: { activeDark },
    },
    dimensions: {
      spacing: { small, xLarge, large },
    },
  } = theme;

  return {
    display: 'flex',
    padding: open ? large : `${large}px ${small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: xLarge,
    flex: '1 0 0',
    background: isBuyerFlow ? '#1F2A37' : activeDark, // Token not available
  };
});

const Header = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xLarge },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: xLarge,
    flex: '1 0 0',
    alignSelf: 'stretch',
  };
});

const Brand = styled(Box)<MenuTogglerProps>(({ open, theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
      radius: { card },
    },
  } = theme;

  return {
    display: 'flex',
    gap: xxSmall,
    borderRadius: card,
    justifyContent: open ? 'space-between' : 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  };
});

const MenuToggler = styled(IconButton)<MenuTogglerProps>(({ open, theme }) => {
  const {
    colours: {
      icon: { iconAlt },
    },
    dimensions: {
      spacing: { xSmall, xxxSmall },
      radius: { card },
    },
  } = theme;

  return {
    display: 'flex',
    width: '32px',
    height: '32px',
    padding: xxxSmall,
    justifyContent: 'center',
    alignItems: 'center',
    gap: xSmall,
    borderRadius: card,
    border: '1px solid #28303A', // Token not available
    cursor: 'pointer',
    ...(!open
      ? {
          position: 'fixed',
          left: '62px',
          top: '32px',
          background: '#28303A', // Token not available
        }
      : {
          position: 'absolute',
          right: 'auto',
          top: '32px',
          left: 'calc(340px - 64px)',
        }),
    '> svg > path': {
      fill: iconAlt,
    },
  };
});

//sidebar Toggle
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'layout' && prop !== 'menuOverScreen',
})<MuiDrawerProps>(({ theme, open, menuOverScreen }) => ({
  width: DRAWER_WIDTH,
  flexGrow: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  ...(menuOverScreen && {
    position: 'absolute',
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_MINI_WIDTH,
});

const BBSideBar: FC<BBSideBarProps> = (props: BBSideBarProps) => {
  const { isBuyerFlow } = props;

  const theme = useTheme();

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const [toggle, setToggle] = useState<boolean>(true);

  //Icons
  const [LeftChevronIcon, RightChevronIcon] = useMemo(
    () => [getIcon(tenant, 'leftChevron'), getIcon(tenant, 'rightChevron')],
    [tenant]
  );

  const isTabletLayout: boolean = layout === 'tablet';
  // As per Adam's feedback on UI, we are collapsing the sidebar for small desktop.
  const largeToEXtraLarge = useMediaQuery(
    theme.breakpoints.between('lg', 'xl')
  );
  const menuOverScreen: boolean =
    (isTabletLayout && toggle) || (largeToEXtraLarge && toggle);

  const handleDrawerToggle = () => setToggle(!toggle);

  useEffect(() => {
    setToggle(!isTabletLayout);
  }, [isTabletLayout]);

  useEffect(() => {
    setToggle(!isTabletLayout && !largeToEXtraLarge);
  }, [largeToEXtraLarge, isTabletLayout]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => menuOverScreen && setToggle(!isTabletLayout)}
    >
      <Drawer
        variant="permanent"
        open={toggle}
        menuOverScreen={menuOverScreen}
        id="drawer"
        hideBackdrop
      >
        <Sidebar
          open={toggle}
          isBuyerFlow={isBuyerFlow}
          data-testid="bb-sidebar-container"
        >
          <Header>
            <Brand open={toggle}>
              <BBLogoWrapper
                logoVariant={toggle ? 'logoLight' : 'logoDarkMobile'}
                link={PROPERTY_PAY_LINK}
              />
              <MenuToggler
                open={toggle}
                onClick={handleDrawerToggle}
                disableRipple
              >
                {toggle ? <LeftChevronIcon /> : <RightChevronIcon />}
              </MenuToggler>
            </Brand>
            <BBSideBarNav open={toggle} isBuyerFlow={isBuyerFlow} />
          </Header>
          {toggle && <BBFooter />}
        </Sidebar>
      </Drawer>
    </ClickAwayListener>
  );
};

export default BBSideBar;
