// Types
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { DownloadContractNoteResponse } from '../../core/types/ApiTypes';
import { DownloadDisbursementRequest } from '../../core/types/DisbursementTypes';
// API Wrappers
import { api } from './api';
// Utils
import { buildNotFoundError } from '../../core/utils/Errors';

const contractNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    downloadContractNote: builder.mutation<
      DownloadContractNoteResponse,
      number
    >({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        const result = (await baseQuery({
          url: `propertypay-services/contractNote/downloadContractNotes/${id}`,
          method: 'POST',
        })) as QueryReturnValue<
          DownloadContractNoteResponse,
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;

        if (result.data && !result.data?.documentAsbyteArray) {
          // TODO: This is a temporary work around, since API doesn't throw an error
          // when contract note is not available and just returns nulls
          return {
            error: buildNotFoundError('Contract note was not found'),
          };
        }

        return result;
      },
    }),

    downloadDisbursementContractNotes: builder.mutation<
      DownloadContractNoteResponse[],
      DownloadDisbursementRequest
    >({
      queryFn: async (data, _api, _extraOptions, baseQuery) => {
        const result = (await baseQuery({
          url: `propertypay-services/contractNote/disbursements/download-contract-note/${data.transactionId}/${data.type}`,
          method: 'POST',
        })) as QueryReturnValue<
          DownloadContractNoteResponse[],
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;

        if (!result.data?.length) {
          // TODO: This is a temporary work around, since API doesn't throw an error
          // when contract note is not available and just returns null
          return {
            error: buildNotFoundError('Contract note was not found'),
          };
        }

        return result;
      },
    }),
  }),
});

export const {
  useDownloadContractNoteMutation,
  useDownloadDisbursementContractNotesMutation,
} = contractNoteApi;
