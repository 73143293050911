import capitalize from 'lodash/capitalize';

// Hooks
import { useTranslations } from './useTranslations';

export const useErrorTranslations = (translationPrefix: string) => {
  const { translate } = useTranslations();

  const translateError = (field: string, errorType: string, variables = {}) => {
    const message = translate(`common.errors.${errorType}`, {
      field: translate(`${translationPrefix}.${field}`)?.toLowerCase(),
      ...variables,
    });

    return capitalize(message);
  };

  return { translateError };
};
