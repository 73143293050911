import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Types
import { LayoutProps } from '../../../../../core/types/LayoutProps';
import { LayoutSize } from '../../../../../core/types/LayoutTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';

export const MainWrapper = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const isMobileLayout = layout === 'mobile';
  const {
    dimensions: {
      spacing: { medium, xLarge, xxxLarge },
    },
    colours: {
      backgrounds: { white },
    },
  } = theme;

  return {
    display: 'flex',
    padding: isMobileLayout
      ? `${xLarge}px ${medium}px`
      : `${xxxLarge}px ${xLarge}px`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: xLarge,
    alignSelf: 'center',
    background: white,
  };
});

export const CompletionInfoContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { large },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: large,
    alignSelf: 'stretch',
  };
});

export const CompletionTextWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xxSmall,
    textAlign: 'center',
  };
});

export const CompletionActionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layout',
})<{ layout: LayoutSize }>(({ layout }) => {
  const isMobileLayout: boolean = layout
    ? isMobileLayoutApplicable(layout)
    : false;
  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(isMobileLayout && {
      width: '100%',
    }),
    '> button': {
      ...(isMobileLayout && {
        width: 'inherit',
      }),
      ...((isDesktopLayout || isTabletLayout) && {
        width: '327px',
      }),
    },
  };
});
