import { FC, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, styled } from '@mui/material';

// Contexts
import {
  LayoutContext,
  LanguageContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../atoms/BBButton';
import BBSelectNew from '../../atoms/BBSelect';
import TransactionSummary from './TransactionSummary';
import Page from '../../organisms/Page';
import PaymentRequest from './PaymentRequest';
import CompletionPayments from './CompletionPayments';
import BBTabs, { BBTab, BBTabList, BBTabPanel } from '../../molecules/BBTabs';
// Hooks
import { useFilters } from './useFilters';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { ThreePaValue } from '../../../core/types/TransactionTypes';
import {
  PaymentRequestDetails,
  TransactionDetailsFilter,
} from '../../../core/types/PaymentRequestsTypes';
// API Wrappers
import {
  useGetThreePaDetailsByTransactionIdQuery,
  useGetTransactionQuery,
} from '../../../redux/api';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper/TranslateWrapper';
import { mapPaymentToPaymentRequests } from './PaymentRequest/PaymentsRequestTable/utils';
import { getFormattedNamesFromCrmAccounts } from '../../../core/utils/Format';
// Constants
import { REQUEST, COMPLETION } from '../../../core/utils/Constants/Constants';

type RouterParams = {
  id: string;
};

export const LoaderContainer = styled(Box)({
  textAlign: 'center',
});

const ActionContainer = styled(Box)<LayoutProps>(({ layout }) => {
  const isMobileLayout = layout === 'mobile';
  const isTabletLayout: boolean = layout === 'tablet';
  return {
    alignContent: 'center',
    flexWrap: 'wrap',
    width: isMobileLayout ? '100%' : isTabletLayout ? '300px' : '220px', //Token not available
    '> button': {
      width: isMobileLayout ? '100%' : isTabletLayout ? '300px' : '220px', //Token not available
    },
  };
});

const TabsPlusButtonWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    gap: spacing.xxxLarge,
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  };
});

const StyledBBTabList = styled(BBTabList)(() => {
  return {
    flex: '1 0 0',
  };
});

const StyledBBTabPanel = styled(BBTabPanel)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

const TransactionDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouterParams>();

  const {
    isLoading: isTransactionLoading,
    data: transactionData,
    error: transactionError,
  } = useGetTransactionQuery(id ? Number(id) : skipToken);

  const { isLoading: isThreePaLoading, data: threePaData } =
    useGetThreePaDetailsByTransactionIdQuery(id ? Number(id) : skipToken);

  const TRANSACTION_DETAILS_FILTERS = useMemo<TransactionDetailsFilter[]>(
    () => [
      {
        key: 'request',
        disabled: false,
      },
      {
        key: 'completion',
        disabled:
          isThreePaLoading ||
          threePaData?.threePa?.trim().toLowerCase() ===
            ThreePaValue.No.toLowerCase(),
      },
    ],
    [isThreePaLoading, threePaData?.threePa]
  );

  const { filters, setFilter, filter } = useFilters(
    TRANSACTION_DETAILS_FILTERS
  );

  const payments = useMemo(() => {
    return (
      transactionData?.paymentRequests?.map((paymentData) =>
        mapPaymentToPaymentRequests(paymentData, transactionData.currency ?? '')
      ) ?? []
    );
  }, [transactionData]);

  const Payments: PaymentRequestDetails[] =
    transactionData?.paymentRequests ?? [];

  const isPaymentsAvailable: boolean = Payments.length > 0;

  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const isDesktopLayout: boolean = layout === 'desktop';

  const handleRequestPaymentClick = () => {
    navigate(`/payment-request/request-details/${id}`);
  };

  const handleAddDisbursementClick = () => {
    navigate(`/transaction-details/${id}/disbursements/new`);
  };

  const ActionButtons = (
    <ActionContainer layout={layout}>
      {filter === REQUEST && (
        <BBButton
          btnType="secondary"
          size="medium"
          onClick={handleRequestPaymentClick}
        >
          {translate('transactionDetails.requestBtnText')}
        </BBButton>
      )}

      {filter === COMPLETION && (
        <BBButton
          btnType="secondary"
          size="medium"
          onClick={handleAddDisbursementClick}
        >
          {translate('transactionDetails.disbursementBtnText')}
        </BBButton>
      )}
    </ActionContainer>
  );

  return (
    <Page isLoading={isTransactionLoading} error={transactionError}>
      <TransactionSummary
        transactionData={transactionData}
        threePaData={threePaData}
      />

      <BBTabs value={filter}>
        {isDesktopLayout && (
          <TabsPlusButtonWrapper>
            <StyledBBTabList
              onChange={(_, filterTabList) => setFilter(filterTabList)}
              type="line"
            >
              {filters.map((filterTab) => (
                <BBTab
                  key={filterTab.value}
                  value={filterTab.value}
                  label={filterTab.label}
                  disabled={filterTab.disabled}
                  type="line"
                />
              ))}
            </StyledBBTabList>
            {ActionButtons}
          </TabsPlusButtonWrapper>
        )}

        {!isDesktopLayout && (
          <>
            <BBSelectNew
              value={filter}
              onChange={setFilter}
              options={filters}
              isSearchable={false}
              placeholderLabel={translate('transactions.filterByTitle')}
              name="filter"
              variant="small"
            />
            {ActionButtons}
          </>
        )}

        <div>
          {filters.map((filter) => (
            <StyledBBTabPanel value={filter.value} key={filter.value}>
              {filter.value === REQUEST && (
                <PaymentRequest
                  id={id}
                  payments={payments}
                  isPaymentsAvailable={isPaymentsAvailable}
                />
              )}

              {filter.value === COMPLETION && (
                <CompletionPayments
                  id={id}
                  userNames={getFormattedNamesFromCrmAccounts(
                    transactionData?.crmAccounts
                  )}
                  disbursementStatus={transactionData?.disbursementStatus}
                  dateOfDisbursement={transactionData?.dateOfDisbursement}
                />
              )}
            </StyledBBTabPanel>
          ))}
        </div>
      </BBTabs>
    </Page>
  );
};

export default TransactionDetails;
