// Constants
import { PROXY_ENV } from '../Constants/Constants';

interface EnvConfigType {
  SIT: EnvSpecificProperties;
  UAT: EnvSpecificProperties;
  PROD: EnvSpecificProperties;
}

export type EnvSpecificProperties = {
  landingPageURL: string;
};

const ENVIRONMENT_CONFIG_PROPERTIES: EnvConfigType = {
  SIT: {
    landingPageURL:
      'https://register.sit.propertypay.redpincompany.com/register-page/',
  },
  UAT: {
    landingPageURL:
      'https://register.uat.propertypay.redpincompany.com/register-page/',
  },
  PROD: {
    landingPageURL:
      'https://register.propertypay.redpincompany.com/register-page/',
  },
};

export const getEnvSpecificConfigProperty = (
  propertyName: keyof EnvSpecificProperties
) => {
  const currentEnv = PROXY_ENV as keyof EnvConfigType;

  return currentEnv &&
    currentEnv in ENVIRONMENT_CONFIG_PROPERTIES &&
    propertyName in ENVIRONMENT_CONFIG_PROPERTIES[currentEnv]
    ? ENVIRONMENT_CONFIG_PROPERTIES[currentEnv][propertyName]
    : '';
};
