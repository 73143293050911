import { FC, useContext, useMemo, useState } from 'react';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import BBCheckbox from '../../atoms/BBCheckbox';
import BBButton from '../../atoms/BBButton';
import BBLink from '../../atoms/BBLink';
import {
  SectionContainer,
  ContentContainer,
  DescriptionWrapper,
  MainContainer,
  CheckboxContainer,
  ButtonContainer,
  StyledUl,
  StyledSpan,
} from './styles';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { SVGComponent } from '../../../core/types/IconOrgTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
// Constant
import {
  SECURITY_AND_FRAUD_LINK,
  CONTACT_US_FRAUD_LINK,
} from '../../../core/utils/Constants/Constants';

interface ProtectingFromFraudProps {
  setShowProtectingFromFraudSection: (value: boolean) => void;
  disabledSubmitButton: boolean;
  type: 'paymentRequest' | 'disbursement';
}

const ProtectingFromFraud: FC<ProtectingFromFraudProps> = (
  props: ProtectingFromFraudProps
) => {
  const { setShowProtectingFromFraudSection, disabledSubmitButton, type } =
    props;

  const [isChecked, setIsChecked] = useState(false);

  const { translate } = useTranslations();

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const buttonSize =
    layout === 'desktop' ? 'large' : layout === 'tablet' ? 'medium' : 'small';

  const FraudPreventionIcon: SVGComponent = useMemo(
    () => getIcon(tenant, 'fraudPrevention'),
    [tenant]
  );

  const getDescription = (textKey: string) => (
    <BBText variant="body2" type="normal" text={translate(textKey)} />
  );

  const getCombinedDescription1 = (textKey1: string, textKey2: string) => (
    <StyledSpan>
      <BBText variant="body2" type="normal" text={translate(textKey1)} />
      <BBLink href={SECURITY_AND_FRAUD_LINK} target="_new">
        <BBText variant="body2" type="normal" text={translate(textKey2)} />
      </BBLink>
    </StyledSpan>
  );

  const getCombinedDescription2 = (
    textKey1: string,
    textKey2: string,
    textKey3: string
  ) => (
    <StyledSpan>
      <BBText variant="body2" type="normal" text={translate(textKey1)} />
      <BBLink href={CONTACT_US_FRAUD_LINK}>
        <BBText variant="body2" type="normal" text={translate(textKey2)} />
      </BBLink>
      <BBText variant="body2" type="normal" text={translate(textKey3)} />
    </StyledSpan>
  );

  const paymentRequest: JSX.Element = (
    <>
      <StyledUl>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line2`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line3`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line4`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line5`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line6`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line7`
          )}
        </li>
      </StyledUl>
      <>
        {getCombinedDescription2(
          `newRecipient.protectingFromFraud.${type}.description.line8`,
          `newRecipient.protectingFromFraud.${type}.description.line9`,
          `newRecipient.protectingFromFraud.${type}.description.line10`
        )}

        {getDescription(
          `newRecipient.protectingFromFraud.${type}.description.line11`
        )}

        {getDescription(
          `newRecipient.protectingFromFraud.${type}.description.line12`
        )}

        {getCombinedDescription1(
          `newRecipient.protectingFromFraud.${type}.description.line13`,
          `newRecipient.protectingFromFraud.${type}.description.line14`
        )}
      </>
    </>
  );

  const disbursement: JSX.Element = (
    <>
      <StyledUl>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line2`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line3`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line4`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line5`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line6`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line7`
          )}
        </li>
        <li>
          {getDescription(
            `newRecipient.protectingFromFraud.${type}.description.line8`
          )}
        </li>
      </StyledUl>
      <>
        {getCombinedDescription2(
          `newRecipient.protectingFromFraud.${type}.description.line9`,
          `newRecipient.protectingFromFraud.${type}.description.line10`,
          `newRecipient.protectingFromFraud.${type}.description.line11`
        )}

        {getDescription(
          `newRecipient.protectingFromFraud.${type}.description.line12`
        )}

        {getDescription(
          `newRecipient.protectingFromFraud.${type}.description.line13`
        )}

        {getDescription(
          `newRecipient.protectingFromFraud.${type}.description.line14`
        )}

        {getCombinedDescription1(
          `newRecipient.protectingFromFraud.${type}.description.line15`,
          `newRecipient.protectingFromFraud.${type}.description.line16`
        )}
      </>
    </>
  );

  return (
    <MainContainer>
      <SectionContainer layout={layout}>
        <ContentContainer>
          <FraudPreventionIcon />
          <BBText
            variant="h3"
            type="bold"
            text={translate('newRecipient.protectingFromFraud.heading')}
          />
          <DescriptionWrapper>
            {getDescription(
              `newRecipient.protectingFromFraud.${type}.description.line1`
            )}
            {type === 'paymentRequest' && paymentRequest}
            {type === 'disbursement' && disbursement}
          </DescriptionWrapper>
          <CheckboxContainer>
            <BBCheckbox
              value={isChecked}
              label={translate(
                'newRecipient.protectingFromFraud.checkboxLabel'
              )}
              onChange={(_, checked) => setIsChecked(checked)}
            />
          </CheckboxContainer>
        </ContentContainer>
        <ButtonContainer layout={layout}>
          <BBButton
            btnType="secondary"
            size={buttonSize}
            type="submit"
            disabled={disabledSubmitButton || !isChecked}
          >
            {translate('steppers.continueBtn')}
          </BBButton>
          <BBButton
            size={buttonSize}
            btnType="outlined"
            type="button"
            onClick={() => setShowProtectingFromFraudSection(false)}
          >
            {translate('steppers.backBtn')}
          </BBButton>
        </ButtonContainer>
      </SectionContainer>
    </MainContainer>
  );
};

export default ProtectingFromFraud;
