import { FC } from 'react';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../../atoms/BBText';
import BBMenu, { BBMenuItem } from '../../organisms/BBMenu';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Utils
import { getBody2MediumStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
// Constants
import { USER_PROFILE_MENU } from '../../../core/utils/Constants/Constants';
import useLogout from '../../../core/hooks/useLogout';

interface BBUserProfileMenuProps {
  open: boolean;
  handleOnClose: () => void;
  anchorEl: null | HTMLElement;
  fullName: string;
  customerId: string;
}

const StyledBBMenuItem = styled(BBMenuItem)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
    dimensions: {
      spacing: { xxxSmall, xxSmall, large },
      radius: { small },
    },
  } = theme;

  return {
    '&.MuiMenuItem-root': {
      display: 'flex',
      height: large,
      padding: `${xxxSmall}px ${xxSmall}px`,
      alignItems: 'center',
      gap: xxSmall,
      alignSelf: 'stretch',
      borderRadius: small,
      flex: 1,
      ...getBody2MediumStyles(theme),
      overflow: 'hidden',
      color: text,
      textOverflow: 'ellipsis',
      '&:first-child': {
        marginTop: '12px',
      },
    },
  };
});

const UserProfileGreetings = styled('div')(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
    dimensions: {
      spacing: { xxxSmall },
      radius: { small },
    },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingBottom: '16px',
    gap: xxxSmall,
    alignSelf: 'stretch',
    borderRadius: small,
    borderBottom: `1px solid ${borderLighter}`,
  };
});

const UserName = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  return {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color: textWeak,
    flex: 1,
    margin: 'auto 0',
  };
});

const UserID = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeaker },
    },
  } = theme;

  return {
    color: textWeaker,
  };
});

const MenuWrapper = styled('div')(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxxSmall },
      radius: { small },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: xxxSmall,
    alignSelf: 'stretch',
    borderRadius: small,
  };
});

const BBUserProfileMenu: FC<BBUserProfileMenuProps> = (
  props: BBUserProfileMenuProps
) => {
  const { open, handleOnClose, anchorEl, fullName, customerId } = props;

  const { translate } = useTranslations();
  const { handleLogout } = useLogout();

  return (
    <BBMenu
      open={open}
      onClose={handleOnClose}
      anchorEl={anchorEl}
      data-testid="bb-user-profile-menu"
    >
      <UserProfileGreetings>
        <UserName variant="body1" type="bold" text={fullName} />
        <UserID variant="body2" type="normal" text={customerId} />
      </UserProfileGreetings>
      <MenuWrapper>
        {USER_PROFILE_MENU.map((menu, index) => (
          <StyledBBMenuItem
            key={`${menu.label}-${index}`}
            onClick={handleLogout}
          >
            {translate(menu.label)}
          </StyledBBMenuItem>
        ))}
      </MenuWrapper>
    </BBMenu>
  );
};

export default BBUserProfileMenu;
