import { useNavigate } from 'react-router-dom';

// Types
import { AccountPaymentRequest } from '../../../../core/types/AccountPaymentRequestTypes';
import { InvitationStatus } from '../../../../core/types/PaymentRequestsTypes';
// API Wrappers
import { useUpdateAccountPaymentRequestMutation } from '../../../../redux/api';

export const useAcceptInvitation = () => {
  const navigate = useNavigate();

  const [updatePaymentRequest, mutation] =
    useUpdateAccountPaymentRequestMutation();

  const acceptInvitation = async (paymentRequest: AccountPaymentRequest) => {
    try {
      await updatePaymentRequest({
        id: paymentRequest.id,
        invitationStatus: InvitationStatus.Accepted,
      }).unwrap();

      navigate(`/transactions/${paymentRequest.transaction?.id}`);
    } catch {}
  };

  return [acceptInvitation, mutation] as const;
};
