import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useResizeObserver } from 'usehooks-ts';

// Components - Atoms, Molecules, Organisms, Pages
import BBInput from '../../../atoms/BBInput';
import BBSelect from '../../../atoms/BBSelect';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import {
  CountryCodeControlContainer,
  FormControl,
  HorizontalFormControl,
} from './PartyForm.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

interface PartyFormProps extends LayoutProps {
  index: number;
}

const PartyForm = (props: PartyFormProps) => {
  const { index, layout } = props;
  const mobileNumberContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    watch,
    control,
    trigger,
    formState: { isSubmitting },
  } = useFormContext();
  const { translate } = useTranslations();

  const { width: mobileNumberContainerWidth } = useResizeObserver({
    ref: mobileNumberContainerRef,
  });

  const countryCode = watch(`parties.${index}.countryCode`);
  const [previousCountryCode, setPreviousCountryCode] = useState(countryCode);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  if (previousCountryCode !== countryCode) {
    trigger(`parties.${index}.mobileNumber`);
    setPreviousCountryCode(countryCode);
  }

  const isExistingParty = !!watch(`parties.${index}.id`);

  return (
    <>
      <FormControl>
        <ControllerElementWrapper
          name={`parties.${index}.fullName`}
          control={control}
          placeholderLabel={translate(
            'createTransaction.partyDetails.fields.fullName'
          )}
          component={BBInput}
          isDisabled={isSubmitting}
        />
      </FormControl>

      <FormControl>
        <ControllerElementWrapper
          name={`parties.${index}.email`}
          control={control}
          placeholderLabel={translate(
            'createTransaction.partyDetails.fields.email'
          )}
          component={BBInput}
          isDisabled={isSubmitting}
          readOnly={isExistingParty}
          type="email"
        />
      </FormControl>

      <HorizontalFormControl
        ownerState={{ layout }}
        ref={mobileNumberContainerRef}
      >
        <CountryCodeControlContainer ownerState={{ layout }}>
          <ControllerElementWrapper
            name={`parties.${index}.countryCode`}
            control={control}
            placeholderLabel={translate(
              'createTransaction.partyDetails.fields.countryCode'
            )}
            component={BBSelect}
            type="dialingCode"
            toShrinkLabel={true}
            shouldUseCustomFilter={true}
            isExtendedPopper={!isMobileLayout}
            popperWidth={!isMobileLayout && mobileNumberContainerWidth}
            showMaxOptions={!isMobileLayout}
            disabled={isSubmitting}
          />
        </CountryCodeControlContainer>
        <ControllerElementWrapper
          name={`parties.${index}.mobileNumber`}
          control={control}
          placeholderLabel={translate(
            'createTransaction.partyDetails.fields.mobileNumber'
          )}
          component={BBInput}
          isDisabled={isSubmitting}
        />
      </HorizontalFormControl>
    </>
  );
};

export default PartyForm;
