import { createContext, useContext } from 'react';

// Types
import { UserProfile } from '../../types/UserProfileTypes';

export const UserContext = createContext<UserProfile | null>(null);

export const useUser = () => {
  const value = useContext(UserContext);

  if (!value) {
    throw new Error('useUser must be used within UserProvider');
  }

  return value;
};
