import {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { ScrollRestoration } from 'react-router-dom';

// Contexts
import {
  ContentRefContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
// Hooks
import { useLayoutSize } from '../../../core/hooks';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';

export type ComponentChildrenType =
  | string
  | number
  | boolean
  | ReactElement<any, string | JSXElementConstructor<any>>
  | ReactFragment
  | ReactPortal
  | null
  | undefined;

interface BBLayoutProps {
  children: ComponentChildrenType;
}

const BBLayout: FC<BBLayoutProps> = (props: BBLayoutProps) => {
  const layoutSize: LayoutSize = useLayoutSize();

  const [currentLayout, setCurrentLayout] = useState<LayoutSize | ''>('');
  const [contentRefObj, setContentRefObj] =
    useState<RefObject<HTMLDivElement> | null>(null);

  useEffect(() => {
    if (layoutSize !== currentLayout) {
      setCurrentLayout(layoutSize);
    }
  }, [layoutSize]);

  const updateLayout = (updatedLayout: LayoutSize) => {
    if (currentLayout !== updatedLayout) {
      setCurrentLayout(updatedLayout);
    }
  };

  const updateRef = (ref: RefObject<HTMLDivElement>) => setContentRefObj(ref);

  return (
    <>
      <ScrollRestoration />
      {currentLayout ? (
        <LayoutContext.Provider
          value={{
            layout: currentLayout,
            updateLayout,
          }}
        >
          <ContentRefContext.Provider
            value={{
              ref: contentRefObj,
              updateRef,
            }}
          >
            {props.children}
          </ContentRefContext.Provider>
        </LayoutContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default BBLayout;
