//Types
import {
  ConfirmRequest,
  DeleteDisbursementRequest,
  Disbursement,
  DisbursementRequest,
  PaymentOptionTypes,
  VerifyWalletRequest,
  VerifyWalletResponse,
} from '../../core/types/DisbursementTypes';
//API wrappers
import { api } from './api';

const disbursementsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentType: builder.query<PaymentOptionTypes[], void>({
      query: () => `/propertypay-services/disbursements/paymentTypes`,
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10,
    }),

    getDisbursementById: builder.query<Disbursement, number>({
      query: (id) => `/propertypay-services/disbursements/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Disbursements', id }],
    }),

    upsertDisbursement: builder.mutation<Disbursement, DisbursementRequest>({
      query: (data) => ({
        url: `/propertypay-services/disbursements/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Disbursements', id: 'LIST' },
        ...(data.id ? [{ type: 'Disbursements' as const, id: data.id }] : []),
      ],
    }),

    verifyWallet: builder.query<VerifyWalletResponse, VerifyWalletRequest>({
      query: (data) => {
        const { searchEmail, tanNumber } = data;

        const params = new URLSearchParams({
          searchEmail,
          tanNumber,
        });

        return `/propertypay-services/disbursements/isMatchEmailAndTan?${params}`;
      },
      providesTags: (_result, error, data) => [
        { type: 'VerifyWallet', id: data.tanNumber },
      ],
    }),

    confirmDisbursement: builder.mutation<Disbursement, ConfirmRequest>({
      query: ({ disbursementId }) => ({
        url: `propertypay-services/disbursements/${disbursementId}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { disbursementId, transactionId }) => [
        { type: 'Disbursements', id: 'LIST' },
        ...(disbursementId && transactionId
          ? [
              { type: 'Disbursements' as const, id: disbursementId },
              {
                type: 'Disbursements' as const,
                id: `Transaction-${transactionId}`,
              },
            ]
          : []),
      ],
    }),

    deleteDisbursement: builder.mutation<null, DeleteDisbursementRequest>({
      query: (data) => ({
        url: `propertypay-services/disbursements/${data.disbursementId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Disbursements', id: `Transaction-${request.transactionId}` },
      ],
    }),

    disburseFunds: builder.mutation<Disbursement[], number>({
      query: (transactionId) => ({
        url: `propertypay-services/disbursements/${transactionId}/disburseFund`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, transactionId) => [
        {
          type: 'Disbursements',
          transactionId: `Transaction-${transactionId}`,
        },
      ],
    }),
  }),
});

export const {
  useGetPaymentTypeQuery,
  useGetDisbursementByIdQuery,
  useUpsertDisbursementMutation,
  useLazyVerifyWalletQuery,
  useConfirmDisbursementMutation,
  useDeleteDisbursementMutation,
  useDisburseFundsMutation,
} = disbursementsApi;
