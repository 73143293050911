import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

//Components - Atoms, Molecules, Organisms, Pages
import { StyledFormTitle } from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { PaymentRequestDetails } from '../../../../core/types/PaymentRequestsTypes';
// Utils
import { getPayeeNames } from './utils';
import {
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../core/utils/Format';

interface RequestDetailsSummaryDataProps {
  data: PaymentRequestDetails | undefined;
}

const RequestDetailsSummaryData: FC<RequestDetailsSummaryDataProps> = (
  props: RequestDetailsSummaryDataProps
) => {
  const { data } = props;
  const { translate } = useTranslations();

  const REQUEST_DETAILS = [
    {
      id: 0,
      label: translate(
        `paymentRequest.confirmation.${data?.crmAccount && data.crmAccount?.length > 1 ? 'multiplePayees' : 'singlePayee'}`
      ),
      value: getPayeeNames(data?.crmAccount ? data.crmAccount : []),
    },
    {
      id: 1,
      label: translate('paymentRequest.confirmation.paymentType'),
      value: getFormattedValue(
        data?.paymentType?.toLowerCase() === 'other'
          ? data.paymentTypeDescription
          : data?.paymentType
      ),
    },
    {
      id: 2,
      label: translate('paymentRequest.confirmation.paymentValue'),
      value: getFormattedCurrencyValue(data?.payAmount, data?.currency),
    },
    {
      id: 3,
      label: translate('paymentRequest.confirmation.dueDate'),
      value: getFormattedDate(data?.dueDate),
    },
  ];

  return (
    <>
      <StyledFormTitle
        variant="body1"
        type="medium"
        text={translate('paymentRequest.confirmation.requestTitle')}
      />
      <List dense={true}>
        {REQUEST_DETAILS.map((item) => {
          return (
            <ListItem key={item.id}>
              <ListItemText primary={item.label} secondary={item.value} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default RequestDetailsSummaryData;
