import { FC, useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import Accordion, { AccordionSummary } from '../../../../organisms/Accordion';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
// Types
import { SVGComponent } from '../../../../../core/types/IconOrgTypes';
import { AccordionDefaultProps } from '../../../../../core/types/AccordionTypes';
import { AccountType } from '../../../../../core/types/RecipientTypes';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';

interface CDWalletAccordionProps extends AccordionDefaultProps<AccountType> {}

const CDWalletAccordion: FC<CDWalletAccordionProps> = (
  props: CDWalletAccordionProps
) => {
  const { expanded, handleChange } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const [WalletIcon]: SVGComponent[] = useMemo(
    () => [getIcon(tenant, 'wallet')],
    [tenant]
  );

  const accountType = 'wallet';

  return (
    <Accordion
      expanded={expanded === accountType}
      onChange={handleChange(accountType)}
    >
      <AccordionSummary
        aria-controls="wallet-content"
        id="wallet-header"
        isExpandable={false}
        icon={<WalletIcon />}
        title={translate('paymentRequest.accountToBeFunded.cdWallet.title')}
        subtitle={translate(
          'paymentRequest.accountToBeFunded.cdWallet.subTitle'
        )}
      />
    </Accordion>
  );
};

export default CDWalletAccordion;
