import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Accordion } from '@mui/material';

const StyledAccordion = styled(Accordion)(({ theme }) => {
  const {
    dimensions: { radius },
    colours: { borders, text, backgrounds },
  } = theme;

  return {
    borderRadius: radius.small,
    ':first-of-type': {
      borderTopLeftRadius: radius.small,
      borderTopRightRadius: radius.small,
    },
    border: `1px solid ${borders.border}`,
    boxShadow: 'none',
    color: text.text,
    backgroundColor: backgrounds.bgAlt,
    width: '100%',

    '&::before': {
      backgroundColor: 'transparent',
    },

    '&.Mui-expanded': {
      margin: 0,
      border: `2px solid ${backgrounds.active}`,
    },
  };
});

export default memo(StyledAccordion);
