import { useContext, FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useNavigate, useParams } from 'react-router-dom';

//Context
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBStepPanel from '../../../organisms/BBStepPanel/BBStepPanel';
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
import TransactionForm from './TransactionForm';
import PageLoader from '../../../organisms/PageLoader';
import NotFoundRedirect from '../../../organisms/NotFoundRedirect';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { Transaction } from '../../../../core/types/TransactionTypes';
// API Wrappers
import { useGetTransactionQuery } from '../../../../redux/api';
// Utils
import { isNotFoundError } from '../../../../core/utils/Errors';

type RouterParams = {
  id: string;
};

const TransactionDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouterParams>();

  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const { data, isLoading, error } = useGetTransactionQuery(
    id ? Number(id) : skipToken
  );

  const activeStep: number = 0;

  const handleOnContinueClick = (id: Transaction['id']) => {
    navigate(`/create-transactions/party-details/${id}`);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={translate('createTransaction.transactionDetails.title')}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <TransactionForm
          activeStep={activeStep}
          onContinue={handleOnContinueClick}
          transaction={data}
        />
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'create-transaction'}
          idValues={{
            transactionId: id,
          }}
        />
      </MainContainer>
    </>
  );
};

export default TransactionDetails;
