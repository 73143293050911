import { FC, useContext, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import DisburseFundsConfirmation from './CompletionPaymentsModal/DisburseFundsConfirmation';
import DisburseFundsInProgress from './CompletionPaymentsModal/DisburseFundsInProgress';
import SomethingWentWrongModal from './CompletionPaymentsModal/SomethingWentWrongModal';
import {
  DataContainer,
  MainContainer,
  StyledBBButton,
} from './CommonStyles.styles';
import { WeakText } from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// RTK Slice
import { errorStateReset } from '../../../../redux/modules/errorDetailsSlice';
// API Wrappers
import { useGetAvailableBalanceQuery } from '../../../../redux/api';
import { useDisburseFundsMutation } from '../../../../redux/api/disbursements';
// Utils
import { getIcon } from '../../../../core/utils/IconOrgData';
import { areAllFundsAvailable } from './utils';

interface SendDisbursementProps {
  transactionId: string | undefined;
  totalAmount: string;
  userNames: string;
  hasRecordsInReadyState: boolean;
}

type ModalState = {
  disburseFundsConfirmation: boolean;
  disbursingFundInProgress: boolean;
  somethingWentWrong: boolean;
};

const SendDisbursement: FC<SendDisbursementProps> = (
  props: SendDisbursementProps
) => {
  const { transactionId, totalAmount, userNames, hasRecordsInReadyState } =
    props;

  const dispatch = useDispatch();

  const { data, isFetching } = useGetAvailableBalanceQuery(
    transactionId ? Number(transactionId) : skipToken
  );

  const [disburseFunds] = useDisburseFundsMutation();

  const [modals, setModals] = useState<ModalState>({
    disburseFundsConfirmation: false,
    disbursingFundInProgress: false,
    somethingWentWrong: false,
  });

  const { translate } = useTranslations();
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  //Icons
  const [BespokeMoneyIcon] = useMemo(
    () => [getIcon(tenant, 'bespokeMoney')],
    [tenant]
  );

  const baseTranslationKey =
    'transactionDetails.completionPayments.sendDisbursement';

  const showModal = (modalName: keyof ModalState) =>
    setModals((prev) => ({
      ...prev,
      [modalName]: true,
    }));

  const hideModal = (modalName: keyof ModalState) =>
    setModals((prev) => ({
      ...prev,
      [modalName]: false,
    }));

  const handleOnDisburseFunds = async () => {
    setModals((prev) => ({
      ...prev,
      disburseFundsConfirmation: false,
      disbursingFundInProgress: true,
    }));
    try {
      await disburseFunds(Number(transactionId)).unwrap();
      hideModal('disbursingFundInProgress');
    } catch {
      // This will hide the default snackbar that will be triggered by default when an error occurs
      dispatch(errorStateReset());
      setModals((prev) => ({
        ...prev,
        disbursingFundInProgress: false,
        somethingWentWrong: true,
      }));
    }
  };

  const isSendButtonDisabled =
    isFetching ||
    areAllFundsAvailable(data?.confirmWalletBalancesList) ||
    !hasRecordsInReadyState;

  return (
    <MainContainer>
      <DataContainer>
        <BBText
          variant="subtitle1"
          type="bold"
          text={translate(`${baseTranslationKey}.title`)}
        />
        <WeakText
          variant="subtitle1"
          type="normal"
          text={translate(`${baseTranslationKey}.note`)}
        />
      </DataContainer>
      <StyledBBButton
        layout={layout}
        btnType="outlined"
        size="small"
        startIcon={<BespokeMoneyIcon />}
        onClick={() => showModal('disburseFundsConfirmation')}
        disabled={isSendButtonDisabled}
        data-testid="disburse-funds"
      >
        {translate(`${baseTranslationKey}.disburseBtn`)}
      </StyledBBButton>
      <DisburseFundsConfirmation
        isOpen={modals.disburseFundsConfirmation}
        onMaybeLater={() => hideModal('disburseFundsConfirmation')}
        onDisburseFunds={handleOnDisburseFunds}
        totalAmount={totalAmount}
        userNames={userNames}
      />
      <DisburseFundsInProgress isOpen={modals.disbursingFundInProgress} />
      <SomethingWentWrongModal
        isOpen={modals.somethingWentWrong}
        onContinue={() => hideModal('somethingWentWrong')}
      />
    </MainContainer>
  );
};

export default SendDisbursement;
