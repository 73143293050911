import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import { useContext, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../atoms/BBButton';
import {
  ActionButtonsContainer,
  FieldSets,
  FormContainer,
  Section,
  StyledFormTitle,
} from '../../CommonStyles/CommonStyles.styles';
import { AddPartyLink, SectionHeader } from './PartiesForm.styles';
import PartyForm from './PartyForm';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
import RemovePartyConfirmationModal from '../RemovePartyConfirmationModal';
import { RemoveButton } from '../styles';
import TPARequest from './TPARequest';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
import { useNotifications } from '../../../../core/hooks/useNotifications';
// Types
import { FormValues, IndexedErrors, IndexedParty, Party } from './types';
import { Transaction } from '../../../../core/types/TransactionTypes';
// API Wrappers
import {
  useDeleteAccountMutation,
  useUpdateAccountsMutation,
  useUpdateThreeFaMutation,
} from '../../../../redux/api';
// Utils
import { getIcon } from '../../../../core/utils/IconOrgData';
import {
  areResultsSuccessful,
  getPartiesToUpdate,
  getPartyUpdatesFromAccountUpdates,
  getUpdateResultsFromMutation,
  mapAccountUpdateRequestToParty,
  mapFormValuesToThreeFaUpdateRequest,
  mapPartyToAccountUpdateRequest,
} from './utils';
import { isFieldsValidationError } from '../../../../core/utils/Errors';

const defaultParty: Party = {
  fullName: '',
  email: '',
  countryCode: '',
  mobileNumber: '',
};

interface PartiesFormProps {
  transactionId: number;
  activeStep: number;
  transaction?: Transaction;
  onBack: () => void;
  onContinue: () => void;
}

const PartiesForm = (props: PartiesFormProps) => {
  const { activeStep, onBack, transaction, transactionId, onContinue } = props;

  const accounts = transaction?.crmAccounts ?? [];

  const previousParties = useRef(accounts.map(mapAccountUpdateRequestToParty));

  const [
    isRemoveConfirmationModalDisplayed,
    setIsRemoveConfirmationModalDisplayed,
  ] = useState(false);
  const [partyIndexToRemove, setPartyIndexToRemove] = useState<
    number | undefined
  >();

  const defaultParties = previousParties.current.length
    ? previousParties.current
    : [defaultParty];

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const { translate } = useTranslations();
  const { validations } = useValidations();
  const [updateAccounts] = useUpdateAccountsMutation();
  const [updateThreeFa] = useUpdateThreeFaMutation();
  const [deleteAccount, { isLoading: isDeletingAccount }] =
    useDeleteAccountMutation();
  const { showError } = useNotifications();

  const form = useForm<FormValues>({
    defaultValues: {
      parties: defaultParties,
      threeFa: transaction?.threeFa ?? null,
    },
    resolver: yupResolver(validations),
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { isValid, isSubmitting, isDirty },
    setValue,
  } = form;

  const { fields, append, replace } = useFieldArray({
    control,
    name: 'parties',
  });

  const canContinue: boolean = isValid && !isSubmitting;
  const canRemove: boolean = fields.length > 1;

  const mergeFieldsWithUpdates = (updated: IndexedParty[]) => {
    updated.forEach((update) => {
      setValue(`parties.${update.index}`, update.party);
    });
  };

  const applyResponseValidationErrors = (failed: IndexedErrors[]) => {
    failed.forEach((failure) => {
      failure.errors.forEach((error) => {
        setError(`parties.${failure.index}.${error.field}`, {
          type: 'custom',
          message: error.message,
        });
      });
    });
  };

  const onSubmit = async (data: FormValues) => {
    const partiesToUpdate = getPartiesToUpdate(
      data.parties,
      previousParties.current
    );
    const accountUpdates = partiesToUpdate.map(({ party }) => ({
      ...mapPartyToAccountUpdateRequest(party),
      transactionId,
    }));

    const results = await getUpdateResultsFromMutation(
      updateAccounts(accountUpdates).unwrap()
    );

    if (areResultsSuccessful(results) && transaction) {
      const threeFaUpdateRequest = mapFormValuesToThreeFaUpdateRequest(
        transaction,
        data.threeFa
      );
      try {
        await updateThreeFa(threeFaUpdateRequest).unwrap();
        onContinue();
        return;
      } catch (error) {
        if (!isFieldsValidationError(error)) {
          return;
        }
        showError(error.data?.[0]?.errorMessage);
      }
    }

    const { updated, failed } = getPartyUpdatesFromAccountUpdates(
      results,
      partiesToUpdate
    );

    mergeFieldsWithUpdates(updated);
    applyResponseValidationErrors(failed);

    previousParties.current = cloneDeep(getValues('parties'));
  };

  const onRemovePartyConfirmationContinue = async () => {
    onRemovePartyConfirmationClose();

    if (isUndefined(partyIndexToRemove)) {
      return;
    }

    const party = getValues(`parties.${partyIndexToRemove}`);

    if (party?.id) {
      await deleteAccount({ accountId: party.id, transactionId }).unwrap();
    }

    // NOTE: Unfortunately there seems to be an issue in react-hook-form at the moment
    // where when field arrays are used in combination with controllers, the remove
    // function doesn't always remove the requested field after an asynchronous operation
    // that re-renders component during the execution. For now, filtering
    // the initial list of fields manually and then replacing fields is used as
    // a hack around.
    // A somewhat similar issue is being talked about here https://github.com/orgs/react-hook-form/discussions/11609
    const partiesWithRemovedIndex = getValues('parties').filter(
      (_, i) => i !== partyIndexToRemove
    );

    replace(partiesWithRemovedIndex);
  };

  const onRemove = (index: number) => {
    setIsRemoveConfirmationModalDisplayed(true);
    setPartyIndexToRemove(index);
  };

  const onRemovePartyConfirmationClose = () => {
    setIsRemoveConfirmationModalDisplayed(false);
    setPartyIndexToRemove(undefined);
  };

  const AddIcon = useMemo(() => getIcon(tenant, 'add'), [tenant]);

  return (
    <>
      <FormProvider {...form}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          <FieldSets layout={layout}>
            {fields.map((field, index) => (
              <Section layout={layout} key={field.id}>
                <SectionHeader>
                  <StyledFormTitle
                    variant="body1"
                    type="medium"
                    text={translate(
                      'createTransaction.partyDetails.sectionTitle',
                      {
                        number: index + 1,
                      }
                    )}
                  />

                  {canRemove && (
                    <RemoveButton
                      onClick={() => onRemove(index)}
                      disabled={isSubmitting || isDeletingAccount}
                      type="button"
                    >
                      {translate(
                        'createTransaction.partyDetails.removeButtonText'
                      )}
                    </RemoveButton>
                  )}
                </SectionHeader>

                <PartyForm layout={layout} index={index} />
              </Section>
            ))}

            <AddPartyLink
              onClick={(event) => {
                event.preventDefault();
                append(defaultParty);
              }}
              href="#"
            >
              <AddIcon />
              {translate('createTransaction.partyDetails.addButtonText')}
            </AddPartyLink>
          </FieldSets>
          <TPARequest control={control} />
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              size="medium"
              btnType="outlined"
              type="button"
              onClick={onBack}
            >
              {translate('steppers.backBtn')}
            </BBButton>
            <BBButton
              btnType="secondary"
              size="medium"
              type="submit"
              disabled={!canContinue}
            >
              {translate('steppers.continueBtn')}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
      </FormProvider>

      {isDirty && !isSubmitting && <NavigationBlocker />}

      {isRemoveConfirmationModalDisplayed && (
        <RemovePartyConfirmationModal
          onCancel={onRemovePartyConfirmationClose}
          onContinue={onRemovePartyConfirmationContinue}
        />
      )}
    </>
  );
};

export default PartiesForm;
