import dayjs from 'dayjs';

const DISABLE_CONFIRMATION_BEFORE_EXPIRY_TIME_IN_SECONDS = 4;

export const getTimeToDisableConfirmation = (time?: string) => {
  if (!time) {
    return;
  }

  return dayjs(time)
    .subtract(DISABLE_CONFIRMATION_BEFORE_EXPIRY_TIME_IN_SECONDS, 'seconds')
    .toISOString();
};
