import { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { InputBase } from '@mui/material';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface FullScreenAutoCompleteInputProps {
  handleClose: () => void;
  handleCloseIconClick: () => void;
  handleInput: (value: string) => void;
  placeholder: string;
}

const StyledInput = styled(InputBase)(({ theme }) => {
  const {
    colours: {
      borders: { input, focus },
      backgrounds: { bgAlt },
      text: { label, input: textInput },
    },
    dimensions: { spacing },
  } = theme;

  return {
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: bgAlt,
    borderBottom: `1px solid ${input}`,
    padding: `0 ${spacing.small}px 0 ${spacing.xSmall}px`,
    '&.Mui-focused': {
      borderBottomColor: focus,
    },
    '& input': {
      backgroundColor: bgAlt,
      padding: `${spacing.small}px 0 ${spacing.small}px ${spacing.xSmall}px`,
      ...getBody2NormalStyles(theme),
      color: textInput,
      '&:: placeholder': {
        color: label,
      },
    },
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { label },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '24px',
    '> svg': {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      ' > path': {
        fill: label,
      },
    },
  };
});

const FullScreenAutoCompleteInput: FC<FullScreenAutoCompleteInputProps> = (
  props: FullScreenAutoCompleteInputProps
) => {
  const { handleClose, handleCloseIconClick, handleInput, placeholder } = props;

  const [inputValue, setInputValue] = useState('');

  const { tenant } = useContext(TenantContext);
  const LeftArrowIcon = useMemo(
    () => getIcon(tenant, 'navigationChevronLeftIcon'),
    [tenant]
  );
  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const handleClick = () => {
    if (handleCloseIconClick) {
      setInputValue('');
      handleCloseIconClick();
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentInputValue: string = event.target.value;
    handleInput(currentInputValue);
    setInputValue(currentInputValue);
  };

  return (
    <StyledInput
      value={inputValue}
      data-testid="bb-full-screen-autocomplete-input"
      placeholder={placeholder}
      startAdornment={
        <IconContainer>
          <LeftArrowIcon
            onClick={handleClose}
            data-testid="bb-full-screen-autocomplete-input-back-icon"
          />
        </IconContainer>
      }
      endAdornment={
        <IconContainer>
          <CloseIcon
            onClick={handleClick}
            data-testid="bb-full-screen-autocomplete-input-close-icon"
          />
        </IconContainer>
      }
      onChange={handleOnChange}
    />
  );
};

export default FullScreenAutoCompleteInput;
