import { ComponentProps, memo, useContext } from 'react';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../atoms/BBButton';

const getButtonSize = (layout: LayoutSize): ResponsiveButtonProps['size'] => {
  if (isMobileLayoutApplicable(layout)) {
    return 'small';
  }

  if (layout === 'tablet') {
    return 'medium';
  }

  return 'large';
};

interface ResponsiveButtonProps extends ComponentProps<typeof BBButton> {}

const ResponsiveButton = (props: ResponsiveButtonProps) => {
  const { layout } = useContext(LayoutContext);

  return <BBButton {...props} size={getButtonSize(layout)} />;
};

export default memo(ResponsiveButton);
