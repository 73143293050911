import { useLazyLogoutQuery } from '../../redux/api/auth';
import { clearTokens } from '../utils/AuthUtils';

export default function useLogout() {
  const [logout] = useLazyLogoutQuery();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      clearTokens();
    } catch {}
  };

  return { handleLogout };
}
