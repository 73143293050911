import { FC, useState, useEffect } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import ConfirmationScreen from './ConfirmationScreen';
import CompletedScreen from './CompletedScreen';

export type ConfirmationViewType = 'confirmation-screen' | 'completed-screen';

const Confirmation: FC = () => {
  const [viewType, setViewType] = useState<ConfirmationViewType>(
    'confirmation-screen'
  );

  let currentView: JSX.Element;

  useEffect(() => {
    if (viewType !== 'confirmation-screen') {
      setViewType('confirmation-screen');
    }
  }, []);

  switch (viewType) {
    case 'confirmation-screen':
      currentView = <ConfirmationScreen setViewType={setViewType} />;
      break;
    case 'completed-screen':
      currentView = <CompletedScreen />;
      break;
    default:
      currentView = <ConfirmationScreen setViewType={setViewType} />;
  }

  return <>{currentView}</>;
};

export default Confirmation;
