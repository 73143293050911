import React, { FC, useEffect, useState } from 'react';
import { Slide, SlideProps, Snackbar, SnackbarProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import BBNotification from '../BBNotification';

interface CustomSliderProps extends SlideProps {}

interface CustomSnackbarProps extends SlideProps {}

const CustomSlider = styled(Slide)<CustomSliderProps>(({ theme }) => {
  return {
    position: 'relative',
    width: '100%'
  };
});

const CustomSnackbar = styled(Snackbar)<CustomSnackbarProps>(() => {
  const snackBarWidth: number = 610;

  return {
    top: '90px !important',
    width: snackBarWidth,
    right: 'auto !important',
    left: `calc((100vw - ${snackBarWidth}px ) / 2) !important`,
    transform: 'none !important',
    '@media (max-width: 550px)': {
      width: 'auto !important',
      left: '8px !important',
      right: '8px !important'
    }
  };
});

interface BBSnackbarProps extends SnackbarProps {
  hasError: boolean;
  message: string;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const BBSnackbar: FC<BBSnackbarProps> = (props: BBSnackbarProps) => {
  const { hasError, message } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [state] = useState<{
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    Transition: SlideTransition
  });

  useEffect(() => {
    setOpen(hasError);
  }, [hasError]);

  useEffect(() => {
    hasError && setErrorMessage(message);
  }, [hasError, message, setErrorMessage]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      key={state.Transition.name}
    >
      <CustomSlider
        direction={'down'}
        timeout={{
          enter: 1000,
          exit: 1000
        }}
        easing={{
          enter: 'ease-in',
          exit: 'ease-out'
        }}
        in={open}
        mountOnEnter
      >
        <div data-testid="error-text">
          <BBNotification variant="error" icon={true} text={errorMessage} />
        </div>
      </CustomSlider>
    </CustomSnackbar>
  );
};

export default BBSnackbar;
