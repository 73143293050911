import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
//Components
import BBHeader from './BBHeader';
//Hooks
import { useTranslations } from '../../../core/hooks';
//API wrapper
import { useGetProfileQuery } from '../../../redux/api';
//Utils
import {
  getGreetingMessage,
  month,
  day,
  weekday,
  capitalize,
  getNameInitials,
  getFormattedFullName,
  getOrdinal,
} from '../../../core/utils/GetGreetingMessage';
import { DEFAULT_FORMAT_VALUE } from '../../../core/utils/Constants/Constants';

interface BBHeaderContainerProps {
  pageHeaderType?: string;
}

const getValidData = (value: string | undefined | null) => {
  if (!value) {
    return DEFAULT_FORMAT_VALUE;
  }
  return value;
};

export default function BBHeaderContainer(props: BBHeaderContainerProps) {
  const { pageHeaderType } = props;

  const [currentHour, setCurrentHour] = useState<number>(dayjs().hour());

  const { translate } = useTranslations();

  const { data } = useGetProfileQuery();

  const morningGreetingText: string = translate('header.morningLabel');
  const afternoonGreetingText: string = translate('header.afternoonLabel');
  const eveningGreetingText: string = translate('header.eveningLabel');
  const nightGreetingText: string = translate('header.nightLabel');
  const dateText: string = translate('header.dateLabel', {
    weekday: weekday,
    day: day,
    ordinal: getOrdinal(parseInt(day)),
    month: month,
  });
  const firstName = getValidData(data?.firstName);
  const middleName = getValidData(data?.middleName);
  const lastName = getValidData(data?.lastName);

  const IDText: string = translate('header.idLabel', {
    customerId: data?.titanCustomerNo,
  });

  // To check the latest current hours
  useEffect(() => {
    const timeoutId = setInterval(() => {
      setCurrentHour(dayjs().hour());
    }, 6000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <BBHeader
      firstName={capitalize(firstName)}
      fullName={getFormattedFullName(firstName, middleName, lastName)}
      initials={getNameInitials(firstName, lastName)}
      dateMessage={dateText}
      greetingMessage={getGreetingMessage(
        morningGreetingText,
        afternoonGreetingText,
        eveningGreetingText,
        nightGreetingText,
        currentHour
      )}
      customerId={IDText}
      pageHeaderType={pageHeaderType}
    />
  );
}
