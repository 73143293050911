import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

// Components - Atoms, Molecules, Organisms, Pages
import ConfirmationScreen from './ConfirmationScreen';
import CompletedScreen from './CompletedScreen';
import Page from '../../../../organisms/Page';
// Hooks
import { useInitialLoading } from '../../../../../core/hooks';
import { usePaymentInitialQuote } from './ConfirmationScreen/usePaymentInitialQuote';
// API Wrappers
import {
  useGetPaymentQuery,
  useGetPaymentRequestByIdQuery,
  useGetTransactionQuery,
} from '../../../../../redux/api';
// Utils
import { parseNumber } from '../../../../../core/utils/Parse';

export type ConfirmationViewType = 'confirmation-screen' | 'completed-screen';

type RouterParams = {
  paymentId: string;
};

const Confirmation: FC = () => {
  const { paymentId } = useParams<RouterParams>();
  const [viewType, setViewType] = useState<ConfirmationViewType>(
    'confirmation-screen'
  );

  const paymentQuery = useGetPaymentQuery(parseNumber(paymentId) ?? skipToken);

  const { isInitiallyLoading } = useInitialLoading(paymentQuery);

  const { data: payment, error } = paymentQuery;

  const { data: paymentRequest, isLoading: isLoadingPaymentRequest } =
    useGetPaymentRequestByIdQuery(payment?.paymentRequest?.id ?? skipToken);
  const { data: transaction, isLoading: isLoadingTransaction } =
    useGetTransactionQuery(paymentRequest?.transactionId ?? skipToken);

  const { quote, isLoading: isLoadingQuote } = usePaymentInitialQuote(
    payment?.token,
    paymentRequest?.transactionId
  );

  const isLoading =
    isInitiallyLoading ||
    isLoadingPaymentRequest ||
    isLoadingTransaction ||
    isLoadingQuote;

  return (
    <Page isLoading={isLoading} error={error}>
      {payment && transaction && (
        <>
          {viewType === 'confirmation-screen' && (
            <ConfirmationScreen
              setViewType={setViewType}
              payment={payment}
              transaction={transaction}
              quote={quote}
            />
          )}

          {viewType === 'completed-screen' && (
            <CompletedScreen payment={payment} />
          )}
        </>
      )}
    </Page>
  );
};

export default Confirmation;
