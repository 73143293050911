import { useContext, useMemo } from 'react';
//Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
//Components
import { LogoutIcon } from '../../../core/TenantProvider/tenants/whitelabel/commonIcons';
//Hooks
import useLogout from '../../../core/hooks/useLogout';
//Utils
import { getMenuItems } from '../../../core/utils/GetMenuItems';
import { getIcon } from '../../../core/utils/IconOrgData';
//Constants
import { MenuIdToLabelMapper } from '../../../core/utils/Constants/Constants';

export default function useMenus() {
  const { tenant } = useContext(TenantContext);
  const menuItems = getMenuItems();
  const { handleLogout } = useLogout();

  //Icons
  const [DashboardIcon, ContactIcon, SettingIcon] = useMemo(
    () => [
      getIcon(tenant, 'home'),
      getIcon(tenant, 'email'),
      getIcon(tenant, 'setting'),
    ],
    [tenant]
  );

  const getMenusWithHandlers = (menuItemId: number) => {
    const { DASHBOARD, CONTACT, SETTINGS, LOGOUT } = MenuIdToLabelMapper;
    const menuItemProps = {
      [DASHBOARD]: {
        icon: DashboardIcon,
      },
      [CONTACT]: { icon: ContactIcon },
      [SETTINGS]: { icon: SettingIcon },
      [LOGOUT]: { icon: LogoutIcon, handleClick: handleLogout },
    };
    return menuItemProps[menuItemId];
  };

  const menuItemsWithHandlers = menuItems.map((item) => {
    return {
      ...item,
      ...getMenusWithHandlers(item.id),
    };
  });

  return menuItemsWithHandlers;
}
