// Types
import { AccountToBeFundedDetails } from '../../../../../core/types/PaymentRequestsTypes';
// Utils
import {
  getBankAccountCountry,
  getBankAccountName,
} from '../../../../../core/utils/BankAccount';
import { getFormattedValue } from '../../../../../core/utils/Format';

const ACCOUNT_NUMBER_MASK_LENGTH = 4;

export const getRecipientDescription = (account: AccountToBeFundedDetails) => {
  const { bankAccount, paymentMethod } = account;

  if (paymentMethod === 'Wallet' || !bankAccount) {
    return;
  }

  const name = getBankAccountName(bankAccount);
  const country = getBankAccountCountry(bankAccount);
  const accountNumber = getFormattedValue(
    bankAccount.valueOne?.slice(-ACCOUNT_NUMBER_MASK_LENGTH)
  );

  return `${name} - ${country} | **** ${accountNumber}`;
};
