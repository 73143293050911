import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../BBText';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface BBValidationErrorMessageProps {
  message: string;
}

const Container = styled('div')(({ theme }) => {
  const {
    colours: {
      text: { error: textError },
      icon: { error: iconError },
    },
    dimensions: { spacing },
  } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing.xxSmall,
    gap: spacing.xxxSmall,
    color: iconError,
    '> svg': {
      width: 16,
      height: 16,
    },
    '> h6': {
      color: textError,
    },
  };
});

const BBValidationErrorMessage: FC<BBValidationErrorMessageProps> = ({
  message,
}) => {
  const { tenant } = useContext(TenantContext);

  const ErrorIcon = useMemo(() => getIcon(tenant, 'statusWarning'), [tenant]);

  return (
    <Container data-testid="bb-error-message-container">
      <ErrorIcon />
      <BBText variant="subtitle2" type="normal" text={message} />
    </Container>
  );
};

export default BBValidationErrorMessage;
