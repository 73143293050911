import { ReactNode } from 'react';

// Types
import { RoleType } from '../../../core/types/AccountTypes';

import { useUser } from '../../../core/providers/UserProvider';

interface RequireAccessProps {
  roles: RoleType[];
  children: ReactNode;
}

const RequireAccess = (props: RequireAccessProps) => {
  const { roles, children } = props;
  const { crmAccount } = useUser();

  const isMatchingRoles =
    crmAccount?.roles?.some((role) => roles.includes(role.name)) ?? false;

  if (!isMatchingRoles) {
    return null;
  }

  return <>{children}</>;
};

export default RequireAccess;
