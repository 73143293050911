import * as yup from 'yup';

// Hooks
import {
  useErrorTranslations,
  useTranslations,
} from '../../../../../core/hooks';
// Types
import {
  Payment,
  PaymentMethodType,
} from '../../../../../core/types/PaymentTypes';
import { Wallet } from '../../../../../core/types/WalletTypes';
import { getFormattedCurrencyValue } from '../../../../../core/utils/Format';
// Utils
import { parseNumber } from '../../../../../core/utils/Parse';

interface UseValidationsProps {
  wallet?: Wallet;
  payment: Payment;
}

export const useValidations = (props: UseValidationsProps) => {
  const { payment, wallet } = props;
  const { paymentRequest } = payment;

  const { translate } = useTranslations();
  const { translateError } = useErrorTranslations(
    'buyer.createPayment.paymentDetails.fields'
  );

  const balance =
    parseNumber(wallet?.availableBalance) ?? Number.MAX_SAFE_INTEGER;
  const leftToPay = paymentRequest?.leftToPay ?? 0;
  const isWalletPayment = payment.paymentMethod === PaymentMethodType.Wallet;

  const leftToPayMaximumError = translate(
    'buyer.createPayment.paymentDetails.errors.leftToPayMaximum',
    { amount: getFormattedCurrencyValue(leftToPay, paymentRequest?.currency) }
  );
  const insufficientFundsError = translate(
    'buyer.createPayment.paymentDetails.errors.insufficientFunds',
    { balance: getFormattedCurrencyValue(balance, wallet?.walletCurrency) }
  );

  const validations = yup.object({
    sendAmount: yup
      .number()
      .typeError(translateError('amount', 'invalid'))
      .positive(translateError('amount', 'invalid'))
      .required(translateError('amount', 'required'))
      .when(
        ['sendCurrency', 'recipientCurrency'],
        ([sendCurrency, recipientCurrency], schema) => {
          const isSameCurrency = sendCurrency === recipientCurrency;

          if (!isWalletPayment) {
            return schema;
          }

          if (isSameCurrency && leftToPay < balance) {
            return schema.max(leftToPay, leftToPayMaximumError);
          }

          return schema.max(balance, insufficientFundsError);
        }
      ),
    sendCurrency: yup
      .string()
      .trim()
      .required(translateError('currency', 'required')),
    recipientAmount: yup
      .number()
      .typeError(translateError('amount', 'invalid'))
      .positive(translateError('amount', 'invalid'))
      .max(leftToPay, leftToPayMaximumError)
      .required(translateError('amount', 'required')),
    recipientCurrency: yup
      .string()
      .trim()
      .required(translateError('currency', 'required')),
  });

  return { validations };
};
