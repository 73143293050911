import isUndefined from 'lodash/isUndefined';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Types
import { FieldDetail } from '../../types/addressLookupTypes';
import { Address } from '../../types/AddressTypes';
import { CountryFieldMapping } from '../AddressLookup/CountryFieldsMatrix';
import { Account } from '../../types/AccountTypes';
// Constants
import { DEFAULT_FORMAT_VALUE } from '../Constants/Constants';

dayjs.extend(utc);

export const getFormattedValue = (value?: string) => {
  return value || DEFAULT_FORMAT_VALUE;
};

export const getFormattedNumericValue = (value?: number) => {
  return isNumber(value) && !Number.isNaN(value)
    ? String(value)
    : DEFAULT_FORMAT_VALUE;
};

export const getFormattedDate = (value?: string) => {
  return value ? new Date(value).toLocaleDateString() : DEFAULT_FORMAT_VALUE;
};

export const getFormattedCurrencyValue = (
  value?: number,
  currency?: string
) => {
  if (!currency || isUndefined(value)) {
    return DEFAULT_FORMAT_VALUE;
  }

  return `${getFormattedAmount(value, currency)} ${currency}`;
};

export const getFormattedAmount = (value?: number, currency?: string) => {
  if (!currency || isUndefined(value)) {
    return DEFAULT_FORMAT_VALUE;
  }

  const options = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  }).resolvedOptions();

  const formattedValue = new Intl.NumberFormat(undefined, {
    ...options,
    style: 'decimal',
  }).format(value);

  return formattedValue;
};

export const getFormattedAddress = (address?: Address) => {
  if (!address) {
    return DEFAULT_FORMAT_VALUE;
  }

  const { countryCode } = address;
  const fieldsToDisplayMapping =
    CountryFieldMapping[countryCode] ?? CountryFieldMapping.default;

  return (
    Object.entries(fieldsToDisplayMapping) as [keyof Address, FieldDetail][]
  )
    .filter(([key, value]) => value.visible && address[key])
    .map(([key]) => address[key])
    .join(', ');
};

export const getFormattedNamesFromCrmAccounts = (accounts: Account[] = []) => {
  const partyNames = sortBy(accounts, 'id')
    .map((account) => account.name)
    .slice(0, accounts.length - 2)
    .toString();

  const lastTwoPartyNames = sortBy(accounts, 'id')
    .map((account) => account.name)
    .slice(accounts.length - 2)
    .join(' and ');

  const allPartyNames = [partyNames, lastTwoPartyNames];

  return partyNames.length > 0 ? allPartyNames.join(',') : lastTwoPartyNames;
};

export const currencyInputFormatter = (value: number, currency?: string) => {
  if (!currency || isUndefined(value)) {
    return DEFAULT_FORMAT_VALUE;
  }

  const options = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  }).resolvedOptions();

  const formattedValue = new Intl.NumberFormat(undefined, {
    ...options,
    style: 'decimal',
  }).format(value);

  return `${formattedValue}`;
};

export const getISOToGMTDateFormat = (isoDate?: string, separator?: string) => {
  return isoDate
    ? dayjs(isoDate).utc().format(`DD/MM/YYYY [${separator}] HH:mm [GMT]`)
    : DEFAULT_FORMAT_VALUE;
};

export const getTrimmedLowercaseValue = (value?: string) =>
  value?.trim().toLowerCase() ?? '';
