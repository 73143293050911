export const sortByValues = [
  'createdDate',
  'address',
  'partyName',
  'completionDate',
] as const;

export const orderByValues = ['asc', 'desc'] as const;

export type SortByType = (typeof sortByValues)[number];
export type OrderByType = (typeof orderByValues)[number];

export interface Sort {
  sortBy: SortByType;
  orderBy: OrderByType;
}

export type DeepRequired<Type> = {
  [Key in keyof Type]-?: Type[Key] extends object
    ? DeepRequired<Type[Key]>
    : Type[Key];
};

type ObjectPaths<Type> = {
  [Key in keyof Type]: Type[Key] extends object[]
    ? `${Key & string}.${ObjectPaths<Type[Key][number]> & string}`
    : Type[Key] extends object
      ? `${Key & string}.${ObjectPaths<Type[Key]> & string}`
      : Key;
}[keyof Type];

export type Paths<Type> = ObjectPaths<DeepRequired<Type>>;
