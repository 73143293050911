import { MenuIdToLabelMapper } from '../Constants/Constants';

export const getMenuItems = () => {
  const { DASHBOARD, CONTACT, SETTINGS, LOGOUT } = MenuIdToLabelMapper;

  return [
    {
      id: DASHBOARD,
      label: 'sidebar.primaryNav.dashboard',
      route: '/dashboard',
    },
    {
      id: CONTACT,
      label: 'sidebar.primaryNav.contact',
      route: '/contact',
    },
    {
      id: SETTINGS,
      label: 'sidebar.primaryNav.setting',
      route: '/settings',
    },
    {
      id: LOGOUT,
      label: 'login.userProfileMenu.logout',
      isMobileSpecific: true,
    },
  ];
};
