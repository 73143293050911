import { CountryMetadata } from '../types/CountryMetadataTypes';

export const countryMetadata: CountryMetadata[] = [
  {
    value: 'GBR',
    label: 'United Kingdom',
    icon: 'assets/united-kingdom',
    dialingCode: '+44',
    currencyCode: 'GBP',
    currencyName: 'Pound Sterling',
    currencySymbol: '£ ',
    showInCurrencyDropDown: true,
    languageCode: 'EN'
  },
  {
    value: 'USA',
    label: 'United States of America',
    icon: 'assets/united-states-of-america',
    dialingCode: '+1',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'FRA',
    label: 'France',
    icon: 'assets/france',
    dialingCode: '+33',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ESP',
    label: 'Spain',
    icon: 'assets/spain',
    dialingCode: '+34',
    currencyCode: 'ESP',
    currencyName: 'Spanish Peseta',
    currencySymbol: 'Pts ',
    showInCurrencyDropDown: false,
    languageCode: 'SP'
  },
  {
    value: 'PRT',
    label: 'Portugal',
    icon: 'assets/portugal',
    dialingCode: '+351',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'DEU',
    label: 'Germany',
    icon: 'assets/germany',
    dialingCode: '+49',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GRC',
    label: 'Greece',
    icon: 'assets/greece',
    dialingCode: '+30',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NZL',
    label: 'New Zealand',
    icon: 'assets/new-zealand',
    dialingCode: '+64',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
    currencySymbol: 'NZ$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'AUS',
    label: 'Australia',
    icon: 'assets/australia',
    dialingCode: '+61',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: 'A$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'HKG',
    label: 'Hong Kong',
    icon: 'assets/hong-kong',
    dialingCode: '+852',
    currencyCode: 'HKD',
    currencyName: 'Hong Kong Dollar',
    currencySymbol: 'HK$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'JPN',
    label: 'Japan',
    icon: 'assets/japan',
    dialingCode: '+81',
    currencyCode: 'JPY',
    currencyName: 'Japanese Yen',
    currencySymbol: 'JP¥ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'AFG',
    label: 'Afghanistan',
    icon: 'assets/afghanistan',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ALB',
    label: 'Albania',
    icon: 'assets/albania',
    dialingCode: '+355',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'DZA',
    label: 'Algeria',
    icon: 'assets/algeria',
    dialingCode: '+213',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'AND',
    label: 'Andorra',
    icon: 'assets/andorra',
    dialingCode: '+376',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'AGO',
    label: 'Angola',
    icon: 'assets/angola',
    dialingCode: '+244',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'AIA',
    label: 'Anguilla',
    icon: 'assets/anguilla',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ATG',
    label: 'Antigua And Barbuda',
    icon: 'assets/antigua-and-barbuda',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ARG',
    label: 'Argentina',
    icon: 'assets/argentina',
    dialingCode: '+54',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ARM',
    label: 'Armenia',
    icon: 'assets/armenia',
    dialingCode: '+374',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ABW',
    label: 'Aruba',
    icon: 'assets/aruba',
    dialingCode: '+297',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'AUT',
    label: 'Austria',
    icon: 'assets/austria',
    dialingCode: '+43',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'AZE',
    label: 'Azerbaijan',
    icon: 'assets/azerbaijan',
    dialingCode: '+994',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BHS',
    label: 'Bahamas',
    icon: 'assets/bahamas',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BHR',
    label: 'Bahrain',
    icon: 'assets/bahrain',
    dialingCode: '+973',
    currencyCode: 'BHD',
    currencyName: 'Bahraini Dinar',
    currencySymbol: 'BHD ',
    showInCurrencyDropDown: true
  },
  {
    value: 'BGD',
    label: 'Bangladesh',
    icon: 'assets/bangladesh',
    dialingCode: '+880',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BRB',
    label: 'Barbados',
    icon: 'assets/barbados',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BLR',
    label: 'Belarus',
    icon: 'assets/belarus',
    dialingCode: '+375',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BEL',
    label: 'Belgium',
    icon: 'assets/belgium',
    dialingCode: '+32',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BLZ',
    label: 'Belize',
    icon: 'assets/belize',
    dialingCode: '+501',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BEN',
    label: 'Benin',
    icon: 'assets/benin',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BMU',
    label: 'Bermuda',
    icon: 'assets/bermuda',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BTN',
    label: 'Bhutan',
    icon: 'assets/bhutan',
    dialingCode: '+975',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BOL',
    label: 'Bolivia',
    icon: 'assets/bolivia',
    dialingCode: '+591',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BES',
    label: 'Bonaire Sint Eustatius And Saba',
    icon: 'assets/bonaire',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BIH',
    label: 'Bosnia And Herzegovina',
    icon: 'assets/bosnia-and-herzegovina',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BWA',
    label: 'Botswana',
    icon: 'assets/botswana',
    dialingCode: '+267',
    currencyCode: 'BWP',
    currencyName: 'Botswana Pula',
    currencySymbol: 'P ',
    showInCurrencyDropDown: true
  },
  {
    value: 'BRA',
    label: 'Brazil',
    icon: 'assets/brazil',
    dialingCode: '+55',
    currencyCode: 'BRL',
    currencyName: 'Brazilian Real',
    currencySymbol: 'R$ ',
    showInCurrencyDropDown: false
  },
  {
    value: 'VGB',
    label: 'British Virgin Islands',
    icon: 'assets/british-virgin-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BRN',
    label: 'Brunei Darussalam',
    icon: 'assets/brunei',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BGR',
    label: 'Bulgaria',
    icon: 'assets/bulgaria',
    dialingCode: '+359',
    currencyCode: 'BGN',
    currencyName: 'Bulgaria ',
    currencySymbol: 'BGN ',
    showInCurrencyDropDown: false
  },
  {
    value: 'BFA',
    label: 'Burkina Faso',
    icon: 'assets/burkina-faso',
    dialingCode: '+226',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BDI',
    label: 'Burundi',
    icon: 'assets/burundi',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'KHM',
    label: 'Cambodia',
    icon: 'assets/cambodia',
    dialingCode: '+855',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CMR',
    label: 'Cameroon',
    icon: 'assets/cameroon',
    dialingCode: '+237',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CAN',
    label: 'Canada',
    icon: 'assets/canada',
    dialingCode: '+1',
    currencyCode: 'CAD',
    currencyName: 'Canadian Dollar',
    currencySymbol: 'CA$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'CPV',
    label: 'Cape Verde',
    icon: 'assets/cape-verde',
    dialingCode: '+61',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CYM',
    label: 'Cayman Islands',
    icon: 'assets/cayman-islands',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TCD',
    label: 'Chad',
    icon: 'assets/chad',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CHL',
    label: 'Chile',
    icon: 'assets/chile',
    dialingCode: '+56',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CHN',
    label: 'China',
    icon: 'assets/china',
    dialingCode: '+86',
    currencyCode: 'CNH',
    differentCurrencyCode: 'CNY',
    currencyName: 'Chinese Yuan',
    currencySymbol: '¥ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'COL',
    label: 'Colombia',
    icon: 'assets/colombia',
    dialingCode: '+57',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'COG',
    label: 'Congo',
    icon: 'assets/republic-of-the-congo',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'COD',
    label: 'Congo The Democratic Republic Of The',
    icon: 'assets/democratic-republic-of-congo',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'COK',
    label: 'Cook Islands',
    icon: 'assets/cook-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CRI',
    label: 'Costa Rica',
    icon: 'assets/costa-rica',
    dialingCode: '+506',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CIV',
    label: 'Cote DIvoire',
    icon: 'assets/ivory-coast',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'HRV',
    label: 'Croatia',
    icon: 'assets/croatia',
    dialingCode: '+385',
    currencyCode: 'HRK',
    currencyName: 'Croatian Kuna',
    currencySymbol: 'kn ',
    showInCurrencyDropDown: false
  },
  {
    value: 'CUB',
    label: 'Cuba',
    icon: 'assets/cuba',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CUW',
    label: 'Curacao',
    icon: 'assets/curacao',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CYP',
    label: 'Cyprus',
    icon: 'assets/cyprus',
    dialingCode: '+357',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'CZE',
    label: 'Czech Republic',
    icon: 'assets/czech-republic',
    dialingCode: '+420',
    currencyCode: 'CZK',
    currencyName: 'Czech Koruna',
    currencySymbol: 'CZK ',
    showInCurrencyDropDown: true
  },
  {
    value: 'DNK',
    label: 'Denmark',
    icon: 'assets/denmark',
    dialingCode: '+45',
    currencyCode: 'DKK',
    currencyName: 'Danish Krone',
    currencySymbol: 'Kr. ',
    showInCurrencyDropDown: true
  },
  {
    value: 'DJI',
    label: 'Djibouti',
    icon: 'assets/djibouti',
    dialingCode: '+253',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'DMA',
    label: 'Dominica',
    icon: 'assets/dominica',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'DOM',
    label: 'Dominican Republic',
    icon: 'assets/dominican-republic',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ECU',
    label: 'Ecuador',
    icon: 'assets/ecuador',
    dialingCode: '+593',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'EGY',
    label: 'Egypt',
    icon: 'assets/egypt',
    dialingCode: '+20',
    currencyCode: 'EGP',
    currencyName: 'Egyptian Pound',
    currencySymbol: 'E£ ',
    showInCurrencyDropDown: false
  },
  {
    value: 'SLV',
    label: 'El Salvador',
    icon: 'assets/salvador',
    dialingCode: '+503',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinea',
    icon: 'assets/equatorial-guinea',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ERI',
    label: 'Eritrea',
    icon: 'assets/eritrea',
    dialingCode: '+291',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'EST',
    label: 'Estonia',
    icon: 'assets/estonia',
    dialingCode: '+372',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ETH',
    label: 'Ethiopia',
    icon: 'assets/ethiopia',
    dialingCode: '+251',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'FLK',
    label: 'Falkland Islands',
    icon: 'assets/falkland-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'FRO',
    label: 'Faroe Islands',
    icon: 'assets/faroe-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'FJI',
    label: 'Fiji',
    icon: 'assets/fiji',
    dialingCode: '+679',
    currencyCode: 'FJD',
    currencyName: 'Fijian Dollar',
    currencySymbol: 'FJ$ ',
    showInCurrencyDropDown: false
  },
  {
    value: 'FIN',
    label: 'Finland',
    icon: 'assets/finland',
    dialingCode: '+358',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GUF',
    label: 'French Guiana',
    icon: 'assets/guyana',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PYF',
    label: 'French Polynesia',
    icon: 'assets/french-polynesia',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GAB',
    label: 'Gabon',
    icon: 'assets/gabon',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GMB',
    label: 'Gambia',
    icon: 'assets/gambia',
    dialingCode: '+220',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GEO',
    label: 'Georgia',
    icon: 'assets/georgia',
    dialingCode: '+995',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GHA',
    label: 'Ghana',
    icon: 'assets/ghana',
    dialingCode: '+233',
    currencyCode: 'GHC',
    currencyName: 'Ghanaian Cedi',
    currencySymbol: 'GHC ',
    showInCurrencyDropDown: false
  },
  {
    value: 'GIB',
    label: 'Gibraltar',
    icon: 'assets/gibraltar',
    dialingCode: '+350',
    currencyCode: 'GIP',
    currencyName: 'Gibraltar Pound',
    currencySymbol: '£ ',
    showInCurrencyDropDown: false
  },
  {
    value: 'GRL',
    label: 'Greenland',
    icon: 'assets/greenland',
    dialingCode: '+299',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GRD',
    label: 'Grenada',
    icon: 'assets/grenada',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GUM',
    label: 'Guam',
    icon: 'assets/guam',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GTM',
    label: 'Guatemala',
    icon: 'assets/guatemala',
    dialingCode: '+502',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GGY',
    label: 'Guernsey',
    icon: 'assets/guernsey',
    dialingCode: '+44',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GIN',
    label: 'Guinea',
    icon: 'assets/guinea',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GNB',
    label: 'Guinea-Bissau',
    icon: 'assets/guinea-bissau',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'GUY',
    label: 'Guyana',
    icon: 'assets/guyana',
    dialingCode: '+592',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'HTI',
    label: 'Haiti',
    icon: 'assets/haiti',
    dialingCode: '+509',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'HND',
    label: 'Honduras',
    icon: 'assets/honduras',
    dialingCode: '+504',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'HUN',
    label: 'Hungary',
    icon: 'assets/hungary',
    dialingCode: '+36',
    currencyCode: 'HUF',
    currencyName: 'Hungarian Forint',
    currencySymbol: 'Ft ',
    showInCurrencyDropDown: true
  },
  {
    value: 'ISL',
    label: 'Iceland',
    icon: 'assets/iceland',
    dialingCode: '+354',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'IND',
    label: 'India',
    icon: 'assets/india',
    dialingCode: '+91',
    currencyCode: 'INR',
    currencyName: 'Indian Rupee',
    currencySymbol: 'INR ',
    showInCurrencyDropDown: true
  },
  {
    value: 'IDN',
    label: 'Indonesia',
    icon: 'assets/indonesia',
    dialingCode: '+62',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'IRN',
    label: 'Iran (Islamic Republic Of)',
    icon: 'assets/iran',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'IRQ',
    label: 'Iraq',
    icon: 'assets/iraq',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'IRL',
    label: 'Ireland',
    icon: 'assets/ireland',
    dialingCode: '+353',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'IMN',
    label: 'Isle of Man',
    icon: 'assets/isle-of-man',
    dialingCode: '+44',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ISR',
    label: 'Israel',
    icon: 'assets/israel',
    dialingCode: '+972',
    currencyCode: 'ILS',
    currencyName: 'Israeli Shekel',
    currencySymbol: 'ILS ',
    showInCurrencyDropDown: true
  },
  {
    value: 'ITA',
    label: 'Italy',
    icon: 'assets/italy',
    dialingCode: '+39',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'JAM',
    label: 'Jamaica',
    icon: 'assets/jamaica',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'JEY',
    label: 'Jersey',
    icon: 'assets/jersey',
    dialingCode: '+44',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'JOR',
    label: 'Jordan',
    icon: 'assets/jordan',
    dialingCode: '+962',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'KAZ',
    label: 'Kazakhstan',
    icon: 'assets/kazakhstan',
    dialingCode: '+7',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'KEN',
    label: 'Kenya',
    icon: 'assets/kenya',
    dialingCode: '+254',
    currencyCode: 'KES',
    currencyName: 'Kenyan Shilling',
    currencySymbol: 'Ksh ',
    showInCurrencyDropDown: false
  },
  {
    value: 'PRK',
    label: 'Korea Democratic Peoples Republic Of',
    icon: 'assets/north-korea',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'RKS',
    label: 'Kosovo',
    icon: 'assets/kosovo',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'KWT',
    label: 'Kuwait',
    icon: 'assets/kuwait',
    dialingCode: '+965',
    currencyCode: 'KWD',
    currencyName: 'Kuwaiti Dinar',
    currencySymbol: 'KWD ',
    showInCurrencyDropDown: false
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstan',
    icon: 'assets/kyrgyzstan',
    dialingCode: '+996',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LAO',
    label: 'Lao Peoples Democratic Republic',
    icon: 'assets/laos',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LVA',
    label: 'Latvia',
    icon: 'assets/latvia',
    dialingCode: '+371',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LSO',
    label: 'Lesotho',
    icon: 'assets/lesotho',
    dialingCode: '+266',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LBY',
    label: 'Libya',
    icon: 'assets/libya',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LIE',
    label: 'Liechtenstein',
    icon: 'assets/liechtenstein',
    dialingCode: '+423',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LTU',
    label: 'Lithuania',
    icon: 'assets/lithuania',
    dialingCode: '+370',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LUX',
    label: 'Luxembourg',
    icon: 'assets/luxembourg',
    dialingCode: '+352',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MAC',
    label: 'Macau',
    icon: 'assets/macao',
    dialingCode: '+853',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MDG',
    label: 'Madagascar',
    icon: 'assets/madagascar',
    dialingCode: '+261',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MWI',
    label: 'Malawi',
    icon: 'assets/malawi',
    dialingCode: '+265',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MYS',
    label: 'Malaysia',
    icon: 'assets/malaysia',
    dialingCode: '+60',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MDV',
    label: 'Maldives',
    icon: 'assets/maldives',
    dialingCode: '+960',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MLI',
    label: 'Mali',
    icon: 'assets/mali',
    dialingCode: '+223',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MLT',
    label: 'Malta',
    icon: 'assets/malta',
    dialingCode: '+356',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MRT',
    label: 'Mauritania',
    icon: 'assets/mauritania',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MUS',
    label: 'Mauritius',
    icon: 'assets/mauritius',
    dialingCode: '+230',
    currencyCode: 'MUR',
    currencyName: 'Mauritian Rupee',
    currencySymbol: 'Rs ',
    showInCurrencyDropDown: false
  },
  {
    value: 'MEX',
    label: 'Mexico',
    icon: 'assets/mexico',
    dialingCode: '+52',
    currencyCode: 'MXN',
    currencyName: 'Mexican New Peso',
    currencySymbol: 'MX$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'FSM',
    label: 'Micronesia',
    icon: 'assets/micronesia',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MDA',
    label: 'Moldova',
    icon: 'assets/moldova',
    dialingCode: '+373',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MCO',
    label: 'Monaco',
    icon: 'assets/monaco',
    dialingCode: '+377',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MNG',
    label: 'Mongolia',
    icon: 'assets/mongolia',
    dialingCode: '+976',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MNE',
    label: 'Montenegro',
    icon: 'assets/montenegro',
    dialingCode: '+382',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MAR',
    label: 'Morocco',
    icon: 'assets/morocco',
    dialingCode: '+212',
    currencyCode: 'MAD',
    currencyName: 'Moroccan Dirham',
    currencySymbol: 'DH ',
    showInCurrencyDropDown: true
  },
  {
    value: 'MOZ',
    label: 'Mozambique',
    icon: 'assets/mozambique',
    dialingCode: '+258',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MMR',
    label: 'Myanmar',
    icon: 'assets/myanmar',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NAM',
    label: 'Namibia',
    icon: 'assets/namibia',
    dialingCode: '+264',
    currencyCode: 'NAD',
    currencyName: 'Namibian Dollar',
    currencySymbol: 'N$ ',
    showInCurrencyDropDown: false
  },
  {
    value: 'NPL',
    label: 'Nepal',
    icon: 'assets/nepal',
    dialingCode: '+977',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NLD',
    label: 'Netherlands',
    icon: 'assets/netherlands',
    dialingCode: '+31',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NIC',
    label: 'Nicaragua',
    icon: 'assets/nicaragua',
    dialingCode: '+505',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NER',
    label: 'Niger',
    icon: 'assets/niger',
    dialingCode: '+227',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NGA',
    label: 'Nigeria',
    icon: 'assets/nigeria',
    dialingCode: '+234',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'NOR',
    label: 'Norway',
    icon: 'assets/norway',
    dialingCode: '+47',
    currencyCode: 'NOK',
    currencyName: 'Norwegian Krone',
    currencySymbol: 'kr ',
    showInCurrencyDropDown: true,
    languageCode: 'NO'
  },
  {
    value: 'OMN',
    label: 'Oman',
    icon: 'assets/oman',
    dialingCode: '+968',
    currencyCode: 'OMR',
    currencyName: 'Omani Rial',
    currencySymbol: 'OMR ',
    showInCurrencyDropDown: false
  },
  {
    value: 'PAK',
    label: 'Pakistan',
    icon: 'assets/pakistan',
    dialingCode: '+92',
    currencyCode: 'PKR',
    currencyName: 'Pakistani Rupee',
    currencySymbol: 'Rs ',
    showInCurrencyDropDown: false
  },
  {
    value: 'PSE',
    label: 'Palestinian Territory',
    icon: 'assets/palestine',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PAN',
    label: 'Panama',
    icon: 'assets/panama',
    dialingCode: '+507',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PRY',
    label: 'Paraguay',
    icon: 'assets/paraguay',
    dialingCode: '+595',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PER',
    label: 'Peru',
    icon: 'assets/peru',
    dialingCode: '+51',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PHL',
    label: 'Philippines',
    icon: 'assets/philippines',
    dialingCode: '+63',
    currencyCode: 'PHP',
    currencyName: 'Philippine Peso',
    currencySymbol: 'PHP ',
    showInCurrencyDropDown: true
  },
  {
    value: 'POL',
    label: 'Poland',
    icon: 'assets/republic-of-poland',
    dialingCode: '+48',
    currencyCode: 'PLN',
    currencyName: 'Polish Zloty',
    currencySymbol: 'PLN ',
    showInCurrencyDropDown: true
  },
  {
    value: 'QAT',
    label: 'Qatar',
    icon: 'assets/qatar',
    dialingCode: '+974',
    currencyCode: 'QAR',
    currencyName: 'Qatar Rial',
    currencySymbol: 'QAR ',
    showInCurrencyDropDown: true
  },
  {
    value: 'ROU',
    label: 'Romania',
    icon: 'assets/romania',
    dialingCode: '+40',
    currencyCode: 'ROL',
    differentCurrencyCode: 'RON',
    currencyName: 'Romanian Leu',
    currencySymbol: 'lei ',
    showInCurrencyDropDown: false
  },
  {
    value: 'RUS',
    label: 'Russian Federation',
    icon: 'assets/russia',
    dialingCode: '',
    currencyCode: 'RUB',
    currencyName: 'Russian Ruble',
    currencySymbol: 'RUB ',
    showInCurrencyDropDown: false
  },
  {
    value: 'VCT',
    label: 'Saint Vincent and the Grenadines',
    icon: 'assets/st-vincent-and-the-grenadines',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SMR',
    label: 'San Marino',
    icon: 'assets/san-marino',
    dialingCode: '+378',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SAU',
    label: 'Saudi Arabia',
    icon: 'assets/saudi-arabia',
    dialingCode: '+966',
    currencyCode: 'SAR',
    currencyName: 'Saudi Arabian Riyal',
    currencySymbol: 'SR ',
    showInCurrencyDropDown: true
  },
  {
    value: 'SEN',
    label: 'Senegal',
    icon: 'assets/senegal',
    dialingCode: '+221',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SRB',
    label: 'Serbia',
    icon: 'assets/serbia',
    dialingCode: '+381',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SYC',
    label: 'Seychelles',
    icon: 'assets/seychelles',
    dialingCode: '+248',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SLE',
    label: 'Sierra Leone',
    icon: 'assets/sierra-leone',
    dialingCode: '+232',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SGP',
    label: 'Singapore',
    icon: 'assets/singapore',
    dialingCode: '+65',
    currencyCode: 'SGD',
    currencyName: 'Singapore Dollar',
    currencySymbol: 'S$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'SVK',
    label: 'Slovakia',
    icon: 'assets/slovakia',
    dialingCode: '+421',
    currencyCode: 'SKK',
    currencyName: 'Slovakian Koruna',
    currencySymbol: 'SKK ',
    showInCurrencyDropDown: true
  },
  {
    value: 'SVN',
    label: 'Slovenia',
    icon: 'assets/slovenia',
    dialingCode: '+386',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SLB',
    label: 'Solomon Islands',
    icon: 'assets/solomon-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SOM',
    label: 'Somalia',
    icon: 'assets/somalia',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ZAF',
    label: 'South Africa',
    icon: 'assets/south-africa',
    dialingCode: '+27',
    currencyCode: 'ZAR',
    differentCurrencyCode: 'ZAL',
    currencyName: 'South African Financial Rand',
    currencySymbol: 'R ',
    showInCurrencyDropDown: true
  },
  {
    value: 'KOR',
    label: 'South Korea',
    icon: 'assets/south-korea',
    dialingCode: '+82',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LKA',
    label: 'Sri Lanka',
    icon: 'assets/sri-lanka',
    dialingCode: '+94',
    currencyCode: 'LKR',
    currencyName: 'Sri Lankan Rupee',
    currencySymbol: 'Rs ',
    showInCurrencyDropDown: false
  },
  {
    value: 'KNA',
    label: 'St Kitts and Nevis',
    icon: 'assets/saint-kitts-and-nevis',
    dialingCode: '+1',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: 'EC$ ',
    showInCurrencyDropDown: true
  },
  {
    value: 'LCA',
    label: 'St Lucia',
    icon: 'assets/st-lucia',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SDN',
    label: 'Sudan',
    icon: 'assets/sudan',
    dialingCode: '+249',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SUR',
    label: 'Suriname',
    icon: 'assets/suriname',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SWZ',
    label: 'Swaziland',
    icon: 'assets/swaziland',
    dialingCode: '+268',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SWE',
    label: 'Sweden',
    icon: 'assets/sweden',
    dialingCode: '+46',
    currencyCode: 'SEK',
    currencyName: 'Swedish Krona',
    currencySymbol: 'kr ',
    showInCurrencyDropDown: true,
    languageCode: 'SE'
  },
  {
    value: 'CHE',
    label: 'Switzerland',
    icon: 'assets/switzerland',
    dialingCode: '+41',
    currencyCode: 'CHF',
    currencyName: 'Swiss Franc',
    currencySymbol: 'CHF ',
    showInCurrencyDropDown: true
  },
  {
    value: 'TWN',
    label: 'Taiwan',
    icon: 'assets/taiwan',
    dialingCode: '+886',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TJK',
    label: 'Tajikistan',
    icon: 'assets/tajikistan',
    dialingCode: '+992',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TZA',
    label: 'Tanzania United Republic Of',
    icon: 'assets/tanzania',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'THA',
    label: 'Thailand',
    icon: 'assets/thailand',
    dialingCode: '+66',
    currencyCode: 'THB',
    currencyName: 'Thailand Baht',
    currencySymbol: 'THB ',
    showInCurrencyDropDown: true
  },
  {
    value: 'TLS',
    label: 'Timor-Leste',
    icon: 'assets/east-timor',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TGO',
    label: 'Togo',
    icon: 'assets/togo',
    dialingCode: '+228',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TKL',
    label: 'Tokelau',
    icon: 'assets/tokelau',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TON',
    label: 'Tongo',
    icon: 'assets/tonga',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TTO',
    label: 'Trinidad And Tobago',
    icon: 'assets/trinidad-and-tobago',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TUN',
    label: 'Tunisia',
    icon: 'assets/tunisia',
    dialingCode: '+216',
    currencyCode: 'TND',
    currencyName: 'Tunisian Dinar',
    currencySymbol: 'TND ',
    showInCurrencyDropDown: false
  },
  {
    value: 'TUR',
    label: 'Turkey',
    icon: 'assets/turkey',
    dialingCode: '+90',
    currencyCode: 'TRY',
    currencyName: 'Turkish Lira',
    currencySymbol: 'TRY ',
    showInCurrencyDropDown: true
  },
  {
    value: 'TKM',
    label: 'Turkmenistan',
    icon: 'assets/turkmenistan',
    dialingCode: '+993',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos',
    icon: 'assets/turks-and-caicos',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'TUV',
    label: 'Tuvalu',
    icon: 'assets/tuvalu',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'VIR',
    label: 'US Virgin Islands',
    icon: 'assets/virgin-islands',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'UGA',
    label: 'Uganda',
    icon: 'assets/uganda',
    dialingCode: '+256',
    currencyCode: 'UGX',
    currencyName: 'Ugandan Shilling',
    currencySymbol: 'USh ',
    showInCurrencyDropDown: false
  },
  {
    value: 'UKR',
    label: 'Ukraine',
    icon: 'assets/ukraine',
    dialingCode: '+380',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ARE',
    label: 'United Arab Emirates',
    icon: 'assets/united-arab-emirates',
    dialingCode: '+971',
    currencyCode: 'AED',
    currencyName: 'UAE Dirham',
    currencySymbol: 'AED ',
    showInCurrencyDropDown: true
  },
  {
    value: 'URY',
    label: 'Uruguay',
    icon: 'assets/uruguay',
    dialingCode: '+598',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'UZB',
    label: 'Uzbekistan',
    icon: 'assets/uzbekistn',
    dialingCode: '+998',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'VUT',
    label: 'Vanuatu',
    icon: 'assets/vanuatu',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'VEN',
    label: 'Venezuela',
    icon: 'assets/venezuela',
    dialingCode: '+58',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'VNM',
    label: 'Vietnam',
    icon: 'assets/vietnam',
    dialingCode: '+84',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'YEM',
    label: 'Yemen',
    icon: 'assets/yemen',
    dialingCode: '',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ZMB',
    label: 'Zambia',
    icon: 'assets/zambia',
    dialingCode: '+260',
    currencyCode: 'ZMW',
    currencyName: 'Zambian kwacha',
    currencySymbol: 'ZK ',
    showInCurrencyDropDown: false
  },
  {
    value: 'ZWE',
    label: 'Zimbabwe',
    icon: 'assets/zimbabwe',
    dialingCode: '+263',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  /*{
    value: 'UK',
    label: 'United Kingdom',
    icon: 'assets/united-kingdom',
    dialingCode: '+44',
    currencyCode: 'GBP',
    currencyName: 'Pound Sterling',
    currencySymbol: '£ ',
    showInCurrencyDropDown: false,
  },*/
  {
    value: 'ALA',
    label: 'Aland Islands',
    icon: 'assets/aland-islands',
    dialingCode: '+358',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'COM',
    label: 'Comoros',
    icon: 'assets/comoros',
    dialingCode: '+269',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ATF',
    label: 'French Southern Territories',
    icon: 'assets/french-southern-territories',
    dialingCode: '+262',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'VAT',
    label: 'Holy See (Vatican City State)',
    icon: 'assets/vatican-city',
    dialingCode: '+379',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LBN',
    label: 'Lebanon',
    icon: 'assets/lebanon',
    dialingCode: '+961',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'LBR',
    label: 'Liberia',
    icon: 'assets/liberia',
    dialingCode: '+231',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'MKD',
    label: 'Macedonia',
    icon: 'assets/macedonia',
    dialingCode: '+389',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'ANT',
    label: 'Netherlands Antilles',
    icon: 'assets/netherlands-antilles',
    dialingCode: '+599',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'PRI',
    label: 'Puerto Rico',
    icon: 'assets/puerto-rico',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'RWA',
    label: 'Rwanda',
    icon: 'assets/rwanda',
    dialingCode: '+250',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'BLM',
    label: 'Saint Barthelemy',
    icon: 'assets/st-barts',
    dialingCode: '+1',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SXM',
    label: 'Saint Martin (French part)',
    icon: 'assets/sint-maarten',
    dialingCode: '+590',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  },
  {
    value: 'SYR',
    label: 'Syria',
    icon: 'assets/syria',
    dialingCode: '+963',
    currencyCode: null,
    currencyName: '',
    currencySymbol: null,
    showInCurrencyDropDown: false
  }
];
