import dayjs, { Dayjs } from 'dayjs';
import { FC, FocusEvent, useContext, useMemo, useState } from 'react';
import {
  DatePicker,
  DatePickerSlotProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BaseSingleInputFieldProps, FieldSection } from '@mui/x-date-pickers';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import DateField from './DateField';
import BBPopper from '../../atoms/BBPopper';
import CalendarHeader from './CalendarHeader';
import CalendarLayout from './CalendarLayout';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

const popperOptions: DatePickerSlotProps<Dayjs, false>['popper'] = {
  modifiers: [
    {
      name: 'offset',
      options: { offset: [0, 18] },
    },
  ],
};

interface BBDatePickerProps {
  name: string;
  value: Date | null;
  label: string;
  onChange: (selectedValue: Date | null) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  minDate?: Date;
  maxDate?: Date;
  size?: 'normal' | 'small';
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const BBDatePicker: FC<BBDatePickerProps> = (props: BBDatePickerProps) => {
  const {
    label,
    onChange,
    value,
    onBlur,
    error,
    size,
    minDate,
    maxDate,
    disabled,
    readonly,
    name,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { tenant } = useContext(TenantContext);
  const CalendarIcon = useMemo(() => getIcon(tenant, 'calendar'), [tenant]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slots={{
          field: DateField,
          openPickerIcon: CalendarIcon,
          popper: BBPopper,
          calendarHeader: CalendarHeader,
          layout: CalendarLayout,
        }}
        slotProps={{
          field: {
            onBlur,
            error,
            size,
            isPickerOpen: isOpen,
            name,
          } as BaseSingleInputFieldProps<
            Dayjs | null,
            Dayjs,
            FieldSection,
            false,
            unknown
          >,
          popper: popperOptions,
        }}
        label={label}
        onChange={(date) => onChange(date && date.startOf('day').toDate())}
        value={value && dayjs(value)}
        minDate={minDate && dayjs(minDate)}
        maxDate={maxDate && dayjs(maxDate)}
        disabled={disabled}
        format="DD/MM/YYYY"
        readOnly={readonly}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        dayOfWeekFormatter={(date: Dayjs) => date.format('dd')}
        showDaysOutsideCurrentMonth
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};

export default BBDatePicker;
