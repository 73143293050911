// Hooks
import { useScheduledCallback } from './useScheduledCallback';
// Types
import { FxDealRate } from '../../../../../../core/types/PricingTypes';
import { GetFxDealRateRequest } from '../../../../../../core/types/ApiTypes';
// API Wrappers
import { useLazyGetFxDealRateQuery } from '../../../../../../redux/api';

interface UseFxRateDealProps {
  onRateRefresh: (request: GetFxDealRateRequest, deal: FxDealRate) => void;
  transactionId: number | undefined;
}

export const useFxRateDeal = (props: UseFxRateDealProps) => {
  const { onRateRefresh, transactionId } = props;

  const [fetchRate, query] = useLazyGetFxDealRateQuery();

  const refreshRate = async (data: GetFxDealRateRequest) => {
    try {
      if (!transactionId) return;
      scheduledRefreshCallback.cancel();
      const deal = await fetchRate({ ...data, transactionId }).unwrap();

      scheduledRefreshCallback.scheduleAt(data, deal.rateExpiryTime);
      onRateRefresh(data, deal);

      return deal;
    } catch {}
  };

  const scheduledRefreshCallback = useScheduledCallback(refreshRate);

  return {
    isRefreshingRate: query.isFetching,
    deal: query.data,
    cancelScheduledRefresh: () => scheduledRefreshCallback.cancel(),
    isRefreshScheduled: () => scheduledRefreshCallback.isActive(),
    refreshRate,
  };
};
