import { styled } from '@mui/material/styles';

// Utils
import { getBody2NormalStyles } from '../../../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Steps = styled('ol')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
    margin: 0,
    marginTop: spacing.medium,
    marginLeft: spacing.smallMedium,
    marginRight: spacing.smallMedium,
  };
});

export const Step = styled('li')(({ theme }) => {
  return {
    ...getBody2NormalStyles(theme),
  };
});
