import { FC, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';

// Components - Atoms, Molecules, Organisms, Pages
import { BBSidebarNavProps, MenuItemWithHandler } from './types';

//Hooks
import useMenus from './useMenus';

// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import {
  NavWrapper,
  StyledDiv,
  StyledListItem,
  StyledNavList,
  StyledRouterLink,
} from './BBSidebarNav.styles';
import ListItemContent from './ListItemContent';

const BBSidebarNav: FC<BBSidebarNavProps> = (props: BBSidebarNavProps) => {
  const { open, isBuyerFlow } = props;

  const { layout } = useContext(LayoutContext);

  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);

  const navList = useMenus();

  const handleMenuClick = (item: MenuItemWithHandler) => {
    if (item?.handleClick) item.handleClick();
  };

  return (
    <NavWrapper>
      <StyledNavList dense={true} data-testid="bb-list-with-icon">
        {navList &&
          navList.map((item: MenuItemWithHandler) => {
            if (isNotMobileLayout && item?.isMobileSpecific) {
              return;
            }
            return (
              <StyledListItem key={item.id}>
                {item?.route ? (
                  <StyledRouterLink
                    as={RouterLink}
                    to={item.route}
                    isBuyerFlow={isBuyerFlow}
                    reloadDocument={false}
                    onClick={() => handleMenuClick(item)}
                  >
                    <ListItemContent item={item} open={open} />
                  </StyledRouterLink>
                ) : (
                  <StyledDiv
                    isBuyerFlow={isBuyerFlow}
                    onClick={() => handleMenuClick(item)}
                  >
                    <ListItemContent item={item} open={open} />
                  </StyledDiv>
                )}
              </StyledListItem>
            );
          })}
      </StyledNavList>
    </NavWrapper>
  );
};

export default BBSidebarNav;
