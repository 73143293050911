import { CoralogixRum } from '@coralogix/browser';
import { CoralogixDomain } from '@coralogix/browser/src/types';

// Constants
import { getConfig } from './Constants/configUtils';

const publicKey = getConfig('REACT_APP_CORALOGIX_PUBLIC_KEY');
const application = getConfig('REACT_APP_CORALOGIX_APP_NAME');
const domain = getConfig('REACT_APP_CORALOGIX_DOMAIN') as CoralogixDomain;
const version = getConfig('REACT_APP_VERSION');

if (process.env.NODE_ENV !== 'development' && publicKey) {
  try {
    CoralogixRum.init({
      application,
      coralogixDomain: domain,
      version,
      public_key: publicKey,
      collectIPData: false,
      traceParentInHeader: {
        enabled: true,
      },
    });
  } catch (error) {
    console.error('Error initializing CoralogixRum', error);
  }
}
