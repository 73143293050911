import { FC, memo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, ListItemProps } from '@mui/material';

import { LanguageContext } from '../../../core/TenantProvider/contexts';
import BBAddressDetails from './BBAddressDetails';
import {
  AddressItemsType,
  AddressItem,
} from '../../../core/types/addressLookupTypes';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';

interface BBAddressListProps {
  addressList: AddressItemsType;
  onSelectAddress: (entry: AddressItem) => void;
  hasAddressResults: boolean;
}
interface CustomListItemTextProps {
  isNoResultFound?: boolean;
}

const CustomListItem = styled(ListItem)<ListItemProps>(({ theme }) => {
  const {
    colours: {
      backgrounds: { bgAlt },
    },
    dimensions: { radius },
  } = theme;

  return {
    background: bgAlt,
    borderRadius: radius.small,
    '& .MuiListItemText-root': {
      margin: 0,
    },
  };
});

const CustomListItemText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNoResultFound',
})<CustomListItemTextProps>(({ theme, isNoResultFound }) => {
  const {
    colours: {
      backgrounds: { bgAlt, bg },
      icon: { active },
    },
    dimensions: { spacing, radius },
  } = theme;

  return {
    ...getBody2NormalStyles(theme),
    background: bgAlt,
    borderRadius: radius.small,
    padding: `${spacing.xSmall}px ${spacing.small}px`,
    display: 'flex',
    justifyContent: 'space-between',

    ...(isNoResultFound && {
      background: bg,
      borderRadius: radius.small,
    }),

    '&:hover': {
      background: bg,
      borderRadius: radius.small,
      cursor: isNoResultFound ? 'default' : 'pointer',
      '& svg': {
        '& path': {
          fill: active,
        },
      },
    },
  };
});

const BBAddressList: FC<BBAddressListProps> = ({
  addressList,
  onSelectAddress,
  hasAddressResults,
}: BBAddressListProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const noResultsFound: string = translate('addressLookup.noResultsFound');
  return (
    <List sx={{ width: '100%' }}>
      {hasAddressResults ? (
        addressList?.Items?.map((entry, index) => (
          <CustomListItem
            key={index}
            disableGutters
            disablePadding
            onClick={() => onSelectAddress(entry)}
            data-testid="bb-address-list-item"
          >
            <ListItemText>
              <CustomListItemText>
                <BBAddressDetails entry={entry} />
              </CustomListItemText>
            </ListItemText>
          </CustomListItem>
        ))
      ) : (
        <CustomListItem
          disableGutters
          disablePadding
          data-testid="bb-address-list-item"
        >
          <ListItemText>
            <CustomListItemText isNoResultFound>
              {noResultsFound}
            </CustomListItemText>
          </ListItemText>
        </CustomListItem>
      )}
    </List>
  );
};

export default memo(BBAddressList);
