import { Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, ReactNode } from 'react';

// Utils
import {
  getBodySmallBoldStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  const {
    colours,
    boxShadows,
    dimensions: { radius },
    zindex,
  } = theme;

  return {
    '.MuiTooltip-tooltip': {
      padding: 0,
      background: 'initial',
      boxShadow: boxShadows.small,
      borderRadius: radius.medium,
      color: colours.text.text,
    },

    '.MuiTooltip-arrow': {
      overflow: 'initial',
      color: colours.backgrounds.bgAlt,
      zIndex: zindex.tooltipArrow,

      '&::before': {
        boxShadow: boxShadows.small,
      },
    },
  };
});

const Container = styled('div')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing, radius },
    zindex,
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
    position: 'relative',
    backgroundColor: colours.backgrounds.bgAlt,
    padding: spacing.small,
    borderRadius: radius.medium,
    zIndex: zindex.tooltipContainer,
  };
});

const Title = styled('h6')(({ theme }) => {
  return {
    margin: 0,
    ...getBodySmallBoldStyles(theme),
  };
});

export const Message = styled('p')(({ theme }) => {
  const { colours } = theme;

  return {
    margin: 0,
    color: colours.text.textWeak,
    ...getBodySmallNormalStyles(theme),
  };
});

interface BBTooltipProps extends Omit<TooltipProps, 'arrow' | 'title'> {
  title?: ReactNode;
  message?: ReactNode;
}

const BBTooltip = (props: BBTooltipProps) => {
  const { title, message, ...tooltipProps } = props;

  return (
    <StyledTooltip
      {...tooltipProps}
      arrow
      title={
        <Container>
          {title && <Title>{title}</Title>}
          {message && <Message>{message}</Message>}
        </Container>
      }
    />
  );
};

export default memo(BBTooltip);
