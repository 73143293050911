import { memo } from 'react';
import { TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';

const BBTabPanel = styled(TabPanel)(() => {
  return {
    padding: 0,
  };
});

export default memo(BBTabPanel);
