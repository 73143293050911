import { FC, useContext, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

//Context
import { LayoutContext } from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import {
  MainContainer,
  StyledPageTitle,
  Card,
  CardBody,
  StyledSpan,
} from './SettingsStyles.style';
import BBLink from '../../atoms/BBLink';
import BBSelect from '../../atoms/BBSelect';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
//API wrapper
import { useGetProfileQuery } from '../../../redux/api';
//Utils
import { getFormattedValue } from '../../../core/utils/Format';
//Constants
import {
  EMAIL_LINK,
  DEFAULT_LANGUAGE,
  LANGUAGE_OPTIONS,
} from '../../../core/utils/Constants/Constants';

interface SettingsProps {
  isBuyerFlow?: boolean;
}

interface ListItemType {
  id: number;
  label: string;
  value: string;
}

const Settings: FC<SettingsProps> = (props: SettingsProps) => {
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const { isBuyerFlow } = props;

  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const { data } = useGetProfileQuery();

  const firstName = getFormattedValue(data?.firstName ?? '');
  const middleName = getFormattedValue(data?.middleName ?? '');
  const lastName = getFormattedValue(data?.lastName ?? '');
  const phone = getFormattedValue(data?.contactNo ?? '');
  const email = getFormattedValue(data?.email);
  const companyName = getFormattedValue(data?.accountName ?? '');

  const YOUR_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('settings.yourDetails.name'),
      value: `${firstName} ${middleName} ${lastName}`,
    },
    {
      id: 1,
      label: translate('settings.yourDetails.phone'),
      value: phone,
    },
    {
      id: 2,
      label: translate('settings.yourDetails.email'),
      value: email,
    },
  ];

  const COMPANY_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('settings.companyDetails.name'),
      value: companyName,
    },
  ];

  const getCombinedDescription = (textKey1: string, textKey2: string) => (
    <StyledSpan>
      <BBText variant="body2" type="normal" text={translate(textKey1)} />
      <BBLink href={EMAIL_LINK} target="_new">
        <BBText variant="body2" type="normal" text={translate(textKey2)} />
      </BBLink>
    </StyledSpan>
  );

  return (
    <>
      <StyledPageTitle
        variant="h1"
        type="medium"
        text={translate('settings.title')}
      />
      <MainContainer layout={layout}>
        <Card layout={layout}>
          <CardBody>
            {getCombinedDescription(
              `settings.description.line1`,
              `settings.description.line2`
            )}
            <BBText
              variant="body1"
              type="medium"
              text={translate('settings.preferences.title')}
            />
            <BBSelect
              name="country-drop-down"
              options={LANGUAGE_OPTIONS}
              placeholderLabel={translate('settings.preferences.label')}
              variant="normal"
              isSearchable={false}
              value={currentLanguage}
              onChange={setCurrentLanguage}
            />
            <BBText
              variant="body1"
              type="medium"
              text={translate('settings.yourDetails.title')}
            />
            <List dense={true}>
              {YOUR_DETAILS.map((item: ListItemType) => {
                return (
                  <ListItem key={item.id}>
                    <ListItemText primary={item.label} secondary={item.value} />
                  </ListItem>
                );
              })}
            </List>
            {!isBuyerFlow && (
              <>
                <BBText
                  variant="body1"
                  type="medium"
                  text={translate('settings.companyDetails.title')}
                />
                <List dense={true}>
                  {COMPANY_DETAILS.map((item: ListItemType) => {
                    return (
                      <ListItem key={item.id}>
                        <ListItemText
                          primary={item.label}
                          secondary={item.value}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
          </CardBody>
        </Card>
      </MainContainer>
    </>
  );
};
export default Settings;
