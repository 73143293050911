// Components - Atoms, Molecules, Organisms, Pages
import { getConfig } from './configUtils';
import { FooterLinkConfigType } from '../../../components/molecules/BBFooter/BBFooter';
// Types
import {
  OrganizationDomainCodeConfigType,
  OrganizationCodeTypes,
} from '../../types/OrganizationTypes';
import {
  TransactionFilter,
  TransactionStatus,
} from '../../types/TransactionTypes';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const refreshTokenExpiry: number = 29 / 1440; //29 mins to days

export const BASIC_AUTH_TOKEN = getConfig('REACT_APP_BASIC_AUTH_TOKEN');

export const PROXY_ENV = getConfig('REACT_APP_PROXY_ENV');

export const AUTH_SCOPE = 'property_pay';
export const AUTH_ORG_CODE = 'Redpin';

export const NUMBER_OF_PAGES_WITHOUT_ARROWS = 5;
export const DEFAULT_NUMBER_OF_TABLE_ROWS = 10;
export const SEARCH_DEBOUNCE_TIME_IN_MILLISECONDS = 200;
export const FULL_NAME_MAX_LENGTH = 150;
export const COMPANY_NAME_MAX_LENGTH = 150;
export const FILE_REFERENCE_MAX_LENGTH = 150;
export const OTHER_PAYMENT_TEXT_MAX_LENGTH = 26;
export const FIRST_NAME_MAX_LENGTH = 40;
export const LAST_NAME_MAX_LENGTH = 50;
export const LOGIN_USERNAME_MAX_LENGTH = 250;
export const LOGIN_PASSWORD_MAX_LENGTH = 50;
export const LOGIN_PASSWORD_MIN_LENGTH = 2;

export const DEFAULT_FORMAT_VALUE = '-';

export const CURRENCIES_DIRECT: string = 'Currencies Direct';
export const TENANTS: { [key: string]: OrganizationCodeTypes } = {
  CURRENCIES_DIRECT: 'currenciesdirect',
};
export const TEXT_OR_CONTAINER_REQUIRED: string = 'Text or Container Required';
export const ADDRESS: string = 'Address';
export const PCA_API_KEY: string = 'RZ21-CD99-ZF18-CG63';
export const ENTER_KEY: string = 'Enter';

export const LANG_CODE_MAPPING: { [keyName: string]: string } = {
  EN: 'en-gb',
  FR: 'fr-fr',
  NO: 'no-no',
  SE: 'se-se',
  US: 'en-us',
};

export const ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG: Array<OrganizationDomainCodeConfigType> =
  [
    {
      organizationSearchValue: 'currenciesdirect',
      organizationCode: 'currenciesdirect',
    },
  ];
export const ORGANIZATION_CODE_MAPPING: {
  [K in OrganizationCodeTypes]: string;
} = {
  currenciesdirect: 'currenciesdirect',
};

export const FOOTER_LINKS_CONFIG_ARRAY: FooterLinkConfigType[] = [
  {
    textKey: 'sidebar.footer.terms',
    hrefKey:
      'https://www.currenciesdirect.com/en-gb/terms-of-use?_gl=1*12dxhxw*_gcl_au*OTQ1OTA4NTkxLjE3Mjk3NzkyNDg.*_ga*MTE2Mjk2NDU5OC4xNzE4MjYyNTg3*_ga_RE8W60JW73*MTcyOTc3OTI0OC40LjEuMTcyOTc3OTM0MS4wLjAuMA..',
  },
  {
    textKey: 'sidebar.footer.privacy',
    hrefKey:
      'https://www.currenciesdirect.com/en-gb/privacy-policy?_gl=1*12dxhxw*_gcl_au*OTQ1OTA4NTkxLjE3Mjk3NzkyNDg.*_ga*MTE2Mjk2NDU5OC4xNzE4MjYyNTg3*_ga_RE8W60JW73*MTcyOTc3OTI0OC40LjEuMTcyOTc3OTM0MS4wLjAuMA..',
  },
  {
    textKey: 'sidebar.footer.cookies',
    hrefKey:
      'https://www.currenciesdirect.com/en-gb/cookie-policy?_gl=1*f9hcos*_gcl_au*OTQ1OTA4NTkxLjE3Mjk3NzkyNDg.*_ga*MTE2Mjk2NDU5OC4xNzE4MjYyNTg3*_ga_RE8W60JW73*MTcyOTc3OTI0OC40LjEuMTcyOTc3OTM0Ni4wLjAuMA..',
  },
  {
    textKey: 'sidebar.footer.regulatory',
    hrefKey:
      'https://www.currenciesdirect.com/en-gb/regulatory-information?_gl=1*16pt48n*_gcl_au*OTQ1OTA4NTkxLjE3Mjk3NzkyNDg.*_ga*MTE2Mjk2NDU5OC4xNzE4MjYyNTg3*_ga_RE8W60JW73*MTcyOTc3OTI0OC40LjEuMTcyOTc3OTM0Ny4wLjAuMA..',
  },
];

export const REDPIN_COMPANY_LINK: string = 'https://www.redpincompany.com';
export const PROPERTY_PAY_LINK: string =
  'https://propertypay.sit.redpincompany.com';
export const DRAWER_WIDTH: number = 340;
export const DRAWER_MINI_WIDTH: number = 80;
export const TRANSACTION_FILTERS: TransactionFilter[] = [
  {
    path: '',
    type: 'all',
  },
  {
    path: 'open',
    type: 'open',
    status: TransactionStatus.Open,
  },
  {
    path: 'completed',
    type: 'completed',
    status: TransactionStatus.Completed,
  },
  {
    path: 'cancelled',
    type: 'cancelled',
    status: TransactionStatus.Cancelled,
  },
  {
    path: 'draft',
    type: 'draft',
    status: TransactionStatus.Draft,
  },
];

export const DEFAULT_COUNTRY: string = 'ESP';
export const NON_DEFAULT_PURCHASE_VALUE_CURRENCY: string = 'GBP';
export const DEFAULT_PURCHASE_VALUE_CURRENCY: string = 'EUR';
export const DEFAULT_PAYMENT_VALUE_CURRENCY: string = 'EUR';
export const USER_PROFILE_MENU: Array<{ id: number; label: string }> = [
  { id: 0, label: 'login.userProfileMenu.logout' },
];
export const EXCLUDED_PATH: Array<string> = [
  '/dashboard',
  '/contact',
  '/settings',
];
export const REGEX = {
  name: /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/i,
  companyName: /^[A-Za-z0-9&@£$€¥.,:;#\- ]+$/,
  alphaNumericString: /^[0-9a-zA-Z ]*$/,
  paymentValue: /^(?!(0))(?!$)\d{1,10}(?:\.\d{1,2})?$/,
};
export const REQUEST: string = 'request';
export const COMPLETION: string = 'completion';
export const HIDDEN_VALUE_ASTERISK = '****';

export const MenuIdToLabelMapper = {
  DASHBOARD: 0,
  CONTACT: 1,
  SETTINGS: 2,
  LOGOUT: 3,
};

export const LOGIN_SUCCESS_CODE = 'V000';
export const OTP_VERIFICATION_SUCCESS_CODE = 'C000';

export const LOGIN_ERROR_MESSAGE = 'Please try log in again.';
export const OTP_ERROR_MESSAGE = 'That code is incorrect. Please log in again.';

export const SECURITY_AND_FRAUD_LINK =
  'https://www.currenciesdirect.com/en-gb/security-and-fraud';
export const CONTACT_US_FRAUD_LINK =
  'mailto:propertypayspain@redpincompany.com?subject=PropertyPay support query: fraud prevention';
export const HEADER_COMPARISON_FAILED_ERROR = 'header comparison failed';

export enum PageHeaders {
  Transactions = 'TRANSACTIONS',
  CreateTransactions = 'CREATE_TRANSACTIONS',
  PaymentRequests = 'PAYMENT_REQUESTS',
  Disbursements = 'DISBURSEMENTS',
  Buyer = 'BUYER',
  BuyerPaymentRequests = 'BUYER_PAYMENT_REQUESTS',
}
export const LOCATION_TEXT = 'Spain';
export const LOCATION_LINK = 'https://maps.google.com/maps?q=spain';
export const GIVE_US_CALL_TEXT = '+34 910 786 109';
export const GIVE_US_CALL_LINK = 'tel:34910786109';
export const EMAIL_TEXT = 'propertypayspain@redpincompany.com';
export const EMAIL_LINK = 'mailto:propertypayspain@redpincompany.com';
export const ADDRESS_TEXT =
  'One Canada Square, Canary Wharf London, E14 5AA, United Kingdom';
export const ADDRESS_LINK =
  'https://maps.google.com/maps?q=One Canada Square, Canary Wharf London, E14 5AA, United Kingdom';
export const TIMING_TEXT = '9:30am - 06:00pm (Mon-Fri)';
export const IGNORED_BANK_ACCOUNT_COUNTRIES = ['GBR'];
export const DEFAULT_LANGUAGE = 'GBR';
export const LANGUAGE_OPTIONS = [
  { label: 'English', value: 'GBR', iconPath: 'assets/united-kingdom' },
  { label: 'Spanish', value: 'ESP', iconPath: 'assets/spain' },
];
