import { ReactNode, useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getBodySmallNormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Link = styled(RouterLink)(({ theme }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing.xxxSmall,
    textDecoration: 'none',
    color: colours.text.active,
    ...getBodySmallNormalStyles(theme),

    svg: {
      height: 10,
      width: 10,
    },
  };
});

interface TableRowActionProps {
  children: ReactNode;
  to: string;
}

const TableRowAction = (props: TableRowActionProps) => {
  const { to, children } = props;
  const { tenant } = useContext(TenantContext);
  const ArrowRightIcon = useMemo(() => getIcon(tenant, 'arrowRight'), [tenant]);

  return (
    <Link to={to}>
      {children} <ArrowRightIcon />
    </Link>
  );
};

export default TableRowAction;
