import { FC } from 'react';

//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import { NoRecipientsContainer, StyledDescription } from './styles';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';

const NoRecipientsFound: FC = () => {
  const { translate } = useTranslations();

  return (
    <NoRecipientsContainer>
      <div>
        <BBText
          text={translate('savedRecipient.noRecipientHeading')}
          type="bold"
          variant="body2"
        />
        <StyledDescription
          variant="subtitle1"
          type="normal"
          text={translate('savedRecipient.noRecipientDescription')}
        />
      </div>
    </NoRecipientsContainer>
  );
};

export default NoRecipientsFound;
