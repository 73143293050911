// Components - Atoms, Molecules, Organisms, Pages
import { useTranslations } from '../../../../../../../core/hooks';
import { Message } from './TooltipMessage.styles';
// Hooks
import { useExpiryProgress } from './useExpiryProgress';

interface TooltipMessageProps {
  expiryTime: string;
}

const TooltipMessage = (props: TooltipMessageProps) => {
  const { expiryTime } = props;

  const { secondsUntilExpiry } = useExpiryProgress(expiryTime);
  const { translate } = useTranslations();

  return (
    <Message>
      {translate(
        'buyer.createPayment.paymentDetails.rateTrackerTooltip.message1'
      )}{' '}
      <strong>
        {translate(
          'buyer.createPayment.paymentDetails.rateTrackerTooltip.message2',
          { secondsUntilExpiry }
        )}
      </strong>
    </Message>
  );
};

export default TooltipMessage;
