import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

//Components
import { LoginFormValues } from '../../components/pages/LoginPage/types';
import {
  LoginResponse,
  LoginErrorResponse,
  SMSVerificationRequest,
  SMSVerificationSuccessResponse,
} from '../../core/types/ApiTypes';
// API Wrappers
import { api } from './api';
//Utils
import {
  AUTH_SCOPE,
  AUTH_ORG_CODE,
  OTP_VERIFICATION_SUCCESS_CODE,
  LOGIN_SUCCESS_CODE,
  OTP_ERROR_MESSAGE,
  LOGIN_ERROR_MESSAGE,
} from '../../core/utils/Constants/Constants';
import { LOGIN_URL, SMS_VERIFICATION_URL } from './urlConstants';

const authenticationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginFormValues>({
      queryFn: async (data, _queryApi, _extraOptions, baseQuery) => {
        const password = window.btoa(data.password);
        const body = {
          grant_type: 'password',
          scope: AUTH_SCOPE,
          username: data.emailAddress,
          password: password,
          orgCode: AUTH_ORG_CODE,
        };
        const apiResult = await baseQuery({
          url: LOGIN_URL,
          method: 'POST',
          body,
        });

        if (apiResult.error) {
          return { error: apiResult.error };
        }

        const result = apiResult.data as LoginResponse | LoginErrorResponse;
        if (
          ('code' in result && result.code !== LOGIN_SUCCESS_CODE) ||
          !result
        ) {
          //Forming a custom error
          return {
            error: {
              status: 500,
              data: {
                code: result?.code || 500,
                description: result?.description || LOGIN_ERROR_MESSAGE,
              },
            } as FetchBaseQueryError,
          };
        }

        return { data: result as LoginResponse };
      },
    }),

    verifySMS: builder.mutation<
      SMSVerificationSuccessResponse,
      SMSVerificationRequest
    >({
      queryFn: async (data, _queryApi, _extraOptions, baseQuery) => {
        const body = {
          grant_type: 'password',
          scope: AUTH_SCOPE,
          gw_uuid: data.uuid,
          otp: data.otp,
          orgCode: AUTH_ORG_CODE,
        };
        const apiResult = await baseQuery({
          url: SMS_VERIFICATION_URL,
          method: 'POST',
          body,
        });

        const result = apiResult.data as
          | SMSVerificationSuccessResponse
          | LoginErrorResponse;

        if (
          (apiResult.error as FetchBaseQueryError) ||
          ('code' in result && result.code !== OTP_VERIFICATION_SUCCESS_CODE) ||
          !result
        ) {
          //Forming a custom error
          return {
            error: {
              status: 500,
              data: {
                code: (result as LoginErrorResponse)?.code || 500,
                description: OTP_ERROR_MESSAGE,
              },
            } as FetchBaseQueryError,
          };
        }

        return { data: result as SMSVerificationSuccessResponse };
      },
    }),

    logout: builder.query<void, void>({
      query: () => `v1/property-pay/logout`,
    }),
  }),
});

export const { useLoginMutation, useVerifySMSMutation, useLazyLogoutQuery } =
  authenticationApi;
