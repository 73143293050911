import { styled } from '@mui/material/styles';
import { memo, PropsWithChildren } from 'react';

// Utils
import {
  getBody2NormalStyles,
  getBodyExtraSmallBoldStyles,
  getBodyExtraSmallNormalStyles,
  getBodySmallBoldStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export type VariantType =
  | 'default'
  | 'error'
  | 'warning'
  | 'success'
  | 'notice'
  | 'pending'
  | 'section';

export type SizeType = 'small' | 'normal';

interface LabelOwnerState {
  variant: VariantType;
  size: SizeType;
}

const Label = styled('span')<{ ownerState: LabelOwnerState }>(({
  theme,
  ownerState,
}) => {
  const { size, variant } = ownerState;
  const {
    colours,
    dimensions: { spacing, radius },
  } = theme;

  return {
    borderRadius: radius.small,
    boxSizing: 'border-box',

    ...(size === 'small' && {
      padding: `1px ${spacing.xxxSmall}px`, // no token for 1px
      ...getBodyExtraSmallNormalStyles(theme),
    }),

    ...(size === 'normal' && {
      padding: `2px ${spacing.xxSmall}px`, // no token for 2px
      ...getBody2NormalStyles(theme),
    }),

    ...(variant === 'default' && {
      color: colours.text.text,
      backgroundColor: colours.backgrounds.bg,
    }),

    ...(variant === 'error' && {
      color: colours.text.textAlt,
      backgroundColor: colours.backgrounds.error,
    }),

    ...(variant === 'warning' && {
      color: colours.text.text,
      backgroundColor: colours.backgrounds.warning,
    }),

    ...(variant === 'success' && {
      color: colours.text.textAlt,
      backgroundColor: colours.backgrounds.success,
    }),

    ...(variant === 'notice' && {
      color: colours.text.notice,
      backgroundColor: colours.backgrounds.noticeLighter,
    }),

    ...(variant === 'pending' && {
      color: colours.text.text,
      backgroundColor: colours.backgrounds.pending,
    }),

    ...(variant === 'section' && {
      color: colours.text.text,
      border: `1px solid ${colours.borders.active}`,
      borderRadius: radius.circle,
      padding: `${spacing.xxxSmall}px ${spacing.xxSmall}px`,

      ...(size === 'normal'
        ? getBodySmallBoldStyles(theme)
        : getBodyExtraSmallBoldStyles(theme)),
    }),
  };
});

interface BBLabelProps extends PropsWithChildren {
  variant?: VariantType;
  size?: SizeType;
}

const BBLabel = (props: BBLabelProps) => {
  const { variant = 'default', size = 'normal', children } = props;

  return <Label ownerState={{ variant, size }}>{children}</Label>;
};

export default memo(BBLabel);
