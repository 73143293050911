import { FC, memo, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../../atoms/BBText';
import BBIconButton from '../../atoms/BBIconButton/BBIconButton';
import BBUserProfileMenu from '../BBUserProfileMenu';
import { StyledDivider } from '../../pages/CommonStyles/CommonStyles.styles';
import HeaderNav from './HeaderNav';
import {
  GreetingContainer,
  HeaderContainer,
  MobileGreetingContainer,
  StyledDateText,
  StyledGreetingText,
  StyledUserIdText,
  StyledUserName,
  UserAvatar,
  UserInfoContainer,
} from './BBHeader.styles';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { EXCLUDED_PATH } from '../../../core/utils/Constants/Constants';

interface BBHeaderProps {
  fullName: string;
  initials: string;
  customerId: string;
  firstName?: string;
  dateMessage?: string;
  greetingMessage?: string;
  pageHeaderType?: string;
}

const BBHeader: FC<BBHeaderProps> = (props: BBHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleUserProfileOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserProfileCloseClick = () => {
    setAnchorEl(null);
  };

  const {
    firstName,
    fullName,
    initials,
    greetingMessage,
    dateMessage,
    customerId,
    pageHeaderType,
  } = props;

  const { layout } = useContext(LayoutContext);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const location = useLocation();

  const breadcrumbAvailable = useMemo(() => {
    return !EXCLUDED_PATH.some((path) => location.pathname.includes(path));
  }, [location]);

  return (
    <HeaderContainer layout={layout} data-testid="bb-header-container">
      {isMobileLayout ? (
        <>
          <UserAvatar>
            <BBText variant="subtitle1" type="bold" text={initials} />
          </UserAvatar>
          <MobileGreetingContainer>
            <StyledUserName
              variant="subtitle1"
              type="bold"
              text={fullName}
              layout={layout}
            />
            <StyledUserIdText
              variant="subtitle1"
              type="normal"
              text={customerId}
            />
          </MobileGreetingContainer>
        </>
      ) : (
        <>
          <GreetingContainer>
            {breadcrumbAvailable ? (
              <HeaderNav pageHeaderType={pageHeaderType} />
            ) : (
              <>
                <StyledGreetingText
                  variant="body1"
                  type="bold"
                  text={`${greetingMessage}, ${firstName}`}
                />
                <StyledDivider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <StyledDateText
                  variant="body2"
                  type="normal"
                  text={dateMessage as string}
                />
              </>
            )}
          </GreetingContainer>
          <UserInfoContainer>
            <StyledUserName
              variant="body2"
              type="normal"
              text={fullName}
              layout={layout}
            />
            <BBIconButton
              onClick={handleUserProfileOpenClick}
              name="toggleUserProfile"
              data-testid="bb-user-profile-toggle"
            >
              <UserAvatar>
                <BBText variant="subtitle1" type="bold" text={initials} />
              </UserAvatar>
            </BBIconButton>
          </UserInfoContainer>
          <BBUserProfileMenu
            open={Boolean(anchorEl)}
            handleOnClose={handleUserProfileCloseClick}
            anchorEl={anchorEl}
            fullName={fullName}
            customerId={customerId}
          />
        </>
      )}
    </HeaderContainer>
  );
};

export default memo(BBHeader);
