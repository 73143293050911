import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

//Contexts
import {
  TenantContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
// Hooks
import { useTranslations } from '../../../core/hooks';
//Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import {
  ThreePaDetails,
  Transaction,
} from '../../../core/types/TransactionTypes';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import {
  getFormattedAddress,
  getFormattedDate,
  getFormattedNamesFromCrmAccounts,
  getFormattedValue,
} from '../../../core/utils/Format';

interface TransactionSummaryProps {
  transactionData?: Transaction;
  threePaData?: ThreePaDetails;
}

const ContentWrapper = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const {
    colours: {
      backgrounds: { bg },
      borders: { border },
    },
    dimensions: {
      spacing: { xxxSmall, xSmall, small, medium, large },
    },
  } = theme;
  return {
    display: 'flex',
    borderRadius: xxxSmall,
    border: `1px solid ${border}`,
    backgroundColor: bg,
    gap: isMobileLayout ? xSmall : small,
    padding: isDesktopLayout ? large : isTabletLayout ? medium : small,
    flexDirection: isDesktopLayout ? 'row' : 'column',
  };
});

const UserInfoContent = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xSmall },
    },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: xSmall,
    flex: '1 0 0',
  };
});

const AddressText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { text },
    },
  } = theme;
  return {
    color: text,
  };
});

const NameAndReferenceText = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeaker },
    },
  } = theme;
  return {
    color: textWeaker,
  };
});

const TransactionActivityContainer = styled(Box)<LayoutProps>(({
  theme,
  layout,
}) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const {
    dimensions: {
      spacing: { small, xSmall },
    },
  } = theme;
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: isDesktopLayout ? `${xSmall}px ${small}px` : xSmall,
    alignItems: isMobileLayout ? 'flex-start' : 'center',
    alignContent: isMobileLayout ? 'flex-start' : 'center',
    alignSelf: 'stretch',
    flexDirection: isMobileLayout ? 'column' : 'row',
    'hr.MuiDivider-root': {
      display: isMobileLayout ? 'none' : 'block',
    },
  };
});

const TransactionActivity = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: xxSmall,
    '> svg > path': {
      fill: '#2E86AB', //Token not available
    },
  };
});

const ActivityTextWrapper = styled('span')(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    gap: xxxSmall,
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { borderLighter },
    },
    dimensions: {
      spacing: { smallMedium },
    },
  } = theme;
  return {
    color: borderLighter,
    width: '1px',
    height: smallMedium,
  };
});

const TransactionSummary: FC<TransactionSummaryProps> = (
  props: TransactionSummaryProps
) => {
  const { transactionData, threePaData } = props;

  const transactionStatus: string = transactionData?.transactionStatus ?? '';
  const fileReference: string = transactionData?.fileReference ?? '';
  const completionDate: string =
    getFormattedDate(transactionData?.completionDate) ?? '';
  const account: string =
    getFormattedNamesFromCrmAccounts(transactionData?.crmAccounts) ?? '';
  const wholeAddress: string =
    getFormattedAddress(transactionData?.address) ?? '';
  const transactionId: number = transactionData?.id ?? 1;

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  //Icons
  const [DocumentIcon, CalendarIcon, RenewIcon, FileTaskIcon] = useMemo(
    () => [
      getIcon(tenant, 'document'),
      getIcon(tenant, 'calendar'),
      getIcon(tenant, 'renew'),
      getIcon(tenant, 'fileTask'),
    ],
    [tenant]
  );

  return (
    <ContentWrapper layout={layout} data-testid="pp-add-payment">
      <UserInfoContent>
        <Box>
          <AddressText variant="body1" type="bold" text={wholeAddress} />
          <NameAndReferenceText
            variant="body2"
            type="normal"
            text={`${account} | ${translate('transactionDetails.ref')}: ${fileReference}`}
          />
        </Box>

        <TransactionActivityContainer layout={layout}>
          <TransactionActivity>
            <DocumentIcon />
            <ActivityTextWrapper>
              <BBText
                text={translate('transactionDetails.summary.id')}
                type={'bold'}
                variant="subtitle1"
              />
              <BBText
                text={transactionId.toString()}
                type={'normal'}
                variant="subtitle1"
              />
            </ActivityTextWrapper>
          </TransactionActivity>
          <StyledDivider orientation="vertical" variant="middle" flexItem />

          <TransactionActivity>
            <CalendarIcon />
            <ActivityTextWrapper>
              <BBText
                text={translate('transactionDetails.summary.completion')}
                type={'bold'}
                variant="subtitle1"
              />
              <BBText
                text={completionDate}
                type={'normal'}
                variant="subtitle1"
              />
            </ActivityTextWrapper>
          </TransactionActivity>
          <StyledDivider orientation="vertical" variant="middle" flexItem />

          <TransactionActivity>
            <RenewIcon />
            <ActivityTextWrapper>
              <BBText
                text={translate('transactionDetails.summary.status')}
                type={'bold'}
                variant="subtitle1"
              />
              <BBText
                text={transactionStatus}
                type={'normal'}
                variant="subtitle1"
              />
            </ActivityTextWrapper>
          </TransactionActivity>
          <StyledDivider orientation="vertical" variant="middle" flexItem />

          <TransactionActivity>
            <FileTaskIcon />
            <ActivityTextWrapper>
              <BBText
                text={translate('transactionDetails.summary.tpa')}
                type={'bold'}
                variant="subtitle1"
              />
              <BBText
                text={getFormattedValue(threePaData?.threePa)}
                type={'normal'}
                variant="subtitle1"
              />
            </ActivityTextWrapper>
          </TransactionActivity>
        </TransactionActivityContainer>
      </UserInfoContent>
    </ContentWrapper>
  );
};

export default TransactionSummary;
