import { ReactElement } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import { Container, Tooltip } from './RateTrackerTooltip.styles';
import TooltipMessage from './TooltipMessage';

interface RateTrackerTooltipProps {
  children: ReactElement;
  isRefreshedScheduled: boolean;
  expiryTime?: string;
}

const RateTrackerTooltip = (props: RateTrackerTooltipProps) => {
  const { children, isRefreshedScheduled, expiryTime } = props;

  if (!isRefreshedScheduled || !expiryTime) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      placement="top"
      message={<TooltipMessage expiryTime={expiryTime} />}
    >
      <Container>{children}</Container>
    </Tooltip>
  );
};

export default RateTrackerTooltip;
