// Validation Schema or files in the same folder
import { coloursAlias, dimensionsAlias } from './aliasThemeTokens';
import { fontFaces } from './themeToken';
// Types
import {
  Colours,
  Dimensions,
  ZIndexes,
  FontFaceDetails,
  GlobalStyles,
  Theme,
  BoxShadows,
} from '../../../types/ThemeConfigTypes';
import {
  TypographyVariants,
  FontStyleType,
  FontStyleObjectType,
  FontWeightObjectType,
  LetterSpacingObjectType,
  LinkStyleObjectType,
} from '../../../types/TypographyTypes';

export const FONT_STYLE_NORMAL_CONST: FontStyleType = 'normal';

export const FONT_STYLE_NORMAL: FontStyleObjectType = {
  fontStyle: FONT_STYLE_NORMAL_CONST,
};

export const FONT_WEIGHT_BOLD: FontWeightObjectType = {
  fontWeight: 700,
};

export const FONT_WEIGHT_MEDIUM: FontWeightObjectType = {
  fontWeight: 500,
};

export const FONT_WEIGHT_BOOK: FontWeightObjectType = {
  fontWeight: 400,
};

export const FONT_WEIGHT_REGULAR: FontWeightObjectType = {
  fontWeight: 300,
};

export const LETTER_SPACING_DEFAULT: LetterSpacingObjectType = {
  letterSpacing: 0,
};

export const LETTER_SPACING_QUARTER: LetterSpacingObjectType = {
  letterSpacing: 0.25,
};

export const LINK_STYLE: LinkStyleObjectType = {
  textDecorationLine: 'underline',
};

const { spacing, radius } = dimensionsAlias;

export const getThemeColors = (): Colours => {
  return {
    text: {
      text: coloursAlias.neutralDefault,
      textWeak: coloursAlias.neutralMedium,
      textWeaker: coloursAlias.neutralFair,
      textWeakest: coloursAlias.neutralLighter,
      textAlt: coloursAlias.neutralWhite,
      black: coloursAlias.neutralDefault,
      white: coloursAlias.neutralWhite,
      active: coloursAlias.brandDefault,
      activeMedium: coloursAlias.brandMedium,
      activeFair: coloursAlias.brandFair,
      activeLight: coloursAlias.brandLight,
      activeDark: coloursAlias.brandDark,
      input: coloursAlias.neutralDefault,
      focus: coloursAlias.brandDefault,
      label: coloursAlias.neutralFair,
      disabled: coloursAlias.neutralLight,
      error: coloursAlias.error,
      errorLighter: coloursAlias.errorLighter,
      success: coloursAlias.success,
      successLighter: coloursAlias.successLighter,
      warning: coloursAlias.warning,
      warningLighter: coloursAlias.warningLighter,
      pending: coloursAlias.pending,
      pendingLighter: coloursAlias.warningLighter,
      notice: coloursAlias.brandDefault,
      noticeLighter: coloursAlias.brandLightest,
      accent: coloursAlias.accent,
      accentAlt: coloursAlias.accentAlt,
    },
    backgrounds: {
      bg: coloursAlias.neutralLightest,
      bgMedium: coloursAlias.neutralMedium,
      bgLight: coloursAlias.neutralLight,
      bgLighter: coloursAlias.neutralLighter,
      bgAlt: coloursAlias.neutralWhite,
      black: coloursAlias.neutralDefault,
      white: coloursAlias.neutralWhite,
      active: coloursAlias.brandDefault,
      activeFair: coloursAlias.brandFair,
      activeLight: coloursAlias.brandLight,
      activeLightest: coloursAlias.brandLightest,
      activeDark: coloursAlias.brandDark,
      disabled: coloursAlias.neutralLightest,
      backdrop: 'rgba(0, 0, 0, 0.5000)',
      error: coloursAlias.error,
      errorLight: coloursAlias.errorLight,
      errorLighter: coloursAlias.errorLighter,
      success: coloursAlias.success,
      successLighter: coloursAlias.successLighter,
      warning: coloursAlias.warning,
      warningLighter: coloursAlias.warningLighter,
      pending: coloursAlias.pending,
      pendingLighter: coloursAlias.pendingLighter,
      notice: coloursAlias.notice,
      noticeLighter: coloursAlias.noticeLighter,
      accent: coloursAlias.accent,
      accentAlt: coloursAlias.accentAlt,
    },
    borders: {
      border: coloursAlias.neutralLightest,
      borderLight: coloursAlias.neutralLight,
      borderLighter: coloursAlias.neutralLighter,
      alt: coloursAlias.neutralWhite,
      black: coloursAlias.neutralDefault,
      white: coloursAlias.neutralWhite,
      active: coloursAlias.brandDefault,
      activeFair: coloursAlias.brandFair,
      activeLight: coloursAlias.brandLight,
      activeDark: coloursAlias.brandDark,
      focus: coloursAlias.brandDefault,
      selectionHover: coloursAlias.neutralLight,
      disabled: coloursAlias.neutralLight,
      input: coloursAlias.neutralLighter,
      error: coloursAlias.error,
      errorLight: coloursAlias.errorLight,
      errorLighter: coloursAlias.errorLighter,
      success: coloursAlias.success,
      successLighter: coloursAlias.successLighter,
      warning: coloursAlias.warning,
      warningLighter: coloursAlias.warningLighter,
      pending: coloursAlias.pending,
      pendingLighter: coloursAlias.pendingLighter,
      notice: coloursAlias.notice,
      noticeLighter: coloursAlias.noticeLighter,
      cursor: coloursAlias.neutralBlack,
      accent: coloursAlias.accent,
      accentAlt: coloursAlias.accentAlt,
    },
    icon: {
      icon: coloursAlias.neutralDefault,
      iconAlt: coloursAlias.neutralWhite,
      black: coloursAlias.neutralDefault,
      white: coloursAlias.neutralWhite,
      active: coloursAlias.brandDefault,
      activeFair: coloursAlias.brandFair,
      activeLight: coloursAlias.brandLight,
      activeDark: coloursAlias.brandDark,
      focus: coloursAlias.brandDefault,
      iconDisabled: coloursAlias.neutralFair,
      error: coloursAlias.error,
      success: coloursAlias.success,
      warning: coloursAlias.warning,
      notice: coloursAlias.notice,
      pending: coloursAlias.pending,
      accent: coloursAlias.accent,
      accentAlt: coloursAlias.accentAlt,
    },
    buttons: {
      primary: {
        text: coloursAlias.neutralWhite,
        icon: coloursAlias.neutralWhite,
        bg: coloursAlias.brandDefault,
        hover: {
          text: coloursAlias.neutralWhite,
          icon: coloursAlias.neutralWhite,
          bg: coloursAlias.brandDefault,
        },
        focus: {
          focusBorder: coloursAlias.brandDefault,
          text: coloursAlias.neutralWhite,
          icon: coloursAlias.neutralWhite,
          bg: coloursAlias.brandDefault,
        },
      },
      secondary: {
        text: coloursAlias.neutralWhite,
        icon: coloursAlias.neutralWhite,
        bg: coloursAlias.brandDefault,
        hover: {
          text: coloursAlias.neutralWhite,
          icon: coloursAlias.neutralWhite,
          bg: coloursAlias.brandDark,
        },
        focus: {
          text: coloursAlias.neutralWhite,
          icon: coloursAlias.neutralWhite,
          focusBorder: coloursAlias.brandDefault,
          bg: coloursAlias.brandDark,
        },
      },
      outline: {
        text: coloursAlias.neutralDefault,
        icon: coloursAlias.brandDefault,
        border: coloursAlias.neutralLighter,
        bg: coloursAlias.neutralWhite,
        hover: {
          text: coloursAlias.neutralDefault,
          icon: coloursAlias.brandDefault,
          border: coloursAlias.brandDefault,
          bg: coloursAlias.neutralWhite,
        },
        focus: {
          text: coloursAlias.neutralDefault,
          icon: coloursAlias.brandDefault,
          focusBorder: coloursAlias.brandDefault,
          bg: coloursAlias.brandLightest,
        },
      },
      text: {
        text: coloursAlias.neutralDefault,
        icon: coloursAlias.brandDefault,
        hover: {
          text: coloursAlias.brandDefault,
          icon: coloursAlias.brandDefault,
        },
        focus: {
          text: coloursAlias.brandDefault,
          icon: coloursAlias.brandDefault,
          border: coloursAlias.brandDefault,
        },
      },
      destructive: {
        text: coloursAlias.error,
        icon: coloursAlias.error,
        bg: coloursAlias.errorLighter,
        hover: {
          text: coloursAlias.error,
          icon: coloursAlias.error,
          bg: coloursAlias.errorLight,
        },
        focus: {
          focusBorder: coloursAlias.error,
          text: coloursAlias.error,
          icon: coloursAlias.error,
          bg: coloursAlias.errorLighter,
        },
      },
    },
    scroll: {
      thumbBackground: '#C6C6C6',
      trackBackground: '#F4F4F4',
    },
  };
};

const getThemeDimensions = (): Dimensions => {
  return {
    spacing: {
      xxxSmall: spacing.xxxSmall,
      xxSmall: spacing.xxSmall,
      xSmall: spacing.xSmall,
      small: spacing.small,
      smallMedium: spacing.smallMedium,
      medium: spacing.medium,
      large: spacing.large,
      xLarge: spacing.xLarge,
      xxLarge: spacing.xxLarge,
      xxxLarge: spacing.xxxLarge,
      huge: spacing.huge,
      xHuge: spacing.xHuge,
      xxHuge: spacing.xxHuge,
      xxxHuge: spacing.xxxHuge,
      gigantic: spacing.gigantic,
      xGigantic: spacing.xGigantic,
      xxGigantic: spacing.xxGigantic,
    },
    radius: {
      select: radius.select,
      input: radius.input,
      card: radius.card,
      modal: radius.modal,
      circle: radius.circle,
      small: radius.small,
      btn: radius.btn,
      medium: radius.medium,
      xSmall: radius.xSmall,
    },
  };
};

const getThemeZIndex = (): ZIndexes => {
  return {
    tooltipArrow: 1,
    tooltipContainer: 2,
    addressLookupPopper: 2,
    popper: 2,
    scrollBarThumb: 100,
    dropdownPopper: 100,
    dropdownInput: 101,
    mobileMenu: 200,
  };
};

export const getFontFaceDetails = (): FontFaceDetails[] => {
  return [
    {
      fontName: fontFaces.bold.fontName,
      fileName: fontFaces.bold.fileName,
      fontWeight: fontFaces.bold.fontWeight,
      ...FONT_STYLE_NORMAL,
    },
    {
      fontName: fontFaces.medium.fontName,
      fileName: fontFaces.medium.fileName,
      fontWeight: fontFaces.medium.fontWeight,
      ...FONT_STYLE_NORMAL,
    },
    {
      fontName: fontFaces.book.fontName,
      fileName: fontFaces.book.fileName,
      fontWeight: fontFaces.book.fontWeight,
      ...FONT_STYLE_NORMAL,
    },
    {
      fontName: fontFaces.regular.fontName,
      fileName: fontFaces.regular.fileName,
      fontWeight: fontFaces.regular.fontWeight,
      ...FONT_STYLE_NORMAL,
    },
  ];
};

export const getGlobalStyles = () => {
  return {
    body: {
      margin: '0px',
      fontFamily: 'CircularXX, Helvetica, Roboto, Arial, sans-serif',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      color: colours.text.text,
    },
  };
};

export const getThemeTypography = (): TypographyVariants => {
  return {
    fontFamily: 'CircularXX, Helvetica, Roboto, Arial, sans-serif',

    displayHugeBold: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_BOLD,
      fontSize: 72,
      lineHeight: '80px',
      ...LETTER_SPACING_QUARTER,

      [`@media (max-width: ${breakpoints.sm}px)`]: {
        fontSize: 38,
        lineHeight: '56px',
        ...LETTER_SPACING_DEFAULT,
      },
    },
    displayLargeBold: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_BOLD,
      fontSize: 56,
      lineHeight: '62px',
      ...LETTER_SPACING_QUARTER,

      [`@media (max-width: ${breakpoints.sm}px)`]: {
        fontSize: 28,
        lineHeight: '40px',
        ...LETTER_SPACING_DEFAULT,
      },
    },
    displayMediumNorm: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 26,
      lineHeight: '34px',
      ...LETTER_SPACING_QUARTER,

      [`@media (max-width: ${breakpoints.sm})px`]: {
        ...LETTER_SPACING_DEFAULT,
      },
    },
    h1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 32,
        lineHeight: '48px',
        ...LETTER_SPACING_QUARTER,

        [`@media (max-width: ${breakpoints.sm}px)`]: {
          fontSize: 24,
          lineHeight: '32px',
          ...LETTER_SPACING_DEFAULT,
        },
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
      },
    },
    h2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 28,
        lineHeight: '40px',
        ...LETTER_SPACING_QUARTER,

        [`@media (max-width: ${breakpoints.sm}px)`]: {
          fontSize: 22,
          lineHeight: '32px',
          ...LETTER_SPACING_DEFAULT,
        },
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
      },
    },
    h3: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 24,
        lineHeight: '32px',
        ...LETTER_SPACING_QUARTER,

        [`@media (max-width: ${breakpoints.sm}px)`]: {
          fontSize: 20,
          lineHeight: '28px',
          ...LETTER_SPACING_DEFAULT,
        },
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
      },
    },
    body1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 18,
        lineHeight: '28px',
        ...LETTER_SPACING_DEFAULT,
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      link: {
        ...LINK_STYLE,
      },
      altLink: {
        ...FONT_WEIGHT_BOLD,
      },
    },
    body2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 16,
        lineHeight: '24px',
        ...LETTER_SPACING_DEFAULT,
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      link: {
        ...LINK_STYLE,
      },
      altLink: {
        ...FONT_WEIGHT_BOLD,
      },
    },
    // Corresponds to Body/Small
    subtitle1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 14,
        lineHeight: '22px',
        ...LETTER_SPACING_DEFAULT,
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
      },
      link: {
        ...LINK_STYLE,
      },
      altLink: {
        ...FONT_WEIGHT_MEDIUM,
      },
    },
    // Corresponds to Body/Extra Small
    subtitle2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_BOOK,
        fontSize: 12,
        lineHeight: '18px',
        ...LETTER_SPACING_DEFAULT,
      },
      bold: {
        ...FONT_WEIGHT_MEDIUM,
      },
      link: {
        ...LINK_STYLE,
      },
      altLink: {
        ...FONT_WEIGHT_MEDIUM,
      },
    },
    buttonLarge: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 18,
      lineHeight: '24px',
      ...LETTER_SPACING_DEFAULT,
    },
    buttonMedium: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 16,
      lineHeight: '24px',
      ...LETTER_SPACING_DEFAULT,
    },
    buttonSmall: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 16,
      lineHeight: '24px',
      ...LETTER_SPACING_DEFAULT,
    },
  };
};

const getBreakpoints = () => {
  return {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  };
};

const getBoxShadows = (): BoxShadows => {
  return {
    small: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
  };
};

const colours: Colours = getThemeColors();

const dimensions: Dimensions = getThemeDimensions();

const zindex: ZIndexes = getThemeZIndex();

const fontFace: FontFaceDetails[] = getFontFaceDetails();

const globalStyles: GlobalStyles = getGlobalStyles();

export const breakpoints = getBreakpoints();

const boxShadows: BoxShadows = getBoxShadows();

export const getDefaultTheme = (): Theme => {
  const customTypography = getThemeTypography();

  return {
    customTypography,
    colours,
    dimensions,
    zindex,
    fontFace,
    globalStyles,
    breakpoints: {
      values: breakpoints,
    },
    typography: {
      fontFamily: customTypography.fontFamily,
    },
    boxShadows,
  };
};
