import { useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBConfirmationModal from '../../../molecules/BBModal/BBConfirmationModal';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Utils
import { getIcon } from '../../../../core/utils/IconOrgData';

interface DeleteDisbursementConfirmationProps {
  isOpen: boolean;
  onDelete: () => void;
  onMaybeLater: () => void;
}

const DeleteDisbursementConfirmation = (
  props: DeleteDisbursementConfirmationProps
) => {
  const { isOpen, onDelete, onMaybeLater } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const ImportantNoticeIllustrationIcon = useMemo(
    () => getIcon(tenant, 'importantNoticeIllustration'),
    [tenant]
  );

  const baseTranslationKey = 'disbursement.viewDetails.deleteModal';

  return (
    <BBConfirmationModal
      isOpen={isOpen}
      icon={<ImportantNoticeIllustrationIcon />}
      message={translate(`${baseTranslationKey}.title`)}
      additionMessage={translate(`${baseTranslationKey}.description`)}
      primaryBtnLabel={translate('common.deleteBtn')}
      secondaryBtnLabel={translate(`${baseTranslationKey}.cancelButtonText`)}
      handlePrimaryBtnClick={onDelete}
      handleSecondaryBtnClick={onMaybeLater}
    />
  );
};

export default DeleteDisbursementConfirmation;
