// Types
import { UpsertPaymentRequest } from '../../../../../core/types/ApiTypes';
import {
  Payment,
  PaymentMethodType,
} from '../../../../../core/types/PaymentTypes';
import { FormValues } from './types';

export const mapPaymentToFormValues = (payment: Payment): FormValues => {
  const { paymentMethod, walletId } = payment;

  if (paymentMethod === PaymentMethodType.ManualBankTransfer) {
    return {
      type: 'bankAccount',
    };
  }

  return {
    type: 'wallet',
    walletId: walletId ?? '',
  };
};

export const mapFormValuesToPaymentUpsertRequest = (
  values: FormValues
): UpsertPaymentRequest => {
  if (values.type === 'wallet') {
    return {
      paymentMethod: PaymentMethodType.Wallet,
      walletId: values.walletId,
    };
  }

  return {
    paymentMethod: PaymentMethodType.ManualBankTransfer,
  };
};
