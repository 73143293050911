import { createAction } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

// Types
import { SMSVerificationSuccessResponse } from '../../core/types/ApiTypes';
// Utils
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  setTokens,
} from '../../core/utils/AuthUtils';
// Constants
import {
  AUTH_ORG_CODE,
  AUTH_SCOPE,
  BASIC_AUTH_TOKEN,
  HEADER_COMPARISON_FAILED_ERROR,
} from '../../core/utils/Constants/Constants';
import { REFRESH_TOKEN_URL } from './urlConstants';

const mutex = new Mutex();

const authEndpoints = ['login', 'refresh-token', 'verifysms'];

const fetchQuery = fetchBaseQuery({
  baseUrl: window.location.origin,
  prepareHeaders: (headers, { endpoint }) => {
    const accessToken = getAccessToken();
    if (authEndpoints.includes(endpoint.toLocaleLowerCase())) {
      headers.set('Authorization', `Basic ${BASIC_AUTH_TOKEN}`);
    } else if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
  },
});

const fetchRefreshToken: BaseQueryFn = async (
  refreshToken,
  api,
  extraOptions
) => {
  const data = {
    refresh_token: refreshToken,
    scope: AUTH_SCOPE,
    orgCode: AUTH_ORG_CODE,
  };
  const refreshResults = await fetchQuery(
    {
      url: REFRESH_TOKEN_URL,
      method: 'POST',
      body: data,
    },
    { ...api, endpoint: 'refresh-token' },
    extraOptions
  );
  return refreshResults;
};

const baseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  let result: any = await fetchQuery(args, api, extraOptions);

  let isUnauthorised = (result.error && result.error.status === 401) ?? false;
  const errorDescription =
    result.error && result.error.data?.description?.toLowerCase();

  //Note: User need to be redirected to login on Header comparison failure error(401)
  //All 401 unauthorized error have same error code 3001 from API.
  //So, comparing the error message to logout the user. Need to be revisited.
  if (
    isUnauthorised &&
    errorDescription?.includes(HEADER_COMPARISON_FAILED_ERROR)
  ) {
    clearTokens();
    return;
  }

  // refresh the token on unauthorized api errors
  if (isUnauthorised) {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResults = await fetchRefreshToken(
            refreshToken,
            api,
            extraOptions
          );
          if (refreshResults?.data) {
            setTokens(refreshResults.data as SMSVerificationSuccessResponse);
            //retry the initial query with new token
            result = await fetchQuery(args, api, extraOptions);
          } else {
            // NOTE: need to handle the redirection here
            clearTokens();
          }
        } finally {
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await fetchQuery(args, api, extraOptions);
      }
    } else {
      clearTokens();
    }
  }

  if (result.data && typeof result.data === 'object' && 'data' in result.data) {
    return { ...result, data: result.data.data };
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Transactions',
    'PaymentRequests',
    'BankAccountFields',
    'BankAccountList',
    'Payments',
    'Wallets',
    'AccountPaymentRequests',
    'Profile',
    'Disbursements',
    'AvailableBalance',
    'VerifyWallet',
    'WalletDetailsFromTan',
    'ThreePa',
  ],
  endpoints: () => ({}),
});

export const apiError = createAction<unknown>('api-error');
