// Components - Atoms, Molecules, Organisms, Pages
import BBDataTable from '../../../../organisms/BBDataTable';
// Hooks
import { useColumns } from './useColumns';
// Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
// Utils
import { mapPaymentToPaymentRow } from './utils';

interface PaymentsTableProps {
  payments: PaymentRequestDetails['payments'];
}

const PaymentsTable = (props: PaymentsTableProps) => {
  const { payments } = props;

  const { columns } = useColumns();
  const rows = payments.map(mapPaymentToPaymentRow);

  return (
    <BBDataTable
      columnVisibilityModel={{ createdDate: false }}
      columns={columns}
      rows={rows}
      hideFooter
      defaultSortModel={[{ field: 'createdDate', sort: 'desc' }]}
    />
  );
};

export default PaymentsTable;
