export interface Permission {
  id: number;
  name: string;
}

export enum RoleType {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  SettlementAgent = 'SETTLEMENT_AGENT',
  LeadSolicitor = 'LEADSOLICITOR',
  Administrator = 'ADMINISTRATOR',
  ComplianceUser = 'COMPLAINCEUSER',
}

export interface Role {
  id: number;
  name: RoleType;
  permissions: Permission[];
}

export interface Account {
  id: number;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNo: string;
  accountType: string;
  status: boolean;
  accountName: string;
  titanAccountNo: string;
  crmContactId: string;
  crmAccountId: string;
  legalEntity: string;
  customerType: string;
  isActive: boolean;
  isDeleted: boolean;
  createdDate: string;
  updatedDate: string;
  contactCountryCode: string;
  roles?: Role[];
}
export interface TransactionAccount {
  id: number;
  name: string;
  email: string;
  contactNo: string;
  contactCountryCode: string;
  createdDate: string;
  updatedDate: string;
}

export interface DisbursementCRMAccount
  extends Pick<
    Account,
    | 'id'
    | 'name'
    | 'email'
    | 'contactNo'
    | 'contactCountryCode'
    | 'roles'
    | 'titanAccountNo'
    | 'isDeleted'
    | 'createdDate'
    | 'updatedDate'
  > {}
