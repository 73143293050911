// Types
import { DisbursementStatus } from '../../types/DisbursementTypes';
// Utils
import { getTrimmedLowercaseValue } from '../Format';

export const isAllowedStatus = (status?: string): boolean => {
  const allowedStatuses = [
    DisbursementStatus.Ready.toLowerCase(),
    DisbursementStatus.Processing.toLowerCase(),
    DisbursementStatus.Completed.toLowerCase(),
    DisbursementStatus.Failed.toLowerCase(),
  ];
  return allowedStatuses.includes(getTrimmedLowercaseValue(status));
};
