import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';

//Hooks
import { useTranslations } from '../../../../core/hooks';
import { useValidations } from './useValidation';

//Components
import { DisbursementDetailsFormValues } from './types';
import {
  ActionButtonsContainer,
  CenterizedContent,
  FieldSets,
  FormContainer,
  StyledFormTitle,
  StyledListTitle,
} from '../../CommonStyles/CommonStyles.styles';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import BBSelect from '../../../atoms/BBSelect';
import RHFBBAmount from '../../../molecules/BBAmount';
import BBButton from '../../../atoms/BBButton';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
import { BBSelectProps } from '../../../atoms/BBSelect/BBSelect';
import BBInput, { InputProps } from '../../../atoms/BBInput/BBInput';
import RadioCards from './RadioCards';
import { Section, SectionHeader } from './DisbursementForm.styles';
import BBLoader from '../../../atoms/BBLoader';
import BBText from '../../../atoms/BBText';

//Types
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';
import {
  Disbursement,
  PaymentOptionTypes,
} from '../../../../core/types/DisbursementTypes';

//Api wrappers
import { useUpsertDisbursementMutation } from '../../../../redux/api/disbursements';

//Utils
import { DEFAULT_PAYMENT_VALUE_CURRENCY } from '../../../../core/utils/Constants/Constants';
import {
  mapDisbursementsToFormValues,
  mapFormValuesToDisbursementUpsertRequest,
} from './utils';

interface DetailsFormProps {
  activeStep: number;
  paymentTypeOptions?: PaymentOptionTypes[];
  walletDetails: WalletWithUserDetails[];
  disbursementDetails: Disbursement | undefined;
  transactionId: string;
  isLoadingWallets: boolean | undefined;
  disbursementId: string;
}

const defaultValues: DisbursementDetailsFormValues = {
  walletId: null,
  paymentType: '',
  paymentTypeDescription: '',
  paymentValue: '',
  paymentValueCurrency: DEFAULT_PAYMENT_VALUE_CURRENCY,
};

const getSectionHeading = (text: string) => {
  return <StyledFormTitle variant="body1" type="medium" text={text} />;
};

export default function DetailsForm({
  activeStep,
  paymentTypeOptions,
  walletDetails,
  transactionId,
  isLoadingWallets,
  disbursementId,
  disbursementDetails,
}: DetailsFormProps) {
  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);
  const { validations } = useValidations();
  const navigate = useNavigate();

  const [upsertDisbursement] = useUpsertDisbursementMutation();

  const hasWallets: boolean = Boolean(walletDetails.length);
  const continueBtnText: string = translate('steppers.continueBtn');

  const form = useForm<DisbursementDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: disbursementDetails
      ? mapDisbursementsToFormValues(disbursementDetails)
      : defaultValues,
    // @ts-ignore
    resolver: yupResolver(validations),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isSubmitting, isDirty, dirtyFields },
  } = form;

  const formValues: DisbursementDetailsFormValues = watch();

  const textFieldProps: InputProps = {
    name: 'paymentValue',
    placeholderLabel: translate(
      'disbursement.disbursementDetails.fields.paymentAmount'
    ),
    value: formValues.paymentValue,
    type: 'number',
  };

  const dropdownProps: BBSelectProps = {
    name: 'paymentValueCurrency',
    placeholderLabel: '',
    value: formValues.paymentValueCurrency,
    type: 'currency',
    toShrinkLabel: false,
    isSearchable: false,
    readOnly: true,
  };

  const isPaymentTypeOther: boolean = formValues.paymentType === 'Other';

  const onSubmit = async (data: DisbursementDetailsFormValues) => {
    try {
      const disbursementData = mapFormValuesToDisbursementUpsertRequest(
        data,
        walletDetails,
        transactionId,
        disbursementId
      );
      const paidFromWallet = dirtyFields?.walletId
        ? disbursementData.paidFromWallet
        : disbursementDetails?.paidFromWallet;

      const { id } = await upsertDisbursement({
        ...disbursementData,
        paidFromWallet,
      }).unwrap();
      navigate(
        `/transaction-details/${transactionId}/disbursements/${id}/recipient`
      );
    } catch {}
  };

  useEffect(() => {
    if (!isPaymentTypeOther) {
      setValue('paymentTypeDescription', '');
    }
  }, [isPaymentTypeOther, setValue]);

  return (
    <>
      <FormProvider {...form}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          <FieldSets layout={layout}>
            <Section layout={layout}>
              <SectionHeader>
                {getSectionHeading(
                  translate(
                    'disbursement.disbursementDetails.disbursementSectionHeading'
                  )
                )}
                <StyledListTitle
                  type="normal"
                  variant="body2"
                  text={translate(
                    'disbursement.disbursementDetails.fields.walletList'
                  )}
                />
              </SectionHeader>
              {isLoadingWallets && (
                <CenterizedContent>
                  <BBLoader />
                </CenterizedContent>
              )}
              {!isLoadingWallets && (
                <>
                  {!hasWallets && (
                    <CenterizedContent>
                      <BBText
                        variant="body2"
                        type="bold"
                        text={translate(
                          `buyer.createPayment.paymentMethod.noWalletsText`
                        )}
                      />
                    </CenterizedContent>
                  )}

                  {hasWallets && <RadioCards walletDetails={walletDetails} />}
                </>
              )}
              <FormControl>
                <ControllerElementWrapper
                  name="paymentType"
                  control={control}
                  value={formValues.paymentType}
                  placeholderLabel={translate(
                    'disbursement.disbursementDetails.fields.paymentType'
                  )}
                  component={BBSelect}
                  size="normal"
                  options={paymentTypeOptions}
                />
              </FormControl>
              {isPaymentTypeOther && (
                <FormControl>
                  <ControllerElementWrapper
                    name="paymentTypeDescription"
                    control={control}
                    placeholderLabel={translate(
                      'disbursement.disbursementDetails.fields.otherPayment'
                    )}
                    component={BBInput}
                    value={formValues.paymentTypeDescription}
                  />
                </FormControl>
              )}
            </Section>
            {formValues.paymentType && (
              <Section layout={layout}>
                {getSectionHeading(
                  translate(
                    'disbursement.disbursementDetails.paymentSectionHeading'
                  )
                )}
                <FormControl>
                  <RHFBBAmount
                    control={control}
                    textFieldProps={textFieldProps}
                    dropdownProps={dropdownProps}
                  />
                </FormControl>
              </Section>
            )}
          </FieldSets>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              btnType="secondary"
              size="medium"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {continueBtnText}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
      </FormProvider>
      {isDirty && !isSubmitting && <NavigationBlocker />}
    </>
  );
}
