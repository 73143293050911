import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

// Components - Atoms, Molecules, Organisms, Pages
import BBMobileHeader from '../../molecules/BBMobileHeader';
import BBMobileMenu from '../../molecules/BBMobileMenu';
import BBFooter from '../../molecules/BBFooter';

interface BBMobileMenuContainerProps {
  fullName?: string;
  initials?: string;
  customerId?: string;
  isBuyerFlow?: boolean;
  isLoginLayout?: boolean;
}

const MobileMenuWrapper = styled(Box)(({ theme }) => {
  const {
    zindex: { mobileMenu },
  } = theme;

  return {
    width: '100%',
    height: '80px',
    position: 'fixed',
    top: 0,
    zIndex: mobileMenu,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '500ms',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  };
});

const Container = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { activeDark },
    },
    dimensions: {
      spacing: { small, medium },
    },
  } = theme;

  return {
    minHeight: 'calc(100svh - 120px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: small,
    padding: medium,
    backgroundColor: activeDark,
  };
});

const BBMobileMenuContainer: FC<BBMobileMenuContainerProps> = (
  props: BBMobileMenuContainerProps
) => {
  const {
    fullName,
    initials,
    customerId,
    isBuyerFlow = false,
    isLoginLayout = false,
  } = props;

  const [showMenu, setShowMenu] = useState(false);

  return (
    <MobileMenuWrapper data-testid="mobile-menu-container">
      <BBMobileHeader showMenu={showMenu} setShowMenu={setShowMenu} />
      <Slide direction="left" in={showMenu} mountOnEnter timeout={350}>
        <Container>
          {!isLoginLayout ? (
            <BBMobileMenu
              fullName={fullName ?? ''}
              initials={initials ?? ''}
              customerId={customerId ?? ''}
              isBuyerFlow={isBuyerFlow ?? ''}
            />
          ) : (
            /*TODO - This will act as placeholder for menu, so the footer will 
              always on the bottom. once logout menus are decided it will replace the div
            */
            <div></div>
          )}
          <BBFooter />
        </Container>
      </Slide>
    </MobileMenuWrapper>
  );
};

export default BBMobileMenuContainer;
