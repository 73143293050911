import { useDispatch } from 'react-redux';

// RTK Slice
import {
  errorStateReset,
  showErrorNotification,
} from '../../redux/modules/errorDetailsSlice';

export const useNotifications = () => {
  const dispatch = useDispatch();

  const showError = (message: string) => {
    dispatch(
      showErrorNotification({
        error: true,
        message,
      })
    );

    setTimeout(() => {
      dispatch(errorStateReset());
    }, 6000);
  };

  return { showError };
};
