import { useContext, FC, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../atoms/BBButton';
import BBInput, { InputProps } from '../../../atoms/BBInput/BBInput';
import BBDatePicker from '../../../organisms/BBDatePicker';
import BBSelect, { BBSelectProps } from '../../../atoms/BBSelect/BBSelect';
import RHFBBAmount from '../../../molecules/BBAmount/RHFBBAmount';
import PPClientMultiCheckbox from '../../../molecules/PPClientMultiCheckbox';
import {
  FormContainer,
  FieldSets,
  ActionButtonsContainer,
  StyledFormTitle,
  Section,
  FormControl,
} from '../../CommonStyles/CommonStyles.styles';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { RequestDetailsFormValues } from './types';
import { TransactionAccount } from '../../../../core/types/AccountTypes';
import {
  PaymentRequestTypes,
  PaymentRequestDetails,
} from '../../../../core/types/PaymentRequestsTypes';
// API Wrappers
import { useUpsertPaymentRequestsMutation } from '../../../../redux/api';
// Utils
import {
  mapAccountsResponseFromAPI,
  getPaymentRequestsErrors,
  mapFormValuesToPaymentRequestsUpsertRequest,
  mapPaymentRequestsToFormValues,
} from './utils';
// Constants
import { DEFAULT_PAYMENT_VALUE_CURRENCY } from '../../../../core/utils/Constants/Constants';

interface RequestFormProps {
  activeStep: number;
  onContinue: (paymentRequestId: PaymentRequestDetails['id']) => void;
  transactionAccount: TransactionAccount[];
  paymentTypeOptions: PaymentRequestTypes[];
  transactionId: number;
  paymentRequestId: number;
  paymentRequestData: PaymentRequestDetails | undefined;
}

const defaultValues: RequestDetailsFormValues = {
  crmAccountIds: [],
  paymentType: '',
  paymentTypeDescription: '',
  paymentValue: '',
  paymentValueCurrency: DEFAULT_PAYMENT_VALUE_CURRENCY,
  dueDate: null,
};

const RequestForm: FC<RequestFormProps> = (props: RequestFormProps) => {
  const {
    activeStep,
    onContinue,
    transactionAccount,
    paymentTypeOptions,
    transactionId,
    paymentRequestId,
    paymentRequestData,
  } = props;

  const { layout } = useContext(LayoutContext);

  const { translate } = useTranslations();
  const { validationSchema } = useValidations();

  const [upsertPaymentRequests, { error: upsertPaymentRequestsError }] =
    useUpsertPaymentRequestsMutation();

  const apiErrors = useMemo(() => {
    return {
      ...getPaymentRequestsErrors(upsertPaymentRequestsError),
    };
  }, [upsertPaymentRequestsError]);

  //Translations
  const continueBtnText: string = translate('steppers.continueBtn');

  const form = useForm<RequestDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: paymentRequestData
      ? mapPaymentRequestsToFormValues(paymentRequestData)
      : defaultValues,
    errors: apiErrors,
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isSubmitting, isDirty },
  } = form;

  const formValues: RequestDetailsFormValues = watch();

  const textFieldProps: InputProps = {
    name: 'paymentValue',
    placeholderLabel: translate(
      'paymentRequest.requestDetails.fields.paymentValue'
    ),
    value: formValues.paymentValue,
    type: 'number',
  };

  const dropdownProps: BBSelectProps = {
    name: 'paymentValueCurrency',
    placeholderLabel: '',
    value: formValues.paymentValueCurrency,
    type: 'currency',
    toShrinkLabel: false,
    isSearchable: false,
  };

  const isPaymentTypeOther: boolean = formValues.paymentType === 'Other';

  const handleOnMultiCheckChange = (TransactionAccounts: number[]) => {
    setValue('crmAccountIds', TransactionAccounts);
  };

  const getSectionHeading = (text: string) => {
    return <StyledFormTitle variant="body1" type="medium" text={text} />;
  };

  const onSubmit = async (data: RequestDetailsFormValues) => {
    try {
      const paymentData = {
        ...mapFormValuesToPaymentRequestsUpsertRequest(data),
        transactionId: transactionId,
        ...(!isNaN(paymentRequestId) && { id: paymentRequestId }),
      };

      const { id } = await upsertPaymentRequests(paymentData).unwrap();
      onContinue(id);
    } catch {}
  };

  useEffect(() => {
    if (!isPaymentTypeOther) {
      setValue('paymentTypeDescription', '');
    }
  }, [isPaymentTypeOther, setValue]);

  return (
    <>
      <FormProvider {...form}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          <FieldSets layout={layout}>
            <Section layout={layout}>
              {getSectionHeading(
                translate('paymentRequest.requestDetails.requestSectionHeading')
              )}
              <ControllerElementWrapper
                name="crmAccountIds"
                control={control}
                component={PPClientMultiCheckbox}
                transactionAccountList={mapAccountsResponseFromAPI(
                  transactionAccount
                )}
                defaultValue={formValues.crmAccountIds}
                handleChange={handleOnMultiCheckChange}
              />
              <FormControl>
                <ControllerElementWrapper
                  name="paymentType"
                  control={control}
                  value={formValues.paymentType}
                  placeholderLabel={translate(
                    'paymentRequest.requestDetails.fields.paymentType'
                  )}
                  component={BBSelect}
                  size="normal"
                  options={paymentTypeOptions}
                />
              </FormControl>
              {isPaymentTypeOther && (
                <FormControl>
                  <ControllerElementWrapper
                    name="paymentTypeDescription"
                    control={control}
                    placeholderLabel={translate(
                      'paymentRequest.requestDetails.fields.otherPayment'
                    )}
                    component={BBInput}
                    value={formValues.paymentTypeDescription}
                  />
                </FormControl>
              )}
            </Section>
            {formValues.paymentType && (
              <Section layout={layout}>
                {getSectionHeading(
                  translate(
                    'paymentRequest.requestDetails.paymentSectionHeading'
                  )
                )}
                <FormControl>
                  <RHFBBAmount
                    control={control}
                    textFieldProps={textFieldProps}
                    dropdownProps={dropdownProps}
                  />
                </FormControl>
                <FormControl>
                  <ControllerElementWrapper
                    name="dueDate"
                    control={control}
                    label={translate(
                      'paymentRequest.requestDetails.fields.dueDate'
                    )}
                    component={BBDatePicker}
                    value={formValues.dueDate}
                    minDate={new Date()}
                  />
                </FormControl>
              </Section>
            )}
          </FieldSets>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              btnType="secondary"
              size="medium"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {continueBtnText}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
      </FormProvider>
      {isDirty && !isSubmitting && <NavigationBlocker />}
    </>
  );
};

export default RequestForm;
