import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Stack, styled } from '@mui/material';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { BBText } from '../../atoms/BBText';
import { BBButton } from '../../atoms/BBButton';
import BBLoader from '../../atoms/BBLoader';
import BBInput from '../../atoms/BBInput';
import BBLogoWrapper from '../../organisms/BBLogoWrapper';
import BBLink from '../../atoms/BBLink';
import RHFBBAddressLookup from '../../molecules/BBAddressLookup';
import BBSelect from '../../atoms/BBSelect/BBSelect';
import BBLinearProgressBar from '../../atoms/BBLinearProgressBar/BBLinearProgressBar';
import BBCheckbox from '../../atoms/BBCheckbox';
import BBPagination from '../../organisms/BBPagination';
import BBTabs, { BBTab, BBTabList, BBTabPanel } from '../../molecules/BBTabs';
import BBTextLink from '../../organisms/BBTextLink';
import BBDatePicker from '../../organisms/BBDatePicker';
import BBConfirmationModal from '../../molecules/BBModal/BBConfirmationModal';
import BBLabel from '../../atoms/BBLabel';
import BBTooltip from '../../atoms/BBTooltip';
// Types
import { AppDispatch } from '../../../redux/store';
// RTK Slice
// API Wrappers
import getProfileDetails from '../../../redux/api/getProfileDetails';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';

const Text = styled('div')(({ theme }) => {
  const {
    customTypography: { h1 },
  } = theme;

  return {
    ...h1.normal,
  };
});

const LandingPage = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const [isConfirmationModalDisplayed, setIsConfirmationModalDisplayed] =
    useState(false);

  const selectedCountry = 'GBR';

  const [currentMenuItem, setCurrentMenuItem] = useState('one');

  const [dateValue, setDateValue] = useState<Date | null>(null);

  const onChange = (selected: string) => {
    console.log('selected:', selected);
    // setSelectedCountry(selected?.value)
  };

  const handleDatePickerChange = (value: Date | null) => setDateValue(value);

  const dispatch: AppDispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('all');

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack spacing={2} direction="column">
        {/* <PPAddPaymentSection addPaymentBtnHandler={handleOnAddPaymentClick} /> */}
        <BBPagination count={10} />
        <Text>Some test text</Text>
        <br />
        {/* Type Square, Button, Line */}
        {/* default variant is Square */}

        {/* default variant */}
        <BBTabs value={currentTab}>
          <BBTabList onChange={(_, value) => setCurrentTab(value)}>
            <BBTab value="all" label="All" />
            <BBTab value="open" label="Open" />
            <BBTab value="completed" label="Completed" />
            <BBTab value="cancelled" label="Cancelled" />
            <BBTab value="draft" label="Draft" />
          </BBTabList>
          <BBTabPanel value="all">All items</BBTabPanel>
          <BBTabPanel value="open">Open items</BBTabPanel>
          <BBTabPanel value="completed">Completed items</BBTabPanel>
          <BBTabPanel value="cancelled">Cancelled items</BBTabPanel>
          <BBTabPanel value="draft">Draft items</BBTabPanel>
        </BBTabs>

        {/* Line variant */}
        <BBTabs value={currentTab}>
          <BBTabList onChange={(_, value) => setCurrentTab(value)} type="line">
            <BBTab value="all" label="All" type="line" />
            <BBTab value="open" label="Open" type="line" />
            <BBTab value="completed" label="Completed" type="line" />
            <BBTab value="cancelled" label="Cancelled" type="line" />
            <BBTab value="draft" label="Draft" type="line" />
          </BBTabList>
          <BBTabPanel value="all">All items</BBTabPanel>
          <BBTabPanel value="open">Open items</BBTabPanel>
          <BBTabPanel value="completed">Completed items</BBTabPanel>
          <BBTabPanel value="cancelled">Cancelled items</BBTabPanel>
          <BBTabPanel value="draft">Draft items</BBTabPanel>
        </BBTabs>

        <Stack spacing={1} direction="row" alignItems="center">
          <BBLabel variant="default">Default label</BBLabel>
          <BBLabel variant="error">Error label</BBLabel>
          <BBLabel variant="warning">Warning label</BBLabel>
          <BBLabel variant="success">Success label</BBLabel>
          <BBLabel variant="notice">Notice label</BBLabel>
          <BBLabel variant="pending">Pending label</BBLabel>
          <BBLabel variant="section">Section label</BBLabel>
        </Stack>

        <Stack spacing={1} direction="row" alignItems="center">
          <BBLabel size="small" variant="default">
            Default label
          </BBLabel>
          <BBLabel size="small" variant="error">
            Error label
          </BBLabel>
          <BBLabel size="small" variant="warning">
            Warning label
          </BBLabel>
          <BBLabel size="small" variant="success">
            Success label
          </BBLabel>
          <BBLabel size="small" variant="notice">
            Notice label
          </BBLabel>
          <BBLabel size="small" variant="pending">
            Pending label
          </BBLabel>
          <BBLabel size="small" variant="section">
            Section label
          </BBLabel>
        </Stack>

        <BBDatePicker
          name="datepicker"
          value={dateValue}
          label="Datepicker"
          onChange={handleDatePickerChange}
        />

        <>
          <BBSelect
            name="country-drop-down-1"
            type="country"
            placeholderLabel="Country of residence"
            variant="normal"
            onChange={onChange}
            defaultValue={selectedCountry}
          />
          <RHFBBAddressLookup selectedCountry={selectedCountry} />
        </>
        <BBTextLink
          options={[
            { value: 'one', label: 'Item one' },
            { value: 'two', label: 'Item two' },
            { value: 'three', label: 'Item three' },
          ]}
          value={currentMenuItem}
          onChange={(_, value) => setCurrentMenuItem(value)}
        />
        <span>
          <BBTooltip title="Tooltip content">
            <strong>Text with tooltip</strong>
          </BBTooltip>
        </span>
        {/* Normal Header */}
        <BBSelect
          name="country-drop-down-1"
          type="country"
          placeholderLabel="Country of residence"
          variant="normal"
          isSearchable={false}
        />
        <BBSelect
          name="country-drop-down-1"
          type="currency"
          placeholderLabel="Select currency"
          variant="normal"
          isSearchable={true}
        />
        <BBSelect
          name="country-drop-down-1"
          type="dialingCode"
          placeholderLabel="Select country code"
          variant="normal"
          isSearchable={true}
        />
        <br />
        <BBCheckbox type="large" label="Checkbox large" />
        <BBCheckbox type="normal" label="Checkbox normal" />
        <BBCheckbox type="small" label="Checkbox small" />

        <BBCheckbox type="large" label="Checkbox large" value={true} disabled />
        <BBCheckbox type="normal" label="Checkbox normal" disabled />
        <BBCheckbox type="small" label="Checkbox small" disabled />
        <BBText variant="h2" type="normal" text={translate('common.title')} />
        <BBText
          variant="h2"
          type="normal"
          text={`v${process.env?.REACT_APP_VERSION}`}
        />
        <BBButton size="large" startIconType="start">
          Continue
        </BBButton>
        <BBLoader type="primary" size="large" />
        <BBLinearProgressBar value={20} />
        <BBInput name="inputText" value="" placeholderLabel="First name" />
        <BBInput
          name="inputTextWithError"
          value=""
          placeholderLabel="First name"
          error="Required"
        />

        <BBLogoWrapper
          logoVariant="logo"
          link="https://uatcd.currenciesdirectholdings.com/CustomerPortal/landing.html"
        />
        <BBLink
          text={
            'https://uatcd.currenciesdirectholdings.com/CustomerPortal/landing.html'
          }
          href={
            'https://uatcd.currenciesdirectholdings.com/CustomerPortal/landing.html'
          }
          variant={'body2'}
          type={'medium'}
          target="_blank"
          onClick={() => console.log()}
          data-testid="bb-link-terms-and-conditions"
        />
      </Stack>
      <BBButton onClick={() => setIsConfirmationModalDisplayed(true)}>
        Open confirmation modal
      </BBButton>
      {isConfirmationModalDisplayed && (
        <BBConfirmationModal
          isOpen={isConfirmationModalDisplayed}
          handleClose={() => setIsConfirmationModalDisplayed(false)}
          message="Are you sure?"
          additionMessage="You are about to lose some of the data you have entered."
          handlePrimaryBtnClick={() => setIsConfirmationModalDisplayed(false)}
          handleSecondaryBtnClick={() => setIsConfirmationModalDisplayed(false)}
          primaryBtnLabel="Continue"
          secondaryBtnLabel="Cancel"
        />
      )}
    </Container>
  );
};

export default LandingPage;
