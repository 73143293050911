import { useLocation, useParams } from 'react-router-dom';
import { PageHeaders } from '../../../core/utils/Constants/Constants';
import { useTranslations } from '../../../core/hooks';
import usePayments from '../../pages/Buyer/CreatePayment/shared/hooks/usePayments';

export type HeaderNavigationItem = { label: string | number; path?: string };

const useHeaderNav = (type?: string) => {
  const { id, transactionId, paymentRequestId, paymentId } = useParams();
  const location = useLocation();
  const { translate } = useTranslations();

  const { paymentRequestQuery } = usePayments(paymentId, paymentRequestId);

  const data = paymentRequestQuery?.data;

  const pathname = location.pathname;

  const transactions = translate('header.transactions');
  const createTransaction = translate('header.createTransactionLabel');
  const paymentInstruction = translate('header.paymentInstruction');
  const addPaymentInstruction = translate('header.addPaymentRequestLabel');
  const disbursementDetails = translate('header.disbursementDetails');
  const addDisbursement = translate('header.addDisbursement');

  const getHeaderNavigations = (type: string | undefined) => {
    let navigations: Array<HeaderNavigationItem> = [];

    switch (type) {
      case PageHeaders.Transactions:
        navigations = [
          { label: transactions, path: '/dashboard' },
          ...(id ? [{ label: id }] : []),
        ];
        break;
      case PageHeaders.CreateTransactions:
        navigations = [{ label: createTransaction }];
        break;
      case PageHeaders.PaymentRequests:
        const paymentLabel = pathname.includes('view-details')
          ? paymentInstruction
          : addPaymentInstruction;
        navigations = [
          { label: transactions, path: '/dashboard' },
          ...(transactionId || id
            ? [
                {
                  label: transactionId ?? id ?? '',
                  path: `/transaction-details/${transactionId ?? id}`,
                },
              ]
            : []),
          { label: paymentLabel },
        ];
        break;
      case PageHeaders.Disbursements:
        const label = pathname.includes('view-details')
          ? disbursementDetails
          : addDisbursement;
        navigations = [
          { label: transactions, path: '/dashboard' },
          ...(transactionId || id
            ? [
                {
                  label: transactionId ?? id ?? '',
                  path: `/transaction-details/${transactionId ?? id}`,
                },
              ]
            : []),
          { label },
        ];
        break;
      case PageHeaders.Buyer:
        navigations = [
          { label: transactions, path: '/dashboard' },
          ...(transactionId
            ? [
                {
                  label: transactionId,
                },
              ]
            : []),
        ];
        break;
      case PageHeaders.BuyerPaymentRequests:
        const payemntType = data?.paymentTypeDescription ?? null;
        const buyerTransactionId = data?.transactionId ?? null;
        navigations = [
          { label: transactions, path: '/dashboard' },
          ...(buyerTransactionId
            ? [
                {
                  label: buyerTransactionId,
                  path: `/transactions/${buyerTransactionId}`,
                },
              ]
            : []),
          ...(payemntType
            ? [
                {
                  label: payemntType,
                },
              ]
            : []),
        ];
        break;
    }
    return navigations;
  };

  return getHeaderNavigations(type);
};

export default useHeaderNav;
