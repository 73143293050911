import { useState } from 'react';

interface Props {
  isLoading: boolean;
  isFetching: boolean;
}

export const useInitialLoading = (props: Props) => {
  const { isLoading, isFetching } = props;
  const [isInitiallyLoading, setIsInitiallyLoading] = useState(
    isLoading || isFetching
  );

  if (isInitiallyLoading && !isLoading && !isFetching) {
    setIsInitiallyLoading(false);
  }

  return { isInitiallyLoading };
};
