import { useContext } from 'react';
import { Link } from 'react-router-dom';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  InvitationButton,
  InvitationContainer,
  InvitationContent,
  InvitationText,
  InvitationTitleSummary,
} from './Invitation.styles';
import BBLabel from '../../../atoms/BBLabel';
import BBText from '../../../atoms/BBText';
// Hooks
import { useTranslations } from '../../../../core/hooks';
import { useAcceptInvitation } from './useAcceptInvitation';
// Types
import { InvitationStatus } from '../../../../core/types/PaymentRequestsTypes';
import { Invitation as InvitationType } from './types';
// Utils
import {
  getFormattedAddress,
  getFormattedValue,
} from '../../../../core/utils/Format';

interface InvitationProps {
  invitation: InvitationType;
}

const Invitation = (props: InvitationProps) => {
  const { paymentRequest, pbu } = props.invitation;
  const { invitationStatus, transaction } = paymentRequest;

  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const [acceptInvitation, { isLoading: isAcceptingInvitation }] =
    useAcceptInvitation();

  const canBeAccepted: boolean = invitationStatus !== InvitationStatus.Accepted;

  const onAccept = () => {
    acceptInvitation(paymentRequest);
  };

  return (
    <InvitationContainer ownerState={{ layout }}>
      <InvitationContent>
        <InvitationTitleSummary>
          {canBeAccepted && (
            <BBLabel size="small" variant="success">
              {translate('buyer.invitation.newStatus').toUpperCase()}
            </BBLabel>
          )}

          <BBText
            variant="body1"
            component="h1"
            type="bold"
            text={translate('buyer.invitation.title')}
          />
        </InvitationTitleSummary>
        <InvitationText
          variant="body2"
          type="normal"
          text={translate('buyer.invitation.message', {
            fullName: getFormattedValue(pbu?.pbuName),
            address: getFormattedAddress(transaction?.address),
          })}
        />
      </InvitationContent>

      {canBeAccepted && (
        <InvitationButton
          size="medium"
          ownerState={{ layout }}
          disabled={isAcceptingInvitation}
          onClick={onAccept}
        >
          {translate('buyer.invitation.acceptButtonText')}
        </InvitationButton>
      )}

      {!canBeAccepted && transaction && (
        <InvitationButton
          size="medium"
          ownerState={{ layout }}
          btnType="outlined"
          component={Link}
          to={`/transactions/${transaction.id}`}
        >
          {translate('buyer.invitation.viewButtonText')}
        </InvitationButton>
      )}
    </InvitationContainer>
  );
};

export default Invitation;
