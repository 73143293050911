import { useContext, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

//Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../../core/TenantProvider/contexts';

//Hooks
import { useTranslations } from '../../../../core/hooks';
import { useValidations } from './useValidations';

//Components
import BBText from '../../../atoms/BBText';
import {
  ActionButtonsContainer,
  FieldSets,
  FormContent,
  Header,
  StyledCloseIcon,
  StyledFormContainer,
  SubTitle,
} from './TwoFactorAuthentication.styles';
import BBButton from '../../../atoms/BBButton';
import PinField from './PinField';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';

//API wrappers
import { useVerifySMSMutation } from '../../../../redux/api/auth';

//Utils
import { getIcon } from '../../../../core/utils/IconOrgData';
import { setTokens } from '../../../../core/utils/AuthUtils';
import { SMSVerificationSuccessResponse } from '../../../../core/types/ApiTypes';

export interface SMSFormValues {
  otp?: string[];
}

export default function TwoFactorAuthForm() {
  let { state } = useLocation();

  const [verifySms] = useVerifySMSMutation();

  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();
  const { validations } = useValidations();
  const { tenant } = useContext(TenantContext);
  const navigate = useNavigate();

  const isTabletLayout = layout === 'tablet';
  const isDesktopLayout = layout === 'desktop';

  const buttonSize: 'medium' | 'large' | 'small' = isDesktopLayout
    ? 'large'
    : isTabletLayout
      ? 'medium'
      : 'small';

  const form = useForm<SMSFormValues>({
    resolver: yupResolver(validations),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = form;

  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const onSubmit = async (data: SMSFormValues) => {
    if (state?.userId && data?.otp) {
      try {
        const result = await verifySms({
          otp: data.otp.join(''),
          uuid: state?.userId,
        }).unwrap();

        if (!('code' in result)) {
          setTokens(result as SMSVerificationSuccessResponse);
          navigate('/dashboard', { replace: true });
        }
      } catch {}
    }
  };

  const handleClose = () => {
    navigate('/login');
  };

  //Note: This is to forward user to login page if tried to access the link directly
  //Can be better handled at the route level
  if (!state?.userId) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <StyledCloseIcon
        onClick={handleClose}
        name="closeOTPVerification"
        data-testid="bb-close-otp-verification"
      >
        <CloseIcon />
      </StyledCloseIcon>
      <FormProvider {...form}>
        <StyledFormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          <FormContent>
            <Header>
              <BBText
                variant="h1"
                type="bold"
                text={translate('login.twoFactorAuthentication.title')}
              />
              <SubTitle
                variant="body2"
                type="medium"
                text={translate('login.twoFactorAuthentication.subTitle')}
              />
            </Header>

            <FieldSets layout={layout}>
              <ControllerElementWrapper
                name="otp"
                control={control}
                defaultValue={['', '', '', '', '']}
                component={PinField}
                length={5}
              />
            </FieldSets>
          </FormContent>
          <ActionButtonsContainer layout={layout}>
            <BBButton
              btnType="secondary"
              size={buttonSize}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {translate('login.submitButtonText')}
            </BBButton>
            <BBButton
              size={buttonSize}
              btnType="text"
              onClick={handleClose}
              type="button"
            >
              {translate('login.backBtn')}
            </BBButton>
          </ActionButtonsContainer>
        </StyledFormContainer>
      </FormProvider>
    </>
  );
}
