import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

// RTK Slice
import { getUserProfileDetails } from '../../../../../../redux/modules/userProfileDetailsSlice';
// API Wrappers
import { useGetWalletsQuery } from '../../../../../../redux/api';

export const useWallets = (transactionId?: number) => {
  const { titanCustomerNo } = useSelector(getUserProfileDetails);
  const { data, ...query } = useGetWalletsQuery(
    transactionId && titanCustomerNo
      ? {
          titanAccountNumber: titanCustomerNo,
          transactionId,
        }
      : skipToken
  );

  const wallets = data?.walletList ?? [];

  return { wallets, ...query };
};
