import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import { Container } from '../../styles';
// Types
import { LayoutSize } from '../../../../../core/types/LayoutTypes';
// Utils
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';

interface SummaryContainerOwnerState {
  layout: LayoutSize;
}

export const SummaryContainer = styled(Container)<{
  ownerState: SummaryContainerOwnerState;
}>(({ theme, ownerState }) => {
  const {
    dimensions: { spacing },
  } = theme;
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    alignItems: 'center',
    marginTop: spacing.medium,

    ...(!isMobileLayout && {
      gap: spacing.large,
      flexDirection: 'row',
    }),
  };
});

export const SummaryContent = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    width: '100%',
  };
});

export const ProgressSeparator = styled('hr')(({ theme }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;

  return {
    width: '100%',
    border: 'none',
    margin: `${spacing.xxSmall}px 0`,
    borderTop: `1px solid ${colours.borders.border}`,
  };
});
