import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

// Components - Atoms, Molecules, Organisms, Pages
import PaymentDetailsContent from './PaymentDetailsContent';
import PageLoader from '../../../../organisms/PageLoader';
import NotFoundRedirect from '../../../../organisms/NotFoundRedirect';
// Hooks
import { useInitialLoading } from '../../../../../core/hooks';
import { useWallet } from './useWallet';
// Types
import { PaymentMethodType } from '../../../../../core/types/PaymentTypes';
// API Wrappers
import {
  useGetPaymentQuery,
  useGetPaymentRequestByIdQuery,
} from '../../../../../redux/api';
// Utils
import { parseNumber } from '../../../../../core/utils/Parse';
import { isNotFoundError } from '../../../../../core/utils/Errors';

type RouterParams = {
  paymentId: string;
};

const PaymentDetails = () => {
  const { paymentId } = useParams<RouterParams>();

  const { data: payment, ...paymentQuery } = useGetPaymentQuery(
    parseNumber(paymentId) ?? skipToken
  );

  const { data: paymentRequest } = useGetPaymentRequestByIdQuery(
    payment?.paymentRequest?.id ?? skipToken
  );

  const { isInitiallyLoading } = useInitialLoading(paymentQuery);

  const isWalletPayment: boolean =
    payment?.paymentMethod === PaymentMethodType.Wallet;

  const transactionId = paymentRequest?.transactionId;

  const walletQuery = useWallet(
    isWalletPayment ? payment?.walletId : undefined,
    transactionId
  );

  const isLoading: boolean = isInitiallyLoading || walletQuery.isLoading;

  // Note: once API's are ready, add the wallet errors check too.
  const error: unknown = paymentQuery.error || walletQuery.error;

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error) || !payment) {
    return <NotFoundRedirect />;
  }

  return (
    <PaymentDetailsContent payment={payment} wallet={walletQuery.wallet} />
  );
};

export default PaymentDetails;
