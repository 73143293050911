import { useContext, FC, Fragment, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';

// Contexts
import {
  LayoutContext,
  LanguageContext,
} from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../../atoms/BBButton';
import BBLink from '../../../../atoms/BBLink';
import BBStepPanel from '../../../../organisms/BBStepPanel/BBStepPanel';
import {
  MainContainer,
  FormContainer,
  SummaryField,
  ActionButtonsContainer,
  StyledPageTitle,
  StyledFormTitle,
  SummaryData,
  StyledDivider,
  PartyTitleWrapper,
} from '../../../CommonStyles/CommonStyles.styles';
import PageLoader from '../../../../organisms/PageLoader';
import NotFoundRedirect from '../../../../organisms/NotFoundRedirect';
import RemovePartyConfirmationModal from '../../RemovePartyConfirmationModal';
import { RemoveButton } from '../../styles';
// Hooks
import { useInitialLoading } from '../../../../../core/hooks';
// Types
import { ConfirmationViewType } from '../Confirmation';
// API Wrappers
import {
  useConfirmTransactionMutation,
  useDeleteAccountMutation,
  useGetTransactionQuery,
} from '../../../../../redux/api';
// Utils
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { isNotFoundError } from '../../../../../core/utils/Errors';
import {
  getFormattedAddress,
  getFormattedCurrencyValue,
  getFormattedDate,
  getFormattedValue,
} from '../../../../../core/utils/Format';

interface ConfirmationScreenProps {
  setViewType: (viewType: ConfirmationViewType) => void;
}

interface ListItemType {
  id: number;
  label: string;
  value: string;
}

type RouterParams = {
  id: string;
};

const ConfirmationScreen: FC<ConfirmationScreenProps> = (
  props: ConfirmationScreenProps
) => {
  const { setViewType } = props;

  const navigate = useNavigate();
  const { id } = useParams<RouterParams>();

  const [partyIdToRemove, setPartyIdToRemove] = useState<number | undefined>();

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { layout } = useContext(LayoutContext);

  const query = useGetTransactionQuery(id ? Number(id) : skipToken);
  const { isInitiallyLoading } = useInitialLoading(query);
  const { data, error } = query;

  const [deleteAccount, { isLoading: isDeletingAccount }] =
    useDeleteAccountMutation();
  const [confirmTransaction, { isLoading: isConfirmingTransaction }] =
    useConfirmTransactionMutation();

  const isIncompleteTransaction: boolean = !data?.crmAccounts?.length;
  const canRemoveParties: boolean = (data?.crmAccounts?.length ?? 0) > 1;

  //Translations
  const backBtnText: string = translate('common.backBtn');
  const confirmBtnText: string = translate('common.confirmBtn');

  const headingText: string = translate(
    'createTransaction.confirmation.heading'
  );
  const TransactionTitleText: string = translate(
    'createTransaction.confirmation.TransactionTitle'
  );
  const partyTitleText: string = translate(
    'createTransaction.confirmation.partyTitle'
  );
  const partyText: string = translate('createTransaction.confirmation.party');
  const editText: string = translate('createTransaction.confirmation.edit');
  const removeText: string = translate('createTransaction.confirmation.remove');

  const activeStep: number = 2;

  const TRANSACTION_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('createTransaction.confirmation.transactionReference'),
      value: String(data?.id),
    },
    {
      id: 1,
      label: translate('createTransaction.confirmation.fileReference'),
      value: getFormattedValue(data?.fileReference),
    },
    {
      id: 2,
      label: translate('createTransaction.confirmation.purchaseValue'),
      value: getFormattedCurrencyValue(data?.purchaseValue, data?.currency),
    },
    {
      id: 3,
      label: translate('createTransaction.confirmation.settlementDate'),
      value: getFormattedDate(data?.completionDate),
    },
    {
      id: 4,
      label: translate('createTransaction.confirmation.propertyAddress'),
      value: getFormattedAddress(data?.address),
    },
  ];

  const handleOnContinueClick = async () => {
    if (!id) {
      return;
    }

    await confirmTransaction(Number(id)).unwrap();
    setViewType('completed-screen');
  };

  const handleOnBackClick = () => {
    navigate(`/create-transactions/party-details/${id}`);
  };

  const handleTransactionDetailsEditClick = () => {
    navigate(`/create-transactions/transaction-details/${id}`);
  };

  const handlePartyDetailsEditClick = () => {
    navigate(`/create-transactions/party-details/${id}`);
  };

  const onPartyRemovalConfirmation = async () => {
    if (!partyIdToRemove) {
      return;
    }

    setPartyIdToRemove(undefined);

    await deleteAccount({
      accountId: partyIdToRemove,
      transactionId: Number(id),
    }).unwrap();
  };

  if (isInitiallyLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  if (isIncompleteTransaction) {
    return (
      <Navigate to={`/create-transactions/transaction-details/${id}`} replace />
    );
  }

  return (
    <>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={headingText}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <FormContainer layout={layout}>
          <SummaryField>
            <SummaryData>
              <StyledFormTitle
                variant="body1"
                type="medium"
                text={TransactionTitleText}
              />
              <List dense={true}>
                {TRANSACTION_DETAILS.map((item: ListItemType) => {
                  return (
                    <ListItem key={item.id}>
                      <ListItemText
                        primary={item.label}
                        secondary={item.value}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <BBLink
                underline="none"
                type="medium"
                text={editText}
                onClick={handleTransactionDetailsEditClick}
              />
            </SummaryData>
            <SummaryData>
              {data?.crmAccounts?.map((party, index) => (
                <Fragment key={party.id}>
                  <PartyTitleWrapper>
                    <StyledFormTitle
                      variant="body1"
                      type="medium"
                      text={
                        index === 0
                          ? partyTitleText
                          : `${partyText} ${index + 1}`
                      }
                    />

                    {canRemoveParties && (
                      <RemoveButton
                        disabled={isDeletingAccount}
                        onClick={() => setPartyIdToRemove(party.id)}
                        type="button"
                      >
                        {removeText}
                      </RemoveButton>
                    )}
                  </PartyTitleWrapper>
                  <List dense={true}>
                    <>
                      <ListItem>
                        <ListItemText
                          primary={translate(
                            'createTransaction.confirmation.buyerName'
                          )}
                          secondary={party.name}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={translate(
                            'createTransaction.confirmation.emailAddress'
                          )}
                          secondary={party.email}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={translate(
                            'createTransaction.confirmation.mobileNumber'
                          )}
                          secondary={`${party.contactCountryCode} ${party.contactNo}`}
                        />
                      </ListItem>
                    </>
                    {index + 1 !== data?.crmAccounts?.length && (
                      <StyledDivider variant="middle" flexItem />
                    )}
                  </List>
                </Fragment>
              ))}
              <BBLink
                underline="none"
                type="medium"
                text={editText}
                onClick={handlePartyDetailsEditClick}
              />
            </SummaryData>
          </SummaryField>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              size="medium"
              btnType="outlined"
              type="button"
              onClick={handleOnBackClick}
            >
              {backBtnText}
            </BBButton>
            <BBButton
              btnType="secondary"
              size="medium"
              type="button"
              onClick={handleOnContinueClick}
              disabled={isConfirmingTransaction}
            >
              {confirmBtnText}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'create-transaction'}
          idValues={{
            transactionId: id,
          }}
        />
      </MainContainer>

      {partyIdToRemove && (
        <RemovePartyConfirmationModal
          onCancel={() => setPartyIdToRemove(undefined)}
          onContinue={() => onPartyRemovalConfirmation()}
        />
      )}
    </>
  );
};

export default ConfirmationScreen;
