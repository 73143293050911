import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import { FieldSets as FieldSetsComponent } from '../../../CommonStyles/CommonStyles.styles';
import BBButton from '../../../../atoms/BBButton';
import BBText from '../../../../atoms/BBText';
// Types
import { LayoutSize } from '../../../../../core/types/LayoutTypes';
import { LayoutProps } from '../../../../../core/types/LayoutProps';
// Utils
import IsMobileLayoutApplicable, {
  isMobileLayoutApplicable,
} from '../../../../../core/utils/IsMobileLayoutApplicable';
import {
  getBody2MediumStyles,
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles,
} from '../../../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const FieldSets = styled(FieldSetsComponent)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    gap: spacing.small,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    alignItems: 'stretch',
  };
});

export const FormContent = styled('section')({
  width: '100%',
});

interface FormHeaderOwnerState {
  layout: LayoutSize;
}

export const FormHeader = styled('header')<{
  ownerState: FormHeaderOwnerState;
}>(({ theme, ownerState }) => {
  const {
    colours,
    dimensions: { radius, spacing },
  } = theme;
  const { layout } = ownerState;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTableLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = IsMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: radius.medium,
    borderTopRightRadius: radius.medium,
    border: `1px solid ${colours.borders.border}`,

    ...(isDesktopLayout && {
      padding: `${spacing.small}px ${spacing.large}px`,
    }),

    ...(isTableLayout && {
      padding: `${spacing.small}px ${spacing.medium}px`,
    }),

    ...(isMobileLayout && {
      padding: spacing.small,
    }),
  };
});

interface FormHeaderTitleOwnerState extends LayoutProps {}

export const FormHeaderTitle = styled('h4')<{
  ownerState: FormHeaderTitleOwnerState;
}>(({ theme, ownerState }) => {
  const { colours } = theme;
  const { layout } = ownerState;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    margin: 0,
    color: colours.text.textWeak,
    ...getBody2MediumStyles(theme),

    strong: {
      color: colours.text.text,
      fontWeight: 'inherit',

      ...(isMobileLayout && {
        display: 'block',
      }),
    },
  };
});

export const PayInFullButton = styled(BBButton)(({ theme }) => {
  return {
    minWidth: 116,

    '.MuiTypography-root': {
      color: '#E40051', // No token
      ...getBodyExtraSmallNormalStyles(theme),
    },
  };
});

export const ExchangeSummary = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const ExchangeSummaryItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ExchangeSummaryLabel = styled(BBText)(({ theme }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;

  return {
    color: colours.text.textWeaker,
    marginRight: spacing.xxxSmall,
  };
});

export const WalletAmountContainer = styled('div')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  };
});

export const Balance = styled('span')(({ theme }) => {
  return {
    ...getBody2NormalStyles(theme),

    strong: {
      ...getBody2MediumStyles(theme),
    },
  };
});
