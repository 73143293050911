import { ReactNode } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import PageLoader from '../PageLoader';
import NotFoundRedirect from '../NotFoundRedirect';
// Utils
import { isNotFoundError } from '../../../core/utils/Errors';

interface PageProps {
  isLoading?: boolean;
  error?: unknown;
  children: ReactNode;
}

const Page = (props: PageProps) => {
  const { isLoading, error, children } = props;

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return <>{children}</>;
};

export default Page;
