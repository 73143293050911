import { useContext, useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import IconButton from '@mui/material/IconButton';

//Context
import {
  TenantContext,
  LayoutContext,
} from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBLinearProgressBar from '../../atoms/BBLinearProgressBar';
import CustomStepIcon from './CustomStepIcon';
// Hooks
import { useTranslations } from '../../../core/hooks';
//Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import {
  StepType,
  IdsValueType,
} from '../../../core/utils/GetStepperConfig/index';
//Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import {
  getBody1MediumStyles,
  getBodyExtraSmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { generateSteps } from '../../../core/utils/GetStepperConfig';
// Constants
import { STEP_DETAILS } from '../../../core/utils/GetStepperConfig/StepperData';

interface BBStepPanelProps {
  activeStep: number;
  stepperType: StepType;
  idValues: IdsValueType;
}

const StyledStepStageContainer = styled(Box)<LayoutProps>(({
  theme,
  layout,
}) => {
  const isDesktopLayout = layout === 'desktop';
  const {
    dimensions: {
      spacing: { small },
    },
  } = theme;

  return {
    paddingTop: isDesktopLayout ? small : 0,
    flex: 1,
    alignSelf: 'stretch',
  };
});

const StyledStepConnector = styled(StepConnector)<LayoutProps>(({
  theme,
  layout,
}) => {
  const isTabletLayout = layout === 'tablet';
  const {
    colours: {
      borders: { active, input },
    },
    dimensions: {
      spacing: { xSmall },
    },
  } = theme;

  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: xSmall,
      left: 'calc(-50% + 13px)',
      right: 'calc(50% + 13px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: active,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: active,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: input,
      borderLeftWidth: 1,
      ...(isTabletLayout && {
        borderTopWidth: 1,
      }),
      borderRadius: 1,
    },
  };
});

const StyledStepperLabel = styled(StepLabel)<LayoutProps>(({
  theme,
  layout,
}) => {
  const isTabletLayout = layout === 'tablet';
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;
  const labelStyling = isTabletLayout
    ? { ...getBodyExtraSmallNormalStyles(theme) }
    : { ...getBody1MediumStyles(theme) };
  return {
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiStepLabel-alternativeLabel': {
      marginTop: 0,
    },
    '> .MuiStepLabel-iconContainer': {
      paddingRight: isTabletLayout ? '0px' : '35px',
    },
    '> .MuiStepLabel-labelContainer': {
      '& .Mui-active, .Mui-completed': {
        ...labelStyling,
      },
      '& .Mui-disabled': {
        ...labelStyling,
        color: textWeak,
      },
    },
  };
});

const StyledIconButton = styled(IconButton)<LayoutProps>(({
  theme,
  layout,
}) => {
  const isDesktopLayout = layout === 'desktop';
  const {
    dimensions: {
      spacing: { xxxSmall, xxSmall, small, smallMedium },
    },
    colours: {
      icon: { active },
    },
  } = theme;
  return {
    padding: `0px ${xxSmall}px 5px ${isDesktopLayout ? xxSmall : xxxSmall}px`, // Token not available
    '> svg': {
      width: isDesktopLayout ? smallMedium : small,
      height: isDesktopLayout ? smallMedium : small,
      '> path': {
        fill: active,
      },
    },
  };
});

const BBStepPanel: FC<BBStepPanelProps> = (props: BBStepPanelProps) => {
  const { activeStep, stepperType, idValues } = props;

  const navigate = useNavigate();

  const { translate } = useTranslations();

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const StepperCompletedEditIcon = useMemo(
    () => getIcon(tenant, 'edit'),
    [tenant]
  );

  const isTabletLayout = layout === 'tablet';
  const isDesktopLayout = layout === 'desktop';
  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);

  const steps = useMemo(
    () => generateSteps(stepperType, idValues),
    [stepperType, idValues]
  );

  const currentStepProgress = useMemo(
    () =>
      activeStep === 0
        ? 20
        : (activeStep / STEP_DETAILS[stepperType].length) * 100,
    [activeStep, stepperType]
  );

  const onStepEditClick = (href: string) => {
    navigate(href);
  };

  return (
    <StyledStepStageContainer layout={layout} data-testid="step-container">
      {isNotMobileLayout ? (
        <Stepper
          activeStep={activeStep}
          orientation={isDesktopLayout ? 'vertical' : 'horizontal'}
          alternativeLabel={isTabletLayout}
          connector={<StyledStepConnector layout={layout} />}
          data-testid="step-stepper"
        >
          {steps &&
            steps.map((record) => {
              const showEditIcon = activeStep > record.id;
              return (
                <Step key={record.id} data-testid="step">
                  <StyledStepperLabel
                    StepIconComponent={CustomStepIcon}
                    layout={layout}
                    data-testid="step-label"
                  >
                    {translate(record.textKey)}
                    {showEditIcon && (
                      <StyledIconButton
                        layout={layout}
                        disableRipple
                        onClick={() => onStepEditClick(record.href)}
                      >
                        <StepperCompletedEditIcon />
                      </StyledIconButton>
                    )}
                  </StyledStepperLabel>
                </Step>
              );
            })}
        </Stepper>
      ) : (
        <BBLinearProgressBar value={currentStepProgress} marginBottom="0" />
      )}
    </StyledStepStageContainer>
  );
};

export default BBStepPanel;
