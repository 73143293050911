import { FC, SyntheticEvent, useState, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import WalletToWalletAccordion from './WalletToWalletAccordion/WalletToWalletAccordion';
import NavigationBlocker from '../../../organisms/NavigationBlocker';
import BBButton from '../../../atoms/BBButton';
import SavedRecipientsAccordion from '../../../organisms/SavedRecipientsAccordion/SavedRecipientsAccordion';
import NewRecipientAccordion from '../../../organisms/NewRecipientAccordion';
import ProtectingFromFraud from '../../../organisms/ProtectingFromFraud';
import { FormContent } from './styles';
import {
  ActionButtonsContainer,
  FormContainer,
} from '../../CommonStyles/CommonStyles.styles';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { RecipientDetailsFormValues } from './types';
import { AccountType } from '../../../../core/types/RecipientTypes';
import { Nullable } from '../../../../core/types/UtilTypes';
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';
import { Disbursement } from '../../../../core/types/DisbursementTypes';
import {
  AddBankAccountRequest,
  PaymentMethods,
} from '../../../../core/types/PaymentRequestsTypes';
//Api wrappers
import { useUpsertDisbursementMutation } from '../../../../redux/api/disbursements';
import { useAddBankAccountMutation } from '../../../../redux/api';
// Utils
import {
  createAccountDisbursementUpsertRequest,
  defaultValues,
  mapDisbursementDataToFormValue,
  mapFormValuesToDisbursementUpsertRequest,
  mapFormValuesToFraudAlertRequest,
} from './utils';
import { mapFormValuesToAddBankAccountRequest } from '../../PaymentRequest/AccountToBeFunded/utils';

interface RecipientDetailsFormProps {
  activeStep: number;
  showProtectingFromFraudSection: boolean;
  setShowProtectingFromFraudSection: (value: boolean) => void;
  disbursementId: string;
  transactionId: string;
  disbursementDetails: Nullable<Disbursement>;
}
const RecipientDetailsForm: FC<RecipientDetailsFormProps> = (
  props: RecipientDetailsFormProps
) => {
  const {
    activeStep,
    showProtectingFromFraudSection,
    setShowProtectingFromFraudSection,
    disbursementId,
    transactionId,
    disbursementDetails,
  } = props;

  const [expanded, setExpanded] = useState<Nullable<AccountType>>(null);
  const [walletDetails, setWalletDetails] =
    useState<Nullable<WalletWithUserDetails>>(null);

  const [upsertDisbursement] = useUpsertDisbursementMutation();
  const [addBankAccountRequest] = useAddBankAccountMutation();

  const { translate } = useTranslations();
  const navigate = useNavigate();
  const { layout } = useContext(LayoutContext);

  const { validationSchema } = useValidations();

  const form = useForm<RecipientDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: disbursementDetails
      ? mapDisbursementDataToFormValue(disbursementDetails)
      : defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = form;

  const disabledSubmitButton: boolean = !isValid || isSubmitting;

  const handleAccordionChange =
    (panel: AccountType) => (_: SyntheticEvent, newExpanded: boolean) => {
      const panelValue: Nullable<AccountType> = newExpanded ? panel : null;
      setExpanded(panelValue);
      setValue('accountType', panelValue, {
        shouldTouch: true,
        shouldDirty: true,
      });
      trigger();
    };

  // TODO: Need to refactor the utility functions ans types
  // which are used at both payment requests and in disbursements
  const onSubmit = async (formData: RecipientDetailsFormValues) => {
    try {
      if (showProtectingFromFraudSection && disbursementDetails) {
        // Submit fraud alert data
        const fraudAlertData =
          mapFormValuesToFraudAlertRequest(disbursementDetails);
        const { id } = await upsertDisbursement(fraudAlertData).unwrap();
        handleNavigation(id);
      } else if (formData.accountType === 'new-recipient') {
        // Save newly added bank
        const bankAccountData: AddBankAccountRequest =
          mapFormValuesToAddBankAccountRequest(formData);
        const { id: bankAccountId } =
          await addBankAccountRequest(bankAccountData).unwrap();
        if (bankAccountId && disbursementDetails) {
          const dataToUpdate = createAccountDisbursementUpsertRequest(
            PaymentMethods.Bank,
            bankAccountId,
            transactionId,
            disbursementId
          );
          const { id } = await upsertDisbursement(dataToUpdate).unwrap();

          // Reset add new recipient fields after successful save
          // Make saved accordion tab as expanded
          reset({
            ...defaultValues,
            accountType: 'saved-recipient',
            recipientId: bankAccountId,
          });
          setExpanded('saved-recipient');
          id && setShowProtectingFromFraudSection(true);
        }
      } else {
        const disbursementData = mapFormValuesToDisbursementUpsertRequest(
          formData,
          walletDetails,
          transactionId,
          disbursementId
        );
        const { id } = await upsertDisbursement(disbursementData).unwrap();
        handleNavigation(id);
      }
    } catch {}
  };

  const handleOnBackClick = () => {
    navigate('../details');
  };

  const handleNavigation = (id: Nullable<number>) => {
    navigate(
      `/transaction-details/${transactionId}/disbursements/${id}/confirmation`
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <FormContainer layout={layout} onSubmit={handleSubmit(onSubmit)}>
          {!showProtectingFromFraudSection ? (
            <>
              <FormContent>
                <WalletToWalletAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                  transactionId={transactionId}
                  setWalletDetails={setWalletDetails}
                />
                <SavedRecipientsAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                />
                <NewRecipientAccordion
                  expanded={expanded}
                  handleChange={handleAccordionChange}
                />
              </FormContent>
              <ActionButtonsContainer activeStep={activeStep} layout={layout}>
                <BBButton
                  size="medium"
                  btnType="outlined"
                  type="button"
                  onClick={handleOnBackClick}
                >
                  {translate('steppers.backBtn')}
                </BBButton>
                <BBButton
                  btnType="secondary"
                  size="medium"
                  type="submit"
                  disabled={disabledSubmitButton}
                >
                  {translate('steppers.continueBtn')}
                </BBButton>
              </ActionButtonsContainer>
            </>
          ) : (
            <ProtectingFromFraud
              setShowProtectingFromFraudSection={
                setShowProtectingFromFraudSection
              }
              disabledSubmitButton={disabledSubmitButton}
              type="disbursement"
            />
          )}
        </FormContainer>
      </FormProvider>
      {isDirty && !isSubmitting && <NavigationBlocker />}
    </>
  );
};

export default RecipientDetailsForm;
