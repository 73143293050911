import { useContext, useMemo } from 'react';

// Contexts
import { TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBConfirmationModal from '../../../molecules/BBModal/BBConfirmationModal';
import BBText from '../../../atoms/BBText';
import { Message } from './JoinTransactionConfirmation.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
import { useAcceptInvitation } from './useAcceptInvitation';
// Types
import { InvitationStatus } from '../../../../core/types/PaymentRequestsTypes';
import { Invitation } from './types';
// API Wrappers
import { useUpdateAccountPaymentRequestMutation } from '../../../../redux/api';
// Utils
import { getIcon } from '../../../../core/utils/IconOrgData';
import {
  getFormattedAddress,
  getFormattedValue,
} from '../../../../core/utils/Format';

interface JoinTransactionConfirmationProps {
  invitation?: Invitation;
  isOpen: boolean;
}

const JoinTransactionConfirmation = (
  props: JoinTransactionConfirmationProps
) => {
  const { isOpen, invitation } = props;

  const paymentRequest = invitation?.paymentRequest;
  const transaction = paymentRequest?.transaction;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const [updatePaymentRequest] = useUpdateAccountPaymentRequestMutation();
  const [acceptInvitation] = useAcceptInvitation();

  const ImportantNoticeIllustrationIcon = useMemo(
    () => getIcon(tenant, 'importantNoticeIllustration'),
    [tenant]
  );

  const onAccept = () => {
    if (paymentRequest) {
      acceptInvitation(paymentRequest);
    }
  };

  const onMaybeLater = () => {
    if (paymentRequest) {
      updatePaymentRequest({
        id: paymentRequest.id,
        invitationStatus: InvitationStatus.MayBeLater,
      });
    }
  };

  return (
    <BBConfirmationModal
      isOpen={isOpen}
      icon={<ImportantNoticeIllustrationIcon />}
      message={translate('buyer.invitation.title')}
      primaryBtnLabel={translate('buyer.invitation.acceptButtonText')}
      secondaryBtnLabel={translate('buyer.invitation.cancelButtonText')}
      handlePrimaryBtnClick={onAccept}
      handleSecondaryBtnClick={onMaybeLater}
      additionMessage={
        <Message>
          <BBText
            variant="body2"
            type="bold"
            text={getFormattedValue(invitation?.pbu?.pbuName)}
            component="strong"
          />
          <br />
          {translate('buyer.invitation.modalMessage')}
          <br />
          <br />
          {getFormattedAddress(transaction?.address)}
        </Message>
      }
    />
  );
};

export default JoinTransactionConfirmation;
