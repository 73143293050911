import { FC } from 'react';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBModal, { SizeProps } from './BBModal';
import BBLoader from '../../atoms/BBLoader';
import { StyledBody, StyledMessage, StyledAdditionMessage } from './styles';

interface BBLoaderModalProps extends Partial<SizeProps> {
  isOpen: boolean;
  message: string;
  additionMessage: string;
}

const StyledBBLoader = styled(BBLoader)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing.large,
  };
});

const BBLoaderModal: FC<BBLoaderModalProps> = (props: BBLoaderModalProps) => {
  const { isOpen, message, additionMessage, size = 'normal' } = props;

  const body = (
    <StyledBody size={size} data-testid="modal-body">
      <StyledBBLoader size="medium" />
      {message && (
        <StyledMessage variant="h3" type="bold" text={message} size={size} />
      )}

      {additionMessage && (
        <StyledAdditionMessage size={size}>
          {additionMessage}
        </StyledAdditionMessage>
      )}
    </StyledBody>
  );

  return <BBModal isOpen={isOpen} body={body} size={size} />;
};

export default BBLoaderModal;
