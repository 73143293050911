import { IconObject } from '../../../types/IconOrgTypes';
import {
  ChatIcon,
  LoaderIcon,
  CheckedCheckboxIcon,
  CalendarIcon,
  NavigationChevronDownIcon,
  ToolsShowIcon,
  ToolsHideIcon,
  ToolsTickIcon,
  ToggleLockedIcon,
  IBMSecurityIcon,
  UserAdminIcon,
  TechnologyMobileIcon,
  NavigationCloseIcon,
  NavigationCloseFilledIcon,
  StatusWarningIcon,
  StatusWarningAltIcon,
  StatusCheckmarkOutlineIcon,
  StatusCheckmarkIcon,
  StatusUnCheckmarkIcon,
  EllipseBlankIcon,
  FormattingEditIcon,
  ProgressStepIcon,
  SocialProofIcon,
  AndroidPlayStoreBadgesIcon,
  IOSAppStoreBadgesIcon,
  PlanningTimerIcon,
  FileFolderAddIcon,
  PageIllustrationIcon,
  OperationsSearchIcon,
  GenericErrorIcon,
  UploadDocWelcomeIcon,
  DocumentUploadedIcon,
  PageNotFoundIcon,
  StatusErrorIcon,
  HelpEmailIcon,
  HelpMobileIcon,
  HelpCloseIcon,
  RadioButtonCheckedIcon,
  CheckmarkOffIcon,
  HelpFaqIcon,
  HelpMapIcon,
  DashboardIcon,
  CasesIcon,
  DocumentsIcon,
  ReportsIcon,
  SearchIcon,
  SettingsIcon,
  HelpIcon,
  HourGlassIcon,
  TimeIcon,
  TickIcon,
  CreateCaseIllustrationIcon,
  NotaryOfficesIcon,
  BackArrowIcon,
  ForwardArrowIcon,
  ExpandMoreIcon,
  BinIcon,
  FileDownloadIcon,
  ArrowLeftIcon,
  //PP Assets
  ChevronSortIcon,
  ChevronSortUpIcon,
  ChevronSortDownIcon,
  ArrowRightIcon,
  HomeIcon,
  SwitchIcon,
  EmailIcon,
  SettingIcon,
  LeftChevronIcon,
  RightChevronIcon,
  BurgerMenuCloseIcon,
  BurgerMenuOpenIcon,
  NoRecordsIcon,
  DocumentIcon,
  RenewIcon,
  AddIcon,
  StepperCompletedIcon,
  StepperInActiveIcon,
  StepperActiveIcon,
  StepperCompletedEditIcon,
  CompletedIllustrationIcon,
  NavigationChevronRightIcon,
  NavigationChevronLeftIcon,
  WalletIcon,
  RepeatOperationIcon,
  AddAltIcon,
  StatusCheckmarkFilledIcon,
  FraudPreventionIcon,
  ImportantNoticeIllustrationIcon,
  ImportantNoticeLightIllustrationIcon,
  BankIcon,
  WarningIllustrationIcon,
  FinanceIcon,
  FinanceTransferIcon,
  ArrowUpRightIcon,
  FileCopyIcon,
  PrinterIcon,
  PDFIcon,
  FileTaskIcon,
  RotateIcon,
  BespokeMoneyIcon,
  FileDocumentPDFIcon,
  StatusWarningFilledIcon,
  StatusWarningAltFilledIcon,
  StatusInformationFilledIcon,
  PersonUserGroupIcon,
} from '../whitelabel/commonIcons';

// CD specific icons
import { ReactComponent as CDLogoIcon } from './assets/icons/logo.svg';
import { ReactComponent as CDLogoLightIcon } from './assets/icons/logo-light.svg';
import { ReactComponent as CDLogoLightMobileIcon } from './assets/icons/logo-light-mobile.svg';
import { ReactComponent as CDSearchIcon } from './assets/icons/search-icon.svg';
import { ReactComponent as CDLogoDarkMobileIcon } from './assets/icons/logo-dark-mobile.svg';
import { ReactComponent as LocationWarningIcon } from './assets/icons/location-warning-icon.svg';
import { ReactComponent as LocationLockedIcon } from './assets/icons/location-locked-icon.svg';
import { ReactComponent as MaintenancePageIcon } from './assets/icons/page-maintenance-icon.svg';
import { ReactComponent as AccountErrorIcon } from './assets/icons/account-error-icon.svg';
import { ReactComponent as SessionLogoutIcon } from './assets/icons/session-logout-icon.svg';
import { ReactComponent as SwitcherIcon } from './assets/icons/currency-switcher-icon.svg';
import { ReactComponent as TransactionIcon } from './assets/icons/transactions-icon.svg';
import { ReactComponent as AgentOnlineIcon } from './assets/icons/agent-online-icon.svg';
import { ReactComponent as TrustpilotLogoIcon } from './assets/icons/trustpilot-icon.svg';

export const CDIconObject: IconObject = {
  search: CDSearchIcon,
  chat: ChatIcon,
  loader: LoaderIcon,
  checkedCheckbox: CheckedCheckboxIcon,
  calendar: CalendarIcon,
  navigationChevronDownIcon: NavigationChevronDownIcon,
  toolsShow: ToolsShowIcon,
  toolsHide: ToolsHideIcon,
  toolsTick: ToolsTickIcon,
  toggleLocked: ToggleLockedIcon,
  ibmSecurity: IBMSecurityIcon,
  userAdmin: UserAdminIcon,
  technologyMobile: TechnologyMobileIcon,
  navigationClose: NavigationCloseIcon,
  navigationCloseFilled: NavigationCloseFilledIcon,
  logo: CDLogoIcon,
  logoLight: CDLogoLightIcon,
  logoLightMobile: CDLogoLightMobileIcon,
  statusWarning: StatusWarningIcon,
  statusWarningAlt: StatusWarningAltIcon,
  statusCheckmarkOutlineIcon: StatusCheckmarkOutlineIcon,
  statusCheckmarkIcon: StatusCheckmarkIcon,
  statusUnCheckmarkIcon: StatusUnCheckmarkIcon,
  ellipseBlank: EllipseBlankIcon,
  formattingEdit: FormattingEditIcon,
  progressStep: ProgressStepIcon,
  socialProof: SocialProofIcon,
  androidPlayStoreBadges: AndroidPlayStoreBadgesIcon,
  iOSAppStoreBadges: IOSAppStoreBadgesIcon,
  planningTimer: PlanningTimerIcon,
  fileFolderAdd: FileFolderAddIcon,
  PageIllustration: PageIllustrationIcon,
  magnifyingGlass: OperationsSearchIcon,
  genericError: GenericErrorIcon,
  logoDarkMobile: CDLogoDarkMobileIcon,
  uploadDocWelcome: UploadDocWelcomeIcon,
  documentUploaded: DocumentUploadedIcon,
  pageNotFound: PageNotFoundIcon,
  MaintenancePage: MaintenancePageIcon,
  locationWarning: LocationWarningIcon,
  locationLocked: LocationLockedIcon,
  statusError: StatusErrorIcon,
  helpEmail: HelpEmailIcon,
  helpMobile: HelpMobileIcon,
  helpClose: HelpCloseIcon,
  agentOnline: AgentOnlineIcon,
  radioButtonChecked: RadioButtonCheckedIcon,
  checkmarkOff: CheckmarkOffIcon,
  accountError: AccountErrorIcon,
  sessionLogout: SessionLogoutIcon,
  helpFaq: HelpFaqIcon,
  helpMap: HelpMapIcon,
  switcher: SwitcherIcon,
  transactionIcon: TransactionIcon,
  dashboard: DashboardIcon,
  cases: CasesIcon,
  documents: DocumentsIcon,
  reports: ReportsIcon,
  searchs: SearchIcon,
  settings: SettingsIcon,
  help: HelpIcon,
  hourGlass: HourGlassIcon,
  time: TimeIcon,
  tick: TickIcon,
  caseIllustration: CreateCaseIllustrationIcon,
  notaryOffices: NotaryOfficesIcon,
  backArrow: BackArrowIcon,
  forwardArrow: ForwardArrowIcon,
  expandMore: ExpandMoreIcon,
  bin: BinIcon,
  fileDownload: FileDownloadIcon,
  arrowLeft: ArrowLeftIcon,
  trustpilotLogo: TrustpilotLogoIcon,
  // PP Assets
  chevronSort: ChevronSortIcon,
  chevronSortUp: ChevronSortUpIcon,
  chevronSortDown: ChevronSortDownIcon,
  arrowRight: ArrowRightIcon,
  home: HomeIcon,
  switch: SwitchIcon,
  email: EmailIcon,
  setting: SettingIcon,
  leftChevron: LeftChevronIcon,
  rightChevron: RightChevronIcon,
  menuClose: BurgerMenuCloseIcon,
  menuOpen: BurgerMenuOpenIcon,
  noRecords: NoRecordsIcon,
  document: DocumentIcon,
  renew: RenewIcon,
  add: AddIcon,
  completed: StepperCompletedIcon,
  inactive: StepperInActiveIcon,
  active: StepperActiveIcon,
  edit: StepperCompletedEditIcon,
  completedPageIllustration: CompletedIllustrationIcon,
  navigationChevronRightIcon: NavigationChevronRightIcon,
  navigationChevronLeftIcon: NavigationChevronLeftIcon,
  wallet: WalletIcon,
  repeatOperation: RepeatOperationIcon,
  addAlt: AddAltIcon,
  checkmarkFilled: StatusCheckmarkFilledIcon,
  fraudPrevention: FraudPreventionIcon,
  importantNoticeIllustration: ImportantNoticeIllustrationIcon,
  importantNoticeLightIllustration: ImportantNoticeLightIllustrationIcon,
  bank: BankIcon,
  warningIllustration: WarningIllustrationIcon,
  finance: FinanceIcon,
  financeTransfer: FinanceTransferIcon,
  arrowUpRight: ArrowUpRightIcon,
  fileCopy: FileCopyIcon,
  printer: PrinterIcon,
  pdf: PDFIcon,
  fileTask: FileTaskIcon,
  rotate: RotateIcon,
  bespokeMoney: BespokeMoneyIcon,
  fileDocumentPDF: FileDocumentPDFIcon,
  statusWarningFilled: StatusWarningFilledIcon,
  statusWarningAltFilled: StatusWarningAltFilledIcon,
  statusInformationFilled: StatusInformationFilledIcon,
  personUserGroup: PersonUserGroupIcon,
};
