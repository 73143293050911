import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

//Components - Atoms, Molecules, Organisms, Pages
import BBButton from '../../../atoms/BBButton';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
// Utils
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

export const MainContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { bg },
    },
    dimensions: {
      spacing: { small },
      radius,
    },
  } = theme;

  return {
    display: 'flex',
    height: 'auto',
    padding: small,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: small,
    flex: '1 0 0',
    borderRadius: radius.small,
    background: bg,
  };
});

export const DataContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  };
});

export const StyledBBButton = styled(BBButton)<LayoutProps>(({
  theme,
  layout,
}) => {
  const {
    colours: {
      buttons: {
        outline: { icon },
      },
    },
    dimensions: { spacing },
  } = theme;

  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    ...(isMobileLayout && {
      alignSelf: 'stretch',
    }),

    '> .MuiButton-icon': {
      '> svg': {
        width: `${spacing.smallMedium - 2}px`,
        height: `${spacing.smallMedium - 2}px`,
        '> path': {
          fill: icon,
        },
      },
    },
  };
});
