// Types
import {
  UpsertPaymentRequestsRequest,
  UpsertPaymentRequestsResponse,
} from '../../core/types/ApiTypes';
import {
  PaymentRequestTypes,
  PaymentRequestDetails,
  UpdateAccountToBeFundedRequest,
  UpdateAccountToBeFundedResponse,
  UpdateFraudAlertRequest,
} from '../../core/types/PaymentRequestsTypes';
// API Wrappers
import { api } from './api';

const paymentRequestsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    upsertPaymentRequests: builder.mutation<
      UpsertPaymentRequestsResponse,
      UpsertPaymentRequestsRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/paymentRequests',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'Transactions', id: data.transactionId },
        { type: 'PaymentRequests', id: data.id },
      ],
    }),

    getPaymentRequestById: builder.query<PaymentRequestDetails, number>({
      query: (id) => `propertypay-services/paymentRequests/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'PaymentRequests', id }],
    }),

    getPaymentRequestType: builder.query<PaymentRequestTypes[], void>({
      query: () => `propertypay-services/paymentRequests/types`,
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10,
    }),

    // Update account to be funded
    updateAccountToBeFunded: builder.mutation<
      UpdateAccountToBeFundedResponse,
      UpdateAccountToBeFundedRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/accountToBeFunded',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'PaymentRequests', id: data.paymentRequestId },
      ],
    }),

    // Update fraud alert
    updateFraudAlert: builder.mutation<
      PaymentRequestDetails,
      UpdateFraudAlertRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/paymentRequests',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'Transactions', id: data.transactionId },
        { type: 'PaymentRequests', id: data.id },
      ],
    }),

    confirmPaymentRequest: builder.mutation<
      PaymentRequestDetails,
      PaymentRequestDetails['id']
    >({
      query: (id) => ({
        url: `propertypay-services/paymentRequests/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'PaymentRequests', id },
      ],
    }),
  }),
});

export const {
  useUpsertPaymentRequestsMutation,
  useGetPaymentRequestTypeQuery,
  useGetPaymentRequestByIdQuery,
  useUpdateAccountToBeFundedMutation,
  useUpdateFraudAlertMutation,
  useConfirmPaymentRequestMutation,
} = paymentRequestsApi;
