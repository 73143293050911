import * as yup from 'yup';

// Hooks
import { useErrorTranslations } from '../../../core/hooks';
import {
  LOGIN_PASSWORD_MAX_LENGTH,
  LOGIN_PASSWORD_MIN_LENGTH,
  LOGIN_USERNAME_MAX_LENGTH,
} from '../../../core/utils/Constants/Constants';

export const useValidations = () => {
  const { translateError } = useErrorTranslations('login');

  const validations = yup
    .object({
      emailAddress: yup
        .string()
        .trim()
        .email(translateError('emailAddress', 'invalid'))
        .matches(/\S+@\S+\.\S+/, translateError('emailAddress', 'invalid'))
        .required(translateError('emailAddress', 'required'))
        .max(
          LOGIN_USERNAME_MAX_LENGTH,
          translateError('emailAddress', 'maxCharacters', {
            number: LOGIN_USERNAME_MAX_LENGTH,
          })
        ),
      password: yup
        .string()
        .required(translateError('password', 'required'))
        .min(
          LOGIN_PASSWORD_MIN_LENGTH,
          translateError('password', 'minCharacters', {
            number: LOGIN_PASSWORD_MIN_LENGTH,
          })
        )
        .max(
          LOGIN_PASSWORD_MAX_LENGTH,
          translateError('password', 'maxCharacters', {
            number: LOGIN_PASSWORD_MAX_LENGTH,
          })
        ),
    })
    .required();

  return { validations };
};
