// Components - Atoms, Molecules, Organisms, Pages
import PPStatus, { StatusType } from '../../atoms/PPStatus';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import { PaymentStatus } from '../../../core/types/PaymentRequestsTypes';

const paymentRequestStatusToStatusMapping: Record<PaymentStatus, StatusType> = {
  [PaymentStatus.Completed]: 'completed',
  [PaymentStatus.Sent]: 'pending',
  [PaymentStatus.InProgress]: 'pending',
  [PaymentStatus.AwaitingFunds]: 'pending',
  [PaymentStatus.Cancelled]: 'cancelled',
};

interface PaymentRequestStatusProps {
  status: PaymentStatus;
}

const PaymentRequestStatus = (props: PaymentRequestStatusProps) => {
  const { status } = props;

  const { translate } = useTranslations();

  return (
    <PPStatus status={paymentRequestStatusToStatusMapping[status]}>
      {translate(`statuses.paymentRequest.${status.toLowerCase()}`)}
    </PPStatus>
  );
};

export default PaymentRequestStatus;
