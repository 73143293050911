import { useContext, useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';

//Context
import {
  LayoutContext,
  TenantContext,
} from '../../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../../atoms/BBText';
import BBButton from '../../../../atoms/BBButton';
import { WeakText } from '../../../CommonStyles/CommonStyles.styles';
import {
  CompletionActionContainer,
  CompletionInfoContainer,
  CompletionTextWrapper,
  MainWrapper,
} from './CompletedScreen.styles';
// Hooks
import { useTranslations } from '../../../../../core/hooks/useTranslations';
//Types
import { PaymentRequestDetails } from '../../../../../core/types/PaymentRequestsTypes';
//Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { getFormattedNamesFromCrmAccounts } from '../../../../../core/utils/Format';

interface CompletedScreenProps {
  data?: PaymentRequestDetails;
  transactionId: string;
}

const CompletedScreen: FC<CompletedScreenProps> = (
  props: CompletedScreenProps
) => {
  const { data, transactionId } = props;

  const navigate = useNavigate();

  const combinedNames = data?.crmAccount
    ? getFormattedNamesFromCrmAccounts(data?.crmAccount)
    : '';

  const { translate } = useTranslations();

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const btnText: string = translate('paymentRequest.completed.btnText');

  //Icons
  const CompletedPageIllustration = useMemo(
    () => getIcon(tenant, 'completedPageIllustration'),
    [tenant]
  );

  const handleGotoTransactionClick = () => {
    navigate(`/transaction-details/${transactionId}`);
  };

  return (
    <MainWrapper layout={layout}>
      <CompletionInfoContainer>
        <CompletedPageIllustration />
        <CompletionTextWrapper>
          <BBText
            variant="h3"
            type="bold"
            text={translate('paymentRequest.completed.title')}
          />
          <WeakText
            variant="body2"
            type="normal"
            text={translate('paymentRequest.completed.description', {
              names: combinedNames,
            })}
          />
        </CompletionTextWrapper>
      </CompletionInfoContainer>
      <CompletionActionContainer layout={layout}>
        <BBButton
          btnType="secondary"
          size="medium"
          type="button"
          onClick={handleGotoTransactionClick}
        >
          {btnText}
        </BBButton>
      </CompletionActionContainer>
    </MainWrapper>
  );
};

export default CompletedScreen;
