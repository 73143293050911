// Components - Atoms, Molecules, Organisms, Pages
import { TransactionRef } from './Details.styles';
import CellText from './CellText';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { TransactionRow } from './types';

interface TransactionDetailsProps {
  transaction: TransactionRow;
}

const Details = (props: TransactionDetailsProps) => {
  const { transaction } = props;

  const { translate } = useTranslations();

  return (
    <>
      <CellText variant="body2" type="normal" text={transaction.address} />
      <TransactionRef
        variant="subtitle1"
        type="normal"
        text={`${translate('transactions.propertyPayReference')}: ${transaction.ref}`}
      />
    </>
  );
};

export default Details;
