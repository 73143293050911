//Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';

//Components
import { Box } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

import BBLogoWrapper from '../../organisms/BBLogoWrapper';
import BBMobileMenuContainer from '../../organisms/BBMobileMenuContainer';
import BBLink from '../../atoms/BBLink';
import {
  Container,
  ContentWrapper,
  ContentFooterWrapper,
  Sidebar,
  StyledBBLogoWrapper,
  StyledImage,
  StyledImageWrapper,
  StyledPoweredByText,
  StyledTrustpilotIcon,
} from './LoginLayout.styles';

//Utils
import { useTranslations } from '../../../core/hooks/useTranslations';
import { LoginLayoutProps } from './types';
import {
  FOOTER_LINKS_CONFIG_ARRAY,
  PROPERTY_PAY_LINK,
  REDPIN_COMPANY_LINK,
} from '../../../core/utils/Constants/Constants';
import { getIcon } from '../../../core/utils/IconOrgData';
import { getImage } from '../../../core/utils/ImageOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

const LoginLayout: FC<LoginLayoutProps> = (props: LoginLayoutProps) => {
  const { translate } = useTranslations();

  const { children } = props;
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const { height } = useWindowSize();

  //Required for the content to hide the image in low height resolutions
  const layoutHeight = height <= 600 ? 'lowHeight' : '';

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const LoginBgImage = useMemo(
    () => getImage(tenant, 'loginBgImage'),
    [tenant]
  );

  const TrustPilotLogo = useMemo(
    () => getIcon(tenant, 'trustpilotLogo'),
    [tenant]
  );

  return (
    <Container>
      {!isMobileLayout ? (
        <>
          <Sidebar layout={layout}>
            <BBLogoWrapper logoVariant="logoLight" link={PROPERTY_PAY_LINK} />

            <StyledImageWrapper layout={layout} layoutHeight={layoutHeight}>
              <StyledImage layout={layout} src={LoginBgImage} />
            </StyledImageWrapper>

            <Box className="footer">
              <StyledTrustpilotIcon layout={layout} layoutHeight={layoutHeight}>
                <TrustPilotLogo />
              </StyledTrustpilotIcon>
              <StyledBBLogoWrapper layout={layout} layoutHeight={layoutHeight}>
                <StyledPoweredByText
                  variant="subtitle2"
                  type="normal"
                  text={translate('sidebar.footer.poweredBy')}
                />
                <BBLogoWrapper
                  logoVariant="logo"
                  link={REDPIN_COMPANY_LINK}
                  target="_blank"
                />
              </StyledBBLogoWrapper>
            </Box>
          </Sidebar>
          <ContentWrapper layout={layout} layoutHeight={layoutHeight}>
            {children}
            <ContentFooterWrapper layout={layout}>
              {FOOTER_LINKS_CONFIG_ARRAY.map((linkDetails, index) => (
                <BBLink
                  key={`${linkDetails.textKey}-${index}`}
                  variant="subtitle1"
                  type="normal"
                  text={translate(linkDetails.textKey)}
                  href={linkDetails.hrefKey}
                  underline="none"
                  target="_blank"
                  data-testid="bb-link-footer"
                />
              ))}
            </ContentFooterWrapper>
          </ContentWrapper>
        </>
      ) : (
        <>
          <BBMobileMenuContainer isLoginLayout />
          <ContentWrapper layout={layout}>{children}</ContentWrapper>
        </>
      )}
    </Container>
  );
};

export default LoginLayout;
