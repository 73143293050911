import { Box, styled } from '@mui/material';

//Components
import RadioCard from './RadioCard';

//Types
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';

interface RadioCardType {
  walletDetails: WalletWithUserDetails[];
}

export const RadioCardWrapper = styled('ul')(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xxSmall,
    marginBottom: `${xxSmall}px`,
    padding: 0,
    margin: 0,
    listStyle: 'none',
    maxHeight: 234,
    overflowY: 'auto',
  };
});

export default function RadioCards({ walletDetails }: RadioCardType) {
  return (
    <RadioCardWrapper>
      {walletDetails.map((wallet) => (
        <RadioCard
          key={wallet.walletId}
          id={wallet.walletId}
          walletDetails={wallet}
        />
      ))}
    </RadioCardWrapper>
  );
}
