import * as yup from 'yup';

// Types
import { FormValues } from './types';

export const validations = yup.object({
  type: yup
    .string<Exclude<FormValues['type'], undefined>>()
    .required()
    .oneOf(['bankAccount', 'wallet']),
  walletId: yup
    .string()
    .trim()
    .when('type', {
      is: 'wallet',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
});
