import { useState, useMemo } from 'react';

// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
// Types
import {
  TransactionDetailsFilter,
  TransactionDetailsFilterType,
} from '../../../core/types/PaymentRequestsTypes';

export const useFilters = (
  transactionDetailsFilters: TransactionDetailsFilter[]
) => {
  const [filter, setFilter] = useState<TransactionDetailsFilterType>('request');

  const { translate } = useTranslations();

  const filters = useMemo(() => {
    return transactionDetailsFilters.map((tabDetails) => ({
      value: tabDetails.key,
      label: translate(`transactionDetails.filters.${tabDetails.key}`),
      disabled: tabDetails.disabled,
    }));
  }, [translate, transactionDetailsFilters]);

  return { filters, setFilter, filter };
};
