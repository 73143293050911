import { AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';

const StyledAccordionSummary = styled(AccordionDetails)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const xxSmallSpacing: string = spacing.xxSmall + 'px';
  const smallMediumSpacing: string = spacing.smallMedium + 'px';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    padding: `${xxSmallSpacing} ${smallMediumSpacing} ${smallMediumSpacing}`,
  };
});

export default memo(StyledAccordionSummary);
