import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  useState,
  KeyboardEvent,
  FocusEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import { InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Control } from 'react-hook-form';

// Components - Atoms, Molecules, Organisms, Pages
import BBInput, { InputProps } from '../../atoms/BBInput/BBInput';
import BBSelect, {
  BBSelectProps,
  TypeProps,
} from '../../atoms/BBSelect/BBSelect';
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
// Utils
import { getH3MediumStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { currencyInputFormatter } from '../../../core/utils/Format';

export interface RHFBBAmountProps {
  control: Control<any, any>;
  textFieldProps: InputProps & {
    handleBlur?: FocusEventHandler<HTMLInputElement>;
    handleChange?: ChangeEventHandler<HTMLInputElement>;
  };
  dropdownProps: Omit<BBSelectProps, 'type' | 'placeholderLabel'> & {
    type?: TypeProps;
    placeholderLabel?: BBSelectProps['placeholderLabel'];
    handleChange?: ChangeEventHandler<HTMLInputElement>;
  };
}

const Container = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { white },
    },
    dimensions: {
      spacing: { small, xxxSmall, xxSmall, xSmall, smallMedium, medium, large },
    },
    breakpoints,
  } = theme;

  return {
    display: 'flex',
    gap: 1,
    '> div': {
      width: '100%',
      '.MuiFormControl-root': {
        height: 'auto',
      },
      '> div > label.MuiInputLabel-root': {
        // This value will adjust the input label position as input text is of H3Medium style
        top: `${-xxxSmall}px`,
      },
      '> div > div.MuiInputBase-root.MuiFilledInput-root': {
        padding: `0 0 0 ${small}px !important`,
        '> input': {
          // This will make the input value of H3Medium style
          ...getH3MediumStyles(theme),
          paddingTop: small,
          paddingBottom: `${xxxSmall - 1}px`,
          // This styling is required to fix the breaking changes done for amount input
          // ...getH3MediumStyles(theme) is not working here, hence adding only required properties
          // manually and making them important to forcefully override.
          [breakpoints.down('sm')]: {
            fontSize: `${medium}px !important`,
            lineHeight: `${large}px !important`,
            letterSpacing: '0.25px !important',
          },
        },
        '> .MuiInputAdornment-filled': {
          '> div': {
            width: '100%',
            margin: 0,
          },
        },
      },
    },

    '& .MuiInputAdornment-root': {
      //Form control styling for the select input
      '> div': {
        height: '53px',
      },
      '& .MuiTextField-root': {
        '&:hover, :focus-within, :focus': {
          borderRadius: 'unset',
          border: 'none',
          boxShadow: 'none',
          height: '53px',
          backgroundColor: 'transparent',
        },
        boxShadow: 'none',
        border: 'none',
        borderRadius: 'unset',
        height: '53px',
        backgroundColor: 'transparent',
      },

      //select input styling
      '& .MuiFilledInput-root': {
        height: '53px',
        padding: `${xxSmall}px ${small}px ${xxSmall}px ${smallMedium}px`,
      },
      //select popper styling
      '& .base-Popper-root': {
        background: white,
        marginTop: '1px !important',
        '> .MuiAutocomplete-popper': {
          marginTop: '-26px',
          '> .MuiPaper-root': {
            '> ul.MuiAutocomplete-listbox': {
              padding: `${xxxSmall}px ${xSmall}px ${xSmall}px ${xSmall}px`,
              '> li.MuiAutocomplete-option': {
                padding: `${xxSmall}px ${xxSmall}px`,
              },
            },
          },
        },
      },
    },
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { input },
    },
    dimensions: {
      spacing: { large },
    },
  } = theme;
  return {
    color: input,
    width: '1px', //Token not available
    height: `${large - 2}px`,
  };
});

const RHFBBAmount: FC<RHFBBAmountProps> = (props: RHFBBAmountProps) => {
  const { control, dropdownProps, textFieldProps } = props;

  const { value } = textFieldProps;

  const { value: currency, readOnly = false } = dropdownProps;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event;
    const inputElement = target as HTMLInputElement;
    const inputValue = inputElement.value;
    const cursorPosition = inputElement.selectionStart || 0;

    // Allow navigation keys and control keys
    if (
      [
        'Backspace',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
        'Delete',
        'Enter',
      ].includes(key)
    ) {
      return;
    }

    // Allow digits and decimal point
    if (/[\d.]/.test(key)) {
      // Prevent 0 or . at first position
      if (cursorPosition === 0 && (key === '0' || key === '.')) {
        event.preventDefault();
      }

      if (key === '.') {
        // Prevent more than one decimal point
        if (inputValue.includes('.')) {
          event.preventDefault();
        }
        return;
      }

      // Handle decimal places limit
      if (inputValue.includes('.')) {
        const decimalPart = inputValue.split('.')[1] || '';
        if (
          decimalPart.length >= 2 &&
          cursorPosition > inputValue.indexOf('.') + 2
        ) {
          event.preventDefault();
        }
      }
    } else {
      // Prevent non-numeric and non-decimal input
      event.preventDefault();
    }
  };

  return (
    <Container>
      <ControllerElementWrapper
        type="number"
        {...textFieldProps}
        control={control}
        component={BBInput}
        handleBlur={(event: FocusEvent<HTMLInputElement>) => {
          handleOnBlur();
          return textFieldProps.handleBlur?.(event);
        }}
        onFocus={(event: FocusEvent<HTMLInputElement>) => {
          handleOnFocus();
          return textFieldProps.onFocus?.(event);
        }}
        handleOnKeyDown={handleOnKeyDown}
        value={
          isFocused ? value : currencyInputFormatter(Number(value), currency)
        }
        endAdornment={
          <InputAdornment position="end">
            <>
              <StyledDivider orientation="vertical" variant="middle" />
              <ControllerElementWrapper
                type="currency"
                toShrinkLabel={false}
                isSearchable={false}
                placeholderLabel=""
                {...dropdownProps}
                control={control}
                component={BBSelect}
                readOnly={readOnly}
              />
            </>
          </InputAdornment>
        }
      />
    </Container>
  );
};

export default RHFBBAmount;
