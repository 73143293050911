import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

// Hooks
import { useErrorTranslations } from '../../../../core/hooks';
// Types
import { AccountToBeFundedFormValues } from './types';
import {
  AccountType,
  NewAccountType,
} from '../../../../core/types/RecipientTypes';
// Constants
import {
  COMPANY_NAME_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  REGEX,
} from '../../../../core/utils/Constants/Constants';

interface TestContextExtended {
  from: {
    value: AccountToBeFundedFormValues;
  }[];
}

const validateField = (field: string) => (value: any, context: any) => {
  const { from } = context as yup.TestContext & TestContextExtended;

  const { accountType, bankDetailsValidationRules } = from[1].value;

  if (accountType === 'new-recipient') {
    return value?.length && value.match(bankDetailsValidationRules[field]);
  }
  return true;
};

export const useValidations = () => {
  const { translateError } = useErrorTranslations('newRecipient.fields');

  const isSavedRecipient = (accountType: AccountType) => {
    return accountType === 'saved-recipient';
  };

  const isNewRecipient = (accountType: AccountType) => {
    return accountType === 'new-recipient';
  };

  let validationSchema = yup.object({
    // Account type
    accountType: yup
      .mixed<AccountType>()
      .oneOf(['wallet', 'saved-recipient', 'new-recipient']),
    // Saved Recipients
    recipientId: yup
      .number()
      .nullable()
      .when('accountType', {
        is: (accountType: AccountType) => isSavedRecipient(accountType),
        then: (schema) => schema.required(),
      }),
    // New Recipient
    newAccountType: yup.string().oneOf(['Individual', 'Company']),
    firstName: yup.string().when(['accountType', 'newAccountType'], {
      is: (accountType: AccountType, newAccountType: NewAccountType) =>
        isNewRecipient(accountType) &&
        newAccountType?.toLowerCase() === 'individual',
      then: (schema) =>
        schema
          .trim()
          .required(translateError('firstName', 'required'))
          .matches(REGEX.name, translateError('firstName', 'invalidCharacters'))
          .max(
            FIRST_NAME_MAX_LENGTH,
            translateError('firstName', 'maxCharacters', {
              number: FIRST_NAME_MAX_LENGTH,
            })
          ),
    }),
    lastName: yup.string().when(['accountType', 'newAccountType'], {
      is: (accountType: AccountType, newAccountType: NewAccountType) =>
        isNewRecipient(accountType) &&
        newAccountType?.toLowerCase() === 'individual',
      then: (schema) =>
        schema
          .trim()
          .required(translateError('lastName', 'required'))
          .matches(REGEX.name, translateError('lastName', 'invalidCharacters'))
          .max(
            LAST_NAME_MAX_LENGTH,
            translateError('lastName', 'maxCharacters', {
              number: LAST_NAME_MAX_LENGTH,
            })
          ),
    }),
    companyName: yup.string().when(['accountType', 'newAccountType'], {
      is: (accountType: AccountType, newAccountType: NewAccountType) =>
        isNewRecipient(accountType) &&
        newAccountType?.toLowerCase() === 'company',
      then: (schema) =>
        schema
          .trim()
          .required(translateError('companyName', 'required'))
          .matches(
            REGEX.companyName,
            translateError('companyName', 'invalidCharacters')
          )
          .max(
            COMPANY_NAME_MAX_LENGTH,
            translateError('companyName', 'maxCharacters', {
              number: COMPANY_NAME_MAX_LENGTH,
            })
          ),
    }),
    countryCode: yup
      .string()
      .trim()
      .when('accountType', {
        is: (accountType: AccountType) => isNewRecipient(accountType),
        then: (schema) =>
          schema.required(translateError('countryCode', 'required')),
      }),
    currency: yup.string().when('accountType', {
      is: (accountType: AccountType) => isNewRecipient(accountType),
      then: (schema) => schema.required(translateError('currency', 'required')),
    }),
    bankDetails: yup.lazy((value) => {
      if (!isEmpty(value)) {
        const newEntries = Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: yup
              .string()
              .trim()
              .test(
                'validate',
                'Please enter a valid value',
                validateField(val)
              ),
          }),
          {}
        );
        return yup.object().shape(newEntries);
      }
      return yup.mixed().notRequired();
    }),
  });

  return { validationSchema };
};
