// Components - Atoms, Molecules, Organisms, Pages
import PPStatus, { StatusType } from '../../../../atoms/PPStatus';
// Hooks
import { useTranslations } from '../../../../../core/hooks';
// Types
import { PaymentStatus as PaymentStatusType } from '../../../../../core/types/PaymentTypes';

const paymentStatusToStatusMapping: Record<PaymentStatusType, StatusType> = {
  [PaymentStatusType.Draft]: 'draft',
  [PaymentStatusType.Processing]: 'pending',
  [PaymentStatusType.Sent]: 'pending',
  [PaymentStatusType.Cancelled]: 'cancelled',
  [PaymentStatusType.Received]: 'completed',
};

interface PaymentStatusProps {
  status: PaymentStatusType;
}

const PaymentStatus = (props: PaymentStatusProps) => {
  const { status } = props;

  const { translate } = useTranslations();

  return (
    <PPStatus status={paymentStatusToStatusMapping[status]}>
      {translate(`statuses.payment.${status.toLowerCase()}`)}
    </PPStatus>
  );
};

export default PaymentStatus;
