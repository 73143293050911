import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query/react';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBStepPanel from '../../../organisms/BBStepPanel';
import AccountToBeFundedForm from './AccountToBeFundedForm';
import PageLoader from '../../../organisms/PageLoader';
import NotFoundRedirect from '../../../organisms/NotFoundRedirect';
import {
  MainContainer,
  StyledPageTitle,
} from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks/useTranslations';
// Types
import { RouterParams } from './types';
// API Wrappers
import { useGetPaymentRequestByIdQuery } from '../../../../redux/api';
// Utils
import { isNotFoundError } from '../../../../core/utils/Errors';

const AccountToBeFunded: FC = () => {
  const { transactionId, paymentRequestId } = useParams<RouterParams>();

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const [showProtectingFromFraudSection, setShowProtectingFromFraudSection] =
    useState(false);

  const activeStep: number = 1;

  const { data, isLoading, error } = useGetPaymentRequestByIdQuery(
    paymentRequestId ? Number(paymentRequestId) : skipToken
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <>
      {!showProtectingFromFraudSection && (
        <StyledPageTitle
          variant="h3"
          type="bold"
          text={translate('paymentRequest.accountToBeFunded.title')}
          layout={layout}
        />
      )}
      <MainContainer layout={layout}>
        <AccountToBeFundedForm
          activeStep={activeStep}
          transactionId={transactionId ?? ''}
          paymentRequestId={paymentRequestId ?? ''}
          showProtectingFromFraudSection={showProtectingFromFraudSection}
          setShowProtectingFromFraudSection={setShowProtectingFromFraudSection}
          paymentRequestData={data}
        />
        {!showProtectingFromFraudSection && (
          <BBStepPanel
            activeStep={activeStep}
            stepperType={'payment-request'}
            idValues={{
              transactionId: transactionId,
              paymentRequestId: paymentRequestId,
            }}
          />
        )}
      </MainContainer>
    </>
  );
};

export default AccountToBeFunded;
