// Types
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { UserProfile } from '../../core/types/UserProfileTypes';
// API Wrappers
import { api } from './api';
//utils
import { LOGIN_ERROR_MESSAGE } from '../../core/utils/Constants/Constants';

const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<UserProfile, void>({
      query: () => '/propertypay-services/profile/',
      providesTags: ['Profile'],
      transformErrorResponse: (result: FetchBaseQueryError) => {
        if (Number(result?.status) >= 500) {
          return {
            status: result?.status || 500,
            error: LOGIN_ERROR_MESSAGE,
          };
        }
        return result;
      },
    }),
  }),
});

export const { useGetProfileQuery } = profileApi;
