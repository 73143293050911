import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

// Components - Atoms, Molecules, Organisms, Pages
import PaymentMethodContent from './PaymentMethodContent';
import PageLoader from '../../../../organisms/PageLoader';
import NotFoundRedirect from '../../../../organisms/NotFoundRedirect';
// API Wrappers
import {
  useGetPaymentQuery,
  useGetPaymentRequestByIdQuery,
} from '../../../../../redux/api';
// Utils
import { parseNumber } from '../../../../../core/utils/Parse';
import { isNotFoundError } from '../../../../../core/utils/Errors';

type RouterParams = {
  paymentRequestId: string;
  paymentId: string;
};

const PaymentMethod = () => {
  const params = useParams<RouterParams>();
  const paymentId = parseNumber(params.paymentId);
  const paymentRequestId = parseNumber(params.paymentRequestId);

  const paymentQuery = useGetPaymentQuery(paymentId ?? skipToken);
  const paymentRequestQuery = useGetPaymentRequestByIdQuery(
    paymentQuery.data?.paymentRequest?.id ?? paymentRequestId ?? skipToken
  );

  const { data: payment } = paymentQuery;
  const { data: paymentRequest } = paymentRequestQuery;

  const error: unknown = paymentQuery.error || paymentRequestQuery.error;
  const isLoading: boolean =
    paymentQuery.isLoading || paymentRequestQuery.isLoading;

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundRedirect />;
  }

  return (
    <PaymentMethodContent payment={payment} paymentRequest={paymentRequest} />
  );
};

export default PaymentMethod;
