import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import CellText from './CellText';

export const TransactionRef = styled(CellText)(({ theme }) => {
  const { colours } = theme;

  return {
    color: colours.text.textWeaker,
  };
});
