//Types
import {
  Disbursement,
  DisbursementRequest,
  DisbursementStatus,
} from '../../../../core/types/DisbursementTypes';
import { PaymentMethods } from '../../../../core/types/PaymentRequestsTypes';
import { WalletWithUserDetails } from '../../../../core/types/WalletTypes';
import { DisbursementDetailsFormValues } from './types';
//Utils
import { DEFAULT_PAYMENT_VALUE_CURRENCY } from '../../../../core/utils/Constants/Constants';

export const mapDisbursementsToFormValues = (
  disbursementDetails: Disbursement
): DisbursementDetailsFormValues => {
  const {
    paymentType,
    paymentTypeDescription,
    amount,
    currency,
    paidFromWallet,
  } = disbursementDetails;

  return {
    walletId: paidFromWallet.walletId,
    paymentType: paymentType,
    paymentTypeDescription: paymentTypeDescription,
    paymentValue: String(amount) ?? '',
    paymentValueCurrency: currency ?? DEFAULT_PAYMENT_VALUE_CURRENCY,
  };
};

const verifyIds = (id: string) => {
  if (!id) {
    return null;
  }
  return !isNaN(Number(id)) ? Number(id) : null;
};

export const mapFormValuesToDisbursementUpsertRequest = (
  formData: DisbursementDetailsFormValues,
  walletDetails: WalletWithUserDetails[],
  transactionId: string,
  disbursementId: string
): DisbursementRequest => {
  const {
    walletId,
    paymentValue,
    paymentValueCurrency,
    paymentType,
    paymentTypeDescription,
  } = formData;

  const selectedWalletDetails = walletDetails.filter(
    (wallet) => wallet.walletId === walletId
  )?.[0];

  const disbursementApiData = {
    id: verifyIds(disbursementId),
    transaction: { id: verifyIds(transactionId) },
    amount: Number(paymentValue),
    currency: paymentValueCurrency,
    paidFromWallet: selectedWalletDetails,
    fraudAlert: false,
    paymentType,
    paymentTypeDescription,
  };

  return disbursementApiData;
};
