//Components
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

//Utils
import { LayoutProps } from '../../../core/types/LayoutProps';

interface LoginContainerProps extends LayoutProps {
  containerSize?: string;
}

export const LoginContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'containerSize',
})<LoginContainerProps>(({ theme, layout, containerSize }) => {
  const {
    colours: {
      text: { textWeak },
    },
    dimensions: {
      spacing: { xxSmall, large, xLarge },
    },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  const isLarge = containerSize === 'large';

  return {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    gap: xLarge,
    maxWidth: isDesktopLayout
      ? isLarge
        ? '700px'
        : '480px'
      : isTabletLayout
        ? '400px'
        : '100%',
    '& .header': {
      marginTop: large,
      marginBottom: xxSmall,
    },
    '& .subtitle': {
      color: textWeak,
    },
    '& .submitButton': {
      width: '100%',
      marginBottom: xLarge,
    },
  };
});

export const TextWithLinkWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxxSmall },
    },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'flex-start',
    gap: xxxSmall,
    flexWrap: 'wrap',
  };
});

export const FormInputContainer = styled(Box)<LayoutProps>(({
  layout,
  theme,
}) => {
  const {
    dimensions: {
      spacing: { xLarge, xxxLarge, medium, small },
    },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: small,
    marginBottom: isDesktopLayout
      ? `${xxxLarge}px`
      : isTabletLayout
        ? `${xLarge}px`
        : `${medium}px`,
  };
});
