import { useSearchParams } from 'react-router-dom';

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search') ?? '';

  const setSearch = (searchValue: string) => {
    if (!searchValue) {
      searchParams.delete('search');
    } else {
      searchParams.set('search', searchValue);
    }
    setSearchParams(searchParams);
  };

  return { search, setSearch };
};
