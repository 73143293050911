import { styled, Theme } from '@mui/material/styles';
import { memo, ReactNode } from 'react';

export type StatusType =
  | 'draft'
  | 'open'
  | 'pending'
  | 'completed'
  | 'cancelled';

const getStatusColor = (colours: Theme['colours'], status: StatusType) => {
  if (status === 'cancelled') {
    return colours.backgrounds.error;
  }

  if (status === 'open') {
    return colours.backgrounds.notice;
  }

  if (status === 'completed') {
    return colours.backgrounds.success;
  }

  if (status === 'pending') {
    return colours.backgrounds.pending;
  }

  return colours.backgrounds.bgMedium;
};

interface StatusDotOwnerState {
  status: StatusType;
}

export const StatusDot = styled('span')<{ ownerState: StatusDotOwnerState }>(({
  theme,
  ownerState,
}) => {
  const { status } = ownerState;
  const {
    colours,
    dimensions: { radius },
  } = theme;

  return {
    display: 'inline-block',
    borderRadius: radius.circle,
    height: 8,
    width: 8,
    backgroundColor: getStatusColor(colours, status),
  };
});

export const StatusLabel = styled('p')(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    gap: spacing.xxSmall,
    margin: 0,
  };
});

interface PPStatusProps {
  status: StatusType;
  children: ReactNode;
}

const PPStatus = (props: PPStatusProps) => {
  const { status, children } = props;
  return (
    <StatusLabel>
      <StatusDot ownerState={{ status }} data-testid="pp-status-dot" />
      {children}
    </StatusLabel>
  );
};

export default memo(PPStatus);
