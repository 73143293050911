import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// Contexts
import { TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  WarningActions,
  WarningContainer,
  WarningIconContainer,
  WarningText,
} from '../shared/styles';
import BBText from '../../../../atoms/BBText';
import { Step, Steps } from './BankTransferWarningView.styles';
import ResponsiveButton from '../../../../organisms/ResponsiveButton';
// Hooks
import { useTranslations } from '../../../../../core/hooks';
// Types
import { Payment } from '../../../../../core/types/PaymentTypes';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import { getPaymentRecipientName } from './utils';

interface BankTransferWarningViewProps {
  onContinue: () => void;
  onBack: () => void;
  payment: Payment;
}

const BankTransferWarningView = (props: BankTransferWarningViewProps) => {
  const { onBack, onContinue, payment } = props;

  const { tenant } = useContext(TenantContext);
  const { translate } = useTranslations();

  const {
    formState: { isSubmitting },
  } = useFormContext();

  const WarningIcon = useMemo(
    () => getIcon(tenant, 'warningIllustration'),
    [tenant]
  );

  return (
    <WarningContainer>
      <WarningIconContainer>
        <WarningIcon />
      </WarningIconContainer>

      <BBText
        variant="h1"
        type="bold"
        text={translate(
          'buyer.createPayment.paymentDetails.bankTransferWarning.title'
        )}
      />

      <WarningText>
        {translate(
          'buyer.createPayment.paymentDetails.bankTransferWarning.description'
        )}
      </WarningText>
      <Steps>
        <Step>
          {translate(
            'buyer.createPayment.paymentDetails.bankTransferWarning.step1'
          )}
        </Step>
        <Step>
          {translate(
            'buyer.createPayment.paymentDetails.bankTransferWarning.step2'
          )}
        </Step>
        <Step>
          {translate(
            'buyer.createPayment.paymentDetails.bankTransferWarning.step3',
            { recipient: getPaymentRecipientName(payment) ?? 'Wallet' }
          )}
        </Step>
      </Steps>

      <WarningActions>
        <ResponsiveButton onClick={onContinue} disabled={isSubmitting}>
          {translate(
            'buyer.createPayment.paymentDetails.bankTransferWarning.acceptButtonText'
          )}
        </ResponsiveButton>
        <ResponsiveButton
          btnType="outlined"
          onClick={onBack}
          disabled={isSubmitting}
        >
          {translate(
            'buyer.createPayment.paymentDetails.bankTransferWarning.backButtonText'
          )}
        </ResponsiveButton>
      </WarningActions>
    </WarningContainer>
  );
};

export default BankTransferWarningView;
