import { createAction, createSlice } from '@reduxjs/toolkit';

// Types
import { ReduxState } from '../../core/types/ReduxStoreTypes';
// API Wrappers
import getProfileDetails from '../api/getProfileDetails';
// Constants
import { RESET_USER_PROFILE_DETAILS } from '../ActionTypeConstants';

import { initialState } from '../initialState';

export const resetUserProfileDetails = createAction<string>(
  RESET_USER_PROFILE_DETAILS
);

const userProfileDetailsSlice = createSlice({
  name: 'userProfileDetails',
  initialState: initialState.userProfileDetails,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetUserProfileDetails, () => initialState.userProfileDetails)

      // Get Funds and property details
      .addCase(getProfileDetails.pending, (state) => {
        state.userProfileDetailsLoading = 'loading';
      })

      .addCase(getProfileDetails.fulfilled, (state, { payload }) => {
        const {
          title,
          firstName,
          middleName,
          lastName,
          email,
          contactNo,
          titanCustomerNo,
          crmAccountId,
        } = payload.data;

        state.title = title;
        state.firstName = firstName;
        state.middleName = middleName;
        state.lastName = lastName;
        state.email = email;
        state.contactNo = contactNo;
        state.titanCustomerNo = titanCustomerNo;
        state.crmAccountId = crmAccountId;
        state.userProfileDetailsLoading = 'succeeded';
      })

      .addCase(getProfileDetails.rejected, (state, action) => {
        state.userProfileDetailsLoading = 'failed';
        state.error = action.error.message;
      });
  },
});

export const getUserProfileDetails = (state: ReduxState) =>
  state.userProfileDetails;

export default userProfileDetailsSlice.reducer;
