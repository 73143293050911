// Types
import {
  PaginatedResponse,
  UpdateThreeFaRequest,
  UpsertTransactionRequest,
  UpsertTransactionResponse,
} from '../../core/types/ApiTypes';
import { Disbursement } from '../../core/types/DisbursementTypes';
import {
  PbuDetails,
  ThreePaDetails,
  Transaction,
  TransactionStatus,
} from '../../core/types/TransactionTypes';
import { Nullable } from '../../core/types/UtilTypes';
// API Wrappers
import { api, apiError } from './api';

export interface GetTransactionsRequestData {
  page: number;
  pageSize: number;
  sortBy: string[];
  status?: TransactionStatus;
  searchTerm?: string;
  searchFields?: string[];
}

const transactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<
      PaginatedResponse<Transaction>,
      GetTransactionsRequestData
    >({
      query: (data) => {
        const {
          page,
          sortBy,
          pageSize,
          status,
          searchTerm,
          searchFields = [],
        } = data;

        const params = new URLSearchParams({
          page: String(page),
          pageSize: String(pageSize),
          status: status ?? '',
          searchTerm: searchTerm ?? '',
        });

        sortBy.forEach((sortByValue) => params.append('sortBy', sortByValue));
        searchFields.forEach((field) => params.append('searchField', field));

        return `propertypay-services/transactions?${params}`;
      },
      providesTags: [{ type: 'Transactions', id: 'LIST' }],
    }),

    getTransaction: builder.query<Transaction, Transaction['id']>({
      query: (id) => `propertypay-services/transactions/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Transactions', id }],
    }),

    upsertTransaction: builder.mutation<
      UpsertTransactionResponse,
      UpsertTransactionRequest
    >({
      query: (data) => ({
        url: 'propertypay-services/transactions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        ...(data.id ? [{ type: 'Transactions' as const, id: data.id }] : []),
      ],
    }),

    updateThreeFa: builder.mutation<Transaction, UpdateThreeFaRequest>({
      query: (data) => ({
        url: 'propertypay-services/transactions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Transactions', id: 'LIST' },
        ...(data.id ? [{ type: 'Transactions' as const, id: data.id }] : []),
      ],
    }),

    confirmTransaction: builder.mutation<null, Transaction['id']>({
      query: (id) => ({
        url: `propertypay-services/transactions/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Transactions', id: 'LIST' },
        { type: 'Transactions', id },
      ],
    }),

    getTransactionPbuDetails: builder.query<PbuDetails, Transaction['id']>({
      query: (id) => `propertypay-services/transactions/${id}/pbu-details`,
    }),

    getTransactionPbuDetailsByIds: builder.query<
      Nullable<PbuDetails>[],
      Transaction['id'][]
    >({
      queryFn: async (ids, api, _extrasOptions, baseQuery) => {
        const results = await Promise.all(
          ids.map((transactionId) =>
            baseQuery({
              url: `propertypay-services/transactions/${transactionId}/pbu-details`,
              method: 'GET',
            })
          )
        );

        results
          .filter((result) => Boolean(result.error))
          .forEach((errorResult) => {
            api.dispatch(apiError(errorResult.error));
          });

        const data = results.map(
          (result) => result.data as Nullable<PbuDetails>
        );

        return { data };
      },
    }),

    getDisbursementsByTransactionId: builder.query<Disbursement[], number>({
      query: (id) => `propertypay-services/transaction/${id}/disbursements`,
      providesTags: (_result, _error, id) => [
        { type: 'Disbursements', id: `Transaction-${id}` },
      ],
    }),

    getThreePaDetailsByTransactionId: builder.query<ThreePaDetails, number>({
      query: (id) => `propertypay-services/transactions/${id}/threepa-details`,
      providesTags: (_result, _error, id) => [
        { type: 'ThreePa', id: `Transaction-${id}` },
      ],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useUpsertTransactionMutation,
  useUpdateThreeFaMutation,
  useConfirmTransactionMutation,
  useGetTransactionPbuDetailsQuery,
  useGetTransactionPbuDetailsByIdsQuery,
  useGetDisbursementsByTransactionIdQuery,
  useGetThreePaDetailsByTransactionIdQuery,
} = transactionsApi;
