import { Box, styled } from '@mui/material';

//Components
import BBText from '../../../atoms/BBText';
import { FormContainer } from '../../CommonStyles/CommonStyles.styles';
import BBIconButton from '../../../atoms/BBIconButton/BBIconButton';

//Types
import { LayoutProps } from '../../../../core/types/LayoutProps';

//Utils
import { getH2MediumStyles } from '../../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export const Header = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xxSmall },
    },
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: xxSmall,
  };
});

export const SubTitle = styled(BBText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;
  return {
    color: textWeak,
  };
});

export const StyledFormContainer = styled(FormContainer)<LayoutProps>(({
  layout,
  theme,
}) => {
  return {
    alignItems: 'center',
    width: '100%',
  };
});

export const ActionButtonsContainer = styled(Box)<LayoutProps>(({
  theme,
  layout,
}) => {
  const {
    dimensions: {
      spacing: { xxSmall, large },
    },
  } = theme;
  const isDesktopLayout = layout === 'desktop';

  return {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: `${xxSmall}px ${large}px`,
    flexWrap: 'nowrap',
    width: isDesktopLayout ? '420px' : '100%',
    flexDirection: 'column',
    '& button': {
      width: '100%',
    },
  };
});

export const FormContent = styled(Box)(({ theme }) => {
  const {
    dimensions: {
      spacing: { xLarge },
    },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: xLarge,
  };
});

export const FieldSets = styled(Box)<LayoutProps>(({ layout, theme }) => {
  const {
    dimensions: {
      spacing: { xxxHuge, xHuge, xxxLarge, xSmall },
    },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'row',
    gap: xSmall,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& div': {
      '&.MuiFormControl-root': {
        display: 'flex',
        justifyContent: 'center',
        height: isTabletLayout
          ? `${xHuge}px`
          : isDesktopLayout
            ? `${xxxHuge}px`
            : `${xxxLarge}px`,
        width: isTabletLayout
          ? `${xHuge}px`
          : isDesktopLayout
            ? `${xxxHuge}px`
            : `${xxxLarge}px`,
        '& .MuiFilledInput-root': {
          padding: 0,
          '& input': {
            padding: '0',
            textAlign: 'center',
            ...getH2MediumStyles(theme),
          },
        },
      },
    },
  };
});

export const StyledCloseIcon = styled(BBIconButton)(({ theme }) => {
  const {
    dimensions: {
      spacing: { large },
    },
  } = theme;

  return {
    position: 'absolute',
    top: `${large}px`,
    right: `${large}px`,
  };
});
