import * as yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

// Hooks
import { useErrorTranslations } from '../../../../core/hooks';
// Types
import { Party } from './types';
// Constants
import { FULL_NAME_MAX_LENGTH } from '../../../../core/utils/Constants/Constants';

const phoneUtil = PhoneNumberUtil.getInstance();

const areUniqueParties = (parties: Party[]) => {
  const emails = parties.map((party) => party.email);

  return emails.length === new Set(emails).size;
};

const isValidPhoneNumber = (countryCode: string, phoneNumber: string) => {
  try {
    const region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode));
    const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, region);

    return phoneUtil.isValidNumber(phone);
  } catch {
    return false;
  }
};

export const useValidations = () => {
  const { translateError } = useErrorTranslations(
    'createTransaction.partyDetails.fields'
  );

  const validations = yup.object({
    parties: yup
      .array(
        yup.object({
          fullName: yup
            .string()
            .trim()
            .required(translateError('fullName', 'required'))
            .matches(
              /^\p{L}(\p{L}|\s|\p{Pd}|')*\p{L}$/gv,
              translateError('fullName', 'invalidCharacters')
            )
            .max(
              FULL_NAME_MAX_LENGTH,
              translateError('fullName', 'maxCharacters', {
                number: FULL_NAME_MAX_LENGTH,
              })
            ),

          email: yup
            .string()
            .email(translateError('email', 'invalid'))
            .required(translateError('email', 'required'))
            .test('unique', translateError('email', 'unique'), (_, context) => {
              const [, original] = context.from ?? [];
              const parties = original?.value.parties ?? [];

              return areUniqueParties(parties);
            }),

          countryCode: yup
            .string()
            .required(translateError('countryCode', 'required')),

          mobileNumber: yup
            .string()
            .required(translateError('mobileNumber', 'required'))
            .matches(/^\d+$/g, translateError('mobileNumber', 'invalid'))
            .test(
              'valid',
              translateError('mobileNumber', 'invalid'),
              (value, context) => {
                const { parent } = context;

                return isValidPhoneNumber(parent.countryCode, value);
              }
            ),
        })
      )
      .min(1)
      .required(),
    threeFa: yup.boolean().notRequired().default(false),
  });

  return { validations };
};
