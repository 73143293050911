import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FieldError } from 'react-hook-form';

// Types
import { ApiErrorWithDetails, RTKQueryError } from '../../types/ErrorTypes';

const isApiError = (error?: unknown): error is FetchBaseQueryError => {
  if (!error || typeof error !== 'object') {
    return false;
  }

  return 'status' in error;
};

export const isNotFoundError = (error?: unknown) => {
  return isApiError(error) && error.status === 404;
};

export const isBadRequestError = (
  error?: unknown
): error is FetchBaseQueryError => {
  return isApiError(error) && error.status === 400;
};

export const isFieldsValidationError = (
  error?: unknown
): error is RTKQueryError<ApiErrorWithDetails[]> => {
  return (
    isBadRequestError(error) &&
    'data' in error &&
    Array.isArray(error.data) &&
    error.data.some((error) => !error.details?.includes('uri='))
  );
};

export const createApiValidationErrorsMapper =
  <ApiData, FormData>(
    mapping: Partial<Record<keyof ApiData, keyof FormData>>
  ) =>
  (error?: unknown): Partial<Record<keyof FormData, FieldError>> => {
    if (!isFieldsValidationError(error)) {
      return {};
    }

    return error.data.reduce((errors, error) => {
      const field = mapping[error.message as keyof ApiData];

      if (!field) {
        return errors;
      }

      return {
        ...errors,
        [field]: {
          type: 'custom',
          message: error.details,
        },
      };
    }, {});
  };

export const buildNotFoundError = (message: string) => {
  return {
    status: 404,
    error: message,
  };
};
