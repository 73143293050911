import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

// Components - Atoms, Molecules, Organisms, Pages
import ConfirmationScreen from './ConfirmationScreen';
import CompletedScreen from './CompletedScreen';
//API wrappers
import { useGetDisbursementByIdQuery } from '../../../../redux/api/disbursements';
//Types
import { ConfirmationViewType, RouterParams } from './types';

const Confirmation = () => {
  const [viewType, setViewType] = useState<ConfirmationViewType>(
    'confirmation-screen'
  );

  const { disbursementId } = useParams<RouterParams>();

  const { data, isLoading, isFetching, error } = useGetDisbursementByIdQuery(
    disbursementId ? Number(disbursementId) : skipToken
  );

  let currentView: JSX.Element;

  useEffect(() => {
    if (viewType !== 'confirmation-screen') {
      setViewType('confirmation-screen');
    }
  }, []);

  const confirmation = (
    <ConfirmationScreen
      setViewType={setViewType}
      isFetching={isFetching}
      isLoading={isLoading}
      data={data}
      error={error}
    />
  );

  switch (viewType) {
    case 'confirmation-screen':
      currentView = confirmation;
      break;
    case 'completed-screen':
      currentView = (
        <CompletedScreen
          data={data}
          isFetching={isFetching}
          isLoading={isLoading}
          error={error}
        />
      );
      break;
    default:
      currentView = confirmation;
  }

  return <>{currentView}</>;
};

export default Confirmation;
