import { memo } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

// Components - Atoms, Molecules, Organisms, Pages
import BBConfirmationModal from '../../molecules/BBModal/BBConfirmationModal';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';

const NavigationBlocker = () => {
  const { translate } = useTranslations();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );

  useBeforeUnload((event) => {
    event.returnValue = true;
  });

  if (blocker.state !== 'blocked') {
    return null;
  }

  return (
    <BBConfirmationModal
      isOpen
      handleClose={() => blocker.reset()}
      message={translate('navigationBlocker.title')}
      additionMessage={translate('navigationBlocker.message')}
      primaryBtnLabel={translate('navigationBlocker.continueButtonText')}
      secondaryBtnLabel={translate('navigationBlocker.cancelButtonText')}
      handlePrimaryBtnClick={() => blocker.proceed()}
      handleSecondaryBtnClick={() => blocker.reset()}
    />
  );
};

export default memo(NavigationBlocker);
