import { Theme } from '@mui/material/styles';

import {
  TypographyStyleObject,
  TypographyTypes,
} from '../../types/TypographyTypes';

const getTypographyStyles = (
  variant: string | undefined,
  type: TypographyTypes,
  theme: Theme
): TypographyStyleObject => {
  const {
    displayHugeBold,
    displayLargeBold,
    displayMediumNorm,
    h1,
    h2,
    h3,
    body1,
    body2,
    subtitle1,
    subtitle2,
    buttonLarge,
    buttonMedium,
    buttonSmall,
  } = theme.customTypography;

  switch (variant) {
    case 'h1':
      switch (type) {
        case 'display-huge-bold':
          return displayHugeBold;

        case 'display-large-bold':
          return displayLargeBold;

        case 'display-medium-norm':
          return displayMediumNorm;

        default:
          return {
            ...h1.normal,
            ...(type === 'bold' && h1.bold),
            ...(type === 'medium' && h1.medium),
          };
      }
    case 'h2':
      return {
        ...h2.normal,
        ...(type === 'bold' && h2.bold),
        ...(type === 'medium' && h2.medium),
      };
    case 'h3':
      return {
        ...h3.normal,
        ...(type === 'bold' && h3.bold),
        ...(type === 'medium' && h3.medium),
      };
    case 'body1':
      return {
        ...body1.normal,
        ...(type === 'bold' && body1.bold),
        ...(type === 'medium' && body1.medium),
        ...(type === 'link' && body1.link),
        ...(type === 'alt-link' && body1.altLink),
        ...(type === 'button-large' && buttonLarge),
      };
    case 'body2':
      return {
        ...body2.normal,
        ...(type === 'bold' && body2.bold),
        ...(type === 'medium' && body2.medium),
        ...(type === 'link' && body2.link),
        ...(type === 'alt-link' && body2.altLink),
        ...(type === 'button-medium' && buttonMedium),
        ...(type === 'button-small' && buttonSmall),
      };

    case 'subtitle1':
      return {
        ...subtitle1.normal,
        ...(type === 'bold' && subtitle1.bold),
        ...(type === 'link' && subtitle1.link),
        ...(type === 'alt-link' && subtitle1.altLink),
      };
    case 'subtitle2':
      return {
        ...subtitle2.normal,
        ...(type === 'bold' && subtitle2.bold),
        ...(type === 'link' && subtitle2.link),
        ...(type === 'alt-link' && subtitle2.altLink),
      };
    default:
      // Defaults to Body1/Norm
      return body1.normal;
  }
};

export const getH1DisplayHugeBoldStyles = (
  theme: Theme
): TypographyStyleObject =>
  getTypographyStyles('h1', 'display-huge-bold', theme);

export const getH1DisplayLargeBoldStyles = (
  theme: Theme
): TypographyStyleObject =>
  getTypographyStyles('h1', 'display-large-bold', theme);

export const getH1DisplayMediumNormStyles = (
  theme: Theme
): TypographyStyleObject =>
  getTypographyStyles('h1', 'display-medium-norm', theme);

export const getH1BoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h1', 'bold', theme);

export const getH1NormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h1', 'normal', theme);

export const getH1MediumStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h1', 'medium', theme);

export const getH2BoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h2', 'bold', theme);

export const getH2NormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h2', 'normal', theme);

export const getH2MediumStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h2', 'medium', theme);

export const getH3BoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h3', 'bold', theme);

export const getH3NormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h3', 'normal', theme);

export const getH3MediumStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('h3', 'medium', theme);

export const getBody1BoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body1', 'bold', theme);

export const getBody1NormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body1', 'normal', theme);

export const getBody1MediumStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body1', 'medium', theme);

export const getBody2BoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body2', 'bold', theme);

export const getBody2NormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body2', 'normal', theme);

export const getBody2MediumStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('body2', 'medium', theme);

export const getBodySmallBoldStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('subtitle1', 'bold', theme);

export const getBodySmallNormalStyles = (theme: Theme): TypographyStyleObject =>
  getTypographyStyles('subtitle1', 'normal', theme);

export const getBodyExtraSmallBoldStyles = (
  theme: Theme
): TypographyStyleObject => getTypographyStyles('subtitle2', 'bold', theme);

export const getBodyExtraSmallNormalStyles = (
  theme: Theme
): TypographyStyleObject => getTypographyStyles('subtitle2', 'normal', theme);

export const getBody1ButtonLargeStyles = (
  theme: Theme
): TypographyStyleObject => getTypographyStyles('body1', 'button-large', theme);

export const getBody2ButtonMediumStyles = (
  theme: Theme
): TypographyStyleObject =>
  getTypographyStyles('body2', 'button-medium', theme);

export default getTypographyStyles;
