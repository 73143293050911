import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Components - Atoms, Molecules, Organisms, Pages
import BankTransferWarningView from './BankTransferWarningView';
import PaymentDetailsView from './PaymentDetailsView';
import NavigationBlocker from '../../../../organisms/NavigationBlocker';
// Validation Schema or files in the same folder
import { useValidations } from './useValidations';
// Types
import { FormValues } from './types';
import {
  Payment,
  PaymentMethodType,
} from '../../../../../core/types/PaymentTypes';
import { Wallet } from '../../../../../core/types/WalletTypes';
import { FxDealRate } from '../../../../../core/types/PricingTypes';
// API Wrappers
import { useUpsertPaymentMutation } from '../../../../../redux/api';
// Utils
import {
  getPaymentErrors,
  mapFormValuesToUpsertRequest,
  mapPaymentToFormValues,
} from './utils';
import { mapDealToUpsertRequest } from '../shared/utils';

interface PaymentDetailsContentProps {
  payment: Payment;
  wallet?: Wallet;
}

const PaymentDetailsContent = (props: PaymentDetailsContentProps) => {
  const { payment, wallet } = props;

  const [view, setView] = useState<'form' | 'warning'>('form');
  const [latestDeal, setLatestDeal] = useState<FxDealRate | undefined>();

  const navigate = useNavigate();
  const { validations } = useValidations({ payment, wallet });

  const [upsertPayment, { error }] = useUpsertPaymentMutation();

  const errors = useMemo(() => getPaymentErrors(error), [error]);
  const defaultValues = mapPaymentToFormValues(payment);

  const form = useForm<FormValues>({
    // @ts-ignore
    resolver: yupResolver(validations),
    mode: 'all',
    errors,
    defaultValues: {
      ...defaultValues,
      sendCurrency: wallet?.walletCurrency ?? defaultValues.sendCurrency,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = form;

  const shouldBlockNavigation: boolean = isDirty && !isSubmitting;

  const onBack = () => {
    navigate('../method');
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const { sendCurrency, recipientCurrency } = data;

      const isSameCurrency = sendCurrency === recipientCurrency;
      const quote = mapDealToUpsertRequest(latestDeal);

      await upsertPayment({
        ...mapFormValuesToUpsertRequest(data),
        ...quote,
        token: isSameCurrency ? null : quote.token,
        id: payment.id,
        paymentMethod: payment.paymentMethod,
        paymentRequestId: payment.paymentRequest?.id,
      }).unwrap();

      navigate('../confirmation');
    } catch {}
  };

  const onContinue = async () => {
    if (payment.paymentMethod === PaymentMethodType.ManualBankTransfer) {
      setView('warning');
      return;
    }

    await handleSubmit(onSubmit)();
  };

  return (
    <>
      <FormProvider {...form}>
        {view === 'form' && (
          <PaymentDetailsView
            onBack={onBack}
            onContinue={onContinue}
            payment={payment}
            wallet={wallet}
            onDealUpdate={setLatestDeal}
          />
        )}

        {view === 'warning' && (
          <BankTransferWarningView
            onBack={() => setView('form')}
            onContinue={handleSubmit(onSubmit)}
            payment={payment}
          />
        )}
      </FormProvider>

      {shouldBlockNavigation && <NavigationBlocker />}
    </>
  );
};

export default PaymentDetailsContent;
