import { Link } from 'react-router-dom';
import { useContext, useMemo } from 'react';

// Contexts
import {
  LayoutContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import BBSelectNew from '../../atoms/BBSelect';
import BBText from '../../atoms/BBText';
import BBTabs, { BBTab, BBTabList } from '../../molecules/BBTabs';
import BBSearch from '../../molecules/BBSearch/BBSearch';
import TransactionsTable from './TransactionsTable';
import {
  CreateTransactionButton,
  SearchContainer,
  TabsSection,
  TitleSection,
} from './DashboardPage.styles';
// Hooks
import { useFilters } from './useFilters';
import { useTranslations } from '../../../core/hooks/useTranslations';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { useTransactions } from './useTransactions';
import { useSearch } from './useSearch';
// Types
import { TransactionFilter } from '../../../core/types/TransactionTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface DashboardPageProps {
  filter: TransactionFilter;
}

export default function DashboardPage(props: DashboardPageProps) {
  const { filter } = props;

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const { translate } = useTranslations();
  const { filters, setFilter } = useFilters();
  const { page, setPage } = usePagination();
  const { sort, setSort } = useSort();
  const { search, setSearch } = useSearch();

  const { transactions, numberOfTransactions, isFetching } = useTransactions({
    page,
    sort,
    status: filter.status,
    search: search.trim(),
  });

  const isDesktopLayout: boolean = layout === 'desktop';
  const AddIcon = useMemo(() => getIcon(tenant, 'add'), [tenant]);

  return (
    <>
      <header>
        <TitleSection ownerState={{ layout }}>
          <BBText
            variant="h1"
            type="medium"
            text={translate('dashboard.title')}
          />

          <CreateTransactionButton
            btnType="secondary"
            size="medium"
            startIcon={<AddIcon />}
            component={Link}
            to="/create-transactions/transaction-details"
            ownerState={{ layout }}
          >
            {translate('dashboard.headerButtonText')}
          </CreateTransactionButton>
        </TitleSection>

        <TabsSection ownerState={{ layout }}>
          {isDesktopLayout && (
            <BBTabs value={filter.type}>
              <BBTabList onChange={(_, filter) => setFilter(filter)}>
                {filters.map((filter) => (
                  <BBTab
                    key={filter.value}
                    value={filter.value}
                    label={filter.label}
                  />
                ))}
              </BBTabList>
            </BBTabs>
          )}

          {!isDesktopLayout && (
            <BBSelectNew
              value={filter.type}
              onChange={setFilter}
              options={filters}
              isSearchable={false}
              placeholderLabel={translate('transactions.filterByTitle')}
              name="filter"
              variant="small"
            />
          )}

          <SearchContainer ownerState={{ layout }}>
            <BBSearch
              key={filter.status}
              value={search}
              onChange={setSearch}
              name="transactions-search"
              placeholderLabel={translate('dashboard.searchLabel')}
              size="small"
            />
          </SearchContainer>
        </TabsSection>
      </header>

      <TransactionsTable
        transactions={transactions}
        loading={isFetching}
        rowCount={numberOfTransactions}
        page={page}
        onPageChange={setPage}
        sort={sort}
        onSortChange={setSort}
      />
    </>
  );
}
