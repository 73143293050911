import { FC } from 'react';
import { styled } from '@mui/material/styles';

//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import { StyledFormTitle } from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import {
  PaymentMethods,
  PaymentRequestDetails,
} from '../../../../core/types/PaymentRequestsTypes';
// Utils
import { getBankAccountField } from '../ComponentUtils/utils';
import { getCountryName } from '../../../../core/utils/CountryData/GetCountryData';
// Constants
import CountryOptions from '../../../../core/utils/MockData/CountryMockData.json';
import {
  DEFAULT_FORMAT_VALUE,
  HIDDEN_VALUE_ASTERISK,
} from '../../../../core/utils/Constants/Constants';

interface AccountToFundDataProps {
  data?: PaymentRequestDetails;
}

const DataContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

const AccountToFundData: FC<AccountToFundDataProps> = (
  props: AccountToFundDataProps
) => {
  const { data } = props;

  const { translate } = useTranslations();

  const { getFieldValue } = getBankAccountField(data);

  const baseTranslationKey = 'paymentRequest.viewDetails';

  const cdWalletSummary = (
    <>
      <StyledFormTitle
        variant="body1"
        type="bold"
        text={translate(`${baseTranslationKey}.fundedTitle`)}
      />
      <StyledFormTitle
        variant="body2"
        type="normal"
        text={translate(`${baseTranslationKey}.fundedAccountCDWalletTitle`)}
      />
    </>
  );

  const savedRecipientsSummary = () => {
    const valueOne = getFieldValue('valueOne');
    const countryAccountDetails: string = `${
      getCountryName(CountryOptions, getFieldValue('country')) ??
      DEFAULT_FORMAT_VALUE
    } | ${HIDDEN_VALUE_ASTERISK} ${valueOne.substring(valueOne.length - 4)}`;

    const accountName: string =
      getFieldValue('bankAccountType')?.toLowerCase() === 'company'
        ? getFieldValue('companyName')
        : `${getFieldValue('firstName')} ${getFieldValue('lastName')}`;

    return (
      <>
        <StyledFormTitle
          variant="body1"
          type="bold"
          text={translate(`${baseTranslationKey}.fundedTitle`)}
        />
        <StyledFormTitle
          variant="body2"
          type="normal"
          text={translate(`${baseTranslationKey}.fundedAccountTitle`)}
        />

        <DataContainer>
          <BBText type="medium" variant="body2" text={accountName} />
          <BBText type="normal" variant="body2" text={countryAccountDetails} />
        </DataContainer>
      </>
    );
  };

  return data?.accountToBeFunded?.paymentMethod === PaymentMethods.Wallet
    ? cdWalletSummary
    : data?.accountToBeFunded?.paymentMethod === PaymentMethods.Bank
      ? savedRecipientsSummary()
      : null;
};

export default AccountToFundData;
