import { FC, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

//Context
import { LayoutContext } from '../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../atoms/BBText';
import {
  MainContainer,
  StyledPageTitle,
  Card,
  CardTitle,
  CardBody,
} from './ContactUsStyles.style';
// Hooks
import { useTranslations } from '../../../core/hooks/useTranslations';
//Constants
import {
  LOCATION_TEXT,
  GIVE_US_CALL_TEXT,
  EMAIL_TEXT,
  ADDRESS_TEXT,
  TIMING_TEXT,
} from '../../../core/utils/Constants/Constants';

interface ListItemType {
  id: number;
  label: string;
  value: string;
}

const ContactUs: FC = () => {
  const { layout } = useContext(LayoutContext);
  const { translate } = useTranslations();

  const CONTACT_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('contactUs.contactDetails.location'),
      value: LOCATION_TEXT,
    },
    {
      id: 1,
      label: translate('contactUs.contactDetails.giveCall'),
      value: GIVE_US_CALL_TEXT,
    },
    {
      id: 2,
      label: translate('contactUs.contactDetails.email'),
      value: EMAIL_TEXT,
    },
  ];

  const FIND_US_DETAILS: Array<ListItemType> = [
    {
      id: 0,
      label: translate('contactUs.findUs.address'),
      value: ADDRESS_TEXT,
    },
    {
      id: 1,
      label: translate('contactUs.findUs.timings'),
      value: TIMING_TEXT,
    },
  ];

  return (
    <>
      <StyledPageTitle
        variant="h1"
        type="medium"
        text={translate('contactUs.title')}
      />
      <MainContainer layout={layout}>
        <Card layout={layout}>
          <CardTitle>
            <BBText
              variant="body1"
              type="bold"
              text={translate('contactUs.contactDetails.title')}
            />
          </CardTitle>
          <CardBody>
            <List dense={true}>
              {CONTACT_DETAILS.map((item: ListItemType) => {
                return (
                  <ListItem key={item.id}>
                    <ListItemText primary={item.label} secondary={item.value} />
                  </ListItem>
                );
              })}
            </List>
          </CardBody>
        </Card>
        <Card layout={layout}>
          <CardTitle>
            <BBText
              variant="body1"
              type="bold"
              text={translate('contactUs.findUs.title')}
            />
          </CardTitle>
          <CardBody>
            <List dense={true}>
              {FIND_US_DETAILS.map((item: ListItemType) => {
                return (
                  <ListItem key={item.id}>
                    <ListItemText primary={item.label} secondary={item.value} />
                  </ListItem>
                );
              })}
            </List>
          </CardBody>
        </Card>
      </MainContainer>
    </>
  );
};
export default ContactUs;
