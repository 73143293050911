import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { LayoutContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import {
  ActionButtonsContainer,
  FormContainer,
  MainContainer,
  StyledPageTitle,
  SummaryField,
} from '../../../CommonStyles/CommonStyles.styles';
import BBButton from '../../../../atoms/BBButton';
import BBStepPanel from '../../../../organisms/BBStepPanel/BBStepPanel';
import DisbursementSummaryData from '../../ComponentUtils/DisbursementSummaryData';
import Page from '../../../../organisms/Page';
//Hooks
import { useInitialLoading, useTranslations } from '../../../../../core/hooks';
//Api wrappers
import { useConfirmDisbursementMutation } from '../../../../../redux/api/disbursements';
//Types
import { ConfirmationScreenProps, RouterParams } from '../types';

export default function ConfirmationScree(props: ConfirmationScreenProps) {
  const { setViewType, data, isFetching, isLoading, error } = props;

  const { id, disbursementId } = useParams<RouterParams>();

  const { translate } = useTranslations();
  const navigate = useNavigate();
  const { layout } = useContext(LayoutContext);

  const { isInitiallyLoading } = useInitialLoading({ isFetching, isLoading });

  const [confirmDisbursement] = useConfirmDisbursementMutation();

  //Translations
  const backBtnText: string = translate('steppers.backBtn');
  const continueBtnText: string = translate('steppers.continueBtn');

  const activeStep: number = 2;

  const handleContinueClick = async () => {
    if (!disbursementId) {
      return;
    }

    const result = await confirmDisbursement({
      disbursementId: Number(disbursementId),
      transactionId: Number(id),
    }).unwrap();
    result.id && setViewType('completed-screen');
  };
  return (
    <Page isLoading={isInitiallyLoading} error={error}>
      <StyledPageTitle
        variant="h3"
        type="bold"
        text={translate('disbursement.confirmation.heading')}
        layout={layout}
      />
      <MainContainer layout={layout}>
        <FormContainer layout={layout}>
          <SummaryField>
            <DisbursementSummaryData isEditable={true} data={data} />
          </SummaryField>
          <ActionButtonsContainer activeStep={activeStep} layout={layout}>
            <BBButton
              size="medium"
              btnType="outlined"
              type="button"
              onClick={() => navigate('../recipient')}
            >
              {backBtnText}
            </BBButton>
            <BBButton
              btnType="secondary"
              size="medium"
              type="button"
              onClick={handleContinueClick}
            >
              {continueBtnText}
            </BBButton>
          </ActionButtonsContainer>
        </FormContainer>
        <BBStepPanel
          activeStep={activeStep}
          stepperType={'disbursement'}
          idValues={{
            transactionId: id,
            disbursementId: disbursementId,
          }}
        />
      </MainContainer>
    </Page>
  );
}
