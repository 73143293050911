import { FC, ReactNode, ReactElement, forwardRef, Ref } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';

interface FullScreenDialogProps {
  open: boolean;
  children: ReactNode;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: FC<FullScreenDialogProps> = (props: FullScreenDialogProps) => {
  const { open, children } = props;

  return (
    <Dialog fullScreen open={open} data-testid="bb-full-screen-dialog" TransitionComponent={Transition}>
      {children}
    </Dialog>
  );
};

export default FullScreenDialog;