import { useContext } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import {
  getBody2NormalStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

type Sizes = 'small' | 'normal' | 'large';

interface StyledCheckboxProps extends CheckboxProps {
  type?: Sizes;
}

interface StyledFormControlLabelProps {
  type: Sizes;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledFormControlLabelProps>(({ theme, type }) => {
  return {
    '> span:nth-of-type(2)': {
      ...(type === 'small'
        ? {
            ...getBodySmallNormalStyles(theme),
          }
        : {
            ...getBody2NormalStyles(theme),
          }),
    },
  };
});

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledCheckboxProps>(({ theme, type }) => {
  const {
    colours: {
      borders: { input, active, selectionHover },
      backgrounds: { bg, bgAlt },
      text: { disabled, focus },
    },
    dimensions: {
      spacing: { xSmall, small, smallMedium, medium },
      radius,
    },
  } = theme;

  const outlineDimension: number =
    type === 'large' ? 30 : type === 'small' ? 22 : 26; // Token not available
  const iconDimension: number =
    type === 'large' ? medium : type === 'small' ? small : smallMedium;

  return {
    padding: 0,
    marginRight: xSmall,
    height: outlineDimension,
    width: outlineDimension,
    '&, svg': {
      transition: 'all 500ms',
    },
    '> svg': {
      width: iconDimension,
      height: iconDimension,
      borderRadius: radius.xSmall,
    },

    '&.Mui-focusVisible+ span': {
      color: focus,
    },

    '&:not(.Mui-checked)': {
      '> svg': {
        '> rect:nth-of-type(2), > path': {
          display: 'none',
        },
        '> rect:nth-of-type(1)': {
          // Token not available
          fill: 'none',
          x: 1,
          y: 1,
          rx: 1,
          stroke: input,
          strokeWidth: 1,
          width: iconDimension - 2,
          height: iconDimension - 2,
        },
      },
      ':hover': {
        '> svg > rect:nth-of-type(1)': {
          stroke: selectionHover,
        },
      },
      '&.Mui-focusVisible': {
        outline: `1.5px solid ${active}`, // Token not available
        borderRadius: radius.xSmall,
        transition: 'none',
      },

      '&.Mui-disabled': {
        '> svg > rect:nth-of-type(1)': {
          fill: bg,
        },
      },
    },

    // Checked
    '&.Mui-checked': {
      '> svg': {
        '> rect': {
          // Token not available
          x: 0.5,
          y: 0.5,
          rx: 0.5,
          width: iconDimension - (type === 'normal' ? 2 : 1),
          height: iconDimension - (type === 'normal' ? 2 : 1),

          ':nth-of-type(1)': {
            fill: active,
          },
          ':nth-of-type(2)': {
            stroke: active,
          },
        },
        '> path': {
          display: 'none',
          fill: bgAlt,
          ...(type === 'large'
            ? {
                '&.checkbox-large': {
                  display: 'initial',
                },
              }
            : type === 'normal'
              ? {
                  '&.checkbox-normal': {
                    display: 'initial',
                  },
                }
              : {
                  '&.checkbox-small': {
                    display: 'initial',
                  },
                }),
        },
      },
      '&.Mui-disabled': {
        '> svg': {
          '> rect:nth-of-type(1)': {
            fill: bg,
          },
          '> rect:nth-of-type(2)': {
            stroke: input,
          },
          '> path': {
            fill: disabled,
          },
        },
      },
      '&.Mui-focusVisible': {
        outline: `1.5px solid ${active}`, // Token not available
        borderRadius: radius.xSmall,
        transition: 'none',
      },
    },
  };
});

interface BBCheckboxProps extends StyledCheckboxProps {
  label: string;
}

const BBCheckbox = (props: BBCheckboxProps) => {
  const {
    label,
    type = 'normal',
    disabled = false,
    value,
    onChange,
    onBlur,
  } = props;

  const { tenant } = useContext(TenantContext);

  const CheckedCheckboxIcon = getIcon(tenant, 'checkedCheckbox');

  return (
    <StyledFormControlLabel
      type={type}
      label={label}
      control={
        <StyledCheckbox
          disableRipple
          type={type}
          icon={<CheckedCheckboxIcon data-testid="unchecked-checkbox-icon" />}
          checkedIcon={
            <CheckedCheckboxIcon data-testid="checked-checkbox-icon" />
          }
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          checked={value as boolean}
        />
      }
    />
  );
};

export default BBCheckbox;
