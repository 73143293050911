import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

// Contexts
import { LayoutContext } from '../../../../core/TenantProvider/contexts';
//Components - Atoms, Molecules, Organisms, Pages
import BBText from '../../../atoms/BBText';
import BBLabel, { VariantType } from '../../../atoms/BBLabel';
import BBCircularProgress from '../../../molecules/BBCircularProgress';
import { WeakText } from '../../CommonStyles/CommonStyles.styles';
// Hooks
import { useTranslations } from '../../../../core/hooks';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
import {
  PaymentRequestDetails,
  PaymentTrackingStatus,
} from '../../../../core/types/PaymentRequestsTypes';
// Utils
import {
  getFormattedAmount,
  getFormattedCurrencyValue,
} from '../../../../core/utils/Format';

interface PaymentProgressProps {
  data?: PaymentRequestDetails;
}

const Container = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing, radius },
    colours: { borders, backgrounds },
  } = theme;

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    padding: spacing.medium,
    flexDirection: isTabletLayout ? 'row' : 'column',
    gap: spacing.medium,
    flex: 1,
    borderRadius: radius.medium,
    border: `1px solid ${borders.border}`,
    backgroundColor: backgrounds.bgAlt,
    ...(isDesktopLayout && {
      maxWidth: '400px',
    }),
    ...(!isDesktopLayout && {
      alignSelf: 'stretch',
    }),
  };
});

const CircularProgressContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    alignItems: 'center',
  };
});

const StyledDivider = styled(Divider)(({ theme }) => {
  const {
    colours: {
      borders: { border },
    },
  } = theme;
  return {
    borderColor: border,
    width: 'auto',
    height: 1,
    margin: 0,
  };
});

const DetailsContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    flex: 1,
  };
});

const TabletViewContainer = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    dimensions: { spacing },
  } = theme;

  const isTabletLayout = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: isTabletLayout ? 'row' : 'column',
    gap: isTabletLayout ? spacing.xSmall : spacing.small,
  };
});

const DataContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

const LeftToPayContainer = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing, radius },
    colours: { backgrounds },
  } = theme;

  return {
    display: 'flex',
    padding: `${spacing.xxSmall}px ${spacing.xSmall}px`,
    flexDirection: 'column',
    borderRadius: radius.small,
    backgroundColor: backgrounds.bg,
  };
});

const LeftToPayWrapper = styled(Box)(({ theme }) => {
  const {
    dimensions: { spacing },
  } = theme;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
  };
});

const AmountWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'baseline',
  };
});

const paymentRequestTrackingStatusToTrackingStatusMapping: Record<
  PaymentTrackingStatus,
  VariantType
> = {
  [PaymentTrackingStatus.ON_TRACK]: 'success',
  [PaymentTrackingStatus.OFF_TARGET]: 'warning',
  [PaymentTrackingStatus.LATE]: 'error',
};

const PaymentProgress: FC<PaymentProgressProps> = (
  props: PaymentProgressProps
) => {
  const { data } = props;

  const { translate } = useTranslations();
  const { layout } = useContext(LayoutContext);

  const isDesktopLayout = layout === 'desktop';
  const isTabletLayout = layout === 'tablet';

  const baseTranslationKey = 'paymentRequest.viewDetails';

  const showLabel = (labelKey: string) => (
    <WeakText
      type="normal"
      variant="body2"
      text={translate(`${baseTranslationKey}.${labelKey}`)}
    />
  );

  const showValue = (value: string, type: 'normal' | 'medium' = 'normal') => (
    <BBText type={type} variant="body2" text={value} />
  );

  return (
    <Container layout={layout}>
      <CircularProgressContainer>
        {isDesktopLayout && (
          <BBText
            variant="body1"
            type="bold"
            text={translate(`${baseTranslationKey}.paymentProgress`)}
          />
        )}
        <BBCircularProgress
          size={isDesktopLayout ? 'normal' : 'small'}
          value={data?.percentageComplete ?? 0}
          text={translate(`${baseTranslationKey}.totalPaid`)}
        />
      </CircularProgressContainer>
      {!isTabletLayout && (
        <StyledDivider orientation="horizontal" variant="middle" flexItem />
      )}
      <DetailsContainer>
        <TabletViewContainer layout={layout}>
          <DataContainer>
            {showLabel('totalValue')}
            {showValue(
              getFormattedCurrencyValue(data?.payAmount, data?.currency)
            )}
          </DataContainer>
          <DataContainer>
            {showLabel('paidToDate')}
            {showValue(
              getFormattedCurrencyValue(data?.paidToDate, data?.currency)
            )}
          </DataContainer>
        </TabletViewContainer>
        <LeftToPayContainer>
          {showLabel('leftToPay')}
          <LeftToPayWrapper>
            <AmountWrapper>
              <BBText
                type="bold"
                variant="h3"
                text={getFormattedAmount(data?.leftToPay, data?.currency)}
              />
              <BBText
                type="medium"
                variant="body2"
                text={' ' + data?.currency}
              />
            </AmountWrapper>
            {/* Logic for the label is yet to be implemented */}
            {data?.paymentTrackingStatus && (
              <BBLabel
                variant={
                  paymentRequestTrackingStatusToTrackingStatusMapping[
                    data.paymentTrackingStatus
                  ]
                }
                size="small"
              >
                {translate(
                  `${baseTranslationKey}.${data.paymentTrackingStatus}`
                )}
              </BBLabel>
            )}
          </LeftToPayWrapper>
        </LeftToPayContainer>
      </DetailsContainer>
    </Container>
  );
};

export default PaymentProgress;
