import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import BBLink from '../../../atoms/BBLink';

export const AddPartyLink = styled(BBLink)(({ theme }) => {
  const {
    colours,
    dimensions: { spacing },
  } = theme;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing.xxxSmall,

    svg: {
      width: 20,
      height: 20,
      fill: colours.icon.active,
    },
  };
});

export const SectionHeader = styled('header')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});
