// Types
import { GetFxDealRateRequest } from '../../core/types/ApiTypes';
import {
  FxDealRate,
  FxQuote,
  FXQuoteRequest,
} from '../../core/types/PricingTypes';
// API Wrappers
import { api } from './api';

const pricingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFxDealRate: builder.query<FxDealRate, GetFxDealRateRequest>({
      query: (data) => ({
        url: `propertypay-services/pricingEngine/getFxDealRate/transaction/${data.transactionId}`,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 0,
    }),

    getQuote: builder.query<FxQuote, FXQuoteRequest>({
      query: ({ token, transactionId }) => ({
        url: `propertypay-services/pricingEngine/token/${token}/transaction/${transactionId}`,
      }),
    }),
  }),
});

export const { useLazyGetFxDealRateQuery, useGetQuoteQuery } = pricingApi;
