import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import zipObject from 'lodash/zipObject';
import isEmpty from 'lodash/isEmpty';

// Types
import { Invitation } from './types';
// RTK Slice
import { getUserProfileDetails } from '../../../../redux/modules/userProfileDetailsSlice';
// API Wrappers
import {
  useGetAccountPaymentRequestsQuery,
  useGetTransactionPbuDetailsByIdsQuery,
} from '../../../../redux/api';

export const useInvitations = () => {
  const { crmAccountId } = useSelector(getUserProfileDetails);
  const { data: accountPaymentRequests = [], ...accountPaymentRequestsQuery } =
    useGetAccountPaymentRequestsQuery(crmAccountId ?? skipToken);

  const transactionIds = compact(
    uniq(
      accountPaymentRequests.map(
        (paymentRequest) => paymentRequest.transaction?.id
      )
    )
  );

  const { data: pbus = [], ...pbuDetailsQuery } =
    useGetTransactionPbuDetailsByIdsQuery(
      isEmpty(transactionIds) ? skipToken : transactionIds
    );

  const transactionToPbuDetailsMapping = zipObject(transactionIds, pbus);

  const invitations = accountPaymentRequests.map<Invitation>(
    (paymentRequest) => {
      const transactionId = paymentRequest.transaction?.id;

      return {
        paymentRequest,
        pbu: transactionId
          ? (transactionToPbuDetailsMapping[transactionId] ?? null)
          : null,
      };
    }
  );

  return {
    invitations,
    isLoading:
      accountPaymentRequestsQuery.isLoading || pbuDetailsQuery.isLoading,
    isFetching:
      accountPaymentRequestsQuery.isFetching || pbuDetailsQuery.isFetching,
  };
};
